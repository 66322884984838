import { DrawItem, LottoPoolItem, ProductModes } from 'store/modules/games/types'
import { Currencies } from 'store/modules/currencies/types'

export enum LottoGameFlowActionsType {
  CLEAR_NUMBERS = 'lottoGameFlow/CLEAR_NUMBERS',
  ADD_NUMBERS = 'lottoGameFlow/ADD_NUMBERS',
  CLEAR_ACTIVE_LOTTERY = 'lottoGameFlow/CLEAR_ACTIVE_LOTTERY',
  SET_ACTIVE_LOTTERY = 'lottoGameFlow/SET_ACTIVE_LOTTERY',
  SAVE_LOTTO_NUMBERS_HISTORY = 'lottoGameFlow/SAVE_LOTTO_NUMBERS_HISTORY',
  ADD_LOTTO_LINE = 'lottoGameFlow/ADD_LOTTO_LINE',
  SET_CURRENT_LOTTO_LINES = 'lottoGameFlow/SET_CURRENT_LOTTO_LINES',
  CLEAR_CURRENT_LOTTO_LINES = 'lottoGameFlow/CLEAR_CURRENT_LOTTO_LINES',
  UPDATE_LOTTO_TICKET_DRAW_ACTION = 'lottoGameFlow/UPDATE_LOTTO_TICKET_DRAW_ACTION',
  ADD_LOTTO_LINES = 'lottoGameFlow/ADD_LOTTO_LINES',
  LINE_IS_SENDING = 'lottoGameFlow/LINE_IS_SENDING',
}

export const PAYOUT_RECORD = {
  GC: 'goldPayout',
  SC: 'sweepstakePayout',
  USD: 'fiatPayout',
}

export const HOW_TO_WIN_TIRES_TABLE = {
  [ProductModes.FREE]: [
    [[5, 2], 'Balls'],
    [[5, 1], 'Balls'],
    [[5], 'Balls'],
    [[4, 1], 'or', [2], 'Balls'],
    [[4], 'Balls'],
    [[3, 1], 'or', [2], 'Balls'],
    [[3], 'Balls'],
    [[2, 1], 'or', [2], 'Balls'],
    [[2], 'Balls'],
    [[1, 1], 'or', [2], 'Balls'],
    [[1], 'Ball'],
    [[0, 1], 'or', [2], 'Balls'],
  ],
  [ProductModes.GOLD_SWEEPSTAKE]: [
    [[5, 1], 'Balls'],
    [[5], 'Balls'],
    [[4, 1], 'Balls'],
    [[4], 'Balls'],
    [[3, 1], 'Balls'],
    [[3], 'Balls'],
    [[2, 1], 'Balls'],
    [[2], 'Balls'],
    [[1, 1], 'Balls'],
    [[1], 'Ball'],
    [[0, 1], 'Ball'],
    [[], 'No Matches'],
  ],
  [ProductModes.GOLD_SWEEPSTAKE]: [
    [[5, 1], 'Balls'],
    [[5], 'Balls'],
    [[4, 1], 'Balls'],
    [[4], 'Balls'],
    [[3, 1], 'Balls'],
    [[3], 'Balls'],
    [[2, 1], 'Balls'],
    [[2], 'Balls'],
    [[1, 1], 'Balls'],
    [[1], 'Ball'],
    [[0, 1], 'Ball'],
    [[], 'No Matches'],
  ],
}

export const LOTTO_PRIZES_PAYOUT = {
  [ProductModes.FREE]: [
    ['Tier 1', 'Match 5 + 2 Bonus Balls'],
    ['Tier 2', 'Match 5 + 1 Bonus Ball'],
    ['Tier 3', 'Match 5'],
    ['Tier 4', 'Match 4 + 1 or 2 Bonus Balls'],
    ['Tier 5', 'Match 4'],
    ['Tier 6', 'Match 3 + 1 Bonus Ball or 2 Bonus Balls'],
    ['Tier 7', 'Match 3'],
    ['Tier 8', 'Match 2 + 1 or 2 Bonus Balls'],
    ['Tier 9', 'Match 2'],
    ['Tier 10', 'Match 1 + 1 or 2 Bonus Balls'],
    ['Tier 11', 'Match 1'],
    ['Tier 12', 'Match 0 + 1 or 2 Bonus Balls'],
  ],
  [ProductModes.GOLD_SWEEPSTAKE]: [
    ['Tier', 'Match 5 + 1 Bonus Ball'],
    ['Tier 2', 'Match 5'],
    ['Tier 3', 'Match 4 + 1 Bonus Ball'],
    ['Tier 4', 'Match 4'],
    ['Tier 5', 'Match 3 + 1 Bonus Ball'],
    ['Tier 6', 'Match 3'],
    ['Tier 7', 'Match 2 + 1 Bonus Ball'],
    ['Tier 8', 'Match 2'],
    ['Tier 9', 'Match 1 + 1 Bonus Ball'],
    ['Tier 10', 'Match 1'],
    ['Tier 11', 'Match 0 + 1 Bonus Ball'],
    ['Tier 12', 'No Matches'],
  ],
  [ProductModes.GOLD]: [
    ['Tier', 'Match 5 + 1 Bonus Ball'],
    ['Tier 2', 'Match 5'],
    ['Tier 3', 'Match 4 + 1 Bonus Ball'],
    ['Tier 4', 'Match 4'],
    ['Tier 5', 'Match 3 + 1 Bonus Ball'],
    ['Tier 6', 'Match 3'],
    ['Tier 7', 'Match 2 + 1 Bonus Ball'],
    ['Tier 8', 'Match 2'],
    ['Tier 9', 'Match 1 + 1 Bonus Ball'],
    ['Tier 10', 'Match 1'],
    ['Tier 11', 'Match 0 + 1 Bonus Ball'],
    ['Tier 12', 'No Matches'],
  ],
}

export type PAYOUT_TABLE_TYPE<FType = Array<number>> = [FType, string, string]

export type LottoLine = {
  numbers: Array<number>
  additionalNumbers: Array<number>
  disabled?: boolean
}

export type LottoLines = {
  lines: Array<LottoLine>
  transactionId?: string
}

export enum LottoArrayTypes {
  NUMBERS = 'numbers',
  BONUS_NUMBERS = 'additionalNumbers',
}

export type LottoPick = {
  id: number
  lines: Array<LottoLine>
  winningAmount: number
}

export type CurrentLottoPick = {
  drawId: string
  transactionId: string
  lines: Array<LottoLine>
  currency: Currencies
}

export type LottoFlowTypes = 'quickpick' | 'selectNumbers'

export type LottoNumbersHistoryType = {
  id: string
  status: string
  drawDate: string
  closingDate: string
  freePools: LottoPoolItem | null
  realPools: LottoPoolItem | null
  numbers: Array<number>
  additionalNumbers: Array<number>
}

export interface AddNumbersAction {
  type: LottoGameFlowActionsType.ADD_NUMBERS
  payload: LottoLines
}

export interface AddLottoLineAction {
  type: LottoGameFlowActionsType.ADD_LOTTO_LINE
  payload: {
    line: LottoLine
  }
}
export interface SaveLottoNumbersHistory {
  type: LottoGameFlowActionsType.SAVE_LOTTO_NUMBERS_HISTORY
  payload: Array<LottoNumbersHistoryType>
}

export interface ClearLottoLinesAction {
  type: LottoGameFlowActionsType.CLEAR_CURRENT_LOTTO_LINES
}

export interface ClearNumbersAction {
  type: LottoGameFlowActionsType.CLEAR_NUMBERS
  payload: {
    lines: Array<LottoLine>
  }
}

export interface AddLottoLinesAction {
  type: LottoGameFlowActionsType.ADD_LOTTO_LINES
  payload: {
    lines: Array<LottoLine>
  }
}

export interface ClearActiveLotteryAction {
  type: LottoGameFlowActionsType.CLEAR_ACTIVE_LOTTERY
}

export interface SetActiveLotteryAction {
  type: LottoGameFlowActionsType.SET_ACTIVE_LOTTERY
  payload: {
    activeLottery: string
    closestDraw: DrawItem
  }
}

export interface SetCurrentLottoLinens {
  type: LottoGameFlowActionsType.SET_CURRENT_LOTTO_LINES
  payload: {
    lines: Array<LottoLine>
    transactionId: string
  }
}

export interface SetIsSendingAction {
  type: LottoGameFlowActionsType.LINE_IS_SENDING
  payload: {
    isSending: boolean
  }
}

export interface UpdateLottoTicketDrawAction {
  type: LottoGameFlowActionsType.UPDATE_LOTTO_TICKET_DRAW_ACTION
  payload: {
    transactionId: string
    lines: Array<LottoLine>
  }
}

export type LottoGameFlowArrays = {
  numbers: Array<number>
  bonusNumbers: Array<number>
}

export type Actions =
  | AddNumbersAction
  | ClearNumbersAction
  | SetActiveLotteryAction
  | ClearActiveLotteryAction
  | SaveLottoNumbersHistory
  | SetCurrentLottoLinens
  | AddLottoLineAction
  | ClearLottoLinesAction
  | UpdateLottoTicketDrawAction
  | AddLottoLinesAction
  | SetIsSendingAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export type LottoGameFlowState = {
  readonly activeLottery: string | null
  readonly closestDraw: DrawItem | null
  readonly lottoNumbersHistory: Array<LottoNumbersHistoryType> | null
  readonly lottoLines: LottoLines
  readonly isSending: boolean
}
