import useMediaQuery, { Options } from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

export default function userMediaQuery(
  query: 'lg' | 'md' | 'sm' | 'xs',
  direction?: 'down' | 'up' | 'only',
  options?: Options
) {
  const d = direction || 'down'
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints[d](query), options && options)

  return match
}
