import { Reducer } from 'redux'
import { PaymentsFlowActionTypes, PyamentsFlowState, Actions } from './types'

const initialState: PyamentsFlowState = {
  savedCards: null,
  defaultCard: null,
  isPaymentFlowInProcess: false,
}

const reducer: Reducer<PyamentsFlowState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case PaymentsFlowActionTypes.SAVE_PAYMENTS_METHODS: {
      return {
        ...state,
        savedCards: action.payload.methods,
      }
    }

    case PaymentsFlowActionTypes.REMOVE_SAVED_CARD: {
      return {
        ...state,
        savedCards: action.payload.methods,
      }
    }
    case PaymentsFlowActionTypes.SET_DEFAULT_CARD: {
      return {
        ...state,
        defaultCard: action.payload.code,
      }
    }
    case PaymentsFlowActionTypes.SET_IS_PAYMENT_FLOW_IN_PROCESS: {
      return {
        ...state,
        isPaymentFlowInProcess: action.payload.isPaymentFlowInProcess,
      }
    }

    case PaymentsFlowActionTypes.CLEAR_PAYMENTS_STATE: {
      return initialState
    }
    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as paymentsFlowReducer }
