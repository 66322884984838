import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import { isPreviewModeSelector } from 'store/modules/user/selectors'

const getdarkTheme = (state: ApplicationState) => state.appConfig.darkTheme
const appInitialized = (state: ApplicationState) => state.appConfig.init
const isNativeApp = (state: ApplicationState) => state.appConfig.nativeApp
const getAndroidDeviceId = (state: ApplicationState) => state.appConfig.androidDeviceId
const getApps = (state: ApplicationState) => state.appConfig.apps
const getPasswordPolicy = (state: ApplicationState) => state.appConfig.passwordPolicy
const getSweepstakeEnabled = (state: ApplicationState) => state.appConfig.sweepstakeEnabled
const getPushToken = (state: ApplicationState) => state.appConfig.pushToken
const getForcementMode = (state: ApplicationState) => state.appConfig.forcement
const getIsPWAMessageOpen = (state: ApplicationState) => state.appConfig.isPWAMessageOpen
const getAdvertisingId = (state: ApplicationState) => state.appConfig.advertisingId
const getTrackingId = (state: ApplicationState) => state.appConfig.trackingId
const getOsVersion = (state: ApplicationState) => state.appConfig.osVersion
const getAppVersion = (state: ApplicationState) => state.appConfig.appVersion
const getLocationData = (state: ApplicationState) => state.appConfig.location

export const getdarkThemeSelector = createSelector(getdarkTheme, (darkTheme: boolean) => darkTheme)

export const isAppInitializedSelector = createSelector(appInitialized, (initialized: boolean) => initialized)
export const getIsPWAMessageOpenSelector = createSelector(getIsPWAMessageOpen, (isOpen) => isOpen)

export const isNativeAppSelector = createSelector(isNativeApp, (native) => native)
export const getAndroidDeviceIdSelector = createSelector(getAndroidDeviceId, (deviceId) => deviceId)

export const getAppsSelector = createSelector(getApps, (apps) => apps)

export const getFacebookAppIdSelector = createSelector(getAppsSelector, (apps) => apps.facebook?.id)
export const getGoogleAppIdSelector = createSelector(getAppsSelector, (apps) => apps?.googleVerifier?.id)

export const getWorldpayAppIdSelector = createSelector(getAppsSelector, (apps) => apps.worldpay?.id)
export const getStripeAppIdSelector = createSelector(getAppsSelector, (apps) => apps.stripe?.id)
export const getPayWithMyBankConfigSelector = createSelector(getAppsSelector, (apps) => apps?.payWithMyBank)
export const getAdyenIdSelector = createSelector(getAppsSelector, (apps) => apps?.adyen?.id)
export const getSpreedlyIdSelector = createSelector(getAppsSelector, (apps) => apps?.spreedly?.id)

export const getFiservGooglePayConfigSelector = createSelector(getAppsSelector, (apps) => apps?.fiservGooglePay)
export const getFiservApplePayConfigIdSelector = createSelector(
  getAppsSelector,
  (apps) => apps?.fiservApplePay?.merchantId
)
export const getFiservPaymentId = createSelector(getAppsSelector, (apps) => apps?.fiserv?.id)

export const getSkrillSelector = createSelector(getAppsSelector, (apps) => apps?.skrill)
export const getSeonSessionId = createSelector(getAppsSelector, (apps) => apps?.SEON_ID?.id)

export const getPaypalAppIdSelector = createSelector(getAppsSelector, (apps) => apps.paypal?.id)
export const getPrizeoutIdSelector = createSelector(getAppsSelector, (apps) => apps.prizeout?.id)
export const getPrizeoutKeySelector = createSelector(getAppsSelector, (apps) => apps.prizeout?.key)

export const getPasswordPolicySelector = createSelector(getPasswordPolicy, (pPolicy) => pPolicy)

export const sweepstakeEnabledSelector = createSelector(getSweepstakeEnabled, (sweepstakeEnabled) => sweepstakeEnabled)

export const getPushTokenSelector = createSelector(getPushToken, (token) => token)

export const getLocationDataSelector = createSelector(getLocationData, (location) => location)

export const getTrackingIdSelector = createSelector(getTrackingId, (trackingId) => trackingId)

export const getGaidDataSelector = createSelector(
  getAdvertisingId,
  getOsVersion,
  getAppVersion,
  (advertisingId, osVersion, appVersion) => {
    if (advertisingId) {
      return {
        advertisingId,
        osVersion,
        appVersion,
      }
    }

    return null
  }
)

export const getForcementModeSelector = createSelector(getForcementMode, (forcement) => forcement)

export const isSCVisibleSelector = createSelector(
  sweepstakeEnabledSelector,
  isPreviewModeSelector,
  (scEnabled, isPreviewMode) => {
    return scEnabled || isPreviewMode
  }
)

export const getAvailablePaymentProviders = createSelector(
  getPayWithMyBankConfigSelector,
  getSpreedlyIdSelector,
  getSkrillSelector,
  getFiservPaymentId,
  (pwmb, spreedly, skrill, fiserv) => {
    return {
      pay_with_my_bank: Boolean(pwmb),
      skrill: Boolean(skrill),
      fiserv: Boolean(fiserv),
      spreedly_rapyd: Boolean(spreedly),
      spreedly_fiserv: Boolean(spreedly),
      spreedly: Boolean(spreedly),
    }
  }
)
