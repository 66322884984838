import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import { getItemFormLocalStorage } from 'src/utils/localStorage'
import { getAvailablePaymentProviders } from '../appConfig/selectors'

const getSavedCards = (state: ApplicationState) => state.paymentsFlow.savedCards
const getDefaultCardId = (state: ApplicationState) => state.paymentsFlow.defaultCard
const getIsPaymentFlowInProcess = (state: ApplicationState) => state.paymentsFlow.isPaymentFlowInProcess

export const getSavedCardsSelector = createSelector(getSavedCards, (savedCards) => savedCards)

export const getIsPaymentFlowInProcessSelector = createSelector(
  getIsPaymentFlowInProcess,
  (isPaymentFlowInProcess) => isPaymentFlowInProcess
)

export const getDefaultCardSelector = createSelector(
  getDefaultCardId,
  getSavedCardsSelector,
  (defaultCardId, savedCards) => {
    return savedCards && savedCards.find((i) => i.code === defaultCardId)
  }
)

export const getDefaultCardByTokenSelector = (defaultCardId: string) =>
  createSelector(
    getSavedCardsSelector,
    () => defaultCardId,
    (savedCards, _defaultCardId) => {
      return savedCards && savedCards.find((i) => i.token === _defaultCardId)
    }
  )

export const getSavedCardsByProvider = createSelector(
  getSavedCardsSelector,
  (_: ApplicationState, provider: string) => provider,
  (cards, provider) => {
    if (!cards) {
      return null
    }

    return cards?.filter((el) => el.provider === provider)
  }
)

export const getStripeMethodsSelector = createSelector(getSavedCards, (cards) => {
  if (!cards) return null

  return cards.filter((el) => el.provider === 'stripe_card')
})

export const getLatestPWMBCardSelector = createSelector(
  getSavedCardsSelector,
  (savedCards) =>
    savedCards
      .filter((i) => i.provider === 'pay_with_my_bank')
      .sort((a, b) => {
        if (a.paymentMethod.lastUsageAt > b.paymentMethod.lastUsageAt) {
          return -1
        }

        return 1
      })?.[0]
)

export const getLastPMSelector = createSelector(
  getSavedCards,
  getAvailablePaymentProviders,
  () => {
    const lastPM = getItemFormLocalStorage('LAST_PAYMENT_METHOD')

    return lastPM
  },
  (savedCards, availableProvider, lastPM) => {
    if (!savedCards) {
      return null
    }

    if (!availableProvider[lastPM as keyof typeof availableProvider]) {
      return null
    }

    return savedCards
      .filter?.((i) => i.provider === lastPM)
      .sort((a, b) => {
        if (a.paymentMethod.lastUsageAt > b.paymentMethod.lastUsageAt) {
          return -1
        }

        return 1
      })?.[0]
  }
)
