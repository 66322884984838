import { Reducer } from 'redux'
import { AuthState, AuthActionTypes, Actions } from './types'

const initialState: AuthState = {
  loading: false,
  googleAppInit: false,
}

const reducer: Reducer<AuthState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case AuthActionTypes.SET_AUTH_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }

    case AuthActionTypes.SET_GOOGLE_APP_INIT: {
      return {
        ...state,
        googleAppInit: action.payload.googleAppInit,
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as authReducer }
