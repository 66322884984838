import ROUTES from 'config/routes.json'
import { convertTitleToUrl } from 'utils/url'

export const lotteryRoutes = {
  [ROUTES.LOTTERY_FAQ_WITH_CURRENCY as string]: (gameTitle: string, currencyName: string) =>
    `/lottery/${convertTitleToUrl(gameTitle)}/${currencyName}/faq`,
  [ROUTES.LOTTERY_RESULTS_WITH_CURRENCY as string]: (gameTitle: string, currencyName: string) =>
    `/lottery/${convertTitleToUrl(gameTitle)}/${currencyName}/results`,
  [ROUTES.LOTTERY_PRIZES_WITH_CURRENCY as string]: (gameTitle: string, currencyName: string) =>
    `/lottery/${convertTitleToUrl(gameTitle)}/${currencyName}/prizes`,
  [ROUTES.LOTTERY_PLAY_WITH_CURRENCY as string]: (gameTitle: string, currencyName: string) =>
    `/lottery/${convertTitleToUrl(gameTitle)}/${currencyName}`,

  [ROUTES.LOTTERY_FAQ as string]: (gameTitle: string) => `/lottery/${convertTitleToUrl(gameTitle)}/faq`,
  [ROUTES.LOTTERY_RESULTS as string]: (gameTitle: string) => `/lottery/${convertTitleToUrl(gameTitle)}/results`,
  [ROUTES.LOTTERY_PRIZES as string]: (gameTitle: string) => `/lottery/${convertTitleToUrl(gameTitle)}/prizes`,
  [ROUTES.LOTTERY_PLAY as string]: (gameTitle: string) => `/lottery/${convertTitleToUrl(gameTitle)}`,
}

export const lotteryResolver = {
  [ROUTES.LOTTERY_FAQ_WITH_CURRENCY as string]: ROUTES.LOTTERY_FAQ as string,
  [ROUTES.LOTTERY_RESULTS_WITH_CURRENCY as string]: ROUTES.LOTTERY_RESULTS as string,
  [ROUTES.LOTTERY_PRIZES_WITH_CURRENCY as string]: ROUTES.LOTTERY_PRIZES as string,
  [ROUTES.LOTTERY_PLAY_WITH_CURRENCY as string]: ROUTES.LOTTERY_PLAY as string,

  [ROUTES.LOTTERY_FAQ as string]: ROUTES.LOTTERY_FAQ_WITH_CURRENCY as string,
  [ROUTES.LOTTERY_RESULTS as string]: ROUTES.LOTTERY_RESULTS_WITH_CURRENCY as string,
  [ROUTES.LOTTERY_PRIZES as string]: ROUTES.LOTTERY_PRIZES_WITH_CURRENCY as string,
  [ROUTES.LOTTERY_PLAY as string]: ROUTES.LOTTERY_PLAY_WITH_CURRENCY as string,
}
