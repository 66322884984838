import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import { getItemFormLocalStorage } from 'src/utils/localStorage'
import { getSkrillSelector } from '../appConfig/selectors'
import { RealMoneyCurrencies } from 'store/modules/currencies/types'
import { ACHWithdrawMethodType, SkrillACHWithdrawMethodType, SkrillWithdrawMethodType } from './types'

const getIsRedeeming = (state: ApplicationState) => state.redeem.isRedeeming
const getRedeemInfo = (state: ApplicationState) => state.redeem.userRedeemInfo
const getRedeemsItems = (state: ApplicationState) => state.redeem.redeems
const getActiveRedeemId = (state: ApplicationState) => state.redeem.activeRedeemId
const getWithdrawMethods = (state: ApplicationState) => state.redeem.withdrawMethods
const getRedeemPolicy = (state: ApplicationState) => state.redeem.redeemPolicy
const getProvidersPolicy = (state: ApplicationState) => state.redeem.providerPolicies

export const getIsRedeemingSelector = createSelector(getIsRedeeming, (isRedeeming) => isRedeeming)
export const getUserRedeemInfoSelector = createSelector(getRedeemInfo, (info) => info)

export const getRedeemsItemsSelector = createSelector(getRedeemsItems, (redeems) => redeems)
export const getActiveRedeemIdSelector = createSelector(getActiveRedeemId, (activeRedeemId) => activeRedeemId)
export const getWithdrawMethodsSelector = createSelector(getWithdrawMethods, (methods) => methods)
export const getProvidersPolicySelector = createSelector(getProvidersPolicy, (policies) => policies)

export const getInProgressRedeemsSelector = createSelector(getRedeemsItemsSelector, (items) => {
  if (items) {
    return items.filter((it) => it.status === 'pre_authorized' || it.status === 'locked')
  }

  return null
})

export const getInProgressRedeemsSCSelector = createSelector(getInProgressRedeemsSelector, (items) => {
  if (items) {
    return items.filter((it) => it.currency === 'SC')
  }

  return null
})

export const getWithdrawCurrentACHMethodSelector = createSelector(getWithdrawMethodsSelector, (methods) => {
  if (methods) {
    return methods.find((el) => el.withdrawMethod.type === 'mazooma_ach') as ACHWithdrawMethodType
  }

  return null
})

export const getWithdrawCurrentSkrillMethodSelector = createSelector(getWithdrawMethodsSelector, (methods) => {
  if (methods) {
    return methods.find((el) => el.withdrawMethod.type === 'skrill') as SkrillWithdrawMethodType
  }

  return null
})

export const getWithdrawCurrentSkrillACHMethodSelector = createSelector(getWithdrawMethodsSelector, (methods) => {
  if (methods) {
    return methods.find((el) => el.withdrawMethod.type === 'skrill_ach') as SkrillACHWithdrawMethodType
  }

  return null
})

export const getSkrillUserEmailSelector = createSelector(
  getWithdrawCurrentSkrillMethodSelector,
  (method) => method?.withdrawMethod?.email
)

export const isRedeemDisabledSelector = createSelector(
  getSkrillUserEmailSelector,
  getWithdrawCurrentSkrillACHMethodSelector,
  (isEmailAvailable, isACHMethod) =>
    !isEmailAvailable && !(isACHMethod?.withdrawMethod?.transactionId && isACHMethod?.code)
)

export const getRedeemPolicySelector = createSelector(getRedeemPolicy, (redeem) => redeem)
export const getSweepstakeRedeemPolicySelector = createSelector(getRedeemPolicySelector, (policy) => {
  return policy?.sweepstakeRedeemPolicy
})

export const getFiatRedeemPolicySelector = createSelector(getRedeemPolicySelector, (policy) => {
  return policy?.fiatRedeemPolicy
})

export const getNonMonetaryRedeemPolicySelector = createSelector(getRedeemPolicySelector, (policy) => {
  return policy?.nonMonetaryRedeemPolicy
})

export const isRedeemFlowReadySelector = createSelector(
  getSweepstakeRedeemPolicySelector,
  getInProgressRedeemsSCSelector,
  getWithdrawCurrentACHMethodSelector,
  (redeemPolicy, processingRedeems, redeemMethods) => {
    return Boolean(redeemPolicy && processingRedeems && redeemMethods !== null)
  }
)

export const getRedeemPolicyByCurrencySelector = createSelector(
  getRedeemPolicy,
  (_: ApplicationState, c: RealMoneyCurrencies) => c,
  (policies, currency) => {
    const policyDict: { [key in RealMoneyCurrencies]: keyof typeof policies } = {
      SC: 'sweepstakeRedeemPolicy',
      USD: 'fiatRedeemPolicy',
    }

    return policies[policyDict[currency]]
  }
)

export const getDefaultRedeemProviderSelector = createSelector(
  getSkrillSelector,
  getSkrillUserEmailSelector,
  getWithdrawCurrentSkrillACHMethodSelector,
  () => getItemFormLocalStorage('REDEEM_PROVIDER'),
  (skrill, skrillEmail, skrillACH, lsRedeemProvider) => {
    if (lsRedeemProvider) {
      return lsRedeemProvider
    }
    if (skrillACH?.withdrawMethod?.transactionId && skrillACH?.code) {
      return 'SkrillACH'
    }
    if (skrillEmail && skrill) {
      return 'Skrill'
    }

    return ''
  }
)
