import { Theme } from '@material-ui/core/styles'
import { shortenLargeNumber } from 'utils/numbers'

import { Currencies, LottoPoolNames } from 'store/modules/currencies/types'
import { DrawItem, ProductModes } from 'store/modules/games/types'
import config from 'config/layoutConstants.json'

export function calculateComponentsWidth(menuWidth: number, cols: number, gridSize: number, theme: Theme): string {
  return `calc(((100vw - ${menuWidth}px - ${theme.spacing(3)}px - var(--scroll)) / ${cols}) * ${gridSize})`
}

export const getCardTypeIcon = (type: string) => {
  if (type.toLowerCase().includes('visa')) {
    return 'visa'
  }
  if (type.toLowerCase().includes('mastercard')) {
    return 'mastercard'
  }
  if (type.toLowerCase().includes('amex')) {
    return 'amex'
  }

  return 'commonCard'
}

export const fromCamelCaseToDashes = (str: string): string => {
  return str
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
      return `-${y.toLowerCase()}`
    })
    .replace(/^-/, '')
}

export const fromDashesTextToCamelCase = (str: string | string[]): string => {
  return Array.isArray(str)
    ? str[0].replace(/-([a-z])/g, (g) => {
        return g[1].toUpperCase()
      })
    : str.replace(/-([a-z])/g, (g) => {
        return g[1].toUpperCase()
      })
}

export const convertFromDashesToNormal = (str: string | string[]) => {
  return Array.isArray(str)
    ? str[0].replace(/-/g, ' ').replace(/^./, (x) => x.toUpperCase())
    : str.replace(/-/g, ' ').replace(/^./, (x) => x.toUpperCase())
}

export function getKeyByValue(object: { [key: string]: any }, value: any) {
  return Object.keys(object).find((key) => object[key] === value)
}

export const translateWithOptions = (rootKey?: string, ns?: string, t?: Function) => {
  return (key: string, options: Object = {}) => {
    return t(`${ns}:${rootKey ? `${rootKey}.` : ''}${key}`, { ...options })
  }
}
export const getDataFromJson = (obj = {}, rootKey?: string) => {
  return (path: string, useRootKey: boolean = true) => {
    let properties
    if (path) {
      if (rootKey) {
        if (useRootKey) {
          properties = Array.isArray(path) ? [rootKey, ...path] : `${rootKey}.${path}`.split('.')
        } else {
          properties = Array.isArray(path) ? [...path] : `${path}`.split('.')
        }
      } else {
        properties = Array.isArray(path) ? path : path.split('.')
      }
      if (Object.prototype.hasOwnProperty.call(obj, path)) {
        return obj[path as keyof typeof obj]
      }

      return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj) || path
    } else {
      return 'undefined'
    }
  }
}

export const getPoolName = (activeCurrency: Currencies, gameMode: ProductModes) => {
  if (gameMode === ProductModes.FREE) {
    return LottoPoolNames.USD
  }

  return LottoPoolNames[activeCurrency]
}

export const formatJackpot = (
  activeCurrency: Currencies,
  gameMode: ProductModes,
  draw: DrawItem,
  isShortDescription: boolean = true
) => {
  if (!draw || !gameMode) {
    return 'waiting'
  }

  const poolName = getPoolName(activeCurrency, gameMode)
  const pool = draw?.[poolName]
  const poolJackpot = pool ? pool.jackpot : 0
  const currentCurrency = (gameMode === ProductModes.FREE && '$') || activeCurrency
  const jackpot = shortenLargeNumber(poolJackpot, 2, isShortDescription)

  return `${currentCurrency} ${jackpot}`
}

export const checkNotNullItemsInObject = (obj: Object): boolean => {
  return Object.keys(obj).every((it: keyof typeof obj) => Boolean(obj[it]))
}

export const compareArrays = (array1: Array<any>, array2: Array<any>) => {
  // if the other array is a falsy value, return
  if (!array2) return false

  // compare lengths - can save a lot of time
  if (array1.length !== array2.length) return false

  for (let i = 0, l = array1.length; i < l; i += 1) {
    // Check if we have nested arrays
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      // recurse into the nested arrays
      if (!array1[i].equals(array2[i])) return false
    } else if (array1[i] !== array2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }

  return true
}

export const scrollToElement = (id: string, elementWithScroll: Element, isResetScroll = true, attr = 'data') => {
  if (window && id) {
    const query = attr === 'id' ? id : `[data-id='${id.replace('#', '')}']`
    isResetScroll && window.scrollTo({ top: 0 })
    elementWithScroll.scrollBy({
      // @ts-ignore
      top: elementWithScroll.querySelector(query)?.offsetTop - config.headerHeight || 0,
      behavior: 'smooth',
    })
  }
}

export const detectMobile = () => {
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      window.navigator.userAgent
    ) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      window.navigator.userAgent.substr(0, 4)
    )
  )
}

export const sendRNevent = (event: { type: string; [key: string]: string | boolean }) => {
  if (window.ReactNativeWebView) {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(event))
    } catch (error) {
      // eslint-disable-next-line
      console.log('error RN event send', error)
    }
  }
}

export const callPhoneOnApp = (number: string) => {
  sendRNevent({
    type: 'callPhone',
    number,
  })
}

export function hashFnv32a(str: string) {
  /* jshint bitwise:false */
  let i
  let l
  let hval = 0x811c9dc5

  // eslint-disable-next-line
  for (i = 0, l = str.length; i < l; i++) {
    // eslint-disable-next-line
    hval ^= str.charCodeAt(i)
    // eslint-disable-next-line
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24)
  }
  // eslint-disable-next-line
  return hval >>> 0
}

export function once(fn: any) {
  let executed = false

  // eslint-disable-next-line
  return function (): null | any {
    if (executed) {
      return null
    }
    executed = true

    fn.apply(
      this,
      // eslint-disable-next-line
      arguments
    )
  }
}

export function isFacebookBrowser() {
  if (!process.browser) {
    return false
  }

  const ua = navigator.userAgent || navigator.vendor

  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
}

export const isMSFamilyBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor
  const agent = ua.toLowerCase()

  return agent.indexOf('edge') > -1 || agent.indexOf('edg') > -1 || agent.indexOf('trident') > -1
}

export const uniqueItemsInArray = <TArrayItem>(array: Array<TArrayItem>, comparedKey: keyof TArrayItem) => {
  return array.reduce((accumulator, current) => {
    if (!accumulator.some((x) => x[comparedKey] === current[comparedKey])) {
      accumulator.push(current)
    }

    return accumulator
  }, [])
}

export const emailMask = (email: string) => {
  const maskedEmail = email.replace(/([^@.])/g, 'x').split('')
  let previous = ''
  for (let i = 0; i < maskedEmail.length; i += 1) {
    if (i <= 1 || previous === '.' || previous === '@') {
      maskedEmail[i] = email[i]
    }
    previous = email[i]
  }

  return maskedEmail.join('')
}

export const isIOS = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iP(ad|od|hone)/i)

  return iOS
}

export const isSafari = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iP(ad|od|hone)/i)
  const hasSafariInUa = !!ua.match(/Safari/i)
  const noOtherBrowsersInUa = !ua.match(/Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i)
  let result = false
  if (iOS) {
    // detecting Safari in IOS mobile browsers
    const webkit = !!ua.match(/WebKit/i)
    result = webkit && hasSafariInUa && noOtherBrowsersInUa
    // @ts-ignore
  } else if (window.safari !== undefined) {
    // detecting Safari in Desktop Browsers
    result = true
  } else {
    // detecting Safari in other platforms
    result = hasSafariInUa && noOtherBrowsersInUa
  }

  return result
}

export const isMobileScreenSize = (smBreakpoint = 870) => {
  return window.innerWidth < smBreakpoint
}

export const getRegExpFromString = (str: string) => {
  if (str) {
    const main = str.match(/\/(.+)\/.*/)[1]
    const options = str.match(/\/.+\/(.*)/)[1]
    const re = new RegExp(main, options)

    return re
  }

  return null
}

export const socialAuthResolver = (urlKey: string, cb: (token: string) => void) => {
  const url = window.location.hash.replace('#', '?')
  const uri = new URLSearchParams(url)
  const acces_token = uri.get(urlKey)
  window.location.hash = ''
  cb(acces_token)
}
