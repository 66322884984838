import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    margin: '16px auto',
    padding: '0 16px',
    maxWidth: `calc(800px + ${theme.spacing(4)}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    marginRight: '1rem',
    minWidth: 30,
    width: 30,
  },
}))
