// @TODO: move next.config to ts
exports.convertTitleToUrl = (str) => {
  return str
    .toString()
    .replace(/\s-\s/g, ' ')
    .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

exports.getQueryFromRoute = (route) => {
  const regex = /\[.+?\]/g
  // get all dynamic query params from url

  return route.match(regex)
}

exports.generateShareUlrWithUserId = (userId) => {
  const { host } = window.location

  return `${host}/${userId ? `?invited_by=${encodeURIComponent(userId)}` : ''}`
}
