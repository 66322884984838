import useStyles from './styles'
import { connect } from 'react-redux'
import BackButton from 'src/components/BackButton'
import LevelProgress from '../LevelProgress'
import LoggedIn from './LoggedIn'
import LoggedOut from './LoggedOut'

import { useRouter } from 'next/router'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { isAppInitializedSelector, isNativeAppSelector } from 'store/modules/appConfig/selectors'
import LogoLink from '../LogoLink'
import { getActiveCurrencyIdSelector, isCurrenciesAvailableSelector } from 'src/store/modules/currencies/selectors'
import { Currencies } from 'src/store/modules/currencies/types'
import { ApplicationState } from 'store/modules/types'
import ROUTES from 'config/routes.json'

type Props = {
  isLoggedIn: boolean
  isAppInitialized: boolean
  isNative: boolean
  activeCurrency: Currencies
  isCurrenciesAvailable: boolean
}
const mapState = (state: ApplicationState) => ({
  isLoggedIn: isLoggedInSelector(state),
  isAppInitialized: isAppInitializedSelector(state),
  isNative: isNativeAppSelector(state),
  activeCurrency: getActiveCurrencyIdSelector(state),
  isCurrenciesAvailable: isCurrenciesAvailableSelector(state),
})

const getProgressComponent = (isLoggedIn: boolean, activeCurrency: Currencies) => {
  const progressRecord = {
    SC: LogoLink,
    USD: LogoLink,
    GC: LevelProgress,
  }

  if (!isLoggedIn) return LogoLink

  if (Object.prototype.hasOwnProperty.call(progressRecord, activeCurrency)) {
    return progressRecord[activeCurrency]
  }

  return LevelProgress
}

function Header(props: Props) {
  const classes = useStyles(props)
  const router = useRouter()
  const Progress = getProgressComponent(props.isLoggedIn, props.activeCurrency)

  return (
    <div className={classes.root}>
      <BackButton className={classes.backButton} color='white' handler={() => router.push(ROUTES.HOME_LOGGED_OUT)} />

      {props.isAppInitialized && (
        <>
          <Progress />

          <div className={classes.info}>
            {props.isLoggedIn && props.isCurrenciesAvailable ? <LoggedIn /> : !props.isNative && <LoggedOut />}
          </div>
        </>
      )}
    </div>
  )
}

export default connect(mapState)(Header)
