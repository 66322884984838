import { ReactNode } from 'react'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { ApplicationState } from 'store/modules/types'
import MenuItem from 'containers/Menu/MenuItem'
import { isSweepstakeCurrencyActiveSelector } from 'store/modules/currencies/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import ROUTES from 'config/routes.json'

const mapState = (state: ApplicationState) => ({
  isDefaultTheme: isSweepstakeCurrencyActiveSelector(state),
  loggedIn: isLoggedInSelector(state),
  appInitialized: isAppInitializedSelector(state),
})

type MenuLinkProps = {
  isDefaultTheme: boolean
  children?: ReactNode
  link?: string | string[]
  text?: string
  icon: ReactNode
  onClick?: () => void
  key?: number
  loggedIn: boolean
  appInitialized: boolean
}

function MenuLink(props: MenuLinkProps) {
  const router = useRouter()
  const { link, appInitialized } = props
  let isActive
  if (Array.isArray(link)) {
    isActive = link.includes(router.pathname) && appInitialized
  } else {
    isActive = link === router.pathname && appInitialized
  }
  const currentLink: string = link && Array.isArray(link) ? (link[0] as string) : (link as string)

  return (
    <>
      {currentLink ? (
        <MenuItem {...props} isActive={isActive} href={currentLink || ROUTES.HOME_LOGGED_OUT} />
      ) : (
        <MenuItem {...props} isActive={isActive} />
      )}
    </>
  )
}

export default connect(mapState)(MenuLink)
