import { useState, useEffect } from 'react'
import cx from 'classnames'
import { ChevronDown } from 'mdi-material-ui'
import useStyles from './styles'
import { Currencies } from 'store/modules/currencies/types'
import Typography from '@material-ui/core/Typography'
import NumberAnimation from 'src/components/NumberAnimation'

type Props = {
  amount: number
  activeCurrency: Currencies
}
const iconName = {
  GC: 'coin-icon.svg',
  SC: 'sc-icon.png',
  USD: 'sc-icon.png',
}
function Amount(props: Props) {
  const classes = useStyles(props)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (!animate) {
      setTimeout(() => {
        setAnimate(true)
      }, 4000)
    }
  }, [animate])

  return (
    <div className={cx(classes.root, animate && classes.animate)}>
      <div className={cx(classes.amount, animate && classes.hide)}>
        <img src={`${process.env.IMG_ORIGIN}/${iconName[props.activeCurrency]}`} className={classes.icon} />
        <Typography variant='caption'>
          {`${props.activeCurrency} `}
          <NumberAnimation amount={props.amount} currency={props.activeCurrency} />
        </Typography>
      </div>
      <div onClick={() => setAnimate(false)} className={cx(classes.button, animate && classes.showButton)}>
        <ChevronDown />
      </div>
    </div>
  )
}

export default Amount
