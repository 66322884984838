import {
  GamesActionTypes,
  GameProduct,
  SaveGameProductsAction,
  ClearGamesAction,
  LottoProductDraws,
  SaveLottoDrawsAction,
  SetGamesIsLoadedAction,
} from './types'
import { ThunkType } from 'store/modules/types'
import { openSnackbar } from 'src/store/modules/snackbar/actions'
import { WSinstance } from 'services/websocket'
import LevelUpMessage from 'dialogs/LevelUpModal/LevelUpMessage'

import { convertArrayToObject, sortGamesByType } from './utils'
import { getAllGamesProductsSelector } from './selectors'
import { replaceDialog } from '../dialog/actions'
import { ILevel } from '../user/types'
import { isNativeAppSelector } from '../appConfig/selectors'

export const gamesIsLoaded = (): SetGamesIsLoadedAction => {
  return {
    type: GamesActionTypes.GAMES_IS_LOADED,
  }
}

export const saveGameProducts = (games: Array<GameProduct>): SaveGameProductsAction => {
  const sortedGames = sortGamesByType(games)

  return {
    type: GamesActionTypes.SAVE_GAME_PRODUCTS,
    payload: {
      games: sortedGames,
    },
  }
}

export const saveLottoDraws = (lottoDraws: Array<LottoProductDraws>): SaveLottoDrawsAction => {
  const updatedLottoDraws = convertArrayToObject(lottoDraws, 'product')

  return {
    type: GamesActionTypes.SAVE_LOTTOS_DRAWS,
    payload: {
      lotteryDraws: updatedLottoDraws,
    },
  }
}

export const clearGames = (): ClearGamesAction => ({
  type: GamesActionTypes.CLEAR_GAMES,
})

export const addUnlockedGames =
  (ulockedGames: Array<GameProduct>): ThunkType =>
  (dispatch, getState) => {
    const allGames = getAllGamesProductsSelector(getState())
    const concatedGames = allGames.map((game) => {
      if (ulockedGames && ulockedGames.find((it) => it.code === game.code)) {
        return {
          ...game,
          unlocked: true,
        }
      }

      return {
        ...game,
      }
    })
    dispatch(saveGameProducts(concatedGames))
  }

export const getLottoDraws = (): ThunkType => (dispatch) => {
  dispatch(WSinstance.emitWS({ type: 'GetLottoDrawsRequest' }))
}

export const levelUpPULSZHandler =
  (levelInfo: ILevel): ThunkType =>
  (dispatch, getState) => {
    const isNative = isNativeAppSelector(getState())
    if (levelInfo.level === 10 && isNative) {
      dispatch(
        replaceDialog('RATE_US_DIALOG', {
          callBack: () => {
            dispatch(
              openSnackbar({
                message: LevelUpMessage,
                autoHide: 1000,
                positionVertical: 'top',
                positionHorizontal: 'right',
                componentProps: {
                  vipPoints: levelInfo.vipPoints,
                  bonus: levelInfo.bonus,
                  level: levelInfo.level,
                },
              })
            )
          },
        })
      )
    } else {
      dispatch(
        openSnackbar({
          message: LevelUpMessage,
          autoHide: 1000,
          positionVertical: 'top',
          positionHorizontal: 'right',
          componentProps: {
            vipPoints: levelInfo.vipPoints,
            bonus: levelInfo.bonus,
            level: levelInfo.level,
          },
        })
      )
    }
  }

export const levelUpHandler =
  (levelInfo: ILevel): ThunkType =>
  (dispatch) => {
    if (process.env.BRAND_NAME === 'pulsz') {
      dispatch(levelUpPULSZHandler(levelInfo))
    } else {
      dispatch(
        openSnackbar({
          message: LevelUpMessage,
          autoHide: 1000,
          positionVertical: 'top',
          positionHorizontal: 'right',
          componentProps: {
            vipPoints: levelInfo.vipPoints,
            bonus: levelInfo.bonus,
            level: levelInfo.level,
          },
        })
      )
    }
  }
