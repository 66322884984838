import { ReactNode } from 'react'

import NumberAnimation from 'components/NumberAnimation'
import Typography from '@material-ui/core/Typography'
import { Currencies } from 'store/modules/currencies/types'
import useStyles from './styles'

const titlesRecord = {
  USD: 'US$ Real Money',
  GC: 'Gold Coins',
  SC: 'Sweepstake Coins',
}

type Props = {
  currency: Currencies
  t: (arg0: string) => string
  amount: number
  children?: ReactNode
  tooltip?: ReactNode
  showSign?: boolean
}

export default function CurrencyDescriptionItem(props: Props) {
  const classes = useStyles(props)
  const { t, currency, amount } = props
  const iconsRecord = {
    USD: '$',
    GC: <img src={`${process.env.IMG_ORIGIN}/coin-icon.svg`} className={classes.icon} />,
    SC: <img src={`${process.env.IMG_ORIGIN}/sc-icon.png`} className={classes.icon} />,
  }

  return (
    <div className={classes.item}>
      <Typography className={classes.itemText} variant='subtitle2'>
        {t(titlesRecord[currency])}
        {props?.children}
        {props?.tooltip}
      </Typography>
      <Typography className={classes.itemText} variant='subtitle2'>
        {amount && (
          <>
            {iconsRecord[currency]}
            {props.showSign && `${currency} `}

            <NumberAnimation amount={amount} currency={currency} />
          </>
        )}
      </Typography>
    </div>
  )
}
