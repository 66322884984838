// Use `enum`s for better autocompletion of action type names. These will

import { Currencies } from '../currencies/types'
import { PaymentsProviderTypes } from '../paymentsFlow/types'

// be compiled away leaving only the final value in your compiled code.
export enum ShopActionTypes {
  ADD_OFFERS = 'shop/ADD_OFFERS',
  ADD_APPLIED_OFFERS = 'shop/ADD_APPLIED_OFFERS',
  CLEAR_OFFERS = 'shop/CLEAR_PRODUCTS',
  SET_PURCHASE_LIMITS = 'shop/SET_PURCHASE_LIMITS',
}

type OfferType = 'one_time' | 'subscription' | 'permanent'

export type ShopOffer = {
  code: string
  title: string
  price: number
  goldFistOffer: number
  goldMoney: number
  sweepstakeMoney: number
  sweepstakeFirstOffer: number
  vipPoints: number
  offerType: OfferType
  firstPaymentInSubscription?: boolean
  tags: string[]
  bannerImageUrl?: string
  popUpImageUrl?: string
}

export type AppliedShopOffer = {
  code: string
  title: string
  price: number
  goldMoney: number
  offerType: OfferType
  sweepstakeMoney: number
  vipPoints: number
  vipLevel: number
}

export interface PurchaseLimits {
  limitAmount?: string
  limitAvailable?: string
  limitPeriod?: string
  limitEnd?: string
}
export interface RequestKyc {
  requestKyc: boolean
}

export type OfferPurchaseType = {
  amount: number
  currency: Currencies
  firstPaymentInSubscription: boolean
  mode: 'sweepstake_preview_web' | 'sweepstake_preview' | 'default'
  offer: ShopOffer
  provider: PaymentsProviderTypes
  transactionId: string
  firstDeposit: string
  skipOtp?: boolean
}

export interface ClearOffersAction {
  type: ShopActionTypes.CLEAR_OFFERS
}

export interface AddOffersAction {
  type: ShopActionTypes.ADD_OFFERS
  payload: {
    offers: Array<ShopOffer> | null
  }
}

export interface AddAppliedOffersAction {
  type: ShopActionTypes.ADD_APPLIED_OFFERS
  payload: {
    appliedOffers: Array<AppliedShopOffer> | null
  }
}

export interface SetPurchaseLimitsAction {
  type: ShopActionTypes.SET_PURCHASE_LIMITS
  payload: {
    limits: PurchaseLimits | null
  }
}

export type Actions = AddOffersAction | AddAppliedOffersAction | ClearOffersAction | SetPurchaseLimitsAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export type ShopState = {
  offers: Array<ShopOffer>
  applied: Array<AppliedShopOffer>
  limits: PurchaseLimits | null
}
