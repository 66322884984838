import { WSinstance } from 'services/websocket'
import {
  PlayedGamesActionTypes,
  SavePlayedLottosAction,
  ClearPLayedGamesAction,
  UpdatePlayedLottoAction,
  LottoPlayedGame,
  UpdateGameAction,
  GetPlayedGamesAction,
} from './types'
import * as Sentry from '@sentry/nextjs'
import { ThunkType } from 'store/modules/types'
import { GameProduct } from 'store/modules/games/types'
import gatewayEmit from 'src/services/gateway/gatewayEmit'
import { getAllPastLottoDrawsIdCodesSelector } from './selectors'
import { SAVED_UPCOMMING_GAMES, setDataToLocalStorage } from 'src/utils/localStorage'
import { SetPlayedGamesResponse } from 'services/websocket/types/response'

export const savePlayedLottos = (
  accepted: Array<LottoPlayedGame>,
  closed: Array<LottoPlayedGame>
): SavePlayedLottosAction => {
  return {
    type: PlayedGamesActionTypes.SAVE_LOTTOS,
    payload: {
      accepted,
      closed,
    },
  }
}

export const getLottoPicks = (): ThunkType => (dispatch) => {
  dispatch(
    WSinstance.emitWS({
      type: 'GetLottoPicksRequest',
      firstResult: 0,
      maxResults: 50,
    })
  )
}

export const updatePlayedLotto = (game: LottoPlayedGame): UpdatePlayedLottoAction => {
  return {
    type: PlayedGamesActionTypes.UPDATE_PLAYED_LOTTO,
    payload: {
      game,
    },
  }
}

export const updateGame = (game: LottoPlayedGame): UpdateGameAction => {
  return {
    type: PlayedGamesActionTypes.UPDATE_GAME,
    payload: {
      game,
    },
  }
}

export const clearPlayedGames = (): ClearPLayedGamesAction => ({
  type: PlayedGamesActionTypes.CLEAR_ALL_GAMES,
})

export const setPlayedGames = (games: Array<GameProduct>): GetPlayedGamesAction => {
  return {
    type: PlayedGamesActionTypes.GET_PLAYED_GAMES,
    payload: {
      games,
    },
  }
}

export const fetchAllGamesHistory = (): ThunkType => (dispatch) => {
  gatewayEmit<SetPlayedGamesResponse>({
    type: 'GetOptInProductsRequest',
  })
    .then((body) => {
      dispatch(setPlayedGames(body.products))
    })
    .catch((err) => {
      Sentry.captureMessage(`fetch GetOptInProductsRequest ${err.message}`)
    })
}

export const savePlayedGamesToLocalStorage = (): ThunkType => (dispatch, getState) => {
  const pastGames = getAllPastLottoDrawsIdCodesSelector(getState())

  dispatch(setDataToLocalStorage(SAVED_UPCOMMING_GAMES, pastGames))
}
