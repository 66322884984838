import { v4 as uuid } from 'uuid'
import { WSRequestsBody } from '../websocket/types/requests'
import { WSBodyResponses } from '../websocket/types/response'

async function gatewayEmit<T extends WSBodyResponses>(body: WSRequestsBody): Promise<T> {
  const platform = window.ReactNativeWebView ? 'android' : 'web'
  const SERVER_URL = `${process.env.SERVER_GW}?brandName=${process.env.BRAND_NAME}&platform=${platform}`

  return fetch(SERVER_URL, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': process.env.SERVER_GW,
      Accept: 'application/json, text/plain, */*',
    },
    body: JSON.stringify({
      headers: {
        messageId: uuid(),
        timestamp: Date.now(),
      },
      body,
    }), // body data type must match "Content-Type" header
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status.errorCode !== 'err_ok') {
        // eslint-disable-next-line no-console
        console.log('err', res.status.errorText || res.status.errorCode)

        return Promise.reject(res)
      } else {
        return Promise.resolve(res.body as T)
      }
    })
}

export default gatewayEmit
