import { WSRequestsBody } from './types/requests'
import { v4 as uuid } from 'uuid'
import { getAuthFlowLoadingSelector } from 'store/modules/auth/selectors'
import { ApplicationState } from 'store/modules/types'

export const WSinstance = (() => {
  let socketRoot: null | WebSocket = null
  let keepAliveTimer: null | ReturnType<typeof setTimeout> = null

  const _baseEmit = (event: WSRequestsBody, messageId: string) => {
    const data = {
      headers: {
        messageId,
        timestamp: Date.now(),
      },
      body: event,
    }

    if (socketRoot?.readyState === WebSocket.OPEN) {
      socketRoot.send(JSON.stringify(data))
    } else {
      // eslint-disable-next-line
      console.log('readyState', socketRoot?.readyState, event)
    }
  }
  // redux action
  const emitWS = (event: WSRequestsBody) => () => {
    const messageId = uuid()
    _baseEmit(event, messageId)
  }

  const clear = () => {
    socketRoot = null
    clearTimeout(keepAliveTimer)
    keepAliveTimer = null
  }

  const keepAlive = () => {
    _baseEmit(
      {
        type: 'KeepAliveRequest',
      },
      uuid()
    )

    keepAliveTimer = setTimeout(keepAlive, 15000)
  }

  const connect = (dispatch: Function) => {
    return new Promise((resolve) => {
      const platform = window.ReactNativeWebView ? 'android' : 'web'
      const SERVER_URL = `${process.env.WEBSOCKET}/ws?brandName=${process.env.BRAND_NAME}&platform=${platform}`

      socketRoot = new WebSocket(SERVER_URL)
      // @ts-ignore
      window.soc = socketRoot // test

      socketRoot.onopen = () => {
        dispatch({
          type: 'INIT_SOCKET_LISTENERS',
        })
        keepAlive()
        resolve(true)
      }
    })
  }

  const disconnect = () => {
    // eslint-disable-next-line
    console.log('ws disconnect')

    socketRoot?.close(4444)

    clearTimeout(keepAliveTimer)
    socketRoot = null
  }

  return {
    getInstance() {
      return socketRoot
    },

    connect,

    disconnect,

    emitWS,

    clear,
  }
})()

export const appStateActiveHandler = () => (dispatch: Function, getState: () => ApplicationState) => {
  const isAuthFlow = getAuthFlowLoadingSelector(getState())
  if (!isAuthFlow) {
    const socket = WSinstance.getInstance()

    if (!socket || WebSocket.CLOSED === socket?.readyState) {
      // eslint-disable-next-line
      console.log('appStateActiveHandler connect')

      WSinstance.connect(dispatch)
    }
  }
}
