import useStyles from './styles'
import { Typography } from '@material-ui/core'
import Button from 'components/Button'
import { getDataFromJson } from 'utils/helpers'
import content from 'translates/en/pwa/android.json'
import { PWAEventType } from 'src/store/modules/appConfig/types'

const t = getDataFromJson(content)

type Props = {
  pwaInstance: PWAEventType | { prompt: () => void }
  buttonHandler: () => void
}

export default function Android(props: Props) {
  const classes = useStyles(props)

  return (
    <>
      <Typography className={classes.text} align='center' variant='body2'>
        {t('message')}
      </Typography>
      <Button
        className={classes.button}
        size='small'
        onClick={() => {
          props.pwaInstance.prompt()
          props.buttonHandler()
        }}
      >
        {t('button')}
      </Button>
    </>
  )
}
