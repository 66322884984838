import Link from 'next/link'

import ROUTES from 'config/routes.json'

import useStyles from './styles'

type Props = {}

export default function LogoLink(props: Props) {
  const classes = useStyles(props)
  const userId = typeof window !== 'undefined' && window.localStorage.getItem('USER_ID')

  return (
    <Link href={userId ? ROUTES.HOME_LOGGED_IN : ROUTES.HOME_LOGGED_OUT} prefetch={false} data-test-='logo-link'>
      <picture>
        <source
          media='(max-width: 450px)'
          className={classes.logo}
          srcSet={`${process.env.IMG_ORIGIN}/logos/logo_dot_${process.env.BRAND_NAME}.svg`}
        />
        <img
          className={classes.logo}
          data-test='header-logo-link'
          src={`${process.env.IMG_ORIGIN}/logos/logo_full_${process.env.BRAND_NAME}.svg`}
          alt='logo'
        />
      </picture>
    </Link>
  )
}
