import { Fragment, ReactNode } from 'react'
import Header from './components/Header'
import { useRouter } from 'next/router'

type Props = {
  children: ReactNode
}
const MobileLayout = (props: Props) => {
  const router = useRouter()

  return (
    <>
      <Header />
      <Fragment key={router.asPath}>{props.children}</Fragment>
    </>
  )
}

export default MobileLayout
