import { useDispatch, useSelector } from 'react-redux'
import { openDialog } from 'store/modules/dialog/actions'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'

export default function TermsListener(): null {
  const dispatch = useDispatch()
  const dialogStack = useSelector(getDialogStackSelector)

  const isOpened = dialogStack.find((i) => i.modalName === 'TERMS_MODAL')

  //   // show Terms dialog on any page except of SC rules and Terms
  if (!isOpened) {
    dispatch(openDialog('TERMS_MODAL'))
  }

  return null
}
