import * as Sentry from '@sentry/nextjs'
import {
  SaveDailyBonusesAction,
  DailyBonus,
  SaveManualBonusesAction,
  BaseBonus,
  BonusesFlowActionTypes,
  SaveDelayedManualBonuses,
  SaveInvitatonBonusesAction,
  InvitationBonus,
} from './types'
import { ThunkType } from 'store/modules/types'
import { removeDialogByName, sessionDialogsResolver } from '../dialog/actions'

import { trackGAEvent } from 'utils/gtag'
import {
  getDailyBonusesSelector,
  getDelayedBonusesSelector,
  getGCManualAndInvitationBonusesSelector,
  getInvitationBonusesSelector,
  getManualAndInvitationBonusesSelector,
  getManualBonusesSelector,
  getSCManualAndInvitationBonusesSelector,
} from './selectors'
import {
  acceptBonusFlow,
  checkNextUserBonus,
  getActiveBonuses,
  getTodayBonus,
  updateBonusesNotification,
} from './utils'
import { uniqueItemsInArray } from 'src/utils/helpers'
import { sweepstakeEnabledSelector } from '../appConfig/selectors'
import { getDialogStackSelector } from '../dialog/selectors'
import { batch } from 'react-redux'
import {
  AcceptDailyBonusResponse,
  AcceptInvitationBonusResponse,
  AcceptManualBonusResponse,
  GetAccountBonusesResponse,
} from 'src/services/websocket/types/response'
import gatewayEmit from 'src/services/gateway/gatewayEmit'

export const saveDailyBonuses = (dailyBonuses: DailyBonus[]): SaveDailyBonusesAction => {
  const bonuses = getActiveBonuses(dailyBonuses)

  return {
    type: BonusesFlowActionTypes.SAVE_DAILY_BONUSES,
    payload: {
      dailyBonuses: bonuses,
    },
  }
}

export const saveManualBonuses = (manualBonuses: BaseBonus[]): SaveManualBonusesAction => ({
  type: BonusesFlowActionTypes.SAVE_MANUAL_BONUSES,
  payload: {
    manualBonuses,
  },
})

export const saveInvitatonBonuses = (invitationBonuses: InvitationBonus[]): SaveInvitatonBonusesAction => ({
  type: BonusesFlowActionTypes.SAVE_INVITATION_BONUS,
  payload: {
    invitationBonuses,
  },
})

export const saveDelayedBonuses = (delayedBonuses: BaseBonus[]): SaveDelayedManualBonuses => ({
  type: BonusesFlowActionTypes.SAVE_DELAYED_BONUSES,
  payload: {
    delayedBonuses,
  },
})

export const receivedBonuses =
  (body: GetAccountBonusesResponse): ThunkType =>
  (dispatch) => {
    batch(() => {
      dispatch(saveDailyBonuses(body?.daily))
      dispatch(saveManualBonuses(body?.manual))
      dispatch(saveInvitatonBonuses(body?.invitation))
      dispatch({
        type: BonusesFlowActionTypes.RECEIVED_ALL_USERS_BONUSES,
      })
    })
  }

export const checkRewardDialogAndOpen =
  (bonus: BaseBonus): ThunkType =>
  (dispatch, getState) => {
    const isBonusDialogOpen = getDialogStackSelector(getState())?.find((el) => el.modalName === 'REWARD_DIALOG')
    if (!isBonusDialogOpen) {
      dispatch(sessionDialogsResolver('REWARD_DIALOG', { bonus }))
    }
  }
export const checkUserBonuses = (): ThunkType => (dispatch, getState) => {
  const bonuses = getManualAndInvitationBonusesSelector(getState())
  const gcOnlyBonuses = getGCManualAndInvitationBonusesSelector(getState())
  const isScAvailable = sweepstakeEnabledSelector(getState())

  if (isScAvailable) {
    if (bonuses?.length) {
      dispatch(checkRewardDialogAndOpen(bonuses[0]))
    }
  } else if (gcOnlyBonuses?.length) {
    dispatch(checkRewardDialogAndOpen(gcOnlyBonuses[0]))
  }
}

export const openRewardDialogByCurrency =
  (currency: 'sweepstakeAmount' | 'goldAmount' | 'fiatAmount'): ThunkType =>
  (dispatch, getState) => {
    let bonuses = null
    if (currency === 'sweepstakeAmount') {
      bonuses = getSCManualAndInvitationBonusesSelector(getState())
    } else if (currency === 'goldAmount') {
      bonuses = getGCManualAndInvitationBonusesSelector(getState())
    }
    if (bonuses.length) {
      dispatch(sessionDialogsResolver('REWARD_DIALOG', { bonus: bonuses[0], bonusCurrency: currency }))
    }
  }

export const openNextBonusDialog =
  (code: string, currency?: 'sweepstakeAmount' | 'goldAmount' | 'fiatAmount'): ThunkType =>
  (dispatch, getState) => {
    const isScEnabled = sweepstakeEnabledSelector(getState())

    const currentCurrency = isScEnabled ? currency : 'goldAmount'
    const openNextBonus = checkNextUserBonus(code, (bonus: BaseBonus) =>
      dispatch(
        sessionDialogsResolver('REWARD_DIALOG', {
          bonus,
          bonusCurrency: currentCurrency,
        })
      )
    )
    switch (currentCurrency) {
      case 'sweepstakeAmount': {
        const sweepsBonuses = getSCManualAndInvitationBonusesSelector(getState())
        openNextBonus(sweepsBonuses)
        break
      }
      case 'goldAmount': {
        const gcBonusses = getGCManualAndInvitationBonusesSelector(getState())
        openNextBonus(gcBonusses)
        break
      }
      default: {
        const bonuses = getManualAndInvitationBonusesSelector(getState())
        openNextBonus(bonuses)
      }
    }
  }

// delayed bonuses flow
export const addDelayedBonus =
  (delayedBonus: BaseBonus): ThunkType =>
  (dispatch, getState) => {
    const delayedBonuses = getDelayedBonusesSelector(getState())
    const updatedBonuses = delayedBonuses
      ? uniqueItemsInArray([...delayedBonuses, delayedBonus], 'code')
      : [delayedBonus]
    dispatch(saveDelayedBonuses(updatedBonuses))
  }

export const addAllBonusesToDelayArray = (): ThunkType => (dispatch, getState) => {
  const allBonuses = getManualAndInvitationBonusesSelector(getState())
  dispatch(saveDelayedBonuses(allBonuses))
}

const removeDelayedBonusByCode =
  (code: string): ThunkType =>
  (dispatch, getState) => {
    const bonuses = getDelayedBonusesSelector(getState())
    const filteredBonuses = bonuses?.filter((el) => el.code !== code)
    dispatch(saveDelayedBonuses(filteredBonuses))
    dispatch(removeDialogByName('REWARD_DIALOG'))
  }

// acceptt and  ubdate bonus functions
export const acceptManualBonusResponse =
  (code: string): ThunkType =>
  (dispatch) =>
    dispatch(acceptBonusFlow(code, getManualBonusesSelector, saveManualBonuses, removeDelayedBonusByCode))

export const acceptInvitationBonusResponse =
  (code: string): ThunkType =>
  (dispatch) =>
    dispatch(acceptBonusFlow(code, getInvitationBonusesSelector, saveInvitatonBonuses, removeDelayedBonusByCode))

export const updateManualBonusesNotificationListener =
  (bonus: BaseBonus): ThunkType =>
  (dispatch) =>
    dispatch(updateBonusesNotification(bonus, getManualBonusesSelector, saveManualBonuses, checkUserBonuses))

export const updateInvitationBonusesNotificationListener =
  (bonus: InvitationBonus): ThunkType =>
  (dispatch) =>
    dispatch(updateBonusesNotification(bonus, getInvitationBonusesSelector, saveInvitatonBonuses, checkUserBonuses))

// =======daily bonuses
export const checkDailyBonus = (): ThunkType => (dispatch, getState) => {
  const bonuses = getDailyBonusesSelector(getState())
  const todayBonus = getTodayBonus(bonuses)

  if (todayBonus) {
    dispatch(sessionDialogsResolver('DAILY_REWARD_DIALOG'))
  }
}

export const acceptDailyBonus = (): ThunkType => (_, getState) => {
  const bonuses = getDailyBonusesSelector(getState())
  const todayBonus = getTodayBonus(bonuses)

  if (todayBonus) {
    gatewayEmit<AcceptDailyBonusResponse>({
      type: 'AcceptDailyBonusRequest',
      code: todayBonus.code,
    }).catch((err) => {
      Sentry.captureMessage(`fetch AcceptDailyBonusRequest ${err.message}`)
    })

    trackGAEvent('daily SC reward accepted', 'sc_daily_bonus_accepted', todayBonus.code)
  }
}

export const acceptUserBonusByTypeRequest =
  (code: string): ThunkType =>
  (dispatch, getState) => {
    const manualBonuses = getManualBonusesSelector(getState())
    const invitationBonuses = getInvitationBonusesSelector(getState())
    if (manualBonuses.find((el) => el.code === code)) {
      gatewayEmit<AcceptManualBonusResponse>({ type: 'AcceptManualBonusRequest', code })
        .then((body) => {
          dispatch(acceptManualBonusResponse(body.code))
        })
        .catch((err) => {
          Sentry.captureMessage(`fetch AcceptManualBonusRequest ${err.message}`)
        })
    } else if (invitationBonuses.find((el) => el.code === code)) {
      gatewayEmit<AcceptInvitationBonusResponse>({ type: 'AcceptInvitationBonusRequest', code })
        .then((body) => {
          dispatch(acceptInvitationBonusResponse(body.code))
        })
        .catch((err) => {
          Sentry.captureMessage(`fetch AcceptInvitationBonusRequest ${err.message}`)
        })
    }
  }
