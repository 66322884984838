import Root from './Root'

function PWAProvider() {
  return (
    <>
      <div
        id='pwa-detection'
        style={{ width: '100%', height: '100vh', position: 'fixed', left: 0, top: 0, zIndex: -1 }}
      />
      <Root />
    </>
  )
}

export default PWAProvider
