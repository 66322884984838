/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @returns {string|number}
 *
 * @example
 * // returns '12.5k'
 * shortenLargeNumber(12543, 1)
 *
 * @example
 * // returns '-13k'
 * shortenLargeNumber(-12567)
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345)
 */
function toFixedNoRounding(number: number, n?: number) {
  const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g')
  const a = number.toString().match(reg)[0]
  const dot = a.indexOf('.')
  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return `${a}.${'0'.repeat(n)}`
  }
  const b = n - (a.length - dot) + 1

  return b > 0 ? a + '0'.repeat(b) : a
}
export function shortenLargeNumber(num: number, digits?: number, shortenType?: boolean): number | string {
  const units = shortenType
    ? ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y']
    : ['k', ' Million', ' Billion', 'T', 'P', 'E', 'Z', 'Y']
  let decimal

  // eslint-disable-next-line
  for (let i = units.length - 1; i >= 0; i--) {
    decimal = 1000 ** (i + 1)

    if (num <= -decimal || num >= decimal) {
      return `${+toFixedNoRounding(num / decimal, digits)}${units[i]}`
    }
  }

  return num
}

export function intlnumberFormat(x: number, country: string = 'en-US', options?: Intl.NumberFormatOptions) {
  return new Intl.NumberFormat(country, { ...options }).format(x)
}

export function generateRandomNumberInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
