import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import { Counterparties } from './types'

const tempToken = (state: ApplicationState) => state.tempToken

export const getInternalTokenDataSelector = createSelector(tempToken, (tempTokenReducer) => tempTokenReducer.internal)
export const getPrizeoutTokenDataSelector = createSelector(tempToken, (tempTokenReducer) => tempTokenReducer.prizeout)
export const getNetentTokenDataSelector = createSelector(tempToken, (tempTokenReducer) => tempTokenReducer.netent)
export const getMarkortechTokenDataSelector = createSelector(
  tempToken,
  (tempTokenReducer) => tempTokenReducer.markortech
)
export const getRelaxTokenDataSelector = createSelector(tempToken, (tempTokenReducer) => tempTokenReducer.relax)

export const getPragmaticTokenDataSelector = createSelector(tempToken, (tempTokenReducer) => tempTokenReducer.pragmatic)

export const getAvailableSlotTokenSelector = createSelector(
  getNetentTokenDataSelector,
  getMarkortechTokenDataSelector,
  getPragmaticTokenDataSelector,
  getRelaxTokenDataSelector,
  (netentTokenData, markortechTokenData, pragmaticTokenData, relaxTokenData) =>
    netentTokenData.token || markortechTokenData.token || pragmaticTokenData.token || relaxTokenData.token
)

export const getGameTokenDataByProvider = createSelector(
  getNetentTokenDataSelector,
  getMarkortechTokenDataSelector,
  getPragmaticTokenDataSelector,
  getRelaxTokenDataSelector,
  (_: ApplicationState, provider: Counterparties) => provider,
  (netentTokenData, markortechTokenData, pragmaticTokenData, relaxTokenData, provider) => {
    switch (provider) {
      case 'markortech': {
        return markortechTokenData
      }
      case 'pragmatic': {
        return pragmaticTokenData
      }
      case 'netent': {
        return netentTokenData
      }
      case 'relax': {
        return relaxTokenData
      }
      default: {
        return null
      }
    }
  }
)
