import Link from 'next/link'
import { getDataFromJson } from 'src/utils/helpers'
import Button from 'src/components/Button'
import ROUTES from 'config/routes.json'
import content from 'translates/en/common.json'
import useStyles from './styles'

const t = getDataFromJson(content)

type Props = {}

export default function LoggedOut(props: Props) {
  const classes = useStyles(props)

  return (
    <>
      <Link href={ROUTES.LOGIN} prefetch={false}>
        <Button className={classes.button}>{t('Login')}</Button>
      </Link>

      <Link href={ROUTES.REGISTER} prefetch={false}>
        <Button className={classes.button}>{t('Register')}</Button>
      </Link>
    </>
  )
}
