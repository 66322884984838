import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import {
  getLottosWithDrawsSelector,
  getAllLottoRulesSelector,
  getVisibleAndSortedLottosSelector,
} from 'store/modules/games/selectors'
import { getUpcomingLottosSelector } from 'store/modules/playedGames/selectors'
import { getActiveModalCurrencyIdSelector, getFiatCurrencySelector } from 'store/modules/currencies/selectors'
import { compareArrays, getPoolName } from 'utils/helpers'
import { getLottoRulesByGameMode } from 'store/modules/games/utils'

import { PAYOUT_RECORD, HOW_TO_WIN_TIRES_TABLE, LOTTO_PRIZES_PAYOUT } from './types'
import { getClosestDraw } from 'src/containers/LottoTile/utils'
import { intlnumberFormat } from 'src/utils/numbers'
import { ProductModes, PayoutTypes, LottoProduct } from '../games/types'

const getActiveLottoCode = (state: ApplicationState) => state.lottoGameFlow.activeLottery
const getLottoClosestDraw = (state: ApplicationState) => state.lottoGameFlow.closestDraw
const getLottoHistoryNumbers = (state: ApplicationState) => state.lottoGameFlow.lottoNumbersHistory
// @TODO: ??? getOwnProps, getOwnPropsObject
const getOwnProps = (state: ApplicationState, ownProps: string | number) => ownProps

const getLottoLines = (state: ApplicationState) => state.lottoGameFlow.lottoLines

const getIsLineSending = (state: ApplicationState) => state.lottoGameFlow.isSending

export const getActiveLottoCodeSelector = createSelector(getActiveLottoCode, (lottoCode) => lottoCode)
export const getLottoLinesSelector = createSelector(getLottoLines, (item) => item)

export const getActiveLotterySelector = createSelector(
  getLottosWithDrawsSelector,
  getActiveLottoCodeSelector,
  (lottos: LottoProduct[], activeLottoCode) => lottos?.find((it) => it.code === activeLottoCode)
)

export const getActiveLottoRulesSelector = createSelector(
  getActiveLotterySelector,
  getAllLottoRulesSelector,
  (activeLotto, allRules) => {
    return getLottoRulesByGameMode(activeLotto?.mode, allRules)
  }
)

export const getCountLimitersSelector = createSelector(getActiveLottoRulesSelector, (rules) => {
  return {
    maxNumbersArrayLength: rules.maxNumbersArrayLength,
    maxBonusNumbersArrayLength: rules.maxBonusNumbersArrayLength,
  }
})

export const getLottoClosestDrawSelector = createSelector(getLottoClosestDraw, (closestDraw) => closestDraw)

export const getNextLottoSelector = createSelector(
  getActiveLotterySelector,
  getActiveModalCurrencyIdSelector,
  getVisibleAndSortedLottosSelector,
  getUpcomingLottosSelector,
  (activeLottery, activeCurrency, allLottos, sendedLotto) => {
    const activeLottoCurrency = activeLottery.mode === ProductModes.FREE ? 'USD' : activeCurrency
    const nextLotto = allLottos.find((lotto) => {
      const currenuLottoDraw = getClosestDraw(lotto)
      const currentLottoPick = sendedLotto.find(
        (it) => it.product === lotto.code && currenuLottoDraw?.id === it?.drawId && activeLottoCurrency === it.currency
      )

      if (currentLottoPick?.product !== activeLottery.code) {
        return !currentLottoPick?.picks?.length || currentLottoPick?.picks?.length < lotto.maxLines ? lotto : null
      } else {
        return null
      }
    })

    return nextLotto
  }
)

export const getArraysLimitersSelector = createSelector(getActiveLottoRulesSelector, (rules) => ({
  maxBonus: rules.maxBonus,
  maxNumbers: rules.maxNumbers,
}))

export const getLottoPlayAvaliableSelector = createSelector(
  getArraysLimitersSelector,
  getLottoLinesSelector,
  getLottoClosestDrawSelector,
  (limiters, lottoLines, closestDraw) => {
    const lines = lottoLines?.lines.filter((it) => {
      return (
        limiters.maxBonus === it.additionalNumbers.filter(Boolean).length &&
        limiters.maxNumbers === it.numbers.filter(Boolean).length
      )
    })
    const allNumbersSelected = lines?.length === lottoLines?.lines.length

    return Boolean(allNumbersSelected && closestDraw)
  }
)

export const getLottoHistoryNumbersSelector = createSelector(getLottoHistoryNumbers, (history) => {
  if (history) {
    return [
      history[0] && {
        id: history[0].id,
        drawDate: history[0].drawDate,
        numbers: history[0].numbers,
        additionalNumbers: history[0].additionalNumbers,
      },
      history[1] && {
        id: history[1].id,
        drawDate: history[1].drawDate,
        numbers: history[1].numbers,
        additionalNumbers: history[1].additionalNumbers,
      },
      history[2] && {
        id: history[2].id,
        drawDate: history[2].drawDate,
        numbers: history[2].numbers,
        additionalNumbers: history[2].additionalNumbers,
      },
    ].filter(Boolean)
  }

  return null
})

const getOwnPropsSelector = createSelector(getOwnProps, (ownProps) => ownProps)
export const getCurrentLotteryName = createSelector(
  getActiveLotterySelector,
  getOwnPropsSelector,
  (activeLottery, ownProps) => {
    if (activeLottery) {
      return activeLottery.title
    }

    return ownProps
  }
)

export const getCurrentLottoPicksLines = createSelector(
  getUpcomingLottosSelector,
  getActiveLottoCodeSelector,
  getLottoClosestDrawSelector,
  getActiveModalCurrencyIdSelector,
  getFiatCurrencySelector,
  (games, activeLottoCode, closestDraw, activeLocalCurrency, fiatCurrency) => {
    const currentPick = games?.find((it) => {
      const currency = it.currency === activeLocalCurrency || fiatCurrency === it.currency
      const draw = closestDraw?.id === it.drawId
      const lottoCode = it.product === activeLottoCode

      return lottoCode && draw && currency
    })

    return {
      lines: currentPick?.picks.map((it) => it.lines[0]),
      transactionId: currentPick?.transactionId,
      drawId: currentPick?.drawId,
    }
  }
)

export const getMaxDrawLinesSelector = createSelector(getActiveLotterySelector, (game) => {
  return game.maxLines
})

export const getLottoLineNumbersSelector = createSelector(getLottoLines, getOwnProps, (numbersArr, arrId: number) => {
  if (numbersArr) {
    return numbersArr.lines[arrId]
  }

  return null
})

export const isLotteryChangedSelector = createSelector(
  getCurrentLottoPicksLines,
  getLottoLinesSelector,
  (sendedPicks, currentPicks) => {
    const sendedPicksLines = sendedPicks?.lines
    const currentPicksLines = currentPicks?.lines

    if (!sendedPicksLines || !currentPicksLines) return true

    return !compareArrays(sendedPicksLines, currentPicksLines)
  }
)

// new lotto flow

export const getEmptyArraysIndexesSelector = createSelector(
  getLottoLinesSelector,
  getActiveLottoRulesSelector,
  (linesState, rules) => {
    const { maxBonus, maxNumbers } = rules
    const lines = linesState?.lines

    return lines
      ?.map((it, index) => {
        if (!it) return null
        if (it.numbers.length === maxNumbers && it.additionalNumbers.length === maxBonus) return index

        return null
      })
      .filter((it) => it !== null)
  }
)

export const isLinesUpdatedSelector = createSelector(
  getCurrentLottoPicksLines,
  getLottoLinesSelector,
  (sendedLines, localLines) => {
    if (sendedLines?.lines?.length && localLines?.lines?.length === sendedLines?.lines?.length) {
      return true
    }

    return false
  }
)

export const getIsLineSendingSelector = createSelector(getIsLineSending, (isSending) => {
  return isSending
})

const resolvePrizeDescription = (mergedPrizes: any, isCurrecyFullame?: boolean) => {
  const currencyDescription: { [key: string]: string } = {
    USD: 'US$',
    SC: 'Sweepstake Coins',
    GC: 'Gold Coins',
  }

  if (!mergedPrizes) {
    return ''
  }

  const convertPrize = intlnumberFormat(mergedPrizes.p, 'en-US', {
    style: 'decimal',
  })
  switch (isCurrecyFullame) {
    case true: {
      if (Object.prototype.hasOwnProperty.call(currencyDescription, mergedPrizes.c)) {
        return mergedPrizes.c === 'USD'
          ? `${currencyDescription[mergedPrizes.c]} ${convertPrize}`
          : `${convertPrize} ${currencyDescription[mergedPrizes.c]}`
      } else {
        return ''
      }
    }
    default: {
      return `${mergedPrizes.c === 'USD' ? '$' : mergedPrizes.c} ${convertPrize}`
    }
  }
}

const mergePrizes = (prizes: any) => {
  let item: number = null
  const arr: Array<{ p: number }> = []
  prizes.forEach((el: { p: number }) => {
    if (item !== el.p) {
      arr.push(el)
      item = el.p
    }
  })

  return arr
}

const getPrizesAndCurrenciesFromPayoutTableSelector = createSelector(
  getActiveLotterySelector,
  getLottoClosestDrawSelector,
  getActiveModalCurrencyIdSelector,
  getFiatCurrencySelector,
  (lottery, closestDraw, modalCurrency, fiatCurrency) => {
    if (lottery && closestDraw && modalCurrency) {
      const gameMode = getPoolName(modalCurrency, lottery.mode)
      const draw = closestDraw[gameMode]
      const jackpot = draw ? draw.jackpot : ''
      const currentCurrency = lottery.mode === ProductModes.FREE ? fiatCurrency : modalCurrency
      const currentPayout = PAYOUT_RECORD[currentCurrency] as keyof PayoutTypes
      const currentPayoutRecord = { t1: jackpot, ...lottery[currentPayout] }

      const prizes = Object.values(currentPayoutRecord).slice(0, Object.values(currentPayoutRecord).length / 2)
      const currencies = Object.values(currentPayoutRecord).slice(Object.values(currentPayoutRecord).length / 2)
      const mergedPrizes =
        lottery.mode === ProductModes.FREE
          ? mergePrizes(
              prizes.map((el, index) => {
                return {
                  c: currencies[index],
                  p: el,
                }
              })
            )
          : prizes.map((el, index) => {
              return {
                c: currencies[index],
                p: el,
              }
            })

      return {
        mergedPrizes,
        lottoMode: lottery.mode,
      }
    } else {
      return null
    }
  }
)

export const getHowToWinTableSelector = createSelector(
  getPrizesAndCurrenciesFromPayoutTableSelector,
  (pizesCurrecies) => {
    if (
      pizesCurrecies &&
      pizesCurrecies.lottoMode &&
      pizesCurrecies.mergedPrizes &&
      HOW_TO_WIN_TIRES_TABLE[pizesCurrecies.lottoMode as keyof typeof HOW_TO_WIN_TIRES_TABLE]
    ) {
      const { mergedPrizes, lottoMode } = pizesCurrecies

      return HOW_TO_WIN_TIRES_TABLE[lottoMode as keyof typeof HOW_TO_WIN_TIRES_TABLE].map((it, index) => {
        return [...it, `${resolvePrizeDescription(mergedPrizes[index], false)}`]
      })
    }

    return null
  }
)

export const getLottoPrizesTableSelector = createSelector(
  getPrizesAndCurrenciesFromPayoutTableSelector,
  (pizesCurrecies) => {
    if (
      pizesCurrecies &&
      pizesCurrecies.lottoMode &&
      pizesCurrecies.mergedPrizes &&
      LOTTO_PRIZES_PAYOUT[pizesCurrecies.lottoMode as keyof typeof LOTTO_PRIZES_PAYOUT]
    ) {
      const { mergedPrizes, lottoMode } = pizesCurrecies

      return LOTTO_PRIZES_PAYOUT[lottoMode as keyof typeof LOTTO_PRIZES_PAYOUT].map((it, index) => {
        return [...it, `${resolvePrizeDescription(mergedPrizes[index], true)}`]
      })
    }

    return null
  }
)

export const getCurrentTransactionId = createSelector(
  getLottoLinesSelector,
  getActiveLotterySelector,
  getUpcomingLottosSelector,
  getLottoClosestDrawSelector,
  getActiveModalCurrencyIdSelector,
  (lottoLines, activeLottery, upGames, closestDraw, activeCurrency) => {
    if (lottoLines?.transactionId) {
      return lottoLines.transactionId
    }
    if (activeLottery && upGames?.length !== 0) {
      return upGames.find(
        (el) => el.product === activeLottery.code && closestDraw.id === el.drawId && activeCurrency === el.currency
      )?.transactionId
    }

    return null
  }
)

export const getActiveLotteryModeSelector = createSelector(getActiveLotterySelector, (lotto) => lotto?.mode)
