import dynamic from 'next/dynamic'
import { Fragment, ReactNode } from 'react'
import Hidden from '@material-ui/core/Hidden'
import MaintenanceMessage from 'components/MaintenanceMessage'
import Header from './Header'
import Footer from './Footer'
import TabNavigation from './TabNavigation'
import ZendeskChat from 'containers/ZendeskChat'
import { useRouter } from 'next/router'
import useStyles from './styles'

import MenuMobile from 'containers/Menu/MenuMobile'
import useResetScroll from 'hooks/useResetScroll'

const MenuDesktop = dynamic(() => import('containers/Menu/MenuDesktop'))

type LayoutPropsType = {
  children: ReactNode
  layoutType: string | null
  pageProps?: any
}

function Layout(props: LayoutPropsType) {
  const classes = useStyles(props)
  const router = useRouter()

  useResetScroll()

  return (
    <>
      <Header />

      <div className={classes.menuDesktop}>
        <Hidden only={['xs']}>
          <MenuDesktop />
        </Hidden>
      </div>

      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation='css'>
        <MenuMobile />
      </Hidden>

      <div className={classes.content}>
        <main className={classes.main}>
          <TabNavigation pageProps={props?.pageProps} layoutType={props.layoutType} />

          <Fragment key={router.asPath}>
            {process.env.MAINTENANCE && <MaintenanceMessage />}

            {props.children}
          </Fragment>
        </main>

        <ZendeskChat />

        <Footer />
      </div>
    </>
  )
}
export default Layout
