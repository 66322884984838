import Router from 'next/router'
import { ThunkType } from 'store/modules/types'
import {
  SlotGameFlowActionsType,
  ClearSlotGameFlowAction,
  SetActiveGameCodeAction,
  FreeSpinType,
  SaveFreeSpinGamesAction,
} from './types'
import {
  isGuestModeSelector,
  isPreviewModeSelector,
  isLoggedInSelector,
  skipOtpSelector,
  getUserRestrictionsSelector,
} from 'store/modules/user/selectors'
import { GameProduct } from 'store/modules/games/types'

import { openDialog, closeAllDialogs, replaceDialog, sessionDialogsResolver } from 'store/modules/dialog/actions'

import { Currencies, Currency } from 'store/modules/currencies/types'
import { CurrencyToProductMode } from 'store/modules/currencies/utils'
import { gamesRoutesGenerator, gameURLsGeneratorMobileOrDesktop } from 'utils/navigator'
import { trackScreenViewEvent } from 'src/utils/gtag'
import { getFirstUnlockedSlotSelector } from '../games/selectors'
import { getDialogStackSelector } from '../dialog/selectors'
import ROUTES from 'config/routes.json'
import { openPreviewModePromo } from '../appConfig/actions'
import { getActiveSlotSelector } from './selectors'
import { isDiscountOffersAvailable } from '../shop/selectors'
import { getItemFormLocalStorage, setDataToLocalStorage } from 'src/utils/localStorage'

export const setActiveGameCode = (code: string): SetActiveGameCodeAction => ({
  type: SlotGameFlowActionsType.SET_ACTIVE_GAME_CODE,
  payload: {
    code,
  },
})

export const clearSlotGameFlow = (): ClearSlotGameFlowAction => ({
  type: SlotGameFlowActionsType.CLEAR_SLOT_GAME_FLOW,
})

export const saveFreeSpins = (freeSpins: FreeSpinType[]): SaveFreeSpinGamesAction => ({
  type: SlotGameFlowActionsType.SAVE_FREE_SPIN_GAMES,
  payload: {
    freeSpins,
  },
})

export const startExternalGame =
  (game: GameProduct): ThunkType =>
  (dispatch) => {
    trackScreenViewEvent('StartGameScreen')
    dispatch(
      openDialog('START_GAME_FLOW', {
        gameCode: game.code,
        gameType: game.type,
      })
    )
  }

export const goToPlayGame =
  (game: GameProduct, activeCurrency: Currencies): ThunkType =>
  (dispatch) => {
    const smBreakpoint = 870
    const isMobile = window.innerWidth < smBreakpoint

    const href = gameURLsGeneratorMobileOrDesktop('slots', isMobile).game[activeCurrency]
    const as = gamesRoutesGenerator[href](game.title, CurrencyToProductMode[activeCurrency])

    const route = {
      href,
      as,
    }

    dispatch(closeAllDialogs())
    Router.push(route.href, route.as)
  }

export const openFirstSlotGame =
  (location: string): ThunkType =>
  (dispatch, getState) => {
    const dialogStack = getDialogStackSelector(getState())
    if (location === ROUTES.HOME_LOGGED_OUT && !dialogStack?.find((it) => it.modalName === 'START_GAME_FLOW')) {
      const firstGame = getFirstUnlockedSlotSelector(getState())
      if (firstGame) {
        dispatch(startExternalGame(firstGame))
      }
    }
  }

export const startExternalGameFlow =
  (game: GameProduct, activeCurrency: Currencies): ThunkType =>
  (dispatch, getState) => {
    const isPreviewMode = isPreviewModeSelector(getState())
    const isGuest = isGuestModeSelector(getState())
    const isLoggedIn = isLoggedInSelector(getState())
    const skipOtp = skipOtpSelector(getState())
    const preview = isPreviewMode && activeCurrency === 'SC'
    const isOTPrequired = isLoggedIn && !skipOtp && activeCurrency === 'SC' && !window.ReactNativeWebView
    const userRestrictions = getUserRestrictionsSelector(getState())

    if (!isLoggedIn) {
      dispatch(closeAllDialogs())
      Router.push(ROUTES.LOGIN)
    } else if (preview && isGuest) {
      dispatch(openDialog('SCPR_AUTH_WAYS_DIALOG'))
    } else if (preview) {
      dispatch(openPreviewModePromo('START_GAME_FLOW', game.code))
    } else if (isOTPrequired) {
      dispatch(closeAllDialogs())
      dispatch(openDialog('PHONE_VERIFICATION_DIALOG'))
    } else if (userRestrictions.includes('no_game')) {
      dispatch(closeAllDialogs())
      dispatch(openDialog('RESTRICT_USER_DIALOG'))
    } else {
      dispatch(goToPlayGame(game, activeCurrency))
    }
  }

const isSlotOpened = (pathname: string) => {
  return pathname.includes('slots')
}

export const insufficientBalanceNotificationHandler = (): ThunkType => (dispatch, getState) => {
  const isOfferAvaialble = isDiscountOffersAvailable(getState())
  const lastTimeDisplayed = getItemFormLocalStorage('NOT_ENOUGH_FUNDS')
  const timeout = 5 * 60 * 1000
  if (lastTimeDisplayed && Date.now() - Number(lastTimeDisplayed) <= timeout) {
    return null
  }

  dispatch(setDataToLocalStorage('NOT_ENOUGH_FUNDS', Date.now().toString()))
  if (isOfferAvaialble) {
    dispatch(replaceDialog('NOT_ENOUGH_FUNDS'))
    dispatch(replaceDialog('EXTRA_GOLD_DIALOG'))
  } else {
    dispatch(replaceDialog('NOT_ENOUGH_FUNDS'))
  }

  return null
}

export const notEnoughMoneyHandler =
  (balances: Currency[]): ThunkType =>
  (dispatch, getState) => {
    const activeSlot = getActiveSlotSelector(getState())
    const activeCurrency = Router.query?.currencyName ? 'SC' : 'GC'
    const balance = balances.find((i: Currency) => i.currency === activeCurrency)
    const balancePolicy = activeCurrency === 'GC' ? 50 : 0.25
    const isNotEnoughOpen = getDialogStackSelector(getState())?.find((el) => el.modalName === 'NOT_ENOUGH_FUNDS')

    if (
      !isNotEnoughOpen &&
      activeSlot &&
      !activeSlot?.supplier?.includes?.('booongo') &&
      !activeSlot?.provider?.includes?.('relax') &&
      balance?.amount < balancePolicy &&
      isSlotOpened(Router.pathname)
    ) {
      dispatch(insufficientBalanceNotificationHandler())
    }

    return null
  }

export const cleanUpGame = (): ThunkType => (dispatch) => {
  dispatch(clearSlotGameFlow())
}

export const openFreeSpinsDialog = (): ThunkType => (dispatch) => {
  dispatch(sessionDialogsResolver('FREE_SPINS_DIALOG'))
}
