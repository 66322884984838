import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.black,
    minHeight: 36,
    maxHeight: 36,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    zIndex: 2,
    '@media screen and (orientation: landscape)': {
      '@supports (padding-left: constant(safe-area-inset-left))': {
        '--safe-area-inset-left': 'constant(safe-area-inset-left)',
        paddingLeft: 'calc(8px + var(--safe-area-inset-left))',
      },
      '@supports (padding-right: constant(safe-area-inset-right))': {
        '--safe-area-inset-right': 'constant(safe-area-inset-right)',
        paddingRight: 'calc(8px + var(--safe-area-inset-right))',
      },
    },
  },
  backButton: {
    padding: '4px !important',
    margin: -theme.spacing(1),
  },
  info: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  button: {
    height: 24,
    lineHeight: 1,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 8,
    '&:after': {
      top: -theme.spacing(2),
      height: theme.spacing(2),
    },
    '&:before': {
      bottom: -theme.spacing(2),
      height: theme.spacing(2),
    },
    '&:nth-last-of-type': {
      marginLeft: 0,
    },
  },
  // add icon component
  adIcon: {
    maxHeight: 28,
    marginTop: 3,
  },
  adIconRoot: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    position: 'relative',
    minWidth: 40,
    marginRight: '0.5rem',
  },
  adIconText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '0.6rem',
    position: 'absolute',
    width: '100%',
    bottom: 1,
    left: 0,
  },
  movieIcon: {
    fontSize: '0.9rem',
    marginRight: 3,
  },
}))
