import { Reducer } from 'redux'
import { MiniGameFlowState, MiniGameFlowActionTypes, Actions } from './types'

const initialState: MiniGameFlowState = {
  isMiniGameRunning: false,
}

// @TODO rename save lottos to save games
const reducer: Reducer<MiniGameFlowState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case MiniGameFlowActionTypes.MINI_GAME_IS_RUNNING: {
      return {
        ...state,
        isMiniGameRunning: action.payload.isMiniGameRunning,
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as miniGameFlowReducer }
