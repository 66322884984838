import { getLinkObjectFromMessage, LINK_REGEX } from './utils'
import { Fragment, ReactNode, useState } from 'react'

type Props = {
  message: string
}

export default function MessageContent(props: Props) {
  const { message } = props
  const [messageLink] = useState(getLinkObjectFromMessage(message))
  if (messageLink?.title) {
    const parts: Array<ReactNode> = [...message.split(LINK_REGEX)]

    return (
      <>
        {parts.map((el, index) => {
          if (el === messageLink.key) {
            return (
              <a href={messageLink.link} key={index} target='_blank' rel='noreferrer'>
                {messageLink.title}
              </a>
            )
          }

          return <Fragment key={index}>{el}</Fragment>
        })}
      </>
    )
  }

  return <>{message}</>
}
