import {
  ActionTypes,
  TempTokenRequestOptions,
  TempTokenResponseOptions,
  GetTempTokenAction,
  SetTempTokenAction,
  ClearTempTokenAction,
  Counterparties,
} from './types'
import { ThunkType } from 'store/modules/types'

import { WSinstance } from 'services/websocket'

export const getTempToken = (options: TempTokenRequestOptions): GetTempTokenAction => ({
  type: ActionTypes.GET_TEMP_TOKEN,
  payload: {
    options,
  },
})

export const setTempToken = (tokenData: TempTokenResponseOptions): SetTempTokenAction => ({
  type: ActionTypes.SET_TEMP_TOKEN,
  payload: {
    tokenData,
  },
})

export const clearTempToken = (counterparty: Counterparties): ClearTempTokenAction => ({
  type: ActionTypes.CLEAR,
  payload: {
    counterparty,
  },
})

export const requestTempToken =
  (options: TempTokenRequestOptions): ThunkType =>
  (dispatch) => {
    dispatch(clearTempToken(options.counterparty))

    dispatch(getTempToken(options))
    dispatch(WSinstance.emitWS({ type: 'GetTemporaryTokenRequest', ...options }))
  }
