import usePWAInstance, { isPWAAvailable } from 'src/hooks/usePWA'
import cx from 'classnames'

import AccountIdMessage from 'containers/AccountIdMessage'
import SocialButtons from '../SocialButtons'
import PWAInfo from '../PWAInfo'

import useStyles from './styles'

type Props = {}

function MenuFooter(props: Props) {
  const classes = useStyles(props)
  const pwaInstance = usePWAInstance()
  const isPwaAvailable = isPWAAvailable()

  const isPWAInit = Boolean(isPwaAvailable && pwaInstance)

  return (
    <>
      <div className={cx(classes.root, isPWAInit && classes.withPwa)} data-test='menu-footer-links'>
        <SocialButtons />
        {isPWAInit && <PWAInfo pwaInstance={pwaInstance} />}
      </div>
      <AccountIdMessage />
    </>
  )
}

export default MenuFooter
