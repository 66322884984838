import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'

const getDialogStack = (state: ApplicationState) => state.dialog.stack

export const getDialogStackSelector = createSelector(getDialogStack, (stack) => stack)

export const getDialogVisibilitySelector = createSelector(getDialogStackSelector, (stack) => stack.length > 0)

export const getActiveDialogProps = createSelector(getDialogStackSelector, (stack) => {
  return stack[stack.length - 1]
})
