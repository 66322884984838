// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum MiniGameFlowActionTypes {
  MINI_GAME_IS_RUNNING = 'games/MINI_GAME_IS_RUNNING',
}

export interface MiniGameIsRunningAction {
  type: MiniGameFlowActionTypes.MINI_GAME_IS_RUNNING
  payload: {
    isMiniGameRunning: boolean
  }
}

export type Actions = MiniGameIsRunningAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface MiniGameFlowState {
  readonly isMiniGameRunning: boolean
}
