import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// @ts-ignore
import { usePageVisibility } from 'react-page-visibility'
import { appStateActiveHandler } from 'services/websocket'
import { savePlayedGamesToLocalStorage } from 'src/store/modules/playedGames/actions'

export default function VisibilityProvider(): null {
  const isVisible = usePageVisibility()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!process.browser) {
      return null
    }

    // tab becomes active
    if (isVisible) {
      if (!window.ReactNativeWebView) {
        dispatch(appStateActiveHandler())
      }
      // tab in background
    } else {
      dispatch(savePlayedGamesToLocalStorage())
    }

    return null
  }, [isVisible])

  return null
}
