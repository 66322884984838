import { connect } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import {
  getSnackbarVisibilitySelector,
  getSnackbarVariantSelector,
  getSnackbarMessageSelector,
  getSnackbarActionButtonSelector,
  getSnackbarAutoHideSelector,
  getSnackbarMessageAlignSelector,
  getSnackbarComponentPropsSelector,
  getPositionVerticalSelector,
  getPositionHorizontalSelector,
} from 'store/modules/snackbar/selectors'
import { closeSnackbar } from 'store/modules/snackbar/actions'

import { SnackbarVariants, SnackbarActionButton, MessageAlign } from 'store/modules/snackbar/types'
import { ApplicationState } from 'store/modules/types'
import SnackbarContent from './SnackbarContent'

export interface Props {
  isSnackbarVisible: boolean
  closeSnackbar: typeof closeSnackbar
  message: string
  variant: SnackbarVariants
  actionButton: SnackbarActionButton | null
  autoHide: number | null
  messageAlign: MessageAlign
  messageProps?: Record<string, any>
  positionVertical: 'bottom' | 'top'
  positionHorizontal: 'center' | 'left' | 'right'
}

const mapState = (state: ApplicationState) => ({
  isSnackbarVisible: getSnackbarVisibilitySelector(state),
  message: getSnackbarMessageSelector(state),
  variant: getSnackbarVariantSelector(state),
  actionButton: getSnackbarActionButtonSelector(state),
  autoHide: getSnackbarAutoHideSelector(state),
  messageAlign: getSnackbarMessageAlignSelector(state),
  messageProps: getSnackbarComponentPropsSelector(state),
  positionVertical: getPositionVerticalSelector(state),
  positionHorizontal: getPositionHorizontalSelector(state),
})
const mapDispatch = {
  closeSnackbar,
}

function GlobalSnackbar(props: Props) {
  const CurstomComponent = props.message

  if (!CurstomComponent) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.positionVertical || 'bottom',
        horizontal: props.positionHorizontal || 'center',
      }}
      open={props.isSnackbarVisible}
      onClose={() => {
        setTimeout(() => {
          props.closeSnackbar()
        }, props.autoHide || 4000)
      }}
      autoHideDuration={props.autoHide}
    >
      {typeof CurstomComponent === 'string' ? (
        <SnackbarContent
          variant={props.variant}
          message={props.message}
          actionButton={props.actionButton}
          messageAlign={props.messageAlign}
        />
      ) : (
        // @ts-ignore
        <CurstomComponent {...props.messageProps} />
      )}
    </Snackbar>
  )
}

export default connect(mapState, mapDispatch)(GlobalSnackbar)
