import { Reducer } from 'redux'

import { compareByDate } from 'utils/date'
import { PlayedGamesState, PlayedGamesActionTypes, Actions } from './types'

const initialState: PlayedGamesState = {
  acceptedLottos: null,
  closedLottos: null,
  games: null,
}

const reducer: Reducer<PlayedGamesState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case PlayedGamesActionTypes.SAVE_LOTTOS: {
      return {
        ...state,
        acceptedLottos: action.payload.accepted,
        closedLottos: action.payload.closed,
      }
    }

    case PlayedGamesActionTypes.GET_PLAYED_GAMES: {
      return {
        ...state,
        games: action.payload.games,
      }
    }

    case PlayedGamesActionTypes.UPDATE_PLAYED_LOTTO: {
      const { game } = action.payload
      const { acceptedLottos, closedLottos } = state

      // remove from upcoming
      const upcoming = acceptedLottos && acceptedLottos.filter((i) => i.id !== game.id)
      // add to past
      const past = closedLottos ? [...closedLottos, game] : [game]

      return {
        ...state,
        // sort games by date. Earlier first
        acceptedLottos: upcoming?.slice().sort((a, b) => compareByDate(b.drawDate, a.drawDate)),
        closedLottos: past?.slice().sort((a, b) => compareByDate(b.drawDate, a.drawDate)),
      }
    }

    case PlayedGamesActionTypes.UPDATE_GAME: {
      const { acceptedLottos } = state
      const hasGame = acceptedLottos.find((it) => it.id === action.payload.game.id)

      let games
      if (hasGame) {
        games = acceptedLottos.map((it) => {
          if (it.id === hasGame.id) {
            return { ...action.payload.game }
          }

          return it
        })
      } else {
        games = [{ ...action.payload.game }, ...acceptedLottos]
      }

      return {
        ...state,
        acceptedLottos: games,
      }
    }

    case PlayedGamesActionTypes.CLEAR_ALL_GAMES:
      return initialState

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as playedGamesReducer }
