import { useEffect } from 'react'
import usePWAInstance, { isPWAAvailable } from 'src/hooks/usePWA'

import useDeviceDetect from 'src/hooks/useDeviceDetect'
import IOS from './components/IOS'
import Android from './components/Android'
import MessageLayout from './components/MessageLayout'
import { togglePWAMessage } from 'src/store/modules/appConfig/actions'
import { getIsPWAMessageOpenSelector } from 'src/store/modules/appConfig/selectors'
import { connect } from 'react-redux'
import { CLOSE_AUTO_PWA_DIALOG, getItemFormLocalStorage, setDataToLocalStorage } from 'src/utils/localStorage'
import { ApplicationState } from 'store/modules/types'
import useIsSafari from 'src/hooks/useIsSafari'

type Props = {
  togglePWAMessage: typeof togglePWAMessage
  isPWAOpenMessage: boolean
  setDataToLocalStorage: typeof setDataToLocalStorage
}

const mapState = (state: ApplicationState) => ({
  isPWAOpenMessage: getIsPWAMessageOpenSelector(state),
})
const mapDispatch = {
  togglePWAMessage,
  setDataToLocalStorage,
}

const barListener = (isPWAOpenMessage: boolean, togglePWAMessageFunc: (arg: boolean) => void) => () => {
  const isAutoPWADialogClose = getItemFormLocalStorage(CLOSE_AUTO_PWA_DIALOG, false)
  const isMinimalUi = document.getElementById?.('pwa-detection')?.offsetHeight - window.innerHeight <= 0

  if (!isAutoPWADialogClose) {
    if (!isMinimalUi && !isPWAOpenMessage) {
      togglePWAMessageFunc(true)
    } else {
      togglePWAMessageFunc(false)
    }
  }
}

function Root(props: Props) {
  const pwaInstance = usePWAInstance()
  const deviceDetect = useDeviceDetect()
  const isIOS = useIsSafari()
  const isAndroid = deviceDetect.isAndroid()

  const isPwaAvailable = isPWAAvailable()

  const closeMessage = () => {
    props.setDataToLocalStorage(CLOSE_AUTO_PWA_DIALOG, true, false)
    props.togglePWAMessage(false)
  }

  useEffect(() => {
    const scrollHandler = barListener(props.isPWAOpenMessage, props.togglePWAMessage)
    if (isPwaAvailable && (isAndroid || isIOS)) {
      window.addEventListener('scroll', scrollHandler)
    }

    return () => {
      if (isPwaAvailable && (isAndroid || isIOS)) {
        window.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [isPwaAvailable, isAndroid, isIOS])

  if (!isPwaAvailable || !pwaInstance || !props.isPWAOpenMessage) {
    return null
  }

  if (isIOS && props.isPWAOpenMessage) {
    return (
      <MessageLayout closeHandler={closeMessage}>
        <IOS />
      </MessageLayout>
    )
  }
  if (isAndroid && props.isPWAOpenMessage) {
    return (
      <MessageLayout closeHandler={closeMessage}>
        <Android pwaInstance={pwaInstance} buttonHandler={closeMessage} />
      </MessageLayout>
    )
  }

  return null
}

export default connect(mapState, mapDispatch)(Root)
