import Typography from '@material-ui/core/Typography'
import Head from 'next/head'
import Button from 'components/Button'
import { getDataFromJson } from 'src/utils/helpers'
import useStyles from './styles'
import Layout from './Layout'
import { useSelector } from 'react-redux'
import { getUserSelector } from 'src/store/modules/user/selectors'
import { isAppInitializedSelector, sweepstakeEnabledSelector } from 'src/store/modules/appConfig/selectors'
import useResendEmail from './useResendEmail'

const t = getDataFromJson({})

type Props = {}

function VerifyEmailFail(props: Props) {
  const classes = useStyles(props)
  const appInitialized = useSelector(isAppInitializedSelector)
  const userInfo = useSelector(getUserSelector)
  const scEnabled = useSelector(sweepstakeEnabledSelector)
  const { resendMail, inProgress } = useResendEmail()

  if (!appInitialized) {
    return null
  }

  return (
    <>
      <Head>
        <title>{t(`Email was not verified for ${process.env.BRAND}.com`)}</title>
      </Head>

      <Layout>
        <>
          <Typography component='h1' variant='h4' className={classes.title}>
            {t('Email was not verified')}
          </Typography>
          <Typography variant='body2' component='p'>
            {(() => {
              if (scEnabled && userInfo?.id) {
                return (
                  <>
                    Your email was not verified. Please try to resend verification email again, or contact our{' '}
                    <a href={process.env.ZENDESK_SUPPORT_LINK} target='_blank' rel='noreferrer'>
                      support
                    </a>
                    .
                  </>
                )
              }
              if (scEnabled) {
                return (
                  <>
                    Your email was not verified. Please contact our{' '}
                    <a href={process.env.ZENDESK_SUPPORT_LINK} target='_blank' rel='noreferrer'>
                      support
                    </a>
                    .
                  </>
                )
              }
              if (userInfo?.id) {
                return 'Please try to resend verification email again.'
              }

              return ''
            })()}
          </Typography>
          {userInfo?.id && (
            <Button disabled={inProgress} onClick={resendMail}>
              send again
            </Button>
          )}
        </>
      </Layout>
    </>
  )
}

export default VerifyEmailFail
