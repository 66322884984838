import Head from 'next/head'
import content from 'translates/en/document.json'

export default function HeadApp() {
  return (
    <Head>
      <meta
        charSet='utf-8'
        name='viewport'
        content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=1, user-scalable=0'
      />

      <meta property='og:title' content={content.siteName} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={`${process.env.ROOT_URL}/`} />
      <meta property='og:image' content={`${process.env.ROOT_URL}/images/activate_sc_${process.env.BRAND_NAME}.jpg`} />
      <meta property='og:image:type' content='image/jpeg' />
      <meta property='og:image:width' content='300' />
      <meta property='og:image:height' content='300' />
      <meta property='og:image:alt' content={content.siteShareAlt} />
      <meta property='og:description' content={content.siteDescription} />
    </Head>
  )
}
