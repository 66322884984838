import LAYOUTS from 'config/layouts'
import ROUTES from 'config/routes.json'
import { NextRouter } from 'next/router'
import tabsUrls from './tabsUrls.json'

export const pageAnimationDelay = 300

const headerConfigs = {
  [LAYOUTS.LOTTO_TABS_LAYOUT]: (
    activeLottery?: { title: string } | null,
    currencyBaseRouter?: Function,
    router?: NextRouter
  ) => {
    return [
      {
        title: activeLottery?.title,
        pathname: `${ROUTES.LOTTERY_PLAY} ${ROUTES.LOTTERY_PLAY_WITH_CURRENCY}`,
        onClick: (callBack?: () => void) => {
          currencyBaseRouter(router, ROUTES.LOTTERY_PLAY, activeLottery.title).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Rules & FAQ',
        pathname: `${ROUTES.LOTTERY_FAQ} ${ROUTES.LOTTERY_FAQ_WITH_CURRENCY}`,
        onClick: (callBack?: () => void) => {
          currencyBaseRouter(router, ROUTES.LOTTERY_FAQ, activeLottery.title).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Prizes',
        pathname: `${ROUTES.LOTTERY_PRIZES} ${ROUTES.LOTTERY_PRIZES_WITH_CURRENCY}`,
        onClick: (callBack?: () => void) => {
          currencyBaseRouter(router, ROUTES.LOTTERY_PRIZES, activeLottery.title).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Results',
        pathname: `${ROUTES.LOTTERY_RESULTS} ${ROUTES.LOTTERY_RESULTS_WITH_CURRENCY}`,
        onClick: (callBack?: () => void) => {
          currencyBaseRouter(router, ROUTES.LOTTERY_RESULTS, activeLottery.title).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
    ]
  },

  [LAYOUTS.MY_GAMES_TABS_LAYOUT]: (Router?: { push: Function }) => {
    return [
      {
        title: 'Lotto',
        pathname: ROUTES.MY_GAMES_LOTTO,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.MY_GAMES_LOTTO).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Slots',
        pathname: ROUTES.MY_GAMES_SLOTS,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.MY_GAMES_SLOTS).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Table Games',
        pathname: ROUTES.MY_GAME_TABLE,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.MY_GAME_TABLE).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
    ]
      .filter(Boolean)
      .filter((el) => tabsUrls.MyGamesTabs.includes(el.pathname))
  },

  [LAYOUTS.ALL_GAMES_TABS_LAYOUT]: (Router?: { push: Function }) => {
    return [
      {
        title: 'Top',
        pathname: ROUTES.GAMES_TOP,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_TOP).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'New',
        pathname: ROUTES.GAMES_NEW,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_NEW).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Epic',
        pathname: ROUTES.GAMES_EPIC,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_EPIC).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Megaways',
        pathname: ROUTES.MEGAWAYS,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.MEGAWAYS).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Classic',
        pathname: ROUTES.GAMES_CLASSIC,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_CLASSIC).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Jackpots',
        pathname: ROUTES.GAMES_JACKPOTS,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_JACKPOTS).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Hold and Win',
        pathname: ROUTES.GAMES_HAW,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_HAW).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Slots',
        pathname: ROUTES.GAMES_SLOTS,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_SLOTS).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Lotto',
        pathname: ROUTES.GAMES_LOTTOS,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_LOTTOS).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
      {
        title: 'Table Games',
        pathname: ROUTES.GAMES_TABLE,
        onClick: (callBack?: () => void) => {
          Router.push(ROUTES.GAMES_TABLE).then(() => {
            setTimeout(() => {
              callBack()
            }, pageAnimationDelay)
          })
        },
      },
    ]
      .filter(Boolean)
      .filter((el) => tabsUrls.AllGamesTabs.includes(el.pathname))
  },
}

export default headerConfigs
