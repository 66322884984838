import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getItemFormLocalStorage, setDataToLocalStorage } from 'utils/localStorage'

function SaveWebReferrer(): null {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!document?.referrer?.includes(process.env.ROOT_URL)) {
      dispatch(setDataToLocalStorage('REFERRER', document.referrer, false))
    }

    // first utm
    if (!getItemFormLocalStorage('FIRST_UTM', false)) {
      const searchString = new URLSearchParams(window.location?.search?.replace?.('?', ''))
      dispatch(
        setDataToLocalStorage(
          'FIRST_UTM',
          searchString?.has?.('utm_campaign') ? searchString.get('utm_campaign') : 'blank',
          false
        )
      )
    }

    // set query exclude auth flow
    if (!window.location.href.includes('access_token') && !window.location.href.includes('id_token')) {
      dispatch(setDataToLocalStorage('QUERY', window.location?.search?.replace?.('?', ''), false))
    }
  }, [])

  return null
}

export default SaveWebReferrer
