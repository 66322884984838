import { connect } from 'react-redux'

import AppBar from '@material-ui/core/AppBar'
import {
  isLoggedInSelector,
  getUserSelector,
  getUserXpLevelSelector,
  getCurrentLevelPercentageSelector,
  getUserSubscriptionsSelector,
} from 'store/modules/user/selectors'
import { isAppInitializedSelector, isNativeAppSelector } from 'store/modules/appConfig/selectors'

import LogoLink from 'containers/LogoLink'
import { ApplicationState } from 'store/modules/types'
import { User, ILevel, SubscriptionType } from 'store/modules/user/types'
import UserAvatarWithLevel from 'containers/UserAvatarWithLevel'
import useStyles from './styles'
import LoggedIn from './components/LoggedIn'
import LoggedOut from './components/LoggedOut'
import { openDialog } from 'store/modules/dialog/actions'
import { DialogNames, OpenDialogAction } from 'store/modules/dialog/types'

const mapState = (state: ApplicationState) => ({
  isLoggedIn: isLoggedInSelector(state),
  user: getUserSelector(state),
  xpLevel: getUserXpLevelSelector(state),
  currentLevelPercentage: getCurrentLevelPercentageSelector(state),
  isAppInitialized: isAppInitializedSelector(state),
  isUserVipSubscription: getUserSubscriptionsSelector(state),
  isNative: isNativeAppSelector(state),
})
const mapDispatch = {
  openDialog,
}

type HeaderProps = {
  isLoggedIn: boolean
  isAppInitialized: boolean
  user: User
  xpLevel: ILevel
  currentLevelPercentage: number
  openDialog: (dialogName: DialogNames) => OpenDialogAction
  isUserVipSubscription: Array<SubscriptionType>
  isNative: boolean
}

function Header(props: HeaderProps) {
  const classes = useStyles(props)

  return (
    <AppBar className={classes.root} color='default'>
      <div className={classes.logoContainer}>
        {props.isLoggedIn && (
          <>
            {/* <Link href={ROUTES.MY_ACCOUNT}>
              <a className={classes.avatarLink}> */}
            <UserAvatarWithLevel
              isUserVipSubscription={props.isUserVipSubscription}
              user={props.user}
              xpLevel={props.xpLevel}
              levelValue={props.currentLevelPercentage}
            />
            {/* </a>
            </Link> */}
          </>
        )}
        <LogoLink />
      </div>

      {props.isAppInitialized && <>{props.isLoggedIn ? <LoggedIn /> : <LoggedOut openDialog={props.openDialog} />}</>}
    </AppBar>
  )
}

export default connect(mapState, mapDispatch)(Header)
