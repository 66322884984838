import { ShopOffer } from 'store/modules/shop/types'
import { Currencies } from 'store/modules/currencies/types'
import loadScript from 'src/utils/loadScript'

export const fbPurchaseEvent = (offer: Partial<ShopOffer>, fiatCurrency: Currencies) => {
  if (window.fbq) {
    window.fbq('track', 'Purchase', {
      value: offer.price,
      currency: fiatCurrency,
      content_name: offer.title,
      content_ids: [offer.code],
    })
  } else {
    // eslint-disable-next-line
    console.log('fbq not initialized')
  }
}

export const fbCompleteRegistration = () => {
  if (window.fbq) {
    window.fbq('track', 'CompleteRegistration')
  }
}

export const fbAdvancedMatchingEvent = (userInfo: { em: string; fn: string; ln: string; ph: string }) => {
  if (window.fbq) {
    window.fbq('init', process.env.FB_PIXEL, userInfo)
  }
}

type FBResponse = {
  email: string
  name: string
  id: string
  error: { message: string }
}

export const initFacebookApp = (appId: string) =>
  new Promise((res, rej) => {
    loadScript('https://connect.facebook.net/en_US/sdk.js')
      .then(() => {
        window.FB.init({
          appId,
          autoLogAppEvents: true,
          cookie: true,
          status: true,
          xfbml: true,
          version: 'v9.0',
        })
        res(FB)
      })
      .catch((err) => {
        rej(err)
      })
  })

export const getFacebookUserData = (token: string) =>
  new Promise<FBResponse>((res, rej) => {
    window.FB.api(
      '/me',
      {
        access_token: token,
        fields: 'email,name,id',
      },
      (data: FBResponse) => {
        if (data.error) {
          rej(data.error)
        } else {
          res(data)
        }
      }
    )
  })

export const fbLoginViaSDK = () =>
  new Promise<{ authResponse: { accessToken: string } }>((res, rej) => {
    window.FB.login(
      (resault) => {
        if (resault.authResponse) {
          res(resault)
        } else {
          rej(resault.status)
        }
      },
      {
        scope: 'email,public_profile',
      }
    )
  })
