import Link from 'next/link'
import { connect } from 'react-redux'
import ROUTES from 'config/routes.json'
import Typography from '@material-ui/core/Typography/'
import content from 'translates/en/common.json'
import useStyles from './styles'
import { getDataFromJson } from 'src/utils/helpers'
import { onExternalLinkHandler } from 'src/containers/NativeBridge/utils'
import { sweepstakeEnabledSelector, isNativeAppSelector, isSCVisibleSelector } from 'store/modules/appConfig/selectors'
import { freeLottoAllowedSelector } from 'store/modules/games/selectors'
import { ApplicationState } from 'store/modules/types'

import packageJson from '../../../../../package.json'

const t = getDataFromJson(content)
const buildVersion = packageJson.version

const mapState = (state: ApplicationState) => ({
  sweepstakeEnabled: sweepstakeEnabledSelector(state),
  isScPreviewMode: isSCVisibleSelector(state),
  freeLottoAllowed: freeLottoAllowedSelector(state),
  isRNapp: isNativeAppSelector(state),
})
type Props = {
  sweepstakeEnabled: boolean
  freeLottoAllowed: boolean
  isScPreviewMode: boolean
  isRNapp: boolean
}
function Footer(props: Props) {
  const classes = useStyles(props)
  const phone = process.env.SUPPORT_NUMBER?.replace(/[{()}-\s]/g, '')
  const phoneEu = process.env.SUPPORT_NUMBER_EU?.replace(/[{()}-\s]/g, '')

  const showGoogleText = props.isRNapp && (props.isScPreviewMode || props.freeLottoAllowed)

  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <Typography variant='h6' className={classes.logo} data-test='footer-logo'>
          {t('LOGO')}
        </Typography>
        {showGoogleText && (
          <Typography variant='body2' className={classes.text} data-test='footer-google-text'>
            {t('footerP1')}
          </Typography>
        )}

        {props.sweepstakeEnabled && (
          <Typography variant='body2' className={classes.text} data-test='footer-sc-rules'>
            {t('footer no purchase necesary')}{' '}
            <Link href={ROUTES.SWEEPSTAKE_RULES} prefetch={false}>
              <a className={classes.textLink}>{t('Sweeps Rules')}</a>
            </Link>
          </Typography>
        )}

        <Typography variant='body2' className={classes.text} data-test='footer-copy-right'>
          {t('footerP2')}
        </Typography>

        {phone && (
          <Typography variant='body2' className={classes.text} data-test='footer-payment-queries'>
            US Payment Related Queries (24/7):{' '}
            <a className={classes.phoneLink} href={`tel:${phone}`} onClick={onExternalLinkHandler(phone)}>
              {process.env.SUPPORT_NUMBER}
            </a>
          </Typography>
        )}

        {phoneEu && (
          <Typography variant='body2' className={classes.text} data-test='footer-payment-queries'>
            EU/UK Payment Related Queries (24/7):{' '}
            <a className={classes.phoneLink} href={`tel:${phoneEu}`} onClick={onExternalLinkHandler(phoneEu)}>
              {process.env.SUPPORT_NUMBER_EU}
            </a>
          </Typography>
        )}

        <Typography variant='body2' className={classes.span} data-test='footer-call-notification'>
          {t('footerP3')}
        </Typography>

        <div className={classes.links}>
          <Link href={ROUTES.TERMS} prefetch={false}>
            <a data-test='footer-terms-link' className={classes.link}>
              {t('terms')}
            </a>
          </Link>
          {process.env.RESPONSIBLE_PLAY && (
            <Link href={ROUTES.RESPONSIBLE_PLAY} prefetch={false}>
              <a data-test='footer-responsible-play-link' className={classes.link}>
                {t('RESPONSIBLE PLAY')}
              </a>
            </Link>
          )}
          <Link href={ROUTES.PRIVACY} prefetch={false}>
            <a className={classes.link} data-test='footer-privacy-link'>
              {t('privacy')}
            </a>
          </Link>
          {props.sweepstakeEnabled && (
            <Link href={ROUTES.SWEEPSTAKE_RULES} prefetch={false}>
              <a className={classes.link} data-test='footer-sc-rules-link'>
                {t('sweepRules')}
              </a>
            </Link>
          )}
          {props.sweepstakeEnabled && process.env.ZENDESK_SUPPORT_LINK ? (
            <a
              href={process.env.ZENDESK_SUPPORT_LINK}
              data-test='footer-contact-us-link'
              target='_blank'
              className={classes.link}
              rel='noreferrer'
            >
              {t('CONTACT US')}
            </a>
          ) : null}
        </div>

        <span className={classes.buildVersion}>Build version: {buildVersion}</span>
      </div>
    </footer>
  )
}

export default connect(mapState)(Footer)
