import { WSinstance } from 'services/websocket'
import {
  AcceptTermsAndConditionsResponse,
  SetAccountAttributionInfoResponse,
  SetAccountInfoResponse,
  SetAccountModeResponse,
} from 'services/websocket/types/response'
import * as Sentry from '@sentry/nextjs'
import {
  User,
  Password,
  UserActionTypes,
  SetUserAction,
  SetXPLevelAction,
  DeleteUserAction,
  ILevel,
  SetVipLevelAction,
  SetFirstPurchaseOfferAction,
  SetUserSentEmailFieldAction,
  SetUserFlowIsRunningAction,
  SetKycStatusAction,
  SetFirstDepositDateAction,
  SetSkipOTPFlagAction,
  SetIsEmailVerifiedAction,
} from './types'
import { ThunkType } from 'store/modules/types'
import { replaceDialog, removeDialogByName } from 'store/modules/dialog/actions'
import { addUnlockedGames, getLottoDraws, levelUpHandler, saveGameProducts } from 'store/modules/games/actions'
import { getActiveGameCodeSelector } from 'store/modules/slotGameFlow/selectors'
import { trackGAEvent } from 'utils/gtag'
import { getUserSubscriptionsSelector, isLoggedInSelector, getSentWelcomeEmailFieldSelector } from './selectors'
import { getLottoPicks } from 'store/modules/playedGames/actions'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getOffers } from 'store/modules/shop/actions'
import { setSweepstakeEnabled } from 'store/modules/appConfig/actions'
import { setActiveCurrency } from 'store/modules/currencies/actions'
import { getItemFormLocalStorage, setDataToLocalStorage } from 'src/utils/localStorage'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'
import { accumDelayedDialogs } from 'src/store/middlewares/utils'
import gatewayEmit from 'src/services/gateway/gatewayEmit'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { sendRNevent } from 'src/utils/helpers'

export const setUser = (data: User): SetUserAction => {
  return {
    type: UserActionTypes.SET_USER,
    payload: { data },
  }
}

export const setXPLevel = (xpLevel: ILevel): SetXPLevelAction => ({
  type: UserActionTypes.SET_XP_LEVEL,
  payload: { xpLevel },
})

export const setVipLevel = (vipLevel: ILevel): SetVipLevelAction => {
  return {
    type: UserActionTypes.SET_VIP_LEVEL,
    payload: { vipLevel },
  }
}

export const setFirstPurchaseOffer = (name: string): SetFirstPurchaseOfferAction => ({
  type: UserActionTypes.SET_FIRST_PURCHASE_OFFER,
  payload: {
    firstOffer: name,
  },
})

export const setIsEmailVerified = (isVerified: boolean): SetIsEmailVerifiedAction => ({
  type: UserActionTypes.SET_IS_EMAIL_VERIFIED,
  payload: {
    isVerified,
  },
})

export const setFirstDepositDate = (firstDepositDate: string): SetFirstDepositDateAction => ({
  type: UserActionTypes.SET_FIRST_DEPOSIT_DATE,
  payload: {
    firstDeposit: firstDepositDate,
  },
})

// export const changeVipLvl = (points: number) => (dispatch: Function, getState: () => ApplicationState) => {
//   const vipState: VipLevel = getUserVipPointsLevelSelector(getState())

//   dispatch(
//     setVipLevel({
//       ...vipState,
//       current: vipState.current + points
//     })
//   )
// }

export const setLevelProgress =
  (levelInfo: ILevel): ThunkType =>
  (dispatch, getState) => {
    const { category } = levelInfo
    const gameCode = getActiveGameCodeSelector(getState())
    switch (category) {
      case 'free_level': {
        dispatch(setXPLevel(levelInfo))
        if (levelInfo.levelChanged) {
          dispatch(levelUpHandler(levelInfo))

          trackGAEvent(gameCode, 'level_up', levelInfo.level.toString())
          dispatch(addUnlockedGames(levelInfo?.unlocked))
        }
        break
      }
      case 'vip_level': {
        dispatch(setVipLevel(levelInfo))
        dispatch(addUnlockedGames(levelInfo?.unlocked))
        break
      }
      default: {
        break
      }
    }
  }

export const deleteUser = (): DeleteUserAction => ({
  type: UserActionTypes.DELETE_USER,
})

export const getAccountInfo = (): ThunkType => (dispatch) => {
  dispatch(WSinstance.emitWS({ type: 'GetAccountInfoRequest' }))
}

export const updatePassword =
  (formData: Password): ThunkType =>
  (dispatch) => {
    dispatch(
      WSinstance.emitWS({
        type: 'ChangePasswordRequest',
        oldPassword: formData.oldPassword,
        newPassword: formData.password,
      })
    )
  }

export const setUserFlowIsRunning = (userFlowIsRunning: boolean): SetUserFlowIsRunningAction => ({
  type: UserActionTypes.SET_USER_FLOW_IS_RUNNING,
  payload: {
    userFlowIsRunning,
  },
})

export const setUserVipSubscription =
  (subscription: string): ThunkType =>
  (dispatch, getState) => {
    const subscriptions = getUserSubscriptionsSelector(getState())
    dispatch({
      type: UserActionTypes.SET_USER_SUBSCRIPTION,
      payload: {
        subscriptions: subscriptions
          ? subscriptions.concat({ code: subscription, offerCode: subscription })
          : [].concat({ code: subscription }),
      },
    })
  }

// updateAccount = (info) => {
//   const { emitWS } = this.props;

//   emitWS('SetAccountInfoRequest', info);
// };

// uploadAccountWithImage = () => {
//   const {
//     _formData,
//     updateAccount,
//   } = this.props;
//   const contentType = this.getConentType(_formData.profilePhotо.name);

//   fetch(api.UPLOAD_IMAGE, {
//     method: 'POST',
//     credentials: 'include',
//     headers: {
//       'Content-Type': `image/${contentType}`,
//       'Access-Control-Expose-Headers': 'Location',
//     },
//     body: _formData.profilePhotо,
//   })
//     .then(data => {
//       const profilePhotо = data.headers.get('location');
//       updateAccount(normalizers.beforeSubmit({
//         ..._formData,
//         profilePhotо: `${api.config.GATEWAY}${profilePhotо}`,
//       }));
//     })
//     .catch(console.log);
// }

export const getDataAfterChangeAppState = (): ThunkType => (dispatch, getState) => {
  const isLoggedIn = isLoggedInSelector(getState())
  if (isLoggedIn) {
    dispatch(getLottoPicks())
    dispatch(getAccountInfo())
  }
  dispatch(getLottoDraws())
}

export const setSentEmail = (): SetUserSentEmailFieldAction => ({
  type: UserActionTypes.SET_SENT_EMAIL_USER_FIELD,
})

export const setKycStatus = (kycStatus: User['kycStatus']): SetKycStatusAction => ({
  type: UserActionTypes.SET_KYC_STATUS,
  payload: {
    kycStatus,
  },
})

export const openWelcomeEmailDialog = (): ThunkType => (dispatch, getState) => {
  const isEmailSent = getSentWelcomeEmailFieldSelector(getState())
  const isSCEnabled = sweepstakeEnabledSelector(getState())
  const dialogs = getDialogStackSelector(getState())
  if (!isEmailSent && isSCEnabled && !window.ReactNativeWebView) {
    if (dialogs?.length) {
      dispatch(accumDelayedDialogs('WELCOME_EMAIL_SEND_DIALOG'))
    } else {
      dispatch(replaceDialog('WELCOME_EMAIL_SEND_DIALOG'))
    }
  }
}

export const openWelcomeEmailDialogWithDelay =
  (delay: number): ThunkType =>
  (dispatch) => {
    const timerId = setTimeout(() => {
      dispatch(openWelcomeEmailDialog())
    }, delay)
    dispatch(setDataToLocalStorage('WELCOME_EMAIL_DIALOG_TIMER_ID', timerId))
  }

export const sendEmailRequest = (): ThunkType => (dispatch) => {
  dispatch(WSinstance.emitWS({ type: 'SendWelcomeEmailRequest' }))
  trackGAEvent('welcome_email_submit', 'welcome_email_submit', 'welcome_email_submit')
}

export const accountModeChangedHandler =
  (body: SetAccountModeResponse | SetAccountAttributionInfoResponse): ThunkType =>
  (dispatch) => {
    const isScEnabled = window.ReactNativeWebView ? false : body?.sweepstake
    dispatch(setSweepstakeEnabled(isScEnabled))
    dispatch(saveGameProducts(body.products))
    dispatch(setUser(body.account))
    dispatch(getOffers())

    if (isScEnabled) {
      dispatch(setActiveCurrency('SC'))
      dispatch(WSinstance.emitWS({ type: 'GetAccountBonusesRequest' }))
    }
  }

export const acceptTermsAndConditions =
  (version: string): ThunkType =>
  (dispatch) => {
    gatewayEmit<AcceptTermsAndConditionsResponse>({
      type: 'AcceptTermsAndConditionsRequest',
      version,
    })
      .then((body) => {
        if (body.applied) {
          dispatch(setUser(body.account))
          dispatch(removeDialogByName('TERMS_MODAL'))
        }
      })
      .catch((err) => {
        Sentry.captureMessage(`fetch AcceptTermsAndConditionsRequest ${err.message}`)
      })
  }

export const setSkipOTP = (skipOtp: boolean): SetSkipOTPFlagAction => ({
  type: UserActionTypes.SET_SKIP_OTP_FLAG,
  payload: {
    skipOtp,
  },
})

export const updateUser =
  (formData: Partial<User>, callback?: () => void): ThunkType =>
  (dispatch) => {
    gatewayEmit<SetAccountInfoResponse>({
      type: 'SetAccountInfoRequest',
      session: getItemFormLocalStorage('SESSION_ID', false),
      ...formData,
    })
      .then((body) => {
        dispatch(setUser(body))
        callback?.()
      })
      .catch((error) => {
        if (error?.status?.errorText) {
          Sentry.captureMessage(`fetch SetAccountInfoResponse ${error.message}`)
          dispatch(
            openSnackbar({
              message: error.status.errorText,
            })
          )

          sendRNevent({
            type: 'wsError',
            message: error.status.errorText,
            errorCode: error.status.errorCode,
          })
        }
      })
      .finally(() => {
        dispatch(setUserFlowIsRunning(false))
      })
  }
