import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import content from 'translates/en/common.json'
import useStyles from './styles'
import { getDataFromJson } from 'src/utils/helpers'

const t = getDataFromJson(content)

type Props = {
  bonus: number
  level: number
  vipPoints: number
}
function LevelUpMessage(props: Props) {
  const classes = useStyles(props)

  return (
    <Paper className={classes.root}>
      <h5 className={classes.title}>
        {t('Level up')} {props.level}!
      </h5>

      <Typography variant='body1' className={classes.description}>
        <img className={classes.icon} src={`${process.env.IMG_ORIGIN}/coin-icon.svg`} alt='Gold coin' />
        <b>{props.bonus}</b>
        <span className={classes.currencyDescr}>{t('GC')}!</span>
      </Typography>
      {Boolean(props.vipPoints) && (
        <Typography variant='body1' className={classes.description}>
          <b>{props.vipPoints}</b>
          <span className={classes.currencyDescr}>
            {t('VIP')} {t('Points')}!
          </span>
        </Typography>
      )}
    </Paper>
  )
}

export default LevelUpMessage
