export const SIGN_UP_URL: string = `${process.env.GATEWAY}/v1/sign_up/manual`
export const SIGN_UP_FACEBOOK_URL: string = `${process.env.GATEWAY}/v1/sign_up/facebook`
export const SIGN_UP_GOOGLE_URL: string = `${process.env.GATEWAY}/v1/sign_up/google`
export const SIGN_IN_GOOGLE_URL: string = `${process.env.GATEWAY}/v1/sign_in/google`
export const SIGN_IN_FACEBOOK_URL: string = `${process.env.GATEWAY}/v1/sign_in/facebook`
export const SIGN_UP_URL_GUEST: string = `${process.env.GATEWAY}/v1/sign_up/guest`

export const SIGN_IN_PERMANENT_URL: string = `${process.env.GATEWAY}/v1/sign_in/permanent`
export const SIGN_IN_URL: string = `${process.env.GATEWAY}/v1/sign_in/manual`
export const SIGN_OUT_URL: string = `${process.env.GATEWAY}/v1/sign_out`

export const RESET_PASSWORD_URL: string = `${process.env.GATEWAY}/v1/reset-password`
