import { useDispatch, useSelector } from 'react-redux'
import { getUserSelector, isLoggedInSelector } from 'store/modules/user/selectors'
import cx from 'classnames'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import { isAppInitializedSelector, isNativeAppSelector } from 'store/modules/appConfig/selectors'
import useStyles from './styles'
import { getItemFormLocalStorage, IS_USER_REGISTERED } from 'src/utils/localStorage'
import { openShopHandler } from 'src/store/modules/shop/actions'
import ROUTES from 'config/routes.json'
import { useRouter } from 'next/router'

const t = getDataFromJson(content)

function ShopMobileLink() {
  const classes = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const user = useSelector(getUserSelector)
  const appInitialized = useSelector(isAppInitializedSelector)
  const loggedIn = useSelector(isLoggedInSelector)
  const isNative = useSelector(isNativeAppSelector)
  const isUserRegistered = getItemFormLocalStorage(IS_USER_REGISTERED, false)
  const shopDisabled = user?.acceptedTcVersion !== user?.tcVersion

  if (!appInitialized) {
    return <div className={cx(classes.root, shopDisabled && classes.disable)} />
  }

  return loggedIn ? (
    <div
      className={cx(classes.root, shopDisabled && classes.disable)}
      onClick={() => {
        if (!shopDisabled) {
          dispatch(openShopHandler(true))
        }
      }}
    >
      <div className={classes.animatedBg} />
      <span className={cx(classes.text, isNative && classes.smallText)}>{isNative ? t('get coins') : t('Buy')}</span>
    </div>
  ) : (
    <div
      className={classes.root}
      onClick={() => {
        if (isUserRegistered) {
          router.push(ROUTES.LOGIN)
        } else {
          router.push(ROUTES.REGISTER)
        }
      }}
    >
      <div className={classes.animatedBg} />
      <span className={classes.text}>{isUserRegistered ? t('Login') : t('Join')}</span>
    </div>
  )
}

export default ShopMobileLink
