import { makeStyles } from '@material-ui/core/styles'

const avatarColors = ['#C09BD8', '#e2abcc', '#ce559d', '#7b4f55', '#b2c583', '#a875ea', '#E4BE9E', '#cec132']

export default makeStyles({
  base: {
    color: 'white !important',
    textTransform: 'uppercase',
    fontSize: '1rem',
  },

  loader: {
    borderRadius: '50%',
    maxWidth: 40,
  },

  // predefined backgrounds
  bg1: {
    backgroundColor: avatarColors[0],
  },
  bg2: {
    backgroundColor: avatarColors[1],
  },
  bg3: {
    backgroundColor: avatarColors[2],
  },
  bg4: {
    backgroundColor: avatarColors[3],
  },
  bg5: {
    backgroundColor: avatarColors[4],
  },
  bg6: {
    backgroundColor: avatarColors[5],
  },
  bg7: {
    backgroundColor: avatarColors[6],
  },
  bg8: {
    backgroundColor: avatarColors[7],
  },
})
