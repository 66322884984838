import Button from 'components/Button'
import { useRouter } from 'next/router'
import useStyles from './styles'
import { DialogNames, OpenDialogAction } from 'store/modules/dialog/types'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import Hidden from '@material-ui/core/Hidden'
import ROUTES from 'config/routes.json'

const t = getDataFromJson(content)

type Props = {
  openDialog: (dialogName: DialogNames) => OpenDialogAction
}
function LoggedOut(props: Props) {
  const classes = useStyles(props)
  const router = useRouter()

  return (
    <div className={classes.buttonsContainer}>
      <Button
        onClick={() => {
          router.push(ROUTES.LOGIN)
        }}
        size='small'
        className={classes.headerLink}
        data-test='header-login-btn'
      >
        {t('Login')}
      </Button>

      <Hidden xsDown>
        <Button
          onClick={() => {
            router.push(ROUTES.REGISTER)
          }}
          size='small'
          className={classes.headerLink}
          data-test='header-register-btn'
        >
          {t('Register')}
        </Button>
      </Hidden>
    </div>
  )
}

export default LoggedOut
