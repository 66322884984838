import * as Sentry from '@sentry/nextjs'
import {
  SetIsRedeemingAction,
  SetUserRedeemInfoAction,
  SetRedeemsAction,
  RedeemItem,
  RedeemActionTypes,
  SetActiveRedeemIdAction,
  UserRedeemInfo,
  RedeemWithdrawRequestType,
  WithdrawMethodType,
  SetWithdrawMethodsAction,
  ClearRedeemAction,
  SetRedeemPolicyAction,
  RedeemPolicyType,
  ProvidersRedeemPolicy,
  SetProvidersRedeemPolicyAction,
  ProvidersData,
  Providers,
} from './types'
import { ThunkType } from 'store/modules/types'
import {
  getProvidersPolicySelector,
  getRedeemPolicyByCurrencySelector,
  getRedeemsItemsSelector,
  getUserRedeemInfoSelector,
  getWithdrawCurrentACHMethodSelector,
  getWithdrawCurrentSkrillACHMethodSelector,
  getWithdrawCurrentSkrillMethodSelector,
} from './selectors'
import { closeAllDialogs, closeLatestDialog, removeDialogByName, openDialog, replaceDialog } from '../dialog/actions'
import { WSinstance } from 'services/websocket'
import {
  emailHiddenSelector,
  KYCinReviewSelector,
  getUserRealEmailSelector,
  isGuestModeSelector,
  isKYCRequiredSelector,
  skipOtpSelector,
  getUserRestrictionsSelector,
} from 'store/modules/user/selectors'
import { sweepstakeEnabledSelector } from '../appConfig/selectors'
import { getBalanceByIDSelector } from '../currencies/selectors'
import { Currencies, RealMoneyCurrencies } from '../currencies/types'
import { openSnackbar } from '../snackbar/actions'
import { isMobileScreenSize, sendRNevent } from 'src/utils/helpers'

import EXTERNAL_LINKS from 'src/config/externalLinks.json'
import { validateRedeemAmount } from './utils'
import { getItemFormLocalStorage, setDataToLocalStorage } from 'src/utils/localStorage'
import gatewayEmit from 'src/services/gateway/gatewayEmit'
import {
  SetKYCInfoResponse,
  CancelRedeemMoneyResponse,
  GetWithdrawMethodsResponse,
  RedeemMoneyResponse,
} from 'src/services/websocket/types/response'
import Router from 'next/router'
import ROUTES from 'config/routes.json'

export const setIsRedeeming = (isRedeeming: boolean): SetIsRedeemingAction => ({
  type: RedeemActionTypes.SET_IS_REDEEMING,
  payload: {
    isRedeeming,
  },
})

export const setRedeemItems = (redeems: Array<RedeemItem>): SetRedeemsAction => ({
  type: RedeemActionTypes.SET_REDEEMS,
  payload: {
    redeems,
  },
})

export const setActiveRedeemId = (redeemId: number | null): SetActiveRedeemIdAction => ({
  type: RedeemActionTypes.SET_ACTIVE_REDEEM_ID,
  payload: {
    redeemId,
  },
})

export const setRedeemInfo = (userRedeemInfo: UserRedeemInfo): SetUserRedeemInfoAction => ({
  type: RedeemActionTypes.SET_USER_REDEEM_INFO,
  payload: {
    userRedeemInfo,
  },
})

export const setWithdrawMethods = (withdrawMethods: Array<WithdrawMethodType>): SetWithdrawMethodsAction => ({
  type: RedeemActionTypes.SET_WITHDRAW_METHODS,
  payload: {
    withdrawMethods,
  },
})

export const setProvidersRedeemPolicy = (providerPolicies: ProvidersRedeemPolicy): SetProvidersRedeemPolicyAction => ({
  type: RedeemActionTypes.SET_PROVIDERS_REDEEM_POLICY,
  payload: {
    providerPolicies,
  },
})

export const clearRedeemState = (): ClearRedeemAction => ({
  type: RedeemActionTypes.CLEAR_REDEEM_STATE,
})

export const redeemSuccesHandler =
  (redeemId: number, kyc: boolean): ThunkType =>
  (dispatch) => {
    dispatch(setActiveRedeemId(redeemId))
    dispatch(setIsRedeeming(false))
    const isMobile = isMobileScreenSize()
    if (!isMobile) {
      dispatch(closeLatestDialog())
    }
    if (kyc) {
      dispatch(openDialog('SUBMIT_DOCUMENTS_DIALOG'))
    } else {
      dispatch(closeAllDialogs())
      dispatch(replaceDialog('REDEEM_SUCCESSFUL_DIALOG'))
    }

    dispatch(setWithdrawMethods(null))
  }

export const redeemMoney =
  (redeemFields: RedeemWithdrawRequestType): ThunkType =>
  (dispatch) => {
    if (redeemFields.method.withdrawMethod.type === 'mazooma_ach') {
      dispatch(
        setRedeemInfo({
          accountHolderName: redeemFields.method.withdrawMethod.fiAcc,
          accountNumber: redeemFields.method.withdrawMethod.fiRouting,
          amount: redeemFields.amount,
        })
      )
    } else {
      dispatch(
        setRedeemInfo({
          accountHolderName: null,
          accountNumber: null,
          amount: redeemFields.amount,
        })
      )
    }
    /**
*
* {
    ...redeemFields,
    method: {
      ...redeemFields,
      holderName: 'test8',
      abaRoutingNumber: 'test',
      accountNumber: 123,
      bankName: 'ttest',
      bankLocation: 'test',
    },
  }
*
*/
    dispatch(setIsRedeeming(true))
    gatewayEmit<RedeemMoneyResponse>({
      type: 'RedeemMoneyRequest',
      ...redeemFields,
      session: getItemFormLocalStorage('SESSION_ID', false),
    })
      .then((res) => {
        dispatch(redeemSuccesHandler(res.id, res.kyc))
      })
      .catch((err) => {
        dispatch(setIsRedeeming(false))
        dispatch(setRedeemInfo(null))
        // check error text
        if (err.status?.errorText) {
          dispatch(
            openSnackbar({
              message: err.status.errorText,
            })
          )
        }
      })
  }

export const openKYClink = (): ThunkType => (_dispatch, getState) => {
  const redeemInfo = getUserRedeemInfoSelector(getState())
  const userEmail = getUserRealEmailSelector(getState())
  const currentEmail = redeemInfo?.email || userEmail
  const entryFiled = currentEmail ? `&entry.1563963829=${currentEmail}` : ''

  if (window?.ReactNativeWebView) {
    sendRNevent({
      type: 'openLinkInBrowser',
      link: `${EXTERNAL_LINKS.redeemForm}?usp=pp_url${entryFiled}`,
    })
  } else {
    window?.open(`${EXTERNAL_LINKS.redeemForm}?usp=pp_url${entryFiled}`, '_blank')
  }
}

export const setKYCInfoResponseHandler =
  (
    iframeUrl: string,
    type: 'identity_verification' | 'document_verification',
    transactionReference: string
  ): ThunkType =>
  (dispatch) => {
    dispatch(removeDialogByName('WITHDRAW_DIALOG'))
    dispatch(removeDialogByName('SUBMIT_DOCUMENTS_DIALOG'))
    dispatch(removeDialogByName('GET_VERIFIED_DIALOG'))
    dispatch(openDialog('JUMIO_KYC_DIALOG', { iframeUrl, type, transactionReference }))
    dispatch(setIsRedeeming(false))
  }

export const getRedeemMoneyHistoryRequest = (): ThunkType => (dispatch) => {
  dispatch(
    WSinstance.emitWS({
      type: 'GetRedeemMoneyHistoryRequest',
      firstResult: 0,
      maxResults: 50,
    })
  )
}

export const setKYCInfoRequest =
  (phase: 'identity_verification' | 'document_verification'): ThunkType =>
  (dispatch) => {
    dispatch(setIsRedeeming(true))

    gatewayEmit<SetKYCInfoResponse>({
      type: 'SetKYCInfoRequest',
      provider: 'jumio',
      phase,
    })
      .then((data) => {
        const iframeUrl = phase === 'identity_verification' ? data.redirectUrl : data.clientRedirectUrl
        dispatch(setKYCInfoResponseHandler(iframeUrl, phase, data.transactionReference))
      })
      .catch((err) => {
        console.log('err :>> ', err)
      })
      .finally(() => {
        dispatch(setIsRedeeming(false))
      })
  }

export const getWithdrawMethodsRequest = (): ThunkType => (dispatch) => {
  gatewayEmit<GetWithdrawMethodsResponse>({ type: 'GetWithdrawMethodsRequest' })
    .then((res) => {
      dispatch(setWithdrawMethods(res.methods))
      dispatch(setProvidersRedeemPolicy(res.policies))
    })
    .catch((err) => {
      Sentry.captureMessage(`fetch GetWithdrawMethodsResponse ${err.message}`)
    })
}

export const redeemFlowStart =
  (): ThunkType =>
  (dispatch, getState): null => {
    const isGuestMode = isGuestModeSelector(getState())
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())
    const isEmailHidden = emailHiddenSelector(getState())
    const isSkipOtp = skipOtpSelector(getState())
    const isMobile = isMobileScreenSize()
    const userRestrictions = getUserRestrictionsSelector(getState())
    //

    if (isGuestMode) {
      Router.push(ROUTES.REGISTER)

      return null
    }

    // confirm phone number on first redeem
    if (!isSkipOtp) {
      dispatch(openDialog('PHONE_VERIFICATION_DIALOG'))

      return null
    }

    if (userRestrictions.includes('no_redeem')) {
      dispatch(openDialog('RESTRICT_USER_DIALOG'))

      return null
    }

    dispatch(getRedeemMoneyHistoryRequest())
    dispatch(getWithdrawMethodsRequest())

    if (isEmailHidden) {
      dispatch(
        openDialog('WITHDRAW_CONFIRM_EMAIL_DIALOG', {
          title: 'Please enter your active email address',
          cb: () => {
            if (!isMobile) {
              dispatch(closeLatestDialog())
            }
            dispatch(openDialog('WITHDRAW_DIALOG', { mode: sweepstakeEnabled ? 'SC' : 'USD' }))
          },
        })
      )
    } else {
      dispatch(openDialog('WITHDRAW_DIALOG', { mode: sweepstakeEnabled ? 'SC' : 'USD' }))
    }

    return null
  }

export const updateRedeemList =
  (redeemItem: RedeemItem): ThunkType =>
  (dispatch, getState) => {
    const redeems = getRedeemsItemsSelector(getState())
    if (redeems && redeems.length && Object.prototype.hasOwnProperty.call(redeemItem, 'id')) {
      const updatedRedeems = redeems.map((it) => {
        if (it.id === redeemItem.id) {
          return redeemItem
        }

        return it
      })
      dispatch(setRedeemItems(updatedRedeems))
    }
  }

export function setRedeemPolicy(policy: RedeemPolicyType): SetRedeemPolicyAction {
  return {
    type: RedeemActionTypes.SET_REDEEM_COMMON_POLICY,
    payload: {
      policy,
    },
  }
}

// new flow =====
export const resolveRightRedeemWay =
  (currency: RealMoneyCurrencies): ThunkType =>
  (dispatch, getState) => {
    const balance = getBalanceByIDSelector(getState(), currency)
    const currentPolicy = getRedeemPolicyByCurrencySelector(getState(), currency)
    const isMobile = isMobileScreenSize()
    const kycRequired = isKYCRequiredSelector(getState())
    const KYCinReview = KYCinReviewSelector(getState())

    if (balance.redeemable < currentPolicy.minAmount) {
      dispatch(
        openSnackbar({
          message: 'Your current redeemable amount less than redeemable minimum!',
        })
      )
    } else {
      if (!isMobile) {
        dispatch(closeLatestDialog())
      }
      if (KYCinReview) {
        dispatch(openDialog('VERIFICATION_IN_PROGRESS_DIALOG'))
      } else if (kycRequired) {
        dispatch(openDialog('SUBMIT_DOCUMENTS_DIALOG'))
      } else {
        dispatch(openDialog('WITHDRAW_PROVIDERS_DIALOG'))
      }
    }
  }

const achRedeem =
  (currency: Exclude<Currencies, 'GC'>, amount: number): ThunkType =>
  (dispatch, getState) => {
    const currentACHMethod = getWithdrawCurrentACHMethodSelector(getState())
    const isMobile = isMobileScreenSize()

    if (currentACHMethod) {
      dispatch(
        redeemMoney({
          currency,
          amount,
          email: getUserRealEmailSelector(getState()),
          method: {
            code: currentACHMethod.code,
            withdrawMethod: currentACHMethod.withdrawMethod,
          },
        })
      )
    } else {
      if (!isMobile) {
        dispatch(closeLatestDialog())
      }
      dispatch(openDialog('WITHDRAW_SWEEPSTSKE_DIALOG', { amount }))
    }
  }

const skrillRedeem =
  (currency: Exclude<Currencies, 'GC'>, amount: number): ThunkType =>
  (dispatch, getState) => {
    const currentSkrillMethod = getWithdrawCurrentSkrillMethodSelector(getState())
    dispatch(
      redeemMoney({
        currency,
        amount,
        email: getUserRealEmailSelector(getState()),
        method: {
          code: currentSkrillMethod.code,
          withdrawMethod: {
            type: 'skrill',
            email: currentSkrillMethod?.withdrawMethod.email,
          },
        },
      })
    )
  }

const skrillACHRedeem =
  (currency: Exclude<Currencies, 'GC'>, amount: number): ThunkType =>
  (dispatch, getState) => {
    const currentSkrillACHMethod = getWithdrawCurrentSkrillACHMethodSelector(getState())
    if (currentSkrillACHMethod?.withdrawMethod?.transactionId && currentSkrillACHMethod?.code) {
      dispatch(
        redeemMoney({
          currency,
          amount,
          email: getUserRealEmailSelector(getState()),
          method: {
            code: currentSkrillACHMethod.code,
            withdrawMethod: {
              type: 'skrill_ach',
              transactionId: currentSkrillACHMethod.withdrawMethod.transactionId,
            },
          },
        })
      )
    } else {
      dispatch(
        openSnackbar({
          message: 'The method is not available.',
        })
      )
    }
  }

export const confirmRedeemHandler =
  (amounts: ProvidersData, currency: Exclude<Currencies, 'GC'>): ThunkType =>
  (dispatch, getState) => {
    const choosenProviders = Object.keys(amounts).filter((el: Providers) => amounts[el] !== null)
    const balance = getBalanceByIDSelector(getState(), 'SC')
    const redeemPolicy = getRedeemPolicyByCurrencySelector(getState(), 'SC')
    const providerPolicies = getProvidersPolicySelector(getState())

    const error = validateRedeemAmount(
      {
        redeemMax: redeemPolicy.maxAmount,
        redeemMin: redeemPolicy.minAmount,
        skrillMaximum: {
          Skrill: providerPolicies.skrill.sweepstakeRedeemPolicy.maxAmount,
          SkrillACH: providerPolicies.ach.sweepstakeRedeemPolicy.maxAmount,
        },
        balance: balance.redeemable,
        activeCurrency: balance.currency as Exclude<typeof balance.currency, 'GC'>,
      },
      amounts
    )

    if (error) {
      dispatch(
        openSnackbar({
          message: error,
          variant: 'error',
          autoHide: 3000,
        })
      )
    } else {
      choosenProviders.forEach((provider: Providers) => {
        switch (provider) {
          case 'ACH': {
            dispatch(achRedeem(currency, amounts.ACH))
            dispatch(setDataToLocalStorage('REDEEM_PROVIDER', 'ACH'))
            break
          }
          case 'SkrillACH': {
            dispatch(skrillACHRedeem(currency, amounts.SkrillACH))
            dispatch(setDataToLocalStorage('REDEEM_PROVIDER', 'SkrillACH'))
            break
          }
          case 'Skrill': {
            dispatch(skrillRedeem(currency, amounts.Skrill))
            dispatch(setDataToLocalStorage('REDEEM_PROVIDER', 'Skrill'))
            break
          }
          default: {
            // eslint-disable-next-line
            console.log(provider)
            break
          }
        }
      })
    }
  }

export const updateWithdrawMethodInfo =
  (method: WithdrawMethodType): ThunkType =>
  (dispatch) => {
    dispatch(setIsRedeeming(true))
    dispatch(WSinstance.emitWS({ type: 'CreateWithdrawMethodRequest', method }))
  }

export const updateWithdrawMethodResponseHandler = (): ThunkType => (dispatch) => {
  dispatch(setIsRedeeming(false))
  dispatch(setWithdrawMethods(null))
  dispatch(getWithdrawMethodsRequest())
}

export const cancelRedeemRequest =
  (id: number | string): ThunkType =>
  (dispatch) => {
    gatewayEmit<CancelRedeemMoneyResponse>({ type: 'CancelRedeemMoneyRequest', id })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, ...rest } = res
        dispatch(updateRedeemList(rest))
        dispatch(getWithdrawMethodsRequest())
      })
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, ...rest } = err
        dispatch(updateRedeemList(rest))
      })
  }
