import { makeStyles, Theme } from '@material-ui/core/styles'

const iconWidth = '2rem'

export default makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    marginRight: 4,
  },

  lineRoot: {
    transform: 'rotate(-132deg) !important',
    color: theme.palette.defaultTheme.themeColorLighter,
  },

  levelIcon: {
    position: 'absolute',
    left: -13,
    top: -6,
    color: theme.palette.defaultTheme.themeColorLighter,
    fontSize: iconWidth,
  },

  avatar: {
    position: 'absolute',
  },

  level: {
    color: 'white',
    position: 'absolute',
    left: -13,
    top: -6,
    fontSize: '0.55rem',
    textAlign: 'center',
    lineHeight: iconWidth,
    width: iconWidth,
    height: iconWidth,
    // fontWeight: 500,
    userSelect: 'none',
    textShadow: '1px 1px 1px #c1bbbb',
  },
  diamond: {
    color: theme.palette.goldCoinTheme.themeColor,
    position: 'absolute',
    top: -5,
    zIndex: 4,
    right: -11,
  },
}))
