import { v4 as uuid } from 'uuid'
import { removeDialogByName } from 'store/modules/dialog/actions'
import { WSinstance } from 'services/websocket'
import { sendRNevent } from 'utils/helpers'
import { ThunkType } from 'store/modules/types'
import { ShopOffer } from 'store/modules/shop/types'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'

export const payWithGooglePay = (offer: ShopOffer) => {
  sendRNevent({
    type: 'payWithGooglePay',
    code: offer.code,
    price: offer.price.toString(),
  })
}

export const confirmVipGooleOfferCallBack = (): ThunkType => (dispatch, getState) => {
  const dialogs = getDialogStackSelector(getState())
  if (dialogs && dialogs.find((it) => it.modalName === 'VIP_SUBSCRIPTION')) {
    dispatch(removeDialogByName('VIP_SUBSCRIPTION'))
    dispatch(removeDialogByName('VIP_SUBSCRIPTION_PROMO_DIALOG'))
  }
}
export const confirmGooglePayOrder =
  (
    token: string,
    offer: string,
    receiptSignature: string,
    osVersion: string,
    appVersion: string,
    advertisingId: string,
    platform: string,
    receiptData: string
  ): ThunkType =>
  (dispatch) => {
    dispatch(
      WSinstance.emitWS({
        type: 'CreateOrderRequest',
        pkg: process.env.PACKAGE_NAME,
        brandName: process.env.BRAND_NAME,
        provider: 'google_play',
        receiptSignature,
        token,
        offer,
        transactionId: uuid(),
        osVersion,
        appVersion,
        advertisingId,
        platform,
        receiptData,
      })
    )

    dispatch(confirmVipGooleOfferCallBack())
  }
