import { makeStyles, Theme } from '@material-ui/core/styles'
import layoutConfig from 'config/layoutConstants.json'

export default makeStyles((theme: Theme) => ({
  root: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: layoutConfig.headerHeight,
    // @ts-ignore
    background: theme.palette.primary.background,
    justifyContent: 'space-between',
    zIndex: 2,
  },

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    marginRight: theme.spacing(1),
    marginLeft: -theme.spacing(1),
  },
  logo: {
    cursor: 'pointer',
    maxHeight: 16,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
}))
