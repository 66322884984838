import { useDispatch, useSelector } from 'react-redux'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { freeLottoAllowedSelector } from 'store/modules/games/selectors'
import {
  HomeOutline,
  SlotMachineOutline,
  CardBulletedOutline,
  NewBox,
  FrequentlyAskedQuestions,
  MedalOutline,
  AccountOutline,
  AccountMultiplePlusOutline,
  PokerChip,
  GiftOutline,
  Magnify,
} from 'mdi-material-ui'
import { openDialog } from 'src/store/modules/dialog/actions'
import { compose } from 'redux'
import { DialogNames } from 'src/store/modules/dialog/types'
import { ReactType } from 'react'

const icons = {
  HomeOutline,
  SlotMachineOutline,
  CardBulletedOutline,
  NewBox,
  FrequentlyAskedQuestions,
  MedalOutline,
  AccountOutline,
  AccountMultiplePlusOutline,
  PokerChip,
  GiftOutline,
  Magnify,
}

type LinkObject = {
  icon: ReactType
  text: string
  id: number
  link?: string
  flags?: Array<string>
  onClick?: DialogNames
}

type Links = Array<LinkObject>

const bindEvents = (links: Links) => {
  const dispatch = useDispatch()

  return links.map((el) => {
    if (Object.prototype.hasOwnProperty.call(el, 'onClick')) {
      return {
        ...el,
        onClick: () => {
          dispatch(openDialog(el.onClick))
        },
      }
    }

    return el
  })
}

const bindIcons = (links: Links) => {
  return links.map((it) => ({
    ...it,
    icon: icons[it.icon as keyof typeof icons],
  }))
}

const filterLinksByFlags = (links: Links) => {
  const freeLottoAllowed = useSelector(freeLottoAllowedSelector)
  const sweepstakeEnabled = useSelector(sweepstakeEnabledSelector)
  const isLoggedIn = useSelector(isLoggedInSelector)

  const flags = {
    freeLottoAllowed,
    sweepstakeEnabled,
    isLoggedIn,
  }

  return links
    .map((el) => {
      if (Object.prototype.hasOwnProperty.call(el, 'flags')) {
        return {
          ...el,
          show: el.flags.some((item) => flags[item as keyof typeof flags]),
        }
      }

      return {
        ...el,
        show: true,
      }
    })
    .filter((fl) => Boolean(fl.show))
}
export default compose(bindEvents, bindIcons, filterLinksByFlags)
