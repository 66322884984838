enum LAYOUTS {
  LOTTO_TABS_LAYOUT = 'LottoTabs',
  ALL_GAMES_TABS_LAYOUT = 'AllGamesTabs',
  MY_GAMES_TABS_LAYOUT = 'MyGamesTabs',
  CLEAN_LAYOUT = 'CleanLayout',
  MOBILE_LAYOUT = 'MobileLayout',
  EMPTY_PAGE_LAYOUT = 'EmptyPageLayout',
}

export default LAYOUTS
