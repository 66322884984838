import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    borderRadius: '20%',
    overflow: 'hidden',
    margin: '0 5px',
  },
  image: {
    width: '100%',
    height: '100%',
    display: 'block',
  },
}))
