import { Reducer } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import { AppConfigActionTypes, AppConfigState, Actions } from './types'

// Type-safe initialState
export const initialState: AppConfigState = {
  darkTheme: false,
  init: false,
  apps: {
    facebook: { id: null },
    paypal: { id: null },
    stripe: { id: null },
  },
  sweepstakeEnabled: false,
  passwordPolicy: {
    min: 6,
    max: 20,
    lowerCase: 1,
    upperCase: 1,
    special: 0,
    digits: 1,
  },
  goldCurrency: null,
  nativeApp: false,
  androidDeviceId: null,
  pushToken: null,
  trackingId: null,
  forcement: 'default',
  isPWAMessageOpen: false,
  advertisingId: null,
  osVersion: null,
  appVersion: null,
  location: null,
}

const reducer: Reducer<AppConfigState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case AppConfigActionTypes.TOGGLE_DARK_THEME: {
      return {
        ...state,
        darkTheme: !state.darkTheme,
      }
    }

    // @ts-ignore
    case HYDRATE: {
      // eslint-disable-next-line
      console.log('next-redux-wrapper HYDRATE')

      return state
    }

    case AppConfigActionTypes.INIT_APP: {
      return {
        ...state,
        init: true,
      }
    }

    case AppConfigActionTypes.INIT_NATIVE_APP: {
      return {
        ...state,
        nativeApp: true,
      }
    }

    case AppConfigActionTypes.SET_GOLD_CURRENCY: {
      return {
        ...state,
        goldCurrency: action.payload.goldCurrency,
      }
    }

    case AppConfigActionTypes.SET_IS_OPEN_PWA_MESSAGE: {
      return {
        ...state,
        isPWAMessageOpen: action.payload.isPWAMessageOpen,
      }
    }

    case AppConfigActionTypes.SET_ANDROID_DEVICE_ID: {
      return {
        ...state,
        androidDeviceId: action.payload.deviceId,
      }
    }

    case AppConfigActionTypes.SET_SC_ENABLED: {
      return {
        ...state,
        sweepstakeEnabled: action.payload.enabled,
      }
    }

    case AppConfigActionTypes.SAVE_PASSWORD_POLICY: {
      return {
        ...state,
        passwordPolicy: action.payload.passwordPolicy,
      }
    }
    case AppConfigActionTypes.SAVE_APPS_CONFIGS: {
      return {
        ...state,
        apps: action.payload.apps,
      }
    }

    case AppConfigActionTypes.SAVE_GAID_DATA: {
      return {
        ...state,
        advertisingId: action.payload.advertisingId,
        osVersion: action.payload.osVersion,
        appVersion: action.payload.appVersion,
      }
    }

    case AppConfigActionTypes.SAVE_TRACKING_ID: {
      return {
        ...state,
        trackingId: action.payload.trackingId,
      }
    }

    case AppConfigActionTypes.SET_PUSHTOKEN: {
      return {
        ...state,
        pushToken: action.payload.pushToken,
      }
    }

    case AppConfigActionTypes.SAVE_LOCATION_DATA: {
      return {
        ...state,
        location: action.payload.location,
      }
    }

    case AppConfigActionTypes.SET_FORCEMENT_MODE: {
      return {
        ...state,
        forcement: action.payload.forcement,
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as appConfigReducer }
