import { useEffect, useState } from 'react'
import { PWAEventType } from 'src/store/modules/appConfig/types'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import { useDispatch } from 'react-redux'
import { togglePWAMessage } from 'src/store/modules/appConfig/actions'
import { getItemFormLocalStorage, HIDE_INSTALL_PWA_BUTTON, setDataToLocalStorage } from 'src/utils/localStorage'

let GLOBAL_PWA_INSTANCE: PWAEventType | { prompt: () => void } | null = null

const usePWAInstance = (): { prompt: () => void } | PWAEventType | null => {
  const [pwaInstance, setPWAInstance] = useState(GLOBAL_PWA_INSTANCE)
  const [isPWAInstalled, setIsPWAInstalled] = useState(false)
  const isPWAIOSButtonHidden = getItemFormLocalStorage(HIDE_INSTALL_PWA_BUTTON, false)
  const deviceDetect = useDeviceDetect()
  const dispatch = useDispatch()

  const detectPWAInstalled = () => {
    setIsPWAInstalled(true)
    setPWAInstance(null)
  }
  const beforeinstallpromptHandler = (e: PWAEventType) => {
    e.preventDefault()
    GLOBAL_PWA_INSTANCE = e
    setPWAInstance(GLOBAL_PWA_INSTANCE)

    e.userChoice.then((res) => {
      if (res.outcome === 'accepted') {
        detectPWAInstalled()
      }
    })
  }

  useEffect(() => {
    const isIOS = deviceDetect.isIos()
    if (!isPWAIOSButtonHidden && isIOS && !window.matchMedia('(display-mode: standalone)').matches) {
      setPWAInstance({
        prompt: () => {
          dispatch(togglePWAMessage(true))
          dispatch(setDataToLocalStorage(HIDE_INSTALL_PWA_BUTTON, true, false))
          setPWAInstance(null)
        },
      })
    }

    window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler)
    window.addEventListener('appinstalled', detectPWAInstalled)

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeinstallpromptHandler)
      window.removeEventListener('appinstalled', detectPWAInstalled)
    }
  }, [])

  if (pwaInstance && !isPWAInstalled) {
    return pwaInstance
  }

  return null
}

export const isPWAAvailable = () => {
  const [isAvailbalve, setIsAvailable] = useState(null)

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches || window.ReactNativeWebView) {
      setIsAvailable(false)
    } else {
      setIsAvailable(true)
    }
  }, [])

  return isAvailbalve
}

export default usePWAInstance
