import { compose } from 'redux'
import {
  DELAYED_DIALOGS,
  getItemFormLocalStorage,
  LAST_DIALOG_OPEN,
  setDataToLocalStorage,
} from 'src/utils/localStorage'
import { DialogNames } from '../modules/dialog/types'

export const getNextSessionDialog = (sessionDialogsArray: Array<DialogNames>): DialogNames => {
  const lastSessionOpenedDialog = getItemFormLocalStorage(LAST_DIALOG_OPEN)

  if (lastSessionOpenedDialog) {
    const lastIndex = sessionDialogsArray.findIndex((it) => it === lastSessionOpenedDialog)

    return lastIndex !== -1 && lastIndex < sessionDialogsArray.length - 1
      ? sessionDialogsArray[lastIndex + 1]
      : sessionDialogsArray[0]
  }

  return sessionDialogsArray[0]
}

export const accumDelayedDialogs = (dialogName: DialogNames, queryParams?: any) => (dispatch: any) => {
  const dialogs = getItemFormLocalStorage(DELAYED_DIALOGS)

  if (dialogs && Object.keys(dialogs).length) {
    dispatch(setDataToLocalStorage(DELAYED_DIALOGS, { ...dialogs, [dialogName]: queryParams || true }))
  } else {
    dispatch(setDataToLocalStorage(DELAYED_DIALOGS, { [dialogName]: queryParams || true }))
  }
}

type ModalsType = Array<{
  modalName: string
  platform: Array<string>
  flags?: Array<string>
}>
type ConfigModalsResolverType = {
  platform: 'web' | 'mobile'
  flags: {
    isSubscriptionNotUsed: boolean
    scEnabled: boolean
    isFirstPurchaseNotUsed: boolean
    inviteFriendsAvailable: boolean
  }
}

const filterDialogsByPlatofrm = (config: ConfigModalsResolverType) => (modals: ModalsType) =>
  modals.filter((el) => el.platform.find((item) => item === config.platform))

const filterByAvailableFlags = (config: ConfigModalsResolverType) => (modals: ModalsType) =>
  modals.filter((item) => {
    return (
      (!Object.prototype.hasOwnProperty.call(item, 'flags') ||
        item.flags.some((element) => config.flags[element as keyof typeof config.flags])) &&
      item
    )
  })

const getDialogNames = (modals: ModalsType) => {
  return modals
    .map((it) => {
      return it?.modalName
    })
    .filter(Boolean)
}

export const getCorrectSessionDialogs = (config: ConfigModalsResolverType, modals: ModalsType) => {
  return compose(
    getDialogNames,
    filterDialogsByPlatofrm(config),
    filterByAvailableFlags(config)
  )(modals) as Array<DialogNames>
}
