import { openDialog } from '../modules/dialog/actions'
import { DeleteUserAction, UserActionTypes } from '../modules/user/types'
import { getDialogVisibilitySelector } from '../modules/dialog/selectors'
import { isNativeAppSelector } from '../modules/appConfig/selectors'
import { openQuickTourDialog, openRulesUpdateDialog } from '../modules/appConfig/actions'
import { MiddlewareType } from './types'

import { getItemFormLocalStorage } from 'src/utils/localStorage'

import { AddAppliedOffersAction, ShopActionTypes } from '../modules/shop/types'
import { getUserIdSelector, getUserModeSelector, needToVerifyEmailSelector } from '../modules/user/selectors'
import Router from 'next/router'
import { openWelcomeEmailDialogWithDelay } from '../modules/user/actions'
import { BonusesFlowActionTypes, ReceivedAllUsersBonusesAction } from '../modules/bonusesFlow/types'
import { checkDailyBonus, checkUserBonuses } from '../modules/bonusesFlow/actions'
import openSessionDialogs from './openSessionDialogs'

let DIALOG_IS_OPENED = false
let ACCUM_REQUIRED_ACTIONS: string[] = []

const isLogOutUserAction = (action: any): action is DeleteUserAction => action.type === UserActionTypes.DELETE_USER
const isAddAppliedOffersAction = (action: any): action is AddAppliedOffersAction =>
  action.type === ShopActionTypes.ADD_APPLIED_OFFERS

const isSaveBonussesAction = (action: any): action is ReceivedAllUsersBonusesAction =>
  action.type === BonusesFlowActionTypes.RECEIVED_ALL_USERS_BONUSES

// eslint-disable-next-line consistent-return
const sessionStartDialog: MiddlewareType = (store) => (next) => (action) => {
  next(action)

  if (isAddAppliedOffersAction(action) || isSaveBonussesAction(action)) {
    const needToVerifyEmail = needToVerifyEmailSelector(store.getState())
    const isDialogOpen = getDialogVisibilitySelector(store.getState())
    const isNative = isNativeAppSelector(store.getState())
    const userId = getUserIdSelector(store.getState())
    const userMode = getUserModeSelector(store.getState())

    const isSessionDialogsAvailable = userId && !DIALOG_IS_OPENED && !isDialogOpen

    // don't show session dialogs on "verify email" flow
    if (needToVerifyEmail) {
      return null
    }

    if (!DIALOG_IS_OPENED && userMode?.includes?.('sweepstake') && isNative && process.env.BRAND_NAME === 'pulsz') {
      store.dispatch(openDialog('SC_PREVIEW_SESSION_START_DIALOG'))
      DIALOG_IS_OPENED = true

      return null
    }

    ACCUM_REQUIRED_ACTIONS.push(action.type)

    if (
      ACCUM_REQUIRED_ACTIONS.includes(BonusesFlowActionTypes.RECEIVED_ALL_USERS_BONUSES) &&
      ACCUM_REQUIRED_ACTIONS.includes(ShopActionTypes.ADD_APPLIED_OFFERS) &&
      isSessionDialogsAvailable
    ) {
      store.dispatch(openQuickTourDialog())

      store.dispatch(checkDailyBonus())

      if (!Router.pathname.includes('/play')) {
        store.dispatch(openSessionDialogs())
      }
      store.dispatch(checkUserBonuses())
      DIALOG_IS_OPENED = true
    }
    store.dispatch(openRulesUpdateDialog())

    // open dialog if timer null
    const timerId = getItemFormLocalStorage('WELCOME_EMAIL_DIALOG_TIMER_ID')
    if (!timerId) {
      store.dispatch(openWelcomeEmailDialogWithDelay(240000))
    }
  }
  if (isLogOutUserAction(action)) {
    DIALOG_IS_OPENED = false
    ACCUM_REQUIRED_ACTIONS = []
  }
}

export default sessionStartDialog
