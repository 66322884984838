// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum PaymentsFlowActionTypes {
  SAVE_PAYMENTS_METHODS = 'paymentsFlow/SAVE_PAYMENTS_METHODS',
  SET_DEFAULT_CARD = 'paymentsFlow/SET_DEFAULT_CARD',
  REMOVE_SAVED_CARD = 'paymentsFlow/REMOVE_SAVED_CARD',
  SET_IS_PAYMENT_FLOW_IN_PROCESS = 'paymentsFlow/SET_IS_PAYMENT_FLOW_IN_PROCESS',
  SET_PAYMENT_CONFIRMATION = 'paymentsFlow/SET_PAYMENT_CONFIRMATION',
  CLEAR_PAYMENT_CONFIRMATION = 'paymentsFlow/CLEAR_PAYMENT_CONFIRMATION',
  CLEAR_PAYMENTS_STATE = 'paymentsFlow/CLEAR_PAYMENTS_STATE',
}

export type WorldpayResponse = {
  token: string
  ok: boolean
}

export type ConfirmOrder = {
  token: string
  mode: string
}

export type CardDetails = {
  name: string
  cardNumber: string
  expiry: string
  cvc: string
  saveCard?: boolean
  offerCode?: number
}

export type OrderResponse = {
  orderCode: string
  transactionId: string
  secure3D: boolean
  status: string
  tempToken?: string
  md?: string
  paReq?: string
  redirectUrl?: string
  termUrl?: string
  supplier?: string
  paymentData?: string
  firstOfferAt?: string
  at?: string
  clientToken?: string
  publicKeyBase64?: string
  skipOtp?: boolean
  completeMerchantValidation?: string // apple pay
  nextProvider?: string
  sourceId?: string
}

export type PaymentMethodCard = {
  cardClass: string
  cardIssuer: string
  cardSchemeName: string
  cardSchemeType: string
  cardType: 'credit'
  countryCode: string
  expiryMonth: number
  expiryYear: number
  issueNumber: number
  maskedCardNumber: string
  name: string
  startMonth: number
  startYear: number
  lastUsageAt: number // pwmb
  accountNumber: string // pwmb
  paymentProviderName: string // pwmb
  paymentProviderId?: string // pwmb
  email?: string // skrill
  paymentMethodId?: string // spreedly_rapyd
  paymentToken?: string // spreedly_fiserv
  tokenize?: boolean // spreedly
}

export type PaymentsProviderTypes =
  | 'adyen'
  | 'world_pay'
  | 'pay_pal'
  | 'stripe'
  | 'stripe_card'
  | 'skrill'
  | 'skrill_ach'
  | 'pay_with_my_bank'
  | 'google_play'
  | 'adyen_card'
  | 'spreedly'
  | 'spreedly_rapyd'
  | 'fiserv'
  | 'fiserv_google_pay'
  | 'spreedly_fiserv'
  | 'fiserv_apple_pay'

export type PaymentMethod = {
  code: string
  id: number
  method: 'stripe_card'
  provider: PaymentsProviderTypes
  type: 'CardPaymentMethod'
  token: string
  paymentMethod: PaymentMethodCard
  tokenize?: boolean
}

export interface SavePaymentMethodsAction {
  type: PaymentsFlowActionTypes.SAVE_PAYMENTS_METHODS
  payload: {
    methods: Array<PaymentMethod>
  }
}

export interface SetDefaultCardAction {
  type: PaymentsFlowActionTypes.SET_DEFAULT_CARD
  payload: {
    code: string
  }
}

export interface RemoveSavedCardAction {
  type: PaymentsFlowActionTypes.REMOVE_SAVED_CARD
  payload: {
    methods: Array<PaymentMethod>
  }
}

export interface SetPaymentFlowInProcessAction {
  type: PaymentsFlowActionTypes.SET_IS_PAYMENT_FLOW_IN_PROCESS
  payload: {
    isPaymentFlowInProcess: boolean
  }
}

export interface ClearPaymentsStateAction {
  type: PaymentsFlowActionTypes.CLEAR_PAYMENTS_STATE
}

export type Actions =
  | SavePaymentMethodsAction
  | SetDefaultCardAction
  | RemoveSavedCardAction
  | SetPaymentFlowInProcessAction
  | ClearPaymentsStateAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface PyamentsFlowState {
  readonly savedCards: Array<PaymentMethod> | null
  readonly defaultCard: string | null
  readonly isPaymentFlowInProcess: boolean
}
