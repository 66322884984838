import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import Switch from '@material-ui/core/Switch'
import { DescribeSwitcherCurrency } from 'store/modules/currencies/utils'
import useStyles from './styles'
import { Currency } from 'src/store/modules/currencies/types'
import { ApplicationState } from 'store/modules/types'
import { getGoldCurrencySelector, getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'

type CashSwitcherType = {
  activeCurrency: string
  mode?: string
  handleChange: Function
  reversed?: boolean
  GoldCurrencyComponent?: FunctionComponent
  SweepstakeCurrencyComponent?: FunctionComponent
  goldCurrency: Currency
  sweepstakeCurrency: Currency
  dataAttribute: string
}

const mapState = (state: ApplicationState) => ({
  goldCurrency: getGoldCurrencySelector(state),
  sweepstakeCurrency: getSweepstakeCurrencySelector(state),
})

const CashSwitcher = (props: CashSwitcherType) => {
  const { GoldCurrencyComponent, SweepstakeCurrencyComponent } = props
  const classes = useStyles(props)
  const handleChange = () => {
    if (DescribeSwitcherCurrency[props.activeCurrency]) {
      props.handleChange('GC')
    } else {
      props.handleChange('SC')
    }
  }

  return (
    <div
      className={cx(classes.root, props.reversed && classes.reversedRoot, props.mode && props.mode)}
      data-test={`cash-switcher-${props?.dataAttribute}`}
    >
      <div className={cx(classes.switcherWrap, props.mode && props.mode)}>
        <Switch
          data-test={`cash-switch-${props?.dataAttribute}`}
          checked={DescribeSwitcherCurrency[props.activeCurrency]}
          onChange={handleChange}
          classes={{
            root: cx(classes.switcher, props.mode && props.mode),
            thumb: cx(
              classes.thumb,
              DescribeSwitcherCurrency[props.activeCurrency] ? classes.switcherOn : classes.switcherOff
            ),
            track: classes.track,
          }}
        />
      </div>

      <div className={cx(classes.labels, props.reversed && classes.reversedText)}>
        <div
          role='button'
          className={cx(classes.label, !DescribeSwitcherCurrency[props.activeCurrency] && classes.active)}
          onClick={handleChange}
        >
          {GoldCurrencyComponent && props.goldCurrency ? <GoldCurrencyComponent /> : 'Gold Coins'}
        </div>

        <div
          role='button'
          className={cx(
            classes.label,
            classes.labelMobile,
            DescribeSwitcherCurrency[props.activeCurrency] && classes.active
          )}
          onClick={handleChange}
        >
          {SweepstakeCurrencyComponent && props.sweepstakeCurrency ? <SweepstakeCurrencyComponent /> : 'Sweeps Coins'}
        </div>
      </div>
    </div>
  )
}

export default connect(mapState)(CashSwitcher)
