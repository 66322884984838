import { formatDistanceToNow, parseISO, format, isPast, getUnixTime, differenceInMilliseconds } from 'date-fns'

type DateObject = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export function convertDateHoursMinutes(date: string): DateObject | null {
  if (date) {
    const milliSeconds = differenceInMilliseconds(parseISO(date), new Date())

    let milliseconds
    let days
    let hours
    let minutes
    let seconds

    if (milliSeconds > 0) {
      milliseconds = milliSeconds
      days = Math.floor(milliseconds / (24 * 60 * 60 * 1000))
      if (days < 0) {
        days = 0
      }
      milliseconds -= days * 24 * 60 * 60 * 1000

      hours = Math.floor(milliseconds / (60 * 60 * 1000))
      if (hours < 0) {
        hours = 0
      }
      milliseconds -= hours * 60 * 60 * 1000

      minutes = Math.floor(milliseconds / (60 * 1000))
      if (minutes < 0) {
        minutes = 0
      }
      milliseconds -= minutes * 60 * 1000

      seconds = Math.floor(milliseconds / 1000)
    } else {
      // eslint-disable-next-line
      days = hours = minutes = seconds = 0
    }

    return { days, hours, minutes, seconds }
  }

  return null
}

export function compareByDate(date1: string, date2: string) {
  return getUnixTime(parseISO(date1)) - getUnixTime(parseISO(date2))
}

export function convertDateTimeMessage(dateObject: DateObject | null) {
  let message: string = ''
  if (dateObject) {
    if (dateObject.days > 0) {
      message += `${dateObject.days === 1 ? `${dateObject.days} ${'day'}` : `${dateObject.days} ${'days'}`}`
    }
    message += ` ${dateObject.hours === 1 ? `${dateObject.hours} ${'hour'}` : `${dateObject.hours} ${'hours'}`}`

    message += ` ${dateObject.hours === 1 ? `${dateObject.minutes} ${'minute'}` : `${dateObject.minutes} ${'minutes'}`}`

    return message
  }

  return 'waiting'
}

export function formatDrawDate(date: string) {
  if (!date) {
    return 'waiting'
  }

  if (isPast(parseISO(date))) {
    return 'Finished'
  }

  return formatDistanceToNow(parseISO(date), { includeSeconds: true })
}

export function formatCountdownTimer(date?: string | null, checkEndedTimer?: boolean) {
  if (!date) {
    return 'waiting'
  }
  const { days, hours, minutes } = convertDateHoursMinutes(date)

  if (checkEndedTimer && !days && !hours && !minutes) return 'Verifying Results'

  return `${days ? `${days}d` : ''} ${hours ? `${hours}h` : ''} ${`${minutes}m`}`
}

export function formatDrawDateModalWithDay(date: string) {
  return date ? format(parseISO(date), 'iiii p') : 'waiting'
}

export function formatDrawFullDate(date: string, dayDescription?: string) {
  return date ? format(parseISO(date), `${dayDescription || 'iiii'}  d LLL Y p`) : 'waiting'
}

export function formatDateToUSA(date: string) {
  return date ? format(parseISO(date), 'd LLL Y kk:mm:ss') : 'waiting'
}

// add '0' prefix to number
// example: 5 => '05'; 15 => '15'; -2 => '-02';
function addZeroPrefixToNumber(n: string | number): string {
  if (!n || Number(n) === 0) {
    return '00'
  }

  const number = Number(n)
  if (number > 0 && number <= 9) {
    return `0${number}`
  }
  if (number < 0 && number >= -9) {
    const [sign, h] = String(number).split('')

    return `${sign}0${h}`
  }

  return String(n)
}

function toggleNumberSign(number: string): string {
  if (Number(number) < 0) {
    return Math.abs(Number(number)).toString()
  }
  if (Number(number) > 0) {
    return `-${number}`
  }

  return number
}

// format timezone offset to time
// example: -120 -> '+02:00', 150 -> '-02:30', -525 => +08:45
export function formatTimezoneToTime(offset: number) {
  const time = offset / 60
  const [h, m] = String(time).split('.')
  const hours = toggleNumberSign(h)
  const mins = m ? (Number(m) * 0.1 * 60).toString().slice(0, 2) : null
  const plusSign = Number(hours) > 0 ? '+' : ''

  return `${plusSign}${addZeroPrefixToNumber(hours)}:${addZeroPrefixToNumber(mins)}`
}
