import { AppBar } from '@material-ui/core'
import Page from 'components/Page'
import { ReactNode } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'src/components/Button'
import LogoLink from 'src/containers/LogoLink'
import { logOut } from 'src/store/modules/auth/actions'
import { getUserSelector } from 'src/store/modules/user/selectors'
import useStyles from './styles'

type Props = {
  children: ReactNode
}

function Layout(props: Props) {
  const classes = useStyles(props)
  const userInfo = useSelector(getUserSelector)
  const dispatch = useDispatch()

  return (
    <main className={classes.page}>
      {userInfo?.id && !userInfo?.emailVerified && (
        <AppBar className={classes.header} color='default'>
          <LogoLink />

          <Button
            size='small'
            onClick={() => {
              dispatch(logOut())
            }}
            className={classes.logoutBtn}
          >
            logout
          </Button>
        </AppBar>
      )}
      <Page rootClassName={classes.root} className={classes.content}>
        {props.children}
      </Page>
    </main>
  )
}

export default Layout
