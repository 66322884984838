import { Reducer } from 'redux'
import { ShopActionTypes, ShopState, Actions } from './types'

const initialState: ShopState = {
  offers: [],
  applied: [],
  limits: null,
}

const reducer: Reducer<ShopState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ShopActionTypes.ADD_OFFERS: {
      return {
        ...state,
        offers: action.payload.offers,
      }
    }

    case ShopActionTypes.ADD_APPLIED_OFFERS: {
      return {
        ...state,
        applied: action.payload.appliedOffers,
      }
    }

    case ShopActionTypes.SET_PURCHASE_LIMITS: {
      return {
        ...state,
        limits: action.payload.limits,
      }
    }

    case ShopActionTypes.CLEAR_OFFERS:
      return initialState

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as shopReducer }
