import dialogs from 'dialogs/index'
import Modal from '@material-ui/core/Modal'

import { CloseLatestDialogAction, ModalItem } from 'store/modules/dialog/types'
import useStyle from './styles'
import { ComponentType } from 'react'

type RootDialogProps = {
  open: boolean
  closeLatestDialog: () => CloseLatestDialogAction
  dialogStack: Array<ModalItem>
  disablePortal?: boolean
  toggleFullScreen?: Function
}

function RootDialog(props: RootDialogProps) {
  const classes = useStyle(props)

  const config = props.dialogStack.map((dialog) => {
    return {
      component: dialogs[dialog.modalName],
      dialogProps: dialog.dialogProps,
      id: dialog.id,
      options: dialog.options,
    }
  })

  return (
    <Modal disablePortal={props.disablePortal} onClose={props.closeLatestDialog} open={props.open}>
      <>
        {config.map((modal, index) => {
          const Component: ComponentType<any> = modal.component

          return (
            <div key={modal.id} style={{ zIndex: modal?.options?.zIndex || index }} className={classes.root}>
              <Component
                queryParams={modal.dialogProps}
                closeLatestDialog={props.closeLatestDialog}
                toggleFullScreen={props?.toggleFullScreen}
              />
            </div>
          )
        })}
      </>
    </Modal>
  )
}

export default RootDialog
