import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '0 16px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    height: theme.spacing(2),
    width: 'auto',
    marginRight: theme.spacing(1) / 2,
  },
  itemText: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  link: {
    display: 'inline-flex',
    color: theme.palette.text.disabled,
    marginLeft: 4,
    padding: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '8px -8px 0',
  },
  button: {
    margin: '0 8px',
    width: 'calc(100% / 2 - 8px)',
  },
  tooltip: {
    margin: '16px 0',
  },
}))
