import { BaseResponse } from 'utils/request'

const gatewayFetch = (url: string, request: object): Promise<BaseResponse> =>
  fetch(url, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': process.env.GATEWAY,
      Accept: 'application/json, text/plain, */*',
      'X-PLATFORM': window.ReactNativeWebView ? 'android' : 'web',
    },
    body: JSON.stringify(request),
  })
export default gatewayFetch
