import { Component, ReactNode } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { ApplicationState } from 'store/modules/types'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import useStyles from './styles'
import { Currencies } from 'store/modules/currencies/types'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import Link from 'next/link'

const mapState = (state: ApplicationState) => ({
  activeCurrency: getActiveCurrencyIdSelector(state),
  loggedIn: isLoggedInSelector(state),
  appInitialized: isAppInitializedSelector(state),
})

type Props = {
  activeCurrency: Currencies
  children?: ReactNode
  text?: string
  icon: ReactNode
  onClick?: () => void
  key?: number
  loggedIn: boolean
  isActive?: boolean
  href?: string
  'data-test'?: string
}

const t = getDataFromJson(content)

function MenuItemComponent(props: Props) {
  const classes = useStyles(props)

  const Icon = props.icon as typeof Component
  const selectedClass = classes[props.activeCurrency]
  const text = props.children ? props.children.toString() : props.text

  if (props?.href?.includes('https')) {
    return (
      <MenuItem
        data-test={props?.['data-test']}
        component='a'
        onClick={props.onClick}
        className={classes.link}
        href={props.href}
        target='_blank'
      >
        <Icon className={cx(props.isActive && selectedClass)} />
        <Typography variant='inherit' className={cx(classes.linkText, props.isActive && selectedClass)}>
          {t(text)}
        </Typography>
      </MenuItem>
    )
  }
  if (props?.href) {
    return (
      <Link href={props.href} prefetch={false} passHref>
        <MenuItem data-test={props?.['data-test']} component='a' onClick={props.onClick} className={classes.link}>
          <Icon className={cx(props.isActive && selectedClass)} />
          <Typography variant='inherit' className={cx(classes.linkText, props.isActive && selectedClass)}>
            {t(text)}
          </Typography>
        </MenuItem>
      </Link>
    )
  }

  return (
    <MenuItem data-test={props?.['data-test']} component='div' onClick={props.onClick} className={classes.link}>
      <Icon className={cx(props.isActive && selectedClass)} />
      <Typography variant='inherit' className={cx(classes.linkText, props.isActive && selectedClass)}>
        {t(text)}
      </Typography>
    </MenuItem>
  )
}

export default connect(mapState)(MenuItemComponent)
