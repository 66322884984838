// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum CurrenciesActionTypes {
  INIT_CURRENCIES = 'currencies/INIT_CURRENCIES',
  SET_ACTIVE_CURRENCY = 'currencies/SET_ACTIVE_CURRENCY',
  TOGGLE_ACTIVE_CURRENCY = 'currencies/TOGGLE_ACTIVE_CURRENCY',
  SET_FIAT_CURRENCY = 'currencies/SET_FIAT_CURRENCY',
  SET_ACTIVE_MODAL_CURRENCY = 'currencies/SET_ACTIVE_MODAL_CURRENCY',
  UPDATE_BALANCE = 'currencies/UPDATE_BALANCE',
  CLEAR_CURRENCY = 'currencies/CLEAR_CURRENCY',
}

export type Currencies = 'GC' | 'SC' | 'USD'

export enum LottoPoolNames {
  SC = 'sweepstakePool',
  GC = 'goldPool',
  USD = 'fiatPool',
}

export type RealMoneyCurrencies = 'USD' | 'SC'

export type Currency = {
  currency: Currencies
  amount: number
  redeemable: number
  unplayed: number
}

export interface InitCurrenciesAction {
  type: CurrenciesActionTypes.INIT_CURRENCIES
  payload: {
    allCurrencies: Array<Currency>
  }
}

export interface SetActiveCurrencyAction {
  type: CurrenciesActionTypes.SET_ACTIVE_CURRENCY
  payload: {
    activeCurrencyId: Currencies
  }
}

export interface ToggleActiveCurrencyAction {
  type: CurrenciesActionTypes.TOGGLE_ACTIVE_CURRENCY
}

export interface SetFiatCurrencyAction {
  type: CurrenciesActionTypes.SET_FIAT_CURRENCY
  payload: {
    currencyId: Currencies
  }
}

export interface SetActiveModalCurrencyAction {
  type: CurrenciesActionTypes.SET_ACTIVE_MODAL_CURRENCY
  payload: {
    currencyId: Currencies
  }
}

export interface UpdateBalanceAction {
  type: CurrenciesActionTypes.UPDATE_BALANCE
  payload: {
    currency: Array<Currency>
  }
}

export interface ClearCurrencyAction {
  type: CurrenciesActionTypes.CLEAR_CURRENCY
}

export type Actions =
  | InitCurrenciesAction
  | ClearCurrencyAction
  | SetActiveCurrencyAction
  | SetFiatCurrencyAction
  | SetActiveModalCurrencyAction
  | UpdateBalanceAction
  | ToggleActiveCurrencyAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface CurrenciesState {
  readonly allCurrencies: Array<Currency>
  readonly activeCurrencyId: Currencies
  readonly fiatCurrency: Currencies | null
  readonly activeModalCurrencyId: Currencies
}
