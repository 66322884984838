import { setAuthLoading, socialSignUpHandler } from 'store/modules/auth/actions'
import { v4 as uuid } from 'uuid'
import { ThunkType } from 'store/modules/types'
import { sendRNevent } from 'utils/helpers'
import { getFacebookAppIdSelector, isNativeAppSelector } from 'store/modules/appConfig/selectors'
import { openDialog } from 'store/modules/dialog/actions'
import { SocialAuthTypes } from 'store/modules/auth/types'
import { setDataToLocalStorage, LS_AUTH_TYPE } from 'utils/localStorage'
import { getAuthFlowLoadingSelector } from 'src/store/modules/auth/selectors'
import { fbLoginViaSDK, getFacebookUserData, initFacebookApp } from './utils'
import { openSnackbar } from 'src/store/modules/snackbar/actions'

export const connectFacebook = (): ThunkType => async (dispatch, getState) => {
  const flowInProcess = getAuthFlowLoadingSelector(getState())

  if (!flowInProcess) {
    dispatch(setAuthLoading(true))
    // login using fb android sdk
    if (window.ReactNativeWebView) {
      sendRNevent({
        type: 'nativeLoginViaFB',
        authType: SocialAuthTypes.REGISTRATION,
      })
    } else {
      try {
        await initFacebookApp(getFacebookAppIdSelector(getState()))
        const data = await fbLoginViaSDK()
        const user = await getFacebookUserData(data.authResponse.accessToken)
        if (user) {
          dispatch(setAuthLoading(false))
          if (user.email) {
            dispatch(socialSignUpHandler({ accessToken: data.authResponse.accessToken }, 'Facebook'))
          } else {
            dispatch(
              openDialog('WITHDRAW_CONFIRM_EMAIL_DIALOG', {
                title: 'Please enter your active email address',
                cb: () => {
                  dispatch(socialSignUpHandler({ accessToken: data.authResponse.accessToken }, 'Facebook'))
                },
              })
            )
          }
        }
      } catch (err) {
        dispatch(setAuthLoading(false))
        dispatch(
          openSnackbar({
            message: err?.message,
          })
        )
      }
    }
  }
}

export const facebookAuth =
  (authType: SocialAuthTypes): ThunkType =>
  (dispatch, getState) => {
    const isNativeApp = isNativeAppSelector(getState())
    const facebookAppId = getFacebookAppIdSelector(getState())
    try {
      dispatch(setDataToLocalStorage(LS_AUTH_TYPE, authType, false))
      dispatch(setAuthLoading(true))
      if (isNativeApp) {
        sendRNevent({
          type: 'nativeLoginViaFB',
          authType,
        })
      } else {
        window.location.href = `https://www.facebook.com/v9.0/dialog/oauth?client_id=${facebookAppId}&redirect_uri=${
          window.location.origin
        }/auth-redirect.html&state={"{st=${uuid()},ds=${uuid()}}"}&scope=email,public_profile,&response_type=token`
      }
    } catch (err) {
      // eslint-disable-next-line
      console.warn(err)
    }
  }
export type FacebookAuthType = ReturnType<typeof facebookAuth>
