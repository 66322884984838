const cachedScripts: Array<string> = []

export default function loadScript(src: string) {
  let onScriptLoad: () => void = null
  let onScriptError: () => void = null
  let script: HTMLScriptElement = null

  return new Promise((resolve, reject) => {
    if (cachedScripts.includes(src)) {
      resolve(src)
    } else {
      cachedScripts.push(src)

      script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src

      onScriptLoad = () => {
        resolve(script)
      }

      onScriptError = () => {
        const index = cachedScripts.indexOf(src)
        if (index >= 0) cachedScripts.splice(index, 1)
        script.remove()
        reject(script)
      }

      script.addEventListener('load', onScriptLoad, false)
      script.addEventListener('error', onScriptError, false)
      document.body.appendChild(script)
    }
  })
}

export const scriptInitialization = (src: string, instaceName: string) => {
  let timerId: any

  return new Promise((resolve, reject) => {
    loadScript(src)
      .then(() => {
        timerId = setInterval(() => {
          if (window[instaceName as keyof Window]) {
            clearInterval(timerId)
            resolve(true)
          }
        }, 100)

        setTimeout(() => {
          clearInterval(timerId)
          reject(new Error(`looks window.[${instaceName}] doesn't exists`))
        }, 10000)
      })
      .catch((err) => {
        clearInterval(timerId)
        reject(err)
      })
  })
}

export const loadJSONP = (src: string) => {
  let onScriptError: () => void = null
  let script: HTMLScriptElement = null
  const callbackName = `jsonp_callback_${Math.round(100000 * Math.random())}`

  return new Promise((resolve, reject) => {
    // @ts-ignore
    window[callbackName] = (data: any) => {
      // @ts-ignore
      delete window[callbackName]
      resolve(data)
      script.remove()
    }
    script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `${src + (src.indexOf('?') >= 0 ? '&' : '?')}callback=${callbackName}`

    onScriptError = () => {
      script.remove()
      reject(script)
    }

    script.addEventListener('error', onScriptError, false)
    document.body.appendChild(script)
  })
}
