import { isSafari } from 'utils/helpers'
import { useEffect, useState } from 'react'

export default function useIsSafari() {
  const [state, setState] = useState(null)

  useEffect(() => {
    setState(isSafari())
  }, [])

  return state
}
