import AllGames from './headers/AllGames'
import Lotto from './headers/Lotto'
import MyGames from './headers/MyGames'

import LAYOUTS from 'src/config/layouts'
import pageTabsConfigs from 'config/pageTabsConfigs'
import { isTabsInit } from './headers/utils'

type Props = {
  layoutType: string
  pageProps?: any
}

function TabNavigation(props: Props) {
  const isTabLayout = Object.prototype.hasOwnProperty.call(pageTabsConfigs, props.layoutType)

  if (!isTabLayout) {
    return null
  }

  const properties = isTabsInit(pageTabsConfigs[props.layoutType as keyof typeof pageTabsConfigs]())
  if (properties) {
    switch (props.layoutType) {
      case LAYOUTS.ALL_GAMES_TABS_LAYOUT: {
        return <AllGames />
      }
      case LAYOUTS.LOTTO_TABS_LAYOUT: {
        return <Lotto pageProps={props.pageProps} />
      }
      case LAYOUTS.MY_GAMES_TABS_LAYOUT: {
        return <MyGames />
      }
      default: {
        return <header>tabs {props.layoutType}</header>
      }
    }
  } else {
    return null
  }
}
export default TabNavigation
