import { Currencies } from 'store/modules/currencies/types'

export const DescribeSwitcherCurrency: { [key: string]: boolean } = {
  SC: true,
  GC: false,
}

export const CurrencyToProductMode: Record<Currencies, string> = {
  SC: 'sweepstake',
  GC: 'gold',
  USD: 'fiat',
}

export const ProductModeToCurrency: Record<string, Currencies> = {
  sweepstake: 'SC',
  gold: 'GC',
  fiat: 'USD',
}

export const showSweepstakeCurrencySymbol = (currency: Currencies) => {
  switch (currency) {
    case 'USD': {
      return '$'
    }
    default: {
      return `${currency} `
    }
  }
}

export const IS_FIRST_TIME_GLOBAL_SWITCH = 'IS_FIRST_TIME_GLOBAL_SWITCH'
export const IS_FIRST_TIME_DIALOG_SWITCH = 'IS_FIRST_TIME_DIALOG_SWITCH'
