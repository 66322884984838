import { useSelector } from 'react-redux'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { getLocationDataSelector, isNativeAppSelector, isSCVisibleSelector } from 'store/modules/appConfig/selectors'
import ZendeskChatRoot from './Root'

const ZendeskChat = () => {
  const loggedIn = useSelector(isLoggedInSelector)
  const scVisible = useSelector(isSCVisibleSelector)
  const location = useSelector(getLocationDataSelector)
  const isNative = useSelector(isNativeAppSelector)
  const blockedStates =
    typeof process.env.ZENDESK_BLOCKED_STATES === 'string'
      ? process.env.ZENDESK_BLOCKED_STATES.split(',')
      : (process.env.ZENDESK_BLOCKED_STATES as Array<string>)

  if (isNative || !process.env.ZENDESK_APP_ID) {
    return null
  }

  if (
    loggedIn &&
    !scVisible &&
    location &&
    location.country === 'US' &&
    !blockedStates.find((el) => el === location.state)
  ) {
    return <ZendeskChatRoot />
  }

  return null
}

export default ZendeskChat
