import { makeStyles, Theme, fade } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    width: 296,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px 8px',
  },

  title: {
    fontSize: '1.2rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    textAlign: 'center',
  },

  caption: {
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 0,
  },

  description: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 'auto',
    height: theme.spacing(2),
    marginRight: 4,
  },
  currencyDescr: {
    marginLeft: theme.spacing(1),
  },
  list: {
    margin: '0 -16px',
  },
  listItem: {
    justifyContent: 'space-between',
    color: fade(theme.palette.common.black, 0.87),
    position: 'relative',
  },
  listItemIcon: {
    justifyContent: 'flex-end',
    color: fade(theme.palette.common.black, 0.87),
    '.MuiSvgIcon-root': {
      fill: fade(theme.palette.common.black, 0.87),
    },
  },
}))
