import { useSelector } from 'react-redux'
import ThemeProvider from 'containers/ThemeProvider'
import MobileLayout from 'containers/Layouts/MobileLayout'
import CleanLayout from 'containers/Layouts/CleanLayout'
import Layout from 'containers/Layouts/Layout'
import VerifyEmail from 'containers/VerifyEmail'
import SmoothscrollLoader from 'containers/SmoothscrollLoader'
import AppScreenViewTracker from 'containers/AppScreenViewTracker'
import SaveWebReferrer from 'containers/SaveWebReferrer'
import GlobalSnackbar from 'containers/GlobalSnackbar'
import NativeBridge from 'containers/NativeBridge'
import RootDialog from 'dialogs/components/RootDialog'
import VisibilityProvider from 'containers/VisibilityProvider'
import TermsUpdaterProvider from 'containers/TermsUpdaterProvider'
import PWAProvider from 'containers/PWAProvider'
import TurnOffSpinnerInButtons from 'containers/TurnOffSpinnerInButtons'
import { getUserSelector, needToVerifyEmailSelector } from 'store/modules/user/selectors'
import LAYOUTS from 'src/config/layouts'

type Props = {
  pageProps: any
  Component: any
}

export default function AppContent(props: Props) {
  const needToVerifyEmail = useSelector(needToVerifyEmailSelector)
  const user = useSelector(getUserSelector)
  const { Component } = props

  const LayoutRecord = {
    MobileLayout,
    CleanLayout,
  }
  let DynamicLayout = null
  if (Object.prototype.hasOwnProperty.call(LayoutRecord, Component.layoutType)) {
    // @ts-ignore
    DynamicLayout = LayoutRecord[Component.layoutType]
  } else {
    DynamicLayout = Layout
  }

  if (Component.layoutType === 'EmptyPageLayout') {
    return <Component {...props.pageProps} />
  }

  return (
    <>
      {/* components without styling */}
      <SmoothscrollLoader />
      <AppScreenViewTracker />
      <SaveWebReferrer />
      <NativeBridge />
      <TurnOffSpinnerInButtons />
      <VisibilityProvider />
      {user && <TermsUpdaterProvider />}

      <ThemeProvider>
        {/* list here all components which use styles */}
        {props.Component?.layoutType !== LAYOUTS.CLEAN_LAYOUT && <RootDialog />}
        {props.Component?.layoutType !== LAYOUTS.CLEAN_LAYOUT && <GlobalSnackbar />}

        {needToVerifyEmail ? (
          <VerifyEmail />
        ) : (
          <>
            <DynamicLayout pageProps={props.pageProps} layoutType={props.Component.layoutType}>
              <Component {...props.pageProps} />
            </DynamicLayout>
            <PWAProvider />
          </>
        )}
      </ThemeProvider>
    </>
  )
}
