import { batch } from 'react-redux'
import {
  initApp,
  saveAppsConfigs,
  setSweepstakeEnabled,
  saveLocationData,
  captureSeonData,
} from 'store/modules/appConfig/actions'
import {
  setUser,
  setXPLevel,
  deleteUser,
  setVipLevel,
  setFirstPurchaseOffer,
  getAccountInfo,
  setSentEmail,
  accountModeChangedHandler,
  setSkipOTP,
} from 'store/modules/user/actions'
import { savePlayedLottos, updateGame, setPlayedGames, getLottoPicks } from 'store/modules/playedGames/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { setTempToken } from 'store/modules/tempToken/actions'
import { gamesIsLoaded, saveLottoDraws, saveGameProducts } from 'store/modules/games/actions'
import { authProcessComplete, runsCurrentAuthFlow, detectSessionExpired } from 'store/modules/auth/actions'
import { initCurrencies, setFiatCurrency, defaultCurrencyHandler } from 'store/modules/currencies/actions'
import {
  fetchSavedCards,
  purchaseResponseListenerByRouter,
  removePaymentCard,
  savePaymentMethods,
} from 'src/store/modules/paymentsFlow/actions'
import { receivedBonuses } from 'src/store/modules/bonusesFlow/actions'

import { setRedeemItems, setRedeemPolicy, updateWithdrawMethodResponseHandler } from 'store/modules/redeem/actions'
import { setOpenPrerequisites } from 'store/modules/prerequisites/actions'
import { saveLottoNumbersHistory, fetchCurrentLottoLines, setLineIsSending } from 'store/modules/lottoGameFlow/actions'
import { getOffers } from 'store/modules/shop/actions'
import { sendRNevent } from 'utils/helpers'
import { ThunkType } from 'store/modules/types'
import { WSBodyResponses } from './types/response'
import { openDialog, replaceDialog } from 'store/modules/dialog/actions'
import { gaSetUserId, trackGAEvent, GAEvents } from 'src/utils/gtag'
import { setDataToLocalStorage } from 'utils/localStorage'
import { WSinstance } from 'services/websocket'
import { fbAdvancedMatchingEvent } from '../facebook/utils'
import * as Sentry from '@sentry/nextjs'
import { openFreeSpinsDialog, saveFreeSpins } from 'src/store/modules/slotGameFlow/actions'
import Router from 'next/router'
import ROUTES from 'config/routes.json'

// handle all responses from websocket
const wsResponseHandler =
  (body: WSBodyResponses): ThunkType =>
  (dispatch) => {
    // eslint-disable-next-line consistent-return
    batch(() => {
      switch (body.type) {
        case 'SessionHandshakeResponse': {
          const isScEnabled = window.ReactNativeWebView ? false : body?.sweepstake
          dispatch(setFiatCurrency(body.fiatCurrency))
          dispatch(saveAppsConfigs(body.apps))
          dispatch(setSweepstakeEnabled(isScEnabled))
          dispatch(captureSeonData())

          if (body.account) {
            // save user id in localstorage for personalized settings
            dispatch(setDataToLocalStorage('USER_ID', body.account.id, false))
            Sentry.configureScope((scope) => scope.setUser({ id: body.account.id, email: body.account.email }))
            // send ga event
            gaSetUserId(body.account.id)
            fbAdvancedMatchingEvent({
              fn: body.account.firstName,
              ln: body.account.lastName,
              em: body.account.email,
              ph: body.account.phoneNumber,
            })

            if (body.account.kycStatus === 'blocked') {
              dispatch(openDialog('LOCK_USER_DIALOG'))
            }

            dispatch(saveGameProducts(body.products))
            dispatch(WSinstance.emitWS({ type: 'GetAccountSettingsRequest' }))
            dispatch(purchaseResponseListenerByRouter())
            dispatch(saveLocationData(body.location))
            dispatch(setUser(body.account))
            dispatch(setXPLevel(body.account.xpLevel))
            dispatch(setVipLevel(body.account.vipLevel))
            dispatch(setFirstPurchaseOffer(body.account?.firstOffer))
            dispatch(getAccountInfo())
            dispatch(fetchSavedCards())

            dispatch(defaultCurrencyHandler(body.account.mode, isScEnabled))

            dispatch(getOffers())

            if (process.env.BRAND_NAME === 'onl') {
              dispatch(getLottoPicks())
            }

            if (body.account?.purchaseVipLevel) {
              trackGAEvent(body.account?.purchaseVipLevel, 'purchase_vip_level', body.account?.purchaseVipLevel)
            }
            dispatch(setSkipOTP(body?.skipOtp))
            dispatch(authProcessComplete())

            if (!window.ReactNativeWebView) {
              dispatch(
                WSinstance.emitWS({ type: 'GetAccountFreeSpinsRequest', currency: isScEnabled ? ['SC', 'GC'] : ['GC'] })
              )
            }
          }

          if (body.account && isScEnabled) {
            dispatch(WSinstance.emitWS({ type: 'GetAccountBonusesRequest' }))
          }

          if (process.env.BRAND_NAME === 'onl') {
            dispatch(WSinstance.emitWS({ type: 'GetLottoDrawsRequest' }))
          }

          dispatch(initApp())

          // send events to native app

          sendRNevent({ type: 'handshakeReceived', userId: body.account?.id })
          // send event to rn which will hide native auth screens
          sendRNevent({
            type: 'userAuthorized',
            value: Boolean(body.account),
          })

          // action handles autologin or opens auth ways dialog
          dispatch(runsCurrentAuthFlow(Boolean(body.account)))

          // loggout when session expired
          dispatch(detectSessionExpired(Boolean(body?.account)))
          break
        }

        case 'GetAccountInfoResponse': {
          const { balances, prerequisites, ...restUserData } = body
          dispatch(initCurrencies(balances))
          dispatch(setOpenPrerequisites(prerequisites))
          dispatch(setUser(restUserData))
          break
        }

        case 'GetLottoPicksResponse': {
          dispatch(savePlayedLottos(body.accepted, body.closed))
          break
        }

        case 'GetAccountBonusesResponse': {
          // dispatch(checkUserBonuses())
          dispatch(receivedBonuses(body))
          break
        }

        case 'GetOptInProductsResponse': {
          dispatch(setPlayedGames(body.products))
          break
        }

        case 'GetLottoDrawsResponse': {
          dispatch(saveLottoDraws(body.data))
          dispatch(gamesIsLoaded())
          break
        }

        case 'KeepAliveResponse': {
          break
        }

        case 'PostLottoPicksResponse': {
          const {
            // eslint-disable-next-line
            type,
            ...rest
          } = body
          batch(() => {
            dispatch(updateGame(rest))
            dispatch(fetchCurrentLottoLines())
            dispatch(setLineIsSending(false))
          })
          trackGAEvent(
            rest.product,
            `lotto_submitted_${rest.currency}`.toLowerCase() as GAEvents,
            `${rest.product} line ${rest.picks.length}`
          )
          break
        }

        case 'ChangePasswordResponse': {
          dispatch(deleteUser())
          dispatch(
            openSnackbar({
              message: 'Password changed successfully. Please login again',
              variant: 'success',
            })
          )
          Router.push(ROUTES.LOGIN)

          break
        }

        case 'DeletePaymentMethodResponse': {
          dispatch(removePaymentCard(body.code))
          break
        }

        case 'GetPaymentMethodsResponse': {
          dispatch(savePaymentMethods(body.methods))
          break
        }

        case 'GetAccountSettingsResponse': {
          dispatch(
            setRedeemPolicy({
              sweepstakeRedeemPolicy: body.sweepstakeRedeemPolicy,
              fiatRedeemPolicy: body.fiatRedeemPolicy,
              nonMonetaryRedeemPolicy: body.nonMonetaryRedeemPolicy,
            })
          )
          break
        }

        case 'GetLottoNumbersResponse': {
          dispatch(saveLottoNumbersHistory(body.data))
          break
        }

        case 'GetTemporaryTokenResponse': {
          dispatch(
            setTempToken({
              token: body.token,
              counterparty: body.counterparty,
              subject: body.subject,
              country: body.country,
              betLimits: body.betLimits,
            })
          )
          break
        }

        case 'GetRedeemMoneyHistoryResponse': {
          const { redeems } = body
          dispatch(setRedeemItems(redeems))
          break
        }

        case 'SetAccountAttributionInfoResponse': {
          dispatch(accountModeChangedHandler(body))

          break
        }

        case 'SetAccountModeResponse': {
          dispatch(accountModeChangedHandler(body))
          break
        }

        case 'SendWelcomeEmailResponse': {
          dispatch(setSentEmail())
          break
        }

        case 'GetPaymentOrderResponse': {
          if (body.success) {
            dispatch(
              replaceDialog('ORDER_CONFIRMATION_MESSAGE', {
                status: 'success',
                provider: body.provider,
              })
            )
          }
          break
        }

        case 'CreateWithdrawMethodResponse': {
          dispatch(updateWithdrawMethodResponseHandler())
          break
        }
        // case 'CreateOrderResponse': {
        //   dispatch(setSkipOTP(body.skipOtp))
        //   break
        // }

        case 'GetAccountFreeSpinsResponse': {
          if (!window.ReactNativeWebView) {
            dispatch(saveFreeSpins(body?.data))
            if (body?.data?.length) {
              dispatch(openFreeSpinsDialog())
            }
          }
          break
        }

        default: {
          if (process.env.PROFILE !== 'prod') {
            // eslint-disable-next-line
            console.log(`%c${`Unhandled event response - `}`, 'color:Green', body)
          }
          break
        }
      }
    })
  }

export default wsResponseHandler
