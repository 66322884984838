import { Reducer } from 'redux'
import { CurrenciesState, CurrenciesActionTypes, Actions } from './types'

const initialState: CurrenciesState = {
  allCurrencies: [],
  activeCurrencyId: 'GC',
  fiatCurrency: null,
  activeModalCurrencyId: null,
}

const reducer: Reducer<CurrenciesState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case CurrenciesActionTypes.SET_ACTIVE_CURRENCY: {
      return {
        ...state,
        activeCurrencyId: action.payload.activeCurrencyId,
      }
    }

    case CurrenciesActionTypes.TOGGLE_ACTIVE_CURRENCY: {
      return {
        ...state,
        activeCurrencyId: state.activeCurrencyId === 'GC' ? 'SC' : 'GC',
      }
    }

    case CurrenciesActionTypes.SET_FIAT_CURRENCY: {
      return {
        ...state,
        fiatCurrency: action.payload.currencyId,
      }
    }

    case CurrenciesActionTypes.SET_ACTIVE_MODAL_CURRENCY: {
      return {
        ...state,
        activeModalCurrencyId: action.payload.currencyId,
      }
    }

    case CurrenciesActionTypes.INIT_CURRENCIES: {
      return {
        ...state,
        allCurrencies: action.payload.allCurrencies,
      }
    }
    case CurrenciesActionTypes.UPDATE_BALANCE: {
      return {
        ...state,
        allCurrencies: state.allCurrencies.map((currency) => {
          const updatedCurrency = action.payload.currency.find((i) => i.currency === currency.currency)

          return updatedCurrency || currency
        }),
      }
    }

    case CurrenciesActionTypes.CLEAR_CURRENCY: {
      return initialState
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as currenciesReducer }
