import { createSelector } from 'reselect'
import { Currencies } from 'store/modules/currencies/types'
import { ApplicationState } from 'store/modules/types'

const getActiveCurrencyId = (state: ApplicationState) => state.currencies.activeCurrencyId
const getFiatCurrency = (state: ApplicationState) => state.currencies.fiatCurrency
const getActiveModalCurrencyId = (state: ApplicationState) => state.currencies.activeModalCurrencyId
const getAllCurrencies = (state: ApplicationState) => state.currencies.allCurrencies

export const getActiveCurrencyIdSelector = createSelector(getActiveCurrencyId, (activeCurrencyId) => activeCurrencyId)
export const getFiatCurrencySelector = createSelector(getFiatCurrency, (currencyId) => currencyId)

export const getActiveModalCurrencyIdSelector = createSelector(getActiveModalCurrencyId, (currencyId) => currencyId)

export const getAllCurrenciesSelector = createSelector(getAllCurrencies, (allCurrencies) => allCurrencies)
export const isCurrenciesAvailableSelector = createSelector(getAllCurrencies, (currencies) =>
  Boolean(currencies?.length)
)

export const getSweepstakeCurrencySelector = createSelector(getAllCurrenciesSelector, (allCurrencies) =>
  allCurrencies.find((i) => i.currency === 'SC')
)

export const getGoldCurrencySelector = createSelector(getAllCurrenciesSelector, (allCurrencies) =>
  allCurrencies.find((i) => i.currency === 'GC')
)

export const getUSDCurrencySelector = createSelector(getAllCurrenciesSelector, (allCurrencies) =>
  allCurrencies.find((i) => i.currency === 'USD')
)

export const isSweepstakeCurrencyActiveSelector = createSelector(getActiveCurrencyIdSelector, (id) => id === 'SC')

export const getCurrencyInitializedSelector = createSelector(
  getActiveCurrencyIdSelector,
  (activeCurrencyId) => activeCurrencyId !== null
)

export const getBalanceByIDSelector = createSelector(
  getAllCurrenciesSelector,
  (_: ApplicationState, currencyId: Currencies) => currencyId,
  (allCurrencies, currencId) => allCurrencies.find((el) => el.currency === currencId)
)
