import { MouseEvent } from 'react'
import useStyles from './styles'
import cx from 'classnames'
import { onExternalLinkClick } from 'containers/NativeBridge/utils'

type IconTypes = 'facebook' | 'twitter' | 'instagram'

type Props = {
  type: IconTypes
  href: string
  className?: string
}

export default function SocialButton(props: Props) {
  const classes = useStyles(props)
  const onClick = (e: MouseEvent<HTMLAnchorElement>) => onExternalLinkClick(e, props.href)

  return (
    <a
      href={props.href}
      onClick={onClick}
      className={cx(classes.root, props?.className)}
      rel='noopener noreferrer'
      target='_blank'
      data-test={`social-button-${props.type}`}
    >
      <img className={classes.image} src={`${process.env.IMG_ORIGIN}/logos/${props.type}.png`} alt={props.type} />
    </a>
  )
}
