import { connect } from 'react-redux'
import { InformationOutline } from 'mdi-material-ui'
import { ApplicationState } from 'store/modules/types'
import useStyles from './styles'
import Typography from '@material-ui/core/Typography'
import Button from 'src/components/Button'
import IconButton from '@material-ui/core/IconButton'
import { ILevel, User } from 'store/modules/user/types'

import { Currency } from 'store/modules/currencies/types'
import {
  getSweepstakeCurrencySelector,
  getUSDCurrencySelector,
  getGoldCurrencySelector,
} from 'store/modules/currencies/selectors'
import { sweepstakeEnabledSelector, isSCVisibleSelector } from 'store/modules/appConfig/selectors'
import { getUserSelector, getUserVipPointsLevelSelector } from 'store/modules/user/selectors'
import { freeLottoAllowedSelector } from 'store/modules/games/selectors'
import { openDialog } from 'store/modules/dialog/actions'

import { useRouter } from 'next/router'
import useMediaQuery from 'hooks/useMediaQuery'
import ROUTES from 'config/routes.json'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'

import CurrencyDescriptionItem from './CurrencyDescriptionItem'
import SweepTooltip from './TootlTip'
import { redeemFlowStart } from 'store/modules/redeem/actions'
import { openShopHandler } from 'src/store/modules/shop/actions'

type Props = {
  vipLevel: ILevel
  openDialog: typeof openDialog
  sweepstakeEnabled: boolean
  isSCVisible: boolean
  sweepBalance: Currency
  goldBalance: Currency
  realBalance: Currency
  closeMenu?: () => void
  freeLottoAllowed: boolean
  redeemFlowStart: typeof redeemFlowStart
  openShopHandler: typeof openShopHandler
  user: User
}

const mapState = (state: ApplicationState) => ({
  sweepstakeEnabled: sweepstakeEnabledSelector(state),
  sweepBalance: getSweepstakeCurrencySelector(state),
  isSCVisible: isSCVisibleSelector(state),
  goldBalance: getGoldCurrencySelector(state),
  realBalance: getUSDCurrencySelector(state),
  vipLevel: getUserVipPointsLevelSelector(state),
  freeLottoAllowed: freeLottoAllowedSelector(state),
  user: getUserSelector(state),
})

const mapDispatch = {
  openDialog,
  redeemFlowStart,
  openShopHandler,
}

const t = getDataFromJson(content)

function AccountInfo(props: Props) {
  const classes = useStyles(props)
  const router = useRouter()
  const isMobile = useMediaQuery('xs')

  return (
    <div className={classes.root} data-test='menu-account-info'>
      <div className={classes.item}>
        <Typography className={classes.itemText} variant='subtitle2'>
          {t('VIP')}: {props.vipLevel.name}{' '}
          <IconButton
            data-test='menu-vip-info-link'
            className={classes.link}
            onClick={() => {
              if (isMobile) {
                props.openDialog('VIP_REWARDS_DIALOG', { closeMobileMenu: props.closeMenu })
              } else {
                router.push(ROUTES.VIP_REWARDS)
              }
            }}
          >
            <InformationOutline />
          </IconButton>
        </Typography>
        <Typography className={classes.itemText} variant='subtitle2'>
          {props.vipLevel?.current} {t('VIP')} {t('Points')}
        </Typography>
      </div>
      <CurrencyDescriptionItem currency='GC' t={t} amount={props.goldBalance?.amount} showSign />
      {props.freeLottoAllowed && <CurrencyDescriptionItem currency='USD' t={t} amount={props.realBalance?.amount} />}
      {props.isSCVisible && (
        <CurrencyDescriptionItem
          data-test='menu-currency-info-button'
          tooltip={<SweepTooltip balances={props.sweepBalance} />}
          currency='SC'
          showSign
          t={t}
          amount={props.sweepBalance?.amount}
        />
      )}
      <div className={classes.buttons}>
        <Button
          data-test='menu-get-coins-button'
          onClick={() => {
            props.openShopHandler(isMobile, props.closeMenu)
          }}
          className={classes.button}
          disabled={props.user?.acceptedTcVersion !== props.user?.tcVersion}
        >
          {t('Get Coins')}
        </Button>
        {props.sweepstakeEnabled || props.freeLottoAllowed ? (
          <Button
            data-test='menu-redeem-button'
            onClick={props.redeemFlowStart}
            className={classes.button}
            variant='outlined'
            disabled={props.user?.acceptedTcVersion !== props.user?.tcVersion}
          >
            {t('Redeem')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default connect(mapState, mapDispatch)(AccountInfo)
