import { useEffect, ReactNode } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'

import lightTheme from 'src/theme/lightTheme'

type Props = {
  children: ReactNode
}

export default function ThemeProvider(props: Props) {
  useEffect(() => {
    const jssStyles = document.getElementById('#jss-server-side')
    jssStyles?.parentNode?.removeChild(jssStyles)
  }, [])

  return <MuiThemeProvider theme={lightTheme}>{props.children}</MuiThemeProvider>
}
