import { makeStyles, Theme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export default makeStyles((theme: Theme) => ({
  root: {
    '&:after': {
      left: 0,
    },
    '&:before': {
      left: 0,
    },
  },
  disabled: {
    background: `${fade(theme.palette.common.black, 0.38)} !important`,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      backgroundColor: `${fade(theme.palette.common.black, 0.38)} !important`,
    },
  },
  default: {
    background: theme.palette.defaultTheme.gradient,
    color: theme.palette.defaultTheme.textColor,
    '&:hover': {
      boxShadow: theme.palette.defaultTheme.boxShadow,
      backgroundColor: theme.palette.defaultTheme.gradient,
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette.defaultTheme.themeColor}`,
      color: theme.palette.defaultTheme.themeColor,
      background: 'transparent',
      '&:hover': {
        background: fade(theme.palette.defaultTheme.themeColor, 0.08),
        boxShadow: 'none',
      },
    },
  },
  SC: {
    background: theme.palette.sweepCoinTheme.gradient,
    color: theme.palette.sweepCoinTheme.textColor,
    '&:hover': {
      boxShadow: theme.palette.sweepCoinTheme.boxShadow,
      background: theme.palette.sweepCoinTheme.gradient,
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette.sweepCoinTheme.themeColor}`,
      color: theme.palette.sweepCoinTheme.themeColor,
      background: 'transparent',
      '&:hover': {
        background: fade(theme.palette.sweepCoinTheme.themeColor, 0.08),
        boxShadow: 'none',
      },
    },
  },
  GC: {
    background: theme.palette.goldCoinTheme.gradient,
    color: theme.palette.goldCoinTheme.textColor,
    '&:hover': {
      boxShadow: theme.palette.goldCoinTheme.boxShadow,
      backgroundColor: theme.palette.goldCoinTheme.gradient,
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette.goldCoinTheme.themeColor}`,
      color: theme.palette.goldCoinTheme.themeColor,
      background: 'transparent',
      '&:hover': {
        background: fade(theme.palette.goldCoinTheme.themeColor, 0.08),
        boxShadow: 'none',
      },
    },
  },

  USD: {},
}))
