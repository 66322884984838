import { Currencies } from '../currencies/types'

export enum ActionTypes {
  GET_TEMP_TOKEN = 'tempToken/GET_TEMP_TOKEN',
  SET_TEMP_TOKEN = 'tempToken/SET_TEMP_TOKEN',
  CLEAR = 'tempToken/CLEAR',
}

export type Counterparties = 'internal' | 'prizeout' | 'netent' | 'markortech' | 'pragmatic' | 'relax'

export type BetLimits = {
  values: Array<number>
  defaultValue: number
}

export type TempTokenRequestOptions = {
  counterparty: Counterparties
  currency?: Currencies
  code?: string
  shop?: boolean
}

export type TempTokenResponseOptions = {
  country: string
  counterparty: Counterparties
  subject: string
  token: string
  betLimits?: BetLimits
}

export interface GetTempTokenAction {
  type: ActionTypes.GET_TEMP_TOKEN
  payload: {
    options: TempTokenRequestOptions
  }
}

export interface SetTempTokenAction {
  type: ActionTypes.SET_TEMP_TOKEN
  payload: {
    tokenData: TempTokenResponseOptions
  }
}

export interface ClearTempTokenAction {
  type: ActionTypes.CLEAR
  payload: {
    counterparty: Counterparties
  }
}

export type Actions = GetTempTokenAction | SetTempTokenAction | ClearTempTokenAction

export type TempToken = {
  token: string | null
  loading?: boolean
  country?: string
  subject?: string
  counterparty?: Counterparties
  betLimits?: BetLimits
}

export interface TempTokenState {
  readonly internal: TempToken
  readonly prizeout: TempToken
  readonly relax: TempToken
  readonly netent: TempToken
  readonly markortech: TempToken
  readonly pragmatic: TempToken
}
