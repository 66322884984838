import useStyles from './styles'
import { connect } from 'react-redux'
import content from 'translates/en/common.json'
import { Currencies, Currency } from 'store/modules/currencies/types'
import { ApplicationState } from 'store/modules/types'
import { getDataFromJson } from 'src/utils/helpers'
// import AdIcon from './AdIcon'
import Button from 'src/components/Button'
import {
  getGoldCurrencySelector,
  getSweepstakeCurrencySelector,
  getActiveCurrencyIdSelector,
} from 'store/modules/currencies/selectors'
import { isNativeAppSelector } from 'store/modules/appConfig/selectors'
import { openShopHandler } from 'src/store/modules/shop/actions'
import Amount from '../Amount'

const t = getDataFromJson(content)
type Props = {
  openShopHandler: typeof openShopHandler
  isNative: boolean
  currencies: {
    SC: Currency
    GC: Currency
    USD: Currency
  }
  activeCurrency: Currencies
}
const mapState = (state: ApplicationState) => ({
  isNative: isNativeAppSelector(state),
  currencies: {
    SC: getSweepstakeCurrencySelector(state),
    GC: getGoldCurrencySelector(state),
  },
  activeCurrency: getActiveCurrencyIdSelector(state),
})
const mapDispatch = {
  openShopHandler,
}

function LoggedIn(props: Props) {
  const classes = useStyles(props)
  const { amount } = props.currencies[props.activeCurrency]

  return (
    <>
      {/* <AdIcon /> */}
      {amount && <Amount amount={amount} activeCurrency={props.activeCurrency} />}

      <Button onClick={() => props.openShopHandler(true)} className={classes.button} color={props.activeCurrency}>
        {props.isNative ? t('get coins') : t('Buy')}
      </Button>
    </>
  )
}

export default connect(mapState, mapDispatch)(LoggedIn)
