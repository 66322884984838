import { WSResponse } from './types/types'
import { ThunkType } from 'store/modules/types'
import { lottoPickRequsetErrorHandler } from 'store/modules/lottoGameFlow/actions'
import { setIsRedeeming } from 'store/modules/redeem/actions'
import { setIsPaymentFlowInProcess } from 'src/store/modules/paymentsFlow/actions'
import { openDialog, removeDialogByName } from 'src/store/modules/dialog/actions'
import { trackGAEvent } from 'src/utils/gtag'

const wsErrorResponseByTypeHandler =
  (data: WSResponse): ThunkType =>
  (dispatch) => {
    const { body, status } = data
    switch (body.type) {
      case 'PostLottoPicksResponse': {
        dispatch(lottoPickRequsetErrorHandler(status.errorCode, body))
        break
      }

      case 'DeletePaymentMethodResponse': {
        dispatch(setIsPaymentFlowInProcess(false))
        break
      }

      case 'CreateOrderResponse': {
        trackGAEvent('CreateOrderRequest', 'submitted_payment_error', status?.errorCode || status?.errorText)
        if (body.limitAmount) {
          dispatch(removeDialogByName('PAYMENT_DIALOG'))
          dispatch(
            openDialog('PURCHASE_LIMIT_DIALOG', {
              limitAmount: body.limitAmount,
              limitAvailable: body.limitAvailable,
              limitPeriod: body.limitPeriod,
              limitEnd: body.limitEnd,
            })
          )
        }
        if (body.requestKyc) {
          dispatch(removeDialogByName('PAYMENT_DIALOG'))
          dispatch(openDialog('GET_VERIFIED_DIALOG'))
        }

        break
      }

      case 'RepeatPaymentOrderResponse': {
        dispatch(setIsPaymentFlowInProcess(false))
        trackGAEvent('RepeatPaymentOrderResponse', 'submitted_payment_error', 'RepeatPaymentOrderResponse')
        break
      }

      case 'CreateWithdrawMethodResponse': {
        dispatch(setIsRedeeming(false))
        break
      }

      default: {
        break
      }
    }
  }

export default wsErrorResponseByTypeHandler
