import { convertTitleToUrl } from 'utils/url'

export const gameRedirects = (rootURL: string, dynamicIdName: string) => {
  return {
    [`/games/${rootURL}/[${dynamicIdName}]`]: `/games/${rootURL}/[${dynamicIdName}]/[currencyName]`,
    [`/games/${rootURL}/[${dynamicIdName}]/play`]: `/games/${rootURL}/[${dynamicIdName}]/[currencyName]/play`,
    [`/games/${rootURL}/[${dynamicIdName}]/play/mobile`]: `/games/${rootURL}/[${dynamicIdName}]/[currencyName]/play/mobile`,

    [`/games/${rootURL}/[${dynamicIdName}]/[currencyName]`]: `/games/${rootURL}/[${dynamicIdName}]`,
    [`/games/${rootURL}/[${dynamicIdName}]/[currencyName]/play`]: `/games/${rootURL}/[${dynamicIdName}]/play`,
    [`/games/${rootURL}/[${dynamicIdName}]/[currencyName]/play/mobile`]: `/games/${rootURL}/[${dynamicIdName}]/play/mobile`,
  }
}
export const gameRoutesGenerator = (rootUrl: string, dynamicIdName: string) => {
  return {
    [`/games/${rootUrl}/[${dynamicIdName}]`]: (gameTitle: string) =>
      `/games/${rootUrl}/${convertTitleToUrl(gameTitle)}`,

    [`/games/${rootUrl}/[${dynamicIdName}]/play`]: (gameTitle: string) =>
      `/games/${rootUrl}/${convertTitleToUrl(gameTitle)}/play`,

    [`/games/${rootUrl}/[${dynamicIdName}]/play/mobile`]: (gameTitle: string) =>
      `/games/${rootUrl}/${convertTitleToUrl(gameTitle)}/play/mobile`,

    [`/games/${rootUrl}/[${dynamicIdName}]/[currencyName]`]: (gameTitle: string, currencyName: string) =>
      `/games/${rootUrl}/${convertTitleToUrl(gameTitle)}/${currencyName}`,

    [`/games/${rootUrl}/[${dynamicIdName}]/[currencyName]/play`]: (gameTitle: string, currencyName: string) =>
      `/games/${rootUrl}/${convertTitleToUrl(gameTitle)}/${currencyName}/play`,

    [`/games/${rootUrl}/[${dynamicIdName}]/[currencyName]/play/mobile`]: (gameTitle: string, currencyName: string) =>
      `/games/${rootUrl}/${convertTitleToUrl(gameTitle)}/${currencyName}/play/mobile`,
  }
}
