import Typography from '@material-ui/core/Typography'
import Button from 'components/Button'
import Head from 'next/head'
import { getDataFromJson } from 'src/utils/helpers'
import useStyles from './styles'
import ROUTES from 'config/routes.json'
import Layout from './Layout'
import { useRouter } from 'next/router'

const t = getDataFromJson({})

type Props = {}

function VerifyEmailSuccess(props: Props) {
  const classes = useStyles(props)
  const router = useRouter()

  return (
    <>
      <Head>
        <title>{t(`You are welcome at ${process.env.BRAND}.com | Email verified`)}</title>
      </Head>

      <Layout>
        <>
          <Typography component='h1' variant='h4' className={classes.title}>
            {t('Email verified')}
          </Typography>
          <Typography variant='body2' component='p'>
            Thank you! Your email has been verified, now you can continue using the site.
          </Typography>
          <Button
            onClick={() => {
              router.push(ROUTES.HOME_LOGGED_IN)
            }}
            className={classes.logoutBtn}
          >
            go to home page
          </Button>
        </>
      </Layout>
    </>
  )
}

export default VerifyEmailSuccess
