import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    left: 16,
  },

  shopLink: {
    marginRight: 16,
  },

  curencyText: {
    lineHeight: '0.75rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
  },

  icon: {
    width: 'auto',
    height: 16,
    marginRight: 4,
  },

  currency: {
    marginRight: '0.5rem',
    // mobile
    [theme.breakpoints.down('xs')]: {
      marginRight: '1rem',
    },
  },
}))
