import { Reducer } from 'redux'
import { PrerequisitesState, PrerequisitesActionTypes, Actions } from './types'

const initialState: PrerequisitesState = null
const reducer: Reducer<PrerequisitesState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case PrerequisitesActionTypes.SET_OPEN_PREREQUISITES: {
      return {
        ...action.payload.prerequisites,
      }
    }
    case PrerequisitesActionTypes.UPDATE_PREREQUISITES: {
      return {
        ...action.payload.prerequisites,
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as prerequisitesReducer }
