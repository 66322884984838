import { ReactNode } from 'react'
import Slide from '@material-ui/core/Slide'
import useStyles from './styles'
import { Paper } from '@material-ui/core'
import Close from './Close'

type Props = {
  children: ReactNode
  closeHandler: () => void
}
export default function MessageLayout(props: Props) {
  const classes = useStyles(props)

  return (
    <Slide direction='up' in mountOnEnter unmountOnExit>
      <Paper className={classes.root}>
        <Close onClick={props.closeHandler} />
        {props.children}
      </Paper>
    </Slide>
  )
}
