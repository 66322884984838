import { makeStyles, Theme } from '@material-ui/core/styles'
import layoutConfig from 'config/layoutConstants.json'

export default makeStyles((theme: Theme) => ({
  root: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: layoutConfig.headerHeight,
    // @ts-ignore
    background: theme.palette.primary.background,
    justifyContent: 'space-between',
    zIndex: 2,
  },

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logoLink: {
    textDecoration: 'none',
  },
  logo: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },

  avatarLink: {
    textDecoration: 'none',
  },
  avatar: {
    marginRight: 4,
    width: '44px !important',
    height: '44px !important',
    cursor: 'pointer',
  },
}))
