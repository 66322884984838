import { ThunkType } from '../types'
import { SnackbarActionTypes, OpenSnackbarAction, OpenSnackbarOptions } from './types'

export const openSnackbar = (options: OpenSnackbarOptions): OpenSnackbarAction => {
  const defaultOptions: OpenSnackbarOptions = {
    message: '',
    autoHide: 4000,
    variant: 'error',
    messageAlign: 'flex-start',
    positionVertical: 'bottom',
    positionHorizontal: 'center',
  }

  return {
    type: SnackbarActionTypes.OPEN,
    payload: {
      ...defaultOptions,
      ...options,
    },
  }
}

export const closeSnackbar = (): ThunkType => (dispatch) => {
  dispatch({
    type: SnackbarActionTypes.CLOSE,
  })

  setTimeout(() => {
    dispatch({
      type: SnackbarActionTypes.CLEAR,
    })
  }, 500)
}
