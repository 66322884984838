import { makeStyles, Theme } from '@material-ui/core/styles'
import { amber, green } from '@material-ui/core/colors'

export default makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  'flex-end': {
    justifyContent: 'flex-end',
  },
  center: {
    justifyContent: 'center',
  },
  'flex-start': {
    justifyContent: 'flex-start',
  },
  'space-between': {
    justifyContent: 'space-between',
  },
  variantIcon: {
    fontSize: 20,
    marginRight: '0.5rem',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
    '&: a': {
      color: 'inherit',
    },
  },
  messageText: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  messageTextWithButton: {
    marginRight: theme.spacing(1),
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  actionButton: {
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    width: 130,
    marginLeft: 'auto',
    marginBottom: 0,
  },
}))
