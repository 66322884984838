import { v4 as uuid } from 'uuid'

import {
  PaymentMethod,
  PaymentsFlowActionTypes,
  PaymentsProviderTypes,
  SavePaymentMethodsAction,
  SetDefaultCardAction,
  CardDetails,
  WorldpayResponse,
  ClearPaymentsStateAction,
  SetPaymentFlowInProcessAction,
} from './types'
import { ThunkType } from 'store/modules/types'
import { getSavedCardsSelector } from './selectors'
import { WSinstance } from 'services/websocket'
import { openDialog, removeDialogByName, replaceDialog } from '../dialog/actions'
import { openSnackbar } from 'src/store/modules/snackbar/actions'

import { convertToJson, parseStatus } from 'src/utils/request'
import {
  getLocationDataSelector,
  getPayWithMyBankConfigSelector,
  getWorldpayAppIdSelector,
  isNativeAppSelector,
} from 'src/store/modules/appConfig/selectors'
import Router from 'next/router'
import { fbPurchaseEvent } from 'src/services/facebook/utils'
import { tagManagerEcommerceEvent, trackGAEvent } from 'src/utils/gtag'
import { getOffers } from '../shop/actions'
import { getAllSCDiscountOffersSelector } from '../shop/selectors'
import { clearSlotGameFlow } from '../slotGameFlow/actions'
import { setFirstDepositDate, setFirstPurchaseOffer, setUserVipSubscription } from '../user/actions'
import { OfferPurchaseType, ShopOffer } from '../shop/types'
import { Currencies } from '../currencies/types'
import loadScript from 'src/utils/loadScript'
import { getUserSelector } from '../user/selectors'
import { getCustomerData } from 'src/containers/PayWithMyBankProvider/utils'
import { OfferDeclineNotification } from 'src/services/websocket/types/notifications'
import { fetchOrderData } from './utils'
import { getItemFormLocalStorage, setDataToLocalStorage } from 'src/utils/localStorage'
import { WSResponse } from 'src/services/websocket/types/types'
import { sendRNevent } from 'src/utils/helpers'
import { getDialogStackSelector } from '../dialog/selectors'

export const savePaymentMethods = (methods: Array<PaymentMethod>): SavePaymentMethodsAction => ({
  type: PaymentsFlowActionTypes.SAVE_PAYMENTS_METHODS,
  payload: {
    methods,
  },
})

export const setDefaultCard = (code: string): SetDefaultCardAction => ({
  type: PaymentsFlowActionTypes.SET_DEFAULT_CARD,
  payload: {
    code,
  },
})

export const setIsPaymentFlowInProcess = (isPaymentFlowInProcess: boolean): SetPaymentFlowInProcessAction => ({
  type: PaymentsFlowActionTypes.SET_IS_PAYMENT_FLOW_IN_PROCESS,
  payload: {
    isPaymentFlowInProcess,
  },
})

export const clearPaymentsState = (): ClearPaymentsStateAction => ({
  type: PaymentsFlowActionTypes.CLEAR_PAYMENTS_STATE,
})

export const fetchSavedCards = (): ThunkType => (dispatch) => {
  dispatch(WSinstance.emitWS({ type: 'GetPaymentMethodsRequest' }))
}

//= =============
export const makeOrderByProvider =
  (offerCode: string, provider: PaymentsProviderTypes, options?: { [key: string]: string }): ThunkType =>
  (dispatch, getState) => {
    const user = getUserSelector(getState())
    dispatch(
      WSinstance.emitWS({
        type: 'CreateOrderRequest',
        provider,
        brandName: process.env.BRAND_NAME,
        transactionId: uuid(),
        offer: offerCode,
        referer: window.location.href,
        ...options,
        at: user?.firstOfferAt || new Date().toISOString(),
        session: getItemFormLocalStorage('SESSION_ID', false),
      })
    )
  }

export const removePaymentCard =
  (code: string): ThunkType =>
  (dispatch, getState) => {
    const cards = getSavedCardsSelector(getState())
    dispatch(setIsPaymentFlowInProcess(false))
    dispatch({
      type: PaymentsFlowActionTypes.REMOVE_SAVED_CARD,
      payload: {
        methods: cards.filter((it) => it.code !== code),
      },
    })
  }

export const removeSavedCard =
  (code: string): ThunkType =>
  (dispatch) => {
    dispatch(WSinstance.emitWS({ type: 'DeletePaymentMethodRequest', code }))
    dispatch(setIsPaymentFlowInProcess(true))
  }

export const paymentErrorsHandler =
  (error: WSResponse): ThunkType =>
  (dispatch) => {
    const { status, body } = error
    dispatch(setIsPaymentFlowInProcess(false))

    switch (body?.type) {
      case 'CreateOrderResponse': {
        trackGAEvent('CreateOrderRequest', 'submitted_payment_error', status?.errorCode || status?.errorText)
        if (body.limitAmount) {
          dispatch(removeDialogByName('PAYMENT_DIALOG'))
          dispatch(
            openDialog('PURCHASE_LIMIT_DIALOG', {
              limitAmount: body.limitAmount,
              limitAvailable: body.limitAvailable,
              limitPeriod: body.limitPeriod,
              limitEnd: body.limitEnd,
            })
          )
        }
        if (body.requestKyc) {
          dispatch(removeDialogByName('PAYMENT_DIALOG'))
          dispatch(openDialog('GET_VERIFIED_DIALOG'))
        }
        break
      }
      case 'RepeatPaymentOrderResponse': {
        trackGAEvent('RepeatPaymentOrderResponse', 'submitted_payment_error', 'RepeatPaymentOrderResponse')
        break
      }
      default: {
        // eslint-disable-next-line
        console.log('paymet error', error)
      }
    }
    dispatch(
      openSnackbar({
        message: status.errorText,
      })
    )
    sendRNevent({
      type: 'wsError',
      message: status.errorText,
      errorCode: status.errorCode,
    })
  }

export const finishedPaymentFlow = (): ThunkType => (dispatch, getState) => {
  const dialogs = getDialogStackSelector(getState())
  const confirmationSuccesMessage = dialogs?.find?.(
    (el) => el.modalName === 'ORDER_CONFIRMATION_MESSAGE' && el.dialogProps?.status === 'success'
  )
  dispatch(removeDialogByName('PAYMENT_DIALOG'))
  dispatch(removeDialogByName('PAYMENT_CVV_DIALOG'))
  if (!confirmationSuccesMessage) {
    dispatch(replaceDialog('ORDER_CONFIRMATION_MESSAGE', { status: 'waiting' }))
  }
}

const offerPurchaseSuccess =
  (response: OfferPurchaseType): ThunkType =>
  (dispatch, getState) => {
    const isNative = isNativeAppSelector(getState())
    const user = getUserSelector(getState())
    const bonusOffer = getAllSCDiscountOffersSelector(getState())
    const currentDialog =
      isNative && response?.mode === 'sweepstake_preview_web' && bonusOffer
        ? 'switch_to_sweepstake_success_message'
        : 'success'
    // eslint-disable-next-line
    console.log(currentDialog)
    trackGAEvent(response.provider, 'submitted_payment_success', response.offer.code)

    dispatch(removeDialogByName('SHOP_DIALOG'))
    dispatch(removeDialogByName('PAYMENT_DIALOG'))
    dispatch(removeDialogByName('PAYMENT_CVV_DIALOG'))
    dispatch(setIsPaymentFlowInProcess(false))
    dispatch(
      replaceDialog('ORDER_CONFIRMATION_MESSAGE', {
        status: 'success',
        provider: response.provider,
      })
    )
    dispatch(setFirstPurchaseOffer(response.offer.code))
    dispatch(fetchSavedCards())
    tagManagerEcommerceEvent(response.transactionId, response.offer, response.provider)
    fbPurchaseEvent(response.offer, response.currency)
    // @TODO Will remove this code after server changes
    dispatch(
      setDataToLocalStorage(
        'LAST_PAYMENT_METHOD',
        response.provider.includes('spreedly') ? 'spreedly' : response.provider
      )
    )

    if (!user?.firstDeposit) {
      dispatch(setFirstDepositDate(response.firstDeposit))
    }

    if (Router.route.includes('/play')) {
      dispatch(clearSlotGameFlow())
    }
  }

const subscribeSuscess =
  (response: OfferPurchaseType): ThunkType =>
  (dispatch) => {
    trackGAEvent('vip', 'submitted_payment_success', response.offer.code)
    dispatch(setUserVipSubscription(response.offer.code))
    if (response.firstPaymentInSubscription) {
      dispatch(openDialog('VIP_SUBSCRIPTION_CONFIRM_DIALOG'))
    }
  }
export const listenPurchaseNotification =
  (response: OfferPurchaseType): ThunkType =>
  (dispatch) => {
    switch (response.offer.offerType) {
      case 'one_time': {
        dispatch(offerPurchaseSuccess(response))
        break
      }
      case 'permanent': {
        dispatch(offerPurchaseSuccess(response))
        break
      }
      case 'subscription': {
        dispatch(subscribeSuscess(response))
        break
      }
      default: {
        break
      }
    }

    dispatch(getOffers())
  }

export const purchaseResponseListenerByRouter = (): ThunkType => (dispatch) => {
  const query = new URLSearchParams(window.location.search)
  if (query.get('payment_status')) {
    if (query.get('payment_status') === 'success') {
      if (
        query.get('transaction_id') &&
        query.get('amount') &&
        query.get('description') &&
        query.get('offer') &&
        query.get('currency')
      ) {
        const offer = {
          price: Number(query.get('amount')),
          title: query.get('description'),
          code: query.get('offer'),
          transaction_id: query.get('transaction_id'),
          currency: query.get('currency'),
        }
        const provider = query.get('provider')
        tagManagerEcommerceEvent(offer.transaction_id, offer, provider)
        trackGAEvent(provider, 'submitted_payment_success', offer.code)
        fbPurchaseEvent(offer, offer.currency as Currencies)
        dispatch(WSinstance.emitWS({ type: 'GetPaymentOrderRequest', transactionId: offer.transaction_id }))
        if (provider === 'skrill') {
          dispatch(setDataToLocalStorage('LAST_PAYMENT_METHOD', provider))
        }
      }
      dispatch(
        replaceDialog('ORDER_CONFIRMATION_MESSAGE', {
          status: 'waiting',
        })
      )
    }
    const queryString = Router.asPath.slice(Router.asPath.indexOf('?'))
    window.history.replaceState({}, document.title, Router.asPath.replace(queryString, ''))
  }
}

const offerDeclinePWMBHandler =
  ({
    tempToken,
    reason,
    offer,
    transactionId,
    code,
    errCode,
  }: {
    tempToken: string
    reason: string
    offer: ShopOffer
    transactionId: string
    code: string
    errCode: string
  }): ThunkType =>
  (dispatch, getState) => {
    const payWithMyBankConfig = getPayWithMyBankConfigSelector(getState())
    const user = getUserSelector(getState())
    const userLocation = getLocationDataSelector(getState())

    const data = {
      accessId: payWithMyBankConfig.id,
      requestSignature: tempToken,
      merchantId: payWithMyBankConfig.merchant,
      description: offer.title,
      currency: 'USD',
      amount: '0.00',
      merchantReference: transactionId,
      paymentType: 'Verification',
      authToken: 'new',
      transactionId: code,
      returnUrl: '#',
      cancelUrl: '#',
      customer: getCustomerData(user, userLocation, user.firstDeposit),
    }

    if (errCode === 'expired') {
      if (!window?.PayWithMyBank) {
        loadScript(`${process.env.PAY_WITH_MY_BANK}${payWithMyBankConfig.id}`).then(() => {
          window.PayWithMyBank.establish(data)
        })
      } else {
        window.PayWithMyBank.establish(data)
      }
    } else {
      dispatch(removeDialogByName('PAYMENT_DIALOG'))
      dispatch(removeDialogByName('PAYMENT_CVV_DIALOG'))
      dispatch(replaceDialog('PAYMENT_ERROR_DIALOG', { offer, reason }))
    }
  }

export const offerDeclineNotificationHandler =
  ({ tempToken, reason, provider, offer, transactionId, code, errCode }: OfferDeclineNotification): ThunkType =>
  (dispatch) => {
    dispatch(fetchSavedCards())
    dispatch(setIsPaymentFlowInProcess(false))

    trackGAEvent('CreateOrderRequest', 'submitted_payment_error', reason)

    if (provider === 'pay_with_my_bank') {
      dispatch(
        offerDeclinePWMBHandler({
          tempToken,
          reason,
          offer,
          transactionId,
          code,
          errCode,
        })
      )

      return null
    }

    dispatch(removeDialogByName('PAYMENT_DIALOG'))
    dispatch(removeDialogByName('PAYMENT_CVV_DIALOG'))
    dispatch(
      openSnackbar({
        message: reason || 'Payment has failed',
      })
    )

    return null
  }

export const repeatPWMBpurchase =
  (offer: ShopOffer, code: string): ThunkType =>
  (dispatch, getState) => {
    const userInfo = getUserSelector(getState())
    dispatch(setIsPaymentFlowInProcess(true))
    fetchOrderData('pay_with_my_bank', offer.code, { at: userInfo?.firstDeposit || new Date().toISOString() })
      .then((res) => {
        dispatch(WSinstance.emitWS({ type: 'RepeatPaymentOrderRequest', code, transactionId: res.transactionId }))
      })
      .catch((err) => dispatch(paymentErrorsHandler(err)))
  }

export const pwmbOpenIframe =
  (offer: ShopOffer): ThunkType =>
  (dispatch, getState) => {
    const user = getUserSelector(getState())
    const location = getLocationDataSelector(getState())
    const pwmbConfig = getPayWithMyBankConfigSelector(getState())

    dispatch(setIsPaymentFlowInProcess(true))
    fetchOrderData('pay_with_my_bank', offer.code, { at: user?.firstDeposit || new Date().toISOString() })
      .then((res) => {
        const config = {
          merchantReference: res.transactionId,
          description: offer.title,
          requestSignature: res.tempToken,
          currency: 'USD',
          amount: '0.00',
          paymentType: 'Deferred',
          returnUrl: '?',
          cancelUrl: '?',
          displayAmount: offer.price,
          customer: getCustomerData(user, location, res.at),
          accessId: pwmbConfig.id,
          merchantId: pwmbConfig.merchant,
        }
        if (!window!.PayWithMyBank) {
          loadScript(`${process.env.PAY_WITH_MY_BANK}${pwmbConfig.id}`).then(() => {
            window.PayWithMyBank.establish(config)
            dispatch(setIsPaymentFlowInProcess(false))
          })
        } else {
          window.PayWithMyBank.establish(config)
          dispatch(setIsPaymentFlowInProcess(false))
        }
      })
      .catch((err) => dispatch(paymentErrorsHandler(err)))
  }

// starkemc+skrill_212@gmail.com
// WORLD PAY ============
export const makeWorldpayOrder =
  (formValues: CardDetails): ThunkType =>
  (dispatch, getState) => {
    const clientKey = getWorldpayAppIdSelector(getState())
    const { name, cardNumber, cvc, expiry, saveCard, offerCode } = formValues
    const [expiryMonth, expiryYear] = expiry.split(' / ')

    const formData = {
      reusable: saveCard,
      paymentMethod: {
        name,
        cardNumber,
        cvc,
        expiryMonth,
        expiryYear: `20${expiryYear}`,
        type: 'Card',
      },
      clientKey,
    }

    return (
      fetch('https://api.worldpay.com/v1/tokens', {
        method: 'POST',
        body: JSON.stringify(formData),
      })
        .then((data) => dispatch(parseStatus(data)))
        .then(convertToJson)
        .then((result: WorldpayResponse) => {
          dispatch(
            WSinstance.emitWS({
              type: 'CreateOrderRequest',
              provider: 'world_pay',
              brandName: process.env.BRAND_NAME,
              token: result.token,
              transactionId: uuid(),
              remember: saveCard,
              offer: String(offerCode),
            })
          )
        })
        // eslint-disable-next-line
        .catch((err) => console.log('err', err))
    )
  }

export const payWithReusableToken =
  (token: string, cvc: number, offerCode: string): ThunkType =>
  (dispatch, getState) => {
    const clientKey = getWorldpayAppIdSelector(getState())

    const formData = {
      cvc,
      clientKey,
    }

    return (
      fetch(`https://api.worldpay.com/v1/tokens/${token}`, {
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(formData),
      })
        .then((data) => dispatch(parseStatus(data)))
        .then((result: WorldpayResponse) => {
          if (result.ok) {
            dispatch(
              WSinstance.emitWS({
                type: 'CreateOrderRequest',
                token,
                offer: offerCode,
                brandName: process.env.BRAND_NAME,
                transactionId: uuid(),
                remember: false,
              })
            )
          }
        })
        // eslint-disable-next-line
        .catch((err) => console.log('err', err))
    )
  }
