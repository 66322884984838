import useStyles from './styles'
import { IconButton } from '@material-ui/core'
import { Close } from 'mdi-material-ui'

type Props = {
  onClick: () => void
}

export default function CloseButton(props: Props) {
  const classes = useStyles(props)

  return (
    <IconButton onClick={props.onClick} className={classes.close}>
      <Close className={classes.icon} />
    </IconButton>
  )
}
