import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import { getIsAdminUserSelector, getUserSelector } from 'store/modules/user/selectors'
import { User } from 'store/modules/user/types'
import { ApplicationState } from 'store/modules/types'
import content from 'translates/en/common.json'

import useStyles from './styles'
import { getDataFromJson } from 'src/utils/helpers'

type Props = {
  userInfo: User
  isAdmin: boolean
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    userInfo: getUserSelector(state),
    isAdmin: getIsAdminUserSelector(state),
  }
}
const t = getDataFromJson(content)

function AccountIdMessage(props: Props) {
  const classes = useStyles(props)
  if (props.userInfo) {
    const id = props.userInfo.id.split('/')[0]

    return (
      <>
        {props.userInfo?.admin && (
          <Typography className={classes.root} component='div' variant='body2'>
            {t('Admin user')}
          </Typography>
        )}
        <Typography className={classes.root} component='div' variant='body2' paragraph data-test='menu-account-id'>
          {t('Account ID')}: {id}
        </Typography>
      </>
    )
  }

  return null
}

export default connect(mapStateToProps)(AccountIdMessage)
