import { connect } from 'react-redux'
import { ApplicationState } from 'store/modules/types'
import { closeLatestDialog, openDialog } from 'store/modules/dialog/actions'
import { CloseLatestDialogAction, ModalItem } from 'store/modules/dialog/types'
import { getDialogStackSelector, getDialogVisibilitySelector } from 'store/modules/dialog/selectors'

import RootDialogComponent from './components/RootDialog'
import useOpenDialogByQuery from 'hooks/useOpenDialogByQuery'

const mapState = (state: ApplicationState) => ({
  dialogStack: getDialogStackSelector(state),
  visible: getDialogVisibilitySelector(state),
})
const mapDispatch = {
  closeLatestDialog,
  openDialog,
}

type Props = {
  dialogStack: Array<ModalItem>
  visible: boolean
  closeLatestDialog: () => CloseLatestDialogAction
  disablePortal?: boolean
  toggleFullScreen?: Function
}

function RootDialog(props: Props) {
  useOpenDialogByQuery()

  return (
    <RootDialogComponent
      dialogStack={props.dialogStack}
      open={props.visible}
      closeLatestDialog={props.closeLatestDialog}
      disablePortal={props.disablePortal}
      toggleFullScreen={props.toggleFullScreen}
    />
  )
}

export default connect(mapState, mapDispatch)(RootDialog)
