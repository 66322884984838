import { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { useRouter } from 'next/router'

import useStyles from './styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { currencyBaseRouter } from 'src/utils/navigator'

import { isLoggedInSelector } from 'store/modules/user/selectors'
import { getActiveLotterySelector } from 'store/modules/lottoGameFlow/selectors'
import { ApplicationState } from 'store/modules/types'
import { LottoProduct } from 'store/modules/games/types'

import pageTabsConfig, { pageAnimationDelay } from 'config/pageTabsConfigs'
import LAYOUTS from 'src/config/layouts'

const mapState = (state: ApplicationState) => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    activeLottery: getActiveLotterySelector(state),
  }
}

type Props = {
  isLoggedIn: boolean
  activeLottery: LottoProduct
  pageProps: any
}

function RootLotteryPage(props: Props) {
  const classes = useStyles(props)
  const router = useRouter()
  const tabsPropertiesFiltered = pageTabsConfig[LAYOUTS.LOTTO_TABS_LAYOUT](
    props.pageProps.gameProduct,
    currencyBaseRouter,
    router
  )

  const [currentRouter, setRouter] = useState('')
  useEffect(() => {
    setTimeout(() => {
      setRouter(router.pathname)
    }, pageAnimationDelay)
  }, [router.pathname])

  return (
    <>
      <Tabs
        variant='scrollable'
        scrollButtons='off'
        value={tabsPropertiesFiltered.findIndex((it) => it.pathname.includes(currentRouter))}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
        }}
      >
        {tabsPropertiesFiltered.map((it) => (
          <Tab
            classes={{
              root: classes.tabButton,
              selected: classes.selected,
            }}
            selected={it.pathname === currentRouter}
            key={it.pathname}
            label={it.title}
            onClick={() => {
              it.onClick(setRouter.bind(null, it.pathname))
            }}
          />
        ))}
      </Tabs>
    </>
  )
}

export default connect(mapState)(RootLotteryPage)
