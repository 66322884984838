import useStyles from './styles'
import Button from 'src/components/Button'
import { getDataFromJson } from 'utils/helpers'
import content from 'translates/en/common.json'
import { PWAEventType } from 'src/store/modules/appConfig/types'

type Props = {
  pwaInstance: PWAEventType | { prompt: () => void }
}

const t = getDataFromJson(content)

function PWAInfo(props: Props) {
  const classes = useStyles(props)

  const openDialog = () => {
    props.pwaInstance.prompt()
  }

  return (
    <div className={classes.root}>
      <img
        className={classes.icon}
        src={`${process.env.IMG_ORIGIN}/pwa_icon_${process.env.BRAND_NAME}.png`}
        alt='pwa icon'
      />
      <Button variant='outlined' size='small' className={classes.button} onClick={openDialog} data-test='pwa-button'>
        {t('Install')}
      </Button>
    </div>
  )
}

export default PWAInfo
