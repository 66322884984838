import { Star, Diamond } from 'mdi-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserAvatar from 'containers/UserAvatar'

import { User, ILevel, SubscriptionType } from 'store/modules/user/types'

import useStyles from './styles'

type UserAvatarProps = {
  user: User
  xpLevel: ILevel
  levelValue: number
  isUserVipSubscription: Array<SubscriptionType>
}

function UserAvatarWithLevel(props: UserAvatarProps) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      {props.isUserVipSubscription && <Diamond className={classes.diamond} />}
      <UserAvatar userInfo={props.user} avatarClass={classes.avatar} />
      <CircularProgress
        data-test='header-circular-progress'
        classes={{
          root: classes.lineRoot,
        }}
        variant='static'
        value={props.levelValue}
        thickness={4}
      />
      <div data-test='header-level'>
        <Star className={classes.levelIcon} />

        <span className={classes.level}>{props.xpLevel.level}</span>
      </div>
    </div>
  )
}

export default UserAvatarWithLevel
