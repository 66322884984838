import { Reducer } from 'redux'
import { RedeemState, RedeemActionTypes, Actions } from './types'

const initialState: RedeemState = {
  activeRedeemId: null,
  isRedeeming: false,
  userRedeemInfo: null,
  redeems: null,
  withdrawMethods: null,
  redeemPolicy: null,
  providerPolicies: null,
}

const reducer: Reducer<RedeemState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case RedeemActionTypes.SET_IS_REDEEMING: {
      return {
        ...state,
        isRedeeming: action.payload.isRedeeming,
      }
    }

    case RedeemActionTypes.SET_ACTIVE_REDEEM_ID: {
      return {
        ...state,
        activeRedeemId: action.payload.redeemId,
      }
    }

    case RedeemActionTypes.SET_USER_REDEEM_INFO: {
      return {
        ...state,
        userRedeemInfo: action.payload.userRedeemInfo,
      }
    }

    case RedeemActionTypes.SET_REDEEMS: {
      return {
        ...state,
        redeems: action.payload.redeems,
      }
    }

    case RedeemActionTypes.SET_WITHDRAW_METHODS: {
      return {
        ...state,
        withdrawMethods: action.payload.withdrawMethods,
      }
    }

    case RedeemActionTypes.SET_REDEEM_COMMON_POLICY: {
      return {
        ...state,
        redeemPolicy: action.payload.policy,
      }
    }

    case RedeemActionTypes.SET_PROVIDERS_REDEEM_POLICY: {
      return {
        ...state,
        providerPolicies: action.payload.providerPolicies,
      }
    }

    case RedeemActionTypes.CLEAR_REDEEM_STATE: {
      return initialState
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as redeemReducer }
