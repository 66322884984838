import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
})
