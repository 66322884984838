import { Reducer } from 'redux'
import { TempTokenState, ActionTypes, Actions } from './types'

const initialState: TempTokenState = {
  internal: {
    loading: false,
    token: null,
    country: undefined,
    subject: undefined,
  },

  prizeout: {
    loading: false,
    token: null,
    country: undefined,
    subject: undefined,
  },
  netent: {
    loading: false,
    token: null,
    country: undefined,
    subject: undefined,
  },
  markortech: {
    loading: false,
    token: null,
    country: undefined,
    subject: undefined,
  },
  pragmatic: {
    loading: false,
    token: null,
    country: undefined,
    subject: undefined,
  },
  relax: {
    loading: false,
    token: null,
    country: undefined,
    subject: undefined,
  },
}

const reducer: Reducer<TempTokenState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.GET_TEMP_TOKEN: {
      return {
        ...state,
        [action.payload.options.counterparty]: {
          loading: true,
        },
      }
    }

    case ActionTypes.SET_TEMP_TOKEN: {
      return {
        ...state,
        [action.payload.tokenData.counterparty]: {
          ...action.payload.tokenData,
          loading: false,
        },
      }
    }

    case ActionTypes.CLEAR: {
      return {
        ...state,
        [action.payload.counterparty]: {
          ...initialState[action.payload.counterparty],
        },
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as tempTokenReducer }
