import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    textAlign: 'center',
    opacity: 0.6,
    marginBottom: 2,
    fontSize: '0.8rem',
  },
}))
