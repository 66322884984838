import { createStore, applyMiddleware, Store } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from 'store/modules/rootReducer'
import { ApplicationState } from 'store/modules/types'
import { viewResultsMiddleware } from './middlewares/viewResultsMiddleware'
import { initSocketListenersMiddleware } from './middlewares/initSocketListenersMiddleware'
import sessionDialogsMiddleware from './middlewares/sessionDialogsMiddleware'
/// <reference types="types/global.d.ts" />

const makeStore = () =>
  createStore(
    rootReducer,
    // null,
    process.env.PROFILE === 'prod'
      ? applyMiddleware(thunk, sessionDialogsMiddleware, viewResultsMiddleware, initSocketListenersMiddleware)
      : composeWithDevTools(
          applyMiddleware(thunk, sessionDialogsMiddleware, viewResultsMiddleware, initSocketListenersMiddleware)
        )
  )

export const wrapper = createWrapper<Store<ApplicationState>>(makeStore, { debug: false })
