export const LINKS_RECORD: {
  [key: string]: {
    title: string
    link: string
  }
} = {
  link_support: {
    title: 'support',
    link: process.env.ZENDESK_SUPPORT_LINK,
  },
}

export const LINK_REGEX = /\{(.*?)\}/gi

export const getLinkObjectFromMessage = (message: string) => {
  const linkType = LINK_REGEX.exec(message)?.[1] as keyof typeof LINKS_RECORD
  if (Object.prototype.hasOwnProperty.call(LINKS_RECORD, linkType)) {
    return {
      link: LINKS_RECORD[linkType].link,
      title: LINKS_RECORD[linkType].title,
      key: linkType,
    }
  }

  return null
}
