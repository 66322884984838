import { Dispatch } from 'redux'

import { deleteUser } from 'store/modules/user/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { setIsPaymentFlowInProcess } from 'store/modules/paymentsFlow/actions'
import { sendRNevent } from 'utils/helpers'
import { WSResponse } from './types/types'

const errorHandler = (data: WSResponse) => (dispatch: Dispatch) => {
  const { status } = data

  switch (status.errorCode) {
    case 'err_password_incorrect': {
      dispatch(
        openSnackbar({
          message: 'Password is not correct',
        })
      )
      sendRNevent({
        type: 'wsError',
        message: 'Password is not correct',
        errorCode: status.errorCode,
      })
      break
    }

    case 'err_auth': {
      dispatch(deleteUser())
      dispatch(
        openSnackbar({
          message: status.errorText,
        })
      )
      sendRNevent({
        type: 'wsError',
        message: status.errorText,
        errorCode: status.errorCode,
      })
      break
    }

    case 'err_system': {
      dispatch(
        openSnackbar({
          message: status.errorText,
        })
      )
      sendRNevent({
        type: 'wsError',
        message: status.errorText,
        errorCode: status.errorCode,
      })
      break
    }

    case 'err_denied': {
      if (status?.errorText) {
        dispatch(
          openSnackbar({
            message: status.errorText,
          })
        )
      }

      break
    }

    case 'err_payment': {
      dispatch(setIsPaymentFlowInProcess(false))

      if (status?.errorText) {
        dispatch(
          openSnackbar({
            message: status.errorText,
          })
        )
      }

      break
    }

    case 'err_insufficient_funds': {
      break
    }

    default: {
      dispatch(
        openSnackbar({
          message: status.errorText,
        })
      )

      sendRNevent({
        type: 'wsError',
        message: status.errorText,
        errorCode: status.errorCode,
      })
    }
  }
}

export default errorHandler
