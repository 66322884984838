import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 5,
    // background: theme.palette.goldCoinTheme.gradient,
    alignItems: 'center',
  },
  infoRow: {
    display: 'flex',
  },
  text: {
    color: '#fff',
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: 5,
    // marginRight: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 4,
    paddingBottom: 4,
  },
}))
