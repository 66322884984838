import { useRef, useEffect } from 'react'

export default function usePrevious(value: any) {
  // console.log(value)
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}
