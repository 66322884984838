import { parseISO, isPast } from 'date-fns'

import { compareByDate } from 'utils/date'
import { GameProduct, ProductModes, LottoRule, LottoRuleNames, GameTypes, LottoProduct } from './types'

export const getClosestDraw = (game: LottoProduct) => {
  return game.draws?.find((i) => !isPast(parseISO(i.closingDate)))
}

export const convertArrayToObject = (arr: any, key: string) => {
  return (
    arr &&
    arr.reduce((acc: any, item: any) => {
      acc[item[key]] = {
        ...item,
      }

      return acc
    }, {})
  )
}

// const compareByTitle = (a: GameProduct, b: GameProduct) => {
//   const nameA = a.title.toUpperCase() // ignore upper and lowercase
//   const nameB = b.title.toUpperCase() // ignore upper and lowercase
//   if (nameA < nameB) {
//     return -1
//   }
//   if (nameA > nameB) {
//     return 1
//   }

//   // names must be equal
//   return 0
// }

// const compareByLevel = (a: GameProduct, b: GameProduct) => a.fromLevel - b.fromLevel
const sortByOrder = (a: GameProduct, b: GameProduct) => a.rank - b.rank

export const isLottoGameProductGuard = (game: LottoProduct | GameProduct): game is LottoProduct => {
  return Object.prototype.hasOwnProperty.call(game, 'draws')
}

// @TODO hide virtual lotto
export const sortGamesByType = (games: Array<GameProduct>) => {
  const categorizedGames = games.reduce((acc: any, item: any) => {
    let game = item
    if (['table_games', 'video_slots'].includes(item.type)) {
      game = { ...item, type: 'slots' }
    }

    // other categories with single type
    if (!acc[game.type]) {
      acc[game.type] = []
    }
    acc[game.type].push(game)

    return acc
  }, {} as Record<GameTypes, Array<GameProduct>>)

  const keys = Object.keys(categorizedGames)

  keys.map((k: GameTypes) => categorizedGames[k].sort(sortByOrder))

  return categorizedGames
}

export const getLottoRulesByGameMode = (mode: ProductModes, allRules: Record<LottoRuleNames, LottoRule>) => {
  if (mode === ProductModes.FREE) {
    return allRules.WITH_2_ADDITIONAL_BALLS
  }

  return allRules.WITH_1_ADDITIONAL_BALL
}

export const sortLottosByCode = (orderArr: Array<string>) => (currentArr: Array<GameProduct>) => {
  // const sortedCodes = ['onlHour', 'onlMorning', 'onlAfternoon']
  if (currentArr) {
    const preferGames = orderArr
      .map((it) => {
        return currentArr.find((el) => el.code === it)
      })
      .filter(Boolean)

    return [...preferGames, ...currentArr.filter((it) => !orderArr.includes(it.code))]
  }

  return null
}

// free lottos available for mobile only users and which registered before 22 June
export const filterFreeLottos = (loggedIn: boolean) => (currentArr: Array<LottoProduct>) => {
  if (loggedIn) {
    return currentArr
  }

  // hide games with 'free' mode
  return currentArr.filter((lotto) => lotto.mode !== ProductModes.FREE)
}

// sort lottos by closest draw
export const sortLottosByClosestDraw = () => (lottos: Array<LottoProduct>) => {
  // Sort game by closest draw
  // @TODO find better solution for closingDate
  const sortedGames = lottos
    .filter((it) => it?.draws?.length > 0)
    .sort((el1, el2) => compareByDate(getClosestDraw(el1)?.closingDate, getClosestDraw(el2)?.closingDate))

  return sortedGames.length ? sortedGames : lottos
}
