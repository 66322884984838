import { batch } from 'react-redux'
import { removeDialogByName, replaceDialog } from '../dialog/actions'
import { isMiniGameRunningSelector } from './selectors'
import { MiniGameFlowActionTypes, MiniGameIsRunningAction } from './types'
import { ThunkType } from 'store/modules/types'

export const toggleMiniGameIsRunning = (isMiniGameRunning: boolean): MiniGameIsRunningAction => ({
  type: MiniGameFlowActionTypes.MINI_GAME_IS_RUNNING,
  payload: {
    isMiniGameRunning,
  },
})

export const openMiniGame = (): ThunkType => (dispatch) => {
  dispatch(toggleMiniGameIsRunning(true))
  dispatch(replaceDialog('MINI_GAME_DIALOG'))
}

export const adVideoFinishedInMiniGame = (): ThunkType => (dispatch, getState) => {
  const isMiniGameRunning = isMiniGameRunningSelector(getState())
  if (isMiniGameRunning) {
    batch(() => {
      dispatch(removeDialogByName('MINI_GAME_DIALOG'))
      dispatch(removeDialogByName('MINI_GAME_WINNING_DIALOG'))
    })
    // eslint-disable-next-line no-console
    console.log('video is ended in mini-game')
  }
}

export const adVideoClosedByUserInMiniGame = (): ThunkType => (dispatch, getState) => {
  const isMiniGameRunning = isMiniGameRunningSelector(getState())
  if (isMiniGameRunning) {
    batch(() => {
      dispatch(removeDialogByName('MINI_GAME_DIALOG'))
      dispatch(removeDialogByName('MINI_GAME_WINNING_DIALOG'))
    })
    // eslint-disable-next-line no-console
    console.log('user closed video in mini game')
  }
}
