export enum PrerequisitesActionTypes {
  SET_OPEN_PREREQUISITES = 'prerequisites/SET_OPEN_PREREQUISITES',
  UPDATE_PREREQUISITES = 'prerequisites/UPDATE_PREREQUISITES',
}

export type PrerequisitesType = {
  [key: string]: object
}

export interface UpdatePrerequisitesAction {
  type: PrerequisitesActionTypes.UPDATE_PREREQUISITES
  payload: {
    prerequisites: PrerequisitesType
  }
}

export interface SetOpenPrerequisitedsAction {
  type: PrerequisitesActionTypes.SET_OPEN_PREREQUISITES
  payload: {
    prerequisites: PrerequisitesType
  }
}

export type Actions = SetOpenPrerequisitedsAction | UpdatePrerequisitesAction

export type PrerequisitesState = PrerequisitesType | null
