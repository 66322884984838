import { Currencies } from '../currencies/types'

// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum RedeemActionTypes {
  SET_IS_REDEEMING = 'redeem/SET_IS_REDEEMING',
  SET_USER_REDEEM_INFO = 'redeem/SET_USER_REDEEM_INFO',
  SET_REDEEMS = 'redeem/SET_REDEEMS',
  SET_ACTIVE_REDEEM_ID = 'redeem/SET_ACTIVE_REDEEM_ID',
  SET_WITHDRAW_METHODS = 'redeem/SET_WITHDRAW_METHODS',
  CLEAR_REDEEM_STATE = 'redeem/CLEAR_REDEEM_STATE',
  SET_REDEEM_COMMON_POLICY = 'redeem/SET_REDEEM_COMMON_POLICY',
  SET_PROVIDERS_REDEEM_POLICY = 'redeem/SET_PROVIDERS_REDEEM_POLICY',
}

export type Providers = 'PayQuicker' | 'Skrill' | 'ACH' | 'SkrillACH'
export type ProvidersData = {
  [key in Providers]?: number | null
}

export type RedeemPolicyField = {
  minAmount: number
  maxAmount: number
}
export type RedeemPolicyType = {
  sweepstakeRedeemPolicy: RedeemPolicyField
  fiatRedeemPolicy: RedeemPolicyField
  nonMonetaryRedeemPolicy: RedeemPolicyField
}

export type ProvidersRedeemPolicy = {
  ach: RedeemPolicyType
  paypal: RedeemPolicyType
  prizeout: { nonMonetaryRedeemPolicy: RedeemPolicyField }
  pwmb: RedeemPolicyType
  sci: RedeemPolicyType
  skrill: RedeemPolicyType
}

export interface SetRedeemPolicyAction {
  type: RedeemActionTypes.SET_REDEEM_COMMON_POLICY
  payload: {
    policy: RedeemPolicyType
  }
}

export type UserRedeemInfo = {
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  amount: number | null
  accountHolderName: string | null
  accountNumber: string | null
}

export type RedeemItem = {
  amount: number
  createdAt: string
  currency: Currencies
  id: number | string
  status: 'pre_authorized' | 'confirmed' | 'declined' | 'cancelled' | 'locked'
  provider: 'string'
}

export type RedeemWithdrawACHInputsType = {
  fiRouting: string
  fiAcc: string
  fiAccType: string
  firstName: string
  lastName: string
  phoneNumber: string
  birthDate: null | Date
  addr: string
  city: string
  state: string
  zip: string
}

export type ACHWithdrawMethodType = {
  code: string | null
  withdrawMethod: {
    fiRouting: string
    fiAcc: string
    fiAccType: string
    firstName: string
    lastName: string
    addr: string
    city: string
    state: string
    zip: string
    dobYear: string
    dobDay: string
    dobMonth: string
    hphAreaCode: string
    hphLocalNumber: string
    type: 'mazooma_ach'
    accToken?: string
  }
}

export type SkrillWithdrawMethodType = {
  code: string | null
  withdrawMethod: {
    type: 'skrill'
    email: string
  }
}

export type SkrillACHWithdrawMethodType = {
  code: string | null
  withdrawMethod: {
    type: 'skrill_ach'
    transactionId: string
  }
}

export type WithdrawMethodType = ACHWithdrawMethodType | SkrillWithdrawMethodType | SkrillACHWithdrawMethodType

export type RedeemWithdrawRequestType = {
  currency: Currencies
  amount: number
  email: string
  method: WithdrawMethodType
}

export interface SetWithdrawMethodsAction {
  type: RedeemActionTypes.SET_WITHDRAW_METHODS
  payload: {
    withdrawMethods: Array<WithdrawMethodType>
  }
}

export interface SetIsRedeemingAction {
  type: RedeemActionTypes.SET_IS_REDEEMING
  payload: {
    isRedeeming: boolean
  }
}

export interface SetActiveRedeemIdAction {
  type: RedeemActionTypes.SET_ACTIVE_REDEEM_ID
  payload: {
    redeemId: number | null
  }
}

export interface SetUserRedeemInfoAction {
  type: RedeemActionTypes.SET_USER_REDEEM_INFO
  payload: {
    userRedeemInfo: UserRedeemInfo
  }
}

export interface SetRedeemsAction {
  type: RedeemActionTypes.SET_REDEEMS
  payload: {
    redeems: Array<RedeemItem>
  }
}

export interface ClearRedeemAction {
  type: RedeemActionTypes.CLEAR_REDEEM_STATE
}

export interface SetProvidersRedeemPolicyAction {
  type: RedeemActionTypes.SET_PROVIDERS_REDEEM_POLICY
  payload: {
    providerPolicies: ProvidersRedeemPolicy
  }
}

export type Actions =
  | SetIsRedeemingAction
  | SetUserRedeemInfoAction
  | SetRedeemsAction
  | SetActiveRedeemIdAction
  | SetWithdrawMethodsAction
  | ClearRedeemAction
  | SetRedeemPolicyAction
  | SetProvidersRedeemPolicyAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface RedeemState {
  readonly activeRedeemId: number | null
  readonly isRedeeming: boolean
  readonly userRedeemInfo: UserRedeemInfo | null
  readonly redeems: Array<RedeemItem> | null
  readonly withdrawMethods: Array<WithdrawMethodType> | null
  readonly redeemPolicy: null | RedeemPolicyType
  readonly providerPolicies: null | ProvidersRedeemPolicy
}
