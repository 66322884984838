import { BaseBonus, DailyBonus } from 'store/modules/bonusesFlow/types'
import { ApplicationState, ThunkType } from 'store/modules/types'

export const isTodayUTC = (year: number, month: number, day: number) => {
  const now = new Date()

  return year === now.getUTCFullYear() && month === now.getUTCMonth() && day === now.getUTCDate()
}

export const isFutureUTC = (year: number, month: number, day: number) => {
  const now = new Date()

  return year > now.getUTCFullYear() || month > now.getUTCMonth() || day > now.getUTCDate()
}

export const getActiveBonuses = (dailyBonuses: DailyBonus[]) => {
  return dailyBonuses.sort((a, b) => a.offset - b.offset)
}

export const getTodayBonus = (dailyBonuses: DailyBonus[]) => {
  return dailyBonuses.find((i) => {
    return i?.eligible
  })
}

export const acceptBonusFlow =
  <T extends BaseBonus>(
    code: string,
    selector: (state: ApplicationState) => T[],
    saveFunction: (data: T[]) => any,
    updateDelayedBonuses: (codeI: string) => any
  ): ThunkType =>
  (dispatch, getState) => {
    const bonuses = selector(getState())
    const filteredBonuses = bonuses.filter((el) => el.code !== code)
    dispatch(saveFunction(filteredBonuses))
    dispatch(updateDelayedBonuses(code))
  }

export const updateBonusesNotification =
  <T extends BaseBonus>(
    bonus: T,
    selector: (state: ApplicationState) => T[],
    saveFunction: (data: T[]) => any,
    checkUserBonus: () => any
  ): ThunkType =>
  (dispatch, getState) => {
    const bonuses = selector(getState())
    const updatedBonuses = bonuses?.length ? [...bonuses.map((el) => ({ ...el })), bonus] : [bonus]
    dispatch(saveFunction(updatedBonuses))
    dispatch(checkUserBonus())
  }

export const checkNextUserBonus = (code: string, callBack: (bonus: BaseBonus) => void) => (bonuses: BaseBonus[]) => {
  const currenctGameIndex = bonuses.findIndex((el) => el.code === code)
  if (currenctGameIndex < bonuses.length - 1) {
    const nextBonus = bonuses[currenctGameIndex + 1]
    callBack(nextBonus)
  }
}
