import ROUTES from 'config/routes.json'
import { HomeOutline, SlotMachineOutline, Magnify } from 'mdi-material-ui'
import ShopMobileLink from 'src/containers/Menu/ShopMobileLink'
import { useSelector } from 'react-redux'
import { isLoggedInSelector } from 'src/store/modules/user/selectors'
import {
  getRawLottosSelector,
  getNewGamesSelector,
  getSlotProductsSelector,
  getRawTableProductsSelector,
} from 'src/store/modules/games/selectors'

const mobileBottomNavigation = () => {
  const lottoGames = useSelector(getRawLottosSelector)
  const slotGames = useSelector(getSlotProductsSelector)
  const tableGames = useSelector(getRawTableProductsSelector)
  const newGames = useSelector(getNewGamesSelector)
  const loggedIn = useSelector(isLoggedInSelector)

  return [
    {
      label: 'Home',
      path: [loggedIn ? ROUTES.HOME_LOGGED_IN : ROUTES.HOME_LOGGED_OUT],
      icon: HomeOutline,
      id: '1',
    },
    {
      label: 'Games',
      path: [
        newGames.length && ROUTES.GAMES_TOP,
        newGames.length && ROUTES.GAMES_NEW,
        slotGames.length && ROUTES.GAMES_SLOTS,
        lottoGames.length && ROUTES.GAMES_LOTTOS,
        tableGames?.length && ROUTES.GAMES_TABLE,
      ].filter(Boolean),
      icon: SlotMachineOutline,
      id: '2/1',
    },
    {
      icon: ShopMobileLink,
      id: '3',
    },
    {
      label: 'Search',
      path: [ROUTES.SEARCH],
      icon: Magnify,
      id: '4',
    },
  ]
}

export default mobileBottomNavigation
