import { Currencies } from 'store/modules/currencies/types'

export enum AppConfigActionTypes {
  '__NEXT_REDUX_WRAPPER_HYDRATE__' = '__NEXT_REDUX_WRAPPER_HYDRATE__',
  TOGGLE_DARK_THEME = 'appConfig/TOGGLE_DARK_THEME',
  INIT_APP = 'appConfig/INIT_APP',
  INIT_NATIVE_APP = 'appConfig/INIT_NATIVE_APP',
  SAVE_APPS_CONFIGS = 'appConfig/SAVE_APPS_CONFIGS',
  SAVE_PASSWORD_POLICY = 'appConfig/SAVE_PASSWORD_POLICY',
  SET_GOLD_CURRENCY = 'appConfig/SET_GOLD_CURRENCY',
  SET_SC_ENABLED = 'appConfig/SET_SC_ENABLED',
  SET_ANDROID_DEVICE_ID = 'appConfig/SET_ANDROID_DEVICE_ID',
  SET_FORCEMENT_MODE = 'appConfig/SET_FORCEMENT_MODE',
  SET_PUSHTOKEN = 'appConfig/SET_PUSHTOKEN',
  SET_IS_SOCKET_CONNECTING = 'appConfig/SET_IS_SOCKET_CONNECTING',
  SET_IS_OPEN_PWA_MESSAGE = 'appConfig/SET_IS_OPEN_PWA_MESSAGE',
  SAVE_GAID_DATA = 'appConfig/SAVE_GAID_DATA',
  SAVE_LOCATION_DATA = 'appConfig/SAVE_LOCATION_DATA',
  SAVE_TRACKING_ID = 'appConfig/SAVE_TRACKING_ID',
}

export type AppItem = {
  id: string
  merchant?: string
  key?: string
  gateway?: string
  merchantId?: string
  merchantName?: string
}

export type LocationData = {
  country: string
  state: string
  city: string
  ip: string
}

export type PasswordPolicyType = {
  min?: number
  max?: number
  lowerCase?: number
  upperCase?: number
  special?: number
  digits?: number
}

export type PWAEventType = Event & {
  platforms: Array<string>
  userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

export type PWAInstanceType = PWAEventType | 'ios'

export interface ToggleDarkThemeAction {
  type: AppConfigActionTypes.TOGGLE_DARK_THEME
}

export interface InitAppAction {
  type: AppConfigActionTypes.INIT_APP
}

export interface InitNativeAppAction {
  type: AppConfigActionTypes.INIT_NATIVE_APP
}

export interface SetIsOpenPWAMessageAction {
  type: AppConfigActionTypes.SET_IS_OPEN_PWA_MESSAGE
  payload: {
    isPWAMessageOpen: boolean
  }
}
export interface SaveLocationDataAction {
  type: AppConfigActionTypes.SAVE_LOCATION_DATA
  payload: {
    location: LocationData
  }
}

export interface SaveAppsConfigsAction {
  type: AppConfigActionTypes.SAVE_APPS_CONFIGS
  payload: {
    apps: { [key: string]: AppItem }
  }
}

export interface SetGoldCurrencyAction {
  type: AppConfigActionTypes.SET_GOLD_CURRENCY
  payload: {
    goldCurrency: Currencies
  }
}

export interface SetPushTokenAction {
  type: AppConfigActionTypes.SET_PUSHTOKEN
  payload: {
    pushToken: string
  }
}

export interface SetSweepstakeEnabledAction {
  type: AppConfigActionTypes.SET_SC_ENABLED
  payload: {
    enabled: boolean
  }
}

export interface SetAndroidDeviceIdAction {
  type: AppConfigActionTypes.SET_ANDROID_DEVICE_ID
  payload: {
    deviceId: string
  }
}

export interface SetForcementModeAction {
  type: AppConfigActionTypes.SET_FORCEMENT_MODE
  payload: {
    forcement: string
  }
}

export interface SaveGaidDataAction {
  type: AppConfigActionTypes.SAVE_GAID_DATA
  payload: {
    advertisingId: string
    osVersion: string
    appVersion: string
  }
}

export interface SaveTrackingIdAction {
  type: AppConfigActionTypes.SAVE_TRACKING_ID
  payload: {
    trackingId: string
  }
}

export interface SavePasswordPolicyAction {
  type: AppConfigActionTypes.SAVE_PASSWORD_POLICY
  payload: {
    passwordPolicy: PasswordPolicyType
  }
}

export type Actions =
  | InitAppAction
  | ToggleDarkThemeAction
  | InitNativeAppAction
  | SaveAppsConfigsAction
  | SavePasswordPolicyAction
  | SetGoldCurrencyAction
  | SetSweepstakeEnabledAction
  | SetAndroidDeviceIdAction
  | SetPushTokenAction
  | SetForcementModeAction
  | SetIsOpenPWAMessageAction
  | SaveGaidDataAction
  | SaveLocationDataAction
  | SaveTrackingIdAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface AppConfigState {
  readonly darkTheme: boolean
  readonly init: boolean
  readonly goldCurrency: Currencies | null
  readonly sweepstakeEnabled: boolean
  readonly apps: { [key: string]: AppItem } | null
  readonly passwordPolicy: PasswordPolicyType
  readonly nativeApp: boolean
  readonly androidDeviceId: string | null
  readonly pushToken: null | string
  readonly trackingId: null | string
  readonly forcement: string
  readonly isPWAMessageOpen: boolean
  readonly advertisingId: string | null
  readonly osVersion: string | null
  readonly appVersion: string | null
  readonly location: LocationData | null
}
