import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: 36,
    position: 'relative',
    '&.headerMode': {
      height: 'auto',
      alignItems: 'center',
    },
  },
  reversedRoot: {
    flexDirection: 'row-reverse',
  },
  switcherWrap: {
    width: 38,
    '&.headerMode': {
      width: 48,
      height: 48,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },
  switcher: {
    transform: 'rotate(90deg)',
    transformOrigin: 'center',
    left: -10,
    '&.headerMode': {
      left: 0,
      top: 4,
    },
  },
  switcherOff: {
    background: theme.palette.goldCoinTheme.gradient,
  },
  switcherOn: {
    background: theme.palette.sweepCoinTheme.gradient,
  },
  track: {
    backgroundColor: `${theme.palette.common.black} !important`,
    opacity: '.38 !important',
  },
  labels: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '3px 0',
    textAlign: 'left',
    minWidth: 80,
  },
  reversedText: {
    textAlign: 'right',
  },
  thumb: {
    width: 19.8,
    height: 19.8,
  },
  label: {
    display: 'block',
    fontSize: '0.625rem',
    fontWeight: 'normal',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    opacity: 0.5,
  },
  labelMobile: {
    '@media(max-width: 430px)': {
      whiteSpace: 'inherit',
    },
  },
  active: {
    opacity: 1,
  },
}))
