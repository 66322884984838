import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    width: 'auto',
    height: 17,
    display: 'block',

    '@media(max-width: 330px)': {
      height: 14,
    },
  },
})
