import { isNativeAppSelector } from 'store/modules/appConfig/selectors'
import { ThunkType } from 'store/modules/types'
import { openDialog } from 'store/modules/dialog/actions'
import { sendRNevent } from 'utils/helpers'
import { setDataToLocalStorage, getItemFormLocalStorage } from 'utils/localStorage'

import { OnAdEndCallbackType, FlowGameOptions } from 'services/ads/types'

export const openVipPromoDialogAfterFirstAd = () => (dispatch: Function) => {
  const isAdFirstTimeOpened = getItemFormLocalStorage('AD_FIRST_TIME_OPENED')
  if (!isAdFirstTimeOpened) {
    dispatch(openDialog('VIP_SUBSCRIPTION_PROMO_DIALOG', { isOverlayEnable: true }))
  }
  // @ts-ignore
  dispatch(setDataToLocalStorage('AD_FIRST_TIME_OPENED', 'true'))
}

export const showNativeAd = (placementName: string) => {
  if (window.ReactNativeWebView) {
    sendRNevent({
      type: 'showAdVideo',
      placementName,
    })
  }

  return false
}

export const showVideoAd =
  (callbackType: OnAdEndCallbackType, flowGameOptions?: FlowGameOptions): ThunkType =>
  (dispatch, getState) => {
    const isNativeApp = isNativeAppSelector(getState())
    if (isNativeApp) {
      showNativeAd('Extra_Line')
    }

    if (!isNativeApp && location.hostname !== 'localhost') {
      dispatch(openDialog('AD_VIDEO_DIALOG', { callbackType, flowGameOptions }))
    }
  }
