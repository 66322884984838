import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'

const getDailyBonuses = (state: ApplicationState) => state.bonusesFlow?.dailyBonuses
const getManualBonuses = (state: ApplicationState) => state.bonusesFlow?.manualBonuses
const getInvitationBonuses = (state: ApplicationState) => state.bonusesFlow?.invitationBonuses
const getDelayedBonuses = (state: ApplicationState) => state.bonusesFlow?.delayedBonuses
export const getDailyBonusesSelector = createSelector(getDailyBonuses, (dailyBonuses) => dailyBonuses)
export const getManualBonusesSelector = createSelector(getManualBonuses, (bonueses) => bonueses)
export const getInvitationBonusesSelector = createSelector(getInvitationBonuses, (bonuses) => bonuses)

export const getDelayedBonusesSelector = createSelector(getDelayedBonuses, (delayedBonuses) => delayedBonuses)

export const getManualAndInvitationBonusesSelector = createSelector(
  getManualBonusesSelector,
  getInvitationBonuses,
  (manual, invitation) => {
    if (manual && invitation) {
      return [...manual, ...invitation].sort((a, b) => Number(new Date(a.at)) - Number(new Date(b.at)))
    }

    return null
  }
)

export const getSCManualAndInvitationBonusesSelector = createSelector(
  getManualAndInvitationBonusesSelector,
  (bonuses) =>
    bonuses?.filter((el) => el?.sweepstakeAmount && (el?.goldAmount !== null || el?.goldAmount !== undefined))
)
export const getGCManualAndInvitationBonusesSelector = createSelector(
  getManualAndInvitationBonusesSelector,
  (bonuses) => bonuses?.filter((el) => el?.goldAmount && !el?.sweepstakeAmount)
)
export const getFiatManualBonusSelector = createSelector(getManualAndInvitationBonusesSelector, (bonuses) =>
  bonuses?.filter((el) => el?.fiatAmount)
)

export const isSCDelayedManualBonusAvailableSelector = createSelector(
  getDelayedBonusesSelector,
  sweepstakeEnabledSelector,
  (bonuses, sweepsEnabled) => {
    return Boolean(bonuses?.length && bonuses.find((el) => el?.sweepstakeAmount) && sweepsEnabled)
  }
)

export const isGCDelayedManualBonusAvailableSelector = createSelector(getDelayedBonusesSelector, (bonuses) => {
  return Boolean(bonuses?.length && bonuses.find((el) => el?.goldAmount && !el?.sweepstakeAmount))
})
