import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    width: 106,
    display: 'flex',
    alignItems: 'center',
  },
  level: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,
  },
  levelCaption: {
    fontSize: '0.4375rem',
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  star: {
    color: theme.palette.defaultTheme.themeColor,
  },
  progress: {
    width: 87,
    height: 4,
    background: theme.palette.common.white,
    marginLeft: -7,
    position: 'relative',
  },
  progressColor: {
    background: theme.palette.defaultTheme.gradient,
  },
}))
