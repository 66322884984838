import { batch } from 'react-redux'

import { updateCurrenciesBalance } from 'store/modules/currencies/actions'
import { prerequisitesChange } from 'store/modules/prerequisites/actions'
import { updatePlayedLotto } from 'store/modules/playedGames/actions'
import { saveLottoDraws, gamesIsLoaded } from 'store/modules/games/actions'
import { setLevelProgress, setUser, setKycStatus, setSkipOTP, setIsEmailVerified } from 'store/modules/user/actions'

import ROUTES from 'config/routes.json'

import { openViewResultsDialog } from 'store/modules/lottoGameFlow/actions'
import { NotifyResponses } from './types/notifications'
import { insufficientBalanceNotificationHandler, notEnoughMoneyHandler } from 'store/modules/slotGameFlow/actions'
import { updateRedeemList } from 'store/modules/redeem/actions'
import {
  updateInvitationBonusesNotificationListener,
  updateManualBonusesNotificationListener,
} from 'src/store/modules/bonusesFlow/actions'
import { openSnackbar } from 'src/store/modules/snackbar/actions'
import { listenPurchaseNotification, offerDeclineNotificationHandler } from 'src/store/modules/paymentsFlow/actions'
import { openDialog, closeAllDialogs, removeDialogByName } from 'src/store/modules/dialog/actions'
import { logOut, logoutRequest } from 'store/modules/auth/actions'
import { ThunkType } from 'src/store/modules/types'

const wsNotifyHandler =
  ({ body }: { body: NotifyResponses }): ThunkType =>
  (dispatch) => {
    batch(() => {
      switch (body.type) {
        case 'BalanceChangeNotification': {
          dispatch(updateCurrenciesBalance(body.balances))

          dispatch(notEnoughMoneyHandler(body.balances))
          break
        }

        case 'PrerequisitesChangeNotification': {
          dispatch(prerequisitesChange(body.prerequisites))
          break
        }

        case 'LottoDrawsUpdateNotification': {
          dispatch(saveLottoDraws(body.data))
          dispatch(gamesIsLoaded())
          break
        }

        case 'XPLevelChangeNotification': {
          dispatch(setLevelProgress(body))
          break
        }
        case 'VipLevelChangeNotification': {
          dispatch(setLevelProgress(body))
          break
        }

        case 'LottoCompletedNotification': {
          dispatch(updatePlayedLotto(body))
          dispatch(openViewResultsDialog(body))
          break
        }

        case 'OfferPurchaseNotification': {
          dispatch(listenPurchaseNotification(body))
          dispatch(setSkipOTP(body?.skipOtp))
          break
        }

        case 'SignOutNotification': {
          dispatch(logOut())

          break
        }

        case 'RedeemMoneyNotification': {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { type, ...rest } = body
          dispatch(updateRedeemList(rest))
          break
        }

        case 'KYCDocSuccessUploadNotification': {
          dispatch(removeDialogByName('JUMIO_KYC_DIALOG'))
          dispatch(setKycStatus('doc_review'))
          dispatch(openDialog('VERIFICATION_IN_PROGRESS_DIALOG'))
          break
        }

        case 'KYCDocErrorUploadNotification': {
          dispatch(
            openSnackbar({
              message: body.reason || 'Error uploading documents',
            })
          )
          break
        }

        case 'KYCConfirmNotification': {
          dispatch(
            openSnackbar({
              message: 'Document confirmed.',
              variant: 'success',
            })
          )
          break
        }

        case 'KYCDocConfirmNotification': {
          dispatch(setKycStatus('confirmed'))
          break
        }

        case 'KYCDocBlockNotification':
        case 'KYCBlockNotification': {
          dispatch(setKycStatus('blocked'))
          dispatch(closeAllDialogs())
          dispatch(
            openDialog('LOCK_USER_DIALOG', {
              reason: 'Your account has failed verification.',
            })
          )
          break
        }

        case 'AccountBlockNotification': {
          dispatch(closeAllDialogs())
          if (body?.reloginProvider) {
            dispatch(
              logoutRequest(
                `${ROUTES.HOME_LOGGED_OUT}?dialogName=OTP_HELLO_AGAIN_DIALOG&dialogProps.reloginProvider=${
                  body.reloginProvider
                }${body?.reloginEmail ? `&dialogProps.reloginEmail=${body.reloginEmail}` : ''}`
              )
            )
          } else {
            dispatch(openDialog('LOCK_USER_DIALOG'))
          }
          break
        }

        case 'KYCDocDeclineNotification': {
          dispatch(setKycStatus('doc_declined'))
          break
        }

        case 'KYCDeclineNotification': {
          dispatch(setKycStatus('declined'))
          break
        }

        case 'AcceptManualBonusNotification': {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { type, ...rest } = body
          dispatch(updateManualBonusesNotificationListener(rest))
          break
        }

        case 'AcceptInvitationBonusNotification': {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { type, ...rest } = body
          dispatch(updateInvitationBonusesNotificationListener(rest))
          // backward compatibility
          if (rest?.account) {
            dispatch(setUser(rest.account))
          }
          break
        }

        case 'OfferRefundNotification': {
          dispatch(
            openSnackbar({
              message: 'Your purchase has been refunded: Please see email for more information.',
            })
          )
          break
        }

        case 'InsufficientBalanceNotification': {
          dispatch(insufficientBalanceNotificationHandler())
          break
        }

        case 'OfferDeclineNotification': {
          dispatch(offerDeclineNotificationHandler(body))
          break
        }

        case 'EmailVerifiedNotification': {
          dispatch(setIsEmailVerified(true))
          window.location.href = ROUTES.HOME_LOGGED_IN
          break
        }

        case 'SkipOtpChangeNotification': {
          dispatch(setSkipOTP(body?.skipOtp))
          break
        }

        default: {
          if (process.env.PROFILE !== 'prod') {
            // eslint-disable-next-line
            console.log(`%c${`Unhandled notify event - ${body}`}`, 'color:Orange', body)
          }
          break
        }
      }
    })
  }

export default wsNotifyHandler
