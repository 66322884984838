import { NextRouter } from 'next/router'
import { Currencies } from 'src/store/modules/currencies/types'
import { GameTypes } from 'src/store/modules/games/types'
import { gameRedirects, gameRoutesGenerator } from './games'
import { lotteryRoutes, lotteryResolver } from './lotteries'

// @TODO - add description
// bind href with as path for links

export const gamesRoutesGenerator = {
  ...lotteryRoutes,
  ...gameRoutesGenerator('slots', 'gameId'),
  ...gameRoutesGenerator('table-games', 'gameId'),
}

// bind router's href with currency to without currency
export const routeResolver: { [key: string]: string } = {
  ...lotteryResolver,
  ...gameRedirects('slots', 'gameId'),
  ...gameRedirects('table-games', 'gameId'),
}

type ReturnType = {
  game: {
    [key in Currencies]?: string
  }
  info: {
    [key in Currencies]?: string
  }
}
export const gameURLsGeneratorMobileOrDesktop = (gameType: GameTypes, isMobile: boolean): ReturnType => {
  const getCurrentStartUrlByType: { [key in GameTypes]?: string } = {
    slots: 'slots',
    table: 'table-games',
  }

  const getCurrentDynamicKeyBytType: { [key in GameTypes]?: string } = {
    slots: 'gameId',
    table: 'gameId',
  }

  return {
    game: {
      GC: `/games/${getCurrentStartUrlByType[gameType]}/[${getCurrentDynamicKeyBytType[gameType]}]/play${
        isMobile ? '/mobile' : ''
      }`,
      SC: `/games/${getCurrentStartUrlByType[gameType]}/[${getCurrentDynamicKeyBytType[gameType]}]/[currencyName]/play${
        isMobile ? '/mobile' : ''
      }`,
    },
    info: {
      GC: `/games/${getCurrentStartUrlByType[gameType]}/[${getCurrentDynamicKeyBytType[gameType]}]`,
      SC: `/games/${getCurrentStartUrlByType[gameType]}/[${getCurrentDynamicKeyBytType[gameType]}]/[currencyName]`,
    },
  }
}

// @TODO - add description
export function currencyBaseRouter(routerInstance: NextRouter, route: string, lotteryName: string) {
  const pathName = routerInstance.query.currencyName ? routeResolver[route] : route
  const asPath = gamesRoutesGenerator[pathName](
    lotteryName,
    routerInstance.query.currencyName ? routerInstance.query.currencyName.toString() : ''
  )

  return routerInstance.push(pathName, asPath)
}
