import { Reducer } from 'redux'
import { SnackbarActionTypes, SnackbarState, Actions } from './types'

// Type-safe initialState
export const initialState: SnackbarState = {
  visible: false,
  message: '',
  action: null,
  autoHide: null,
  messageAlign: null,
}

const reducer: Reducer<SnackbarState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case SnackbarActionTypes.OPEN: {
      return {
        ...state,
        visible: true,
        ...action.payload,
      }
    }

    case SnackbarActionTypes.CLOSE: {
      return {
        ...initialState,
        visible: false,
      }
    }

    case SnackbarActionTypes.CLEAR: {
      return initialState
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as snackbarReducer }
