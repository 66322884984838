import { Currencies } from '../currencies/types'
import { GameProduct } from '../games/types'

export enum SlotGameFlowActionsType {
  CLEAR_SLOT_GAME_FLOW = 'slotGameFlow/CLEAR_SLOT_GAME_FLOW',
  SET_ACTIVE_GAME_CODE = 'slotGameFlow/SET_ACTIVE_GAME_CODE',
  SHOW_SLOT_AD = 'slotGameFlow/SHOW_SLOT_AD',
  SET_GAME_BET = 'slotGameFlow/SET_GAME_BET',
  SAVE_FREE_SPIN_GAMES = 'slotGameFlow/SAVE_FREE_SPIN_GAMES',
}

export type GameInfoType = {
  minBet: number
  maxBet: number
  maxPrize: number
}

export type FreeSpinType = {
  products: GameProduct[]
  currency: Currencies
  rounds: number
  roundsPlayed: number
  expireAt: string
}

export interface SetActiveGameCodeAction {
  type: SlotGameFlowActionsType.SET_ACTIVE_GAME_CODE
  payload: {
    code: string
  }
}

export interface ShowSlotAdAction {
  type: SlotGameFlowActionsType.SHOW_SLOT_AD
}

export interface SetGameBetAction {
  type: SlotGameFlowActionsType.SET_GAME_BET
  payload: {
    gameBet: number
  }
}

export interface SaveFreeSpinGamesAction {
  type: SlotGameFlowActionsType.SAVE_FREE_SPIN_GAMES
  payload: {
    freeSpins: FreeSpinType[]
  }
}

export interface ClearSlotGameFlowAction {
  type: SlotGameFlowActionsType.CLEAR_SLOT_GAME_FLOW
}

export type Actions =
  | ClearSlotGameFlowAction
  | SetActiveGameCodeAction
  | SetGameBetAction
  | ShowSlotAdAction
  | SaveFreeSpinGamesAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export type SlotGameFlowState = {
  readonly activeGameCode: string | null
  readonly gameBet: number | null
  readonly freeSpins: FreeSpinType[] | null
}
