interface BaseAuthData {
  lang: string
  brandName: string
  timezone: string
  session?: string
  trackingId?: string
}
export interface RegisterRequest extends BaseAuthData {
  email?: string
  password?: string // validation on server-side only - rules might change dynamically
  username?: string
  firstName?: string
  lastName?: string
  profilePhoto?: string
  accessToken?: string
  fingerprint?: string
  pushToken?: string
  advertisingId?: string
  osVersion?: string
  appVersion?: string
  forcement: string
  referrer?: string
  query?: string
}

export interface LoginRequest extends BaseAuthData {
  email: string
  password: string // validation on server-side only - rules might change dynamically
  pushToken?: string
  query?: string
}

export interface RegisterGuestRequest extends BaseAuthData {
  appVersion?: string
  osVersion?: string
  advertisingId?: string
  fingerprint?: string
  pushToken?: string
  forcement: string
  referrer?: string
  query?: string
}

export interface LogoutRequest {
  brandName: string
}

export type VerifyEmailType = {
  email: string
  brandName?: string
  session?: string
}

export type GatewayRegisterResponse = {
  id: string
  newAccount: boolean
}

export type SignOutResponse = {
  applied: boolean
}

export const LOCAL_STORAGE_REDIRECT_URL_KEY = 'redirectRouteAfterAuth'

export const enum SocialAuthTypes {
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
}

export interface AuthResponse {
  accessToken: string
  data_access_expiration_time: number
  expiresIn: number
  signedRequest: string
  userID: string
}

export type RegisterViaEmail = {
  firstName: string
  lastName: string
  email: string
  password: string
}

export type ConfirmFbForm = {
  email?: string
  accept?: boolean
}

export type ConfirmGoogleForm = {
  firstName: string
  lastName: string
}

export type AuthViaSocialAPI = {
  accessToken: string
  email?: string
  firstName?: string
  lastName?: string
}

export type ResetPassword = {
  password: string
  confirmPassword: string
}

export type RegisterFormValues = RegisterViaEmail | AuthViaSocialAPI

export type LoginFormValues = {
  email: string
  password: string
}

export enum AuthActionTypes {
  SET_AUTH_LOADING = 'auth/SET_AUTH_LOADING',
  SET_GOOGLE_APP_INIT = 'auth/SET_GOOGLE_APP_INIT',
}

export interface SetAuthLoadingAction {
  type: AuthActionTypes.SET_AUTH_LOADING
  payload: {
    loading: boolean
  }
}

export interface SetGoogleAppInitAction {
  type: AuthActionTypes.SET_GOOGLE_APP_INIT
  payload: {
    googleAppInit: boolean
  }
}

export type Actions = SetAuthLoadingAction | SetGoogleAppInitAction

export interface AuthState {
  readonly loading: boolean
  readonly googleAppInit: boolean
}
