import SnackbarContent from '@material-ui/core/SnackbarContent'
import cx from 'classnames'
import Message from './Message'
import { AlertCircleOutline, OctagonOutline, InformationVariant, CheckCircleOutline } from 'mdi-material-ui'

import useStyles from './styles'
import { SnackbarActionButton, MessageAlign } from 'store/modules/snackbar/types'

const variantIcon = {
  success: CheckCircleOutline,
  warning: OctagonOutline,
  error: AlertCircleOutline,
  info: InformationVariant,
}

export interface SnackbarProps {
  className?: string
  message?: string
  variant: keyof typeof variantIcon
  actionButton: SnackbarActionButton | null
  messageAlign: MessageAlign
}

function Snackbar(props: SnackbarProps) {
  const classes = useStyles(props)
  const { message, variant, actionButton, messageAlign, ...other } = props
  const buttonOptions =
    actionButton && Object.prototype.hasOwnProperty.call(actionButton, 'buttonOptions')
      ? {
          variant: 'text',
          size: 'small',
          ...actionButton.buttonOptions,
        }
      : {
          variant: 'text',
          size: 'small',
        }

  return (
    <SnackbarContent
      className={cx(classes[variant], classes[messageAlign])}
      message={<Message actionButton={actionButton} message={message} {...buttonOptions} />}
      {...other}
    />
  )
}

export default Snackbar
