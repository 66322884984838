import { Reducer } from 'redux'
import { GamesState, GamesActionTypes, Actions, GameProduct, GameTypes } from './types'
import jsonGames from 'data/products.json'

const convertedGames: Record<GameTypes, GameProduct[]> = jsonGames as any

const initialState: GamesState = {
  games: convertedGames,
  lotteryDraws: null,
  lottoRules: {
    WITH_1_ADDITIONAL_BALL: {
      maxNumbers: 5,
      maxBonus: 1,
      maxNumbersArrayLength: 70,
      maxBonusNumbersArrayLength: 10,
    },
    WITH_2_ADDITIONAL_BALLS: {
      maxNumbers: 5,
      maxBonus: 2,
      maxNumbersArrayLength: 70,
      maxBonusNumbersArrayLength: 20,
    },
  },
  gamesIsLoaded: null,
}

// @TODO rename save lottos to save games
const reducer: Reducer<GamesState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case GamesActionTypes.SAVE_GAME_PRODUCTS: {
      return {
        ...state,
        games: action.payload.games,
      }
    }
    case GamesActionTypes.SAVE_LOTTOS_DRAWS: {
      return {
        ...state,
        lotteryDraws: action.payload.lotteryDraws,
      }
    }

    case GamesActionTypes.CLEAR_GAMES:
      return initialState

    case GamesActionTypes.GAMES_IS_LOADED: {
      return {
        ...state,
        gamesIsLoaded: true,
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as gamesReducer }
