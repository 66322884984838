const baseTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 870,
      md: 992,
      lg: 1200,
      xl: 1920,
    },
  },

  typography: {
    h1: {
      lineHeight: 1.15,
      letterSpacing: -1.5,
    },
    h2: {
      lineHeight: 1.2,
      letterSpacing: -0.5,
    },
    h3: {
      lineHeight: 1.16,
      letterSpacing: 0,
    },
    h4: {
      lineHeight: 1.06,
      letterSpacing: 0,
    },
    h5: {
      lineHeight: 1,
      letterSpacing: 0.18,
    },
    h6: {
      lineHeight: '1.2rem',
      letterSpacing: 0.15,
    },
    body1: {
      letterSpacing: 0.5,
    },
    body2: {
      letterSpacing: 0.25,
    },
    subtitle1: {
      letterSpacing: 0.15,
      lineHeight: 1.5,
    },
    subtitle2: {
      letterSpacing: 0.1,
      fontSize: '0.8125rem',
      lineHeight: '1.5rem',
    },
    button: {
      letterSpacing: 0.25,
    },
    caption: {
      letterSpacing: 0.4,
      lineHeight: 1.33,
    },
    overline: {
      fontWeight: 500,
      fontSize: '0.625rem',
      letterSpacing: 1.5,
      lineHeight: 1.6,
    },
  },

  overrides: {
    MuiDrawer: {
      paper: {
        overflowY: 'auto',
      },
    },

    MuiOutlinedInput: {
      input: {
        padding: 14,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 17px) scale(1)',
      },
    },
    MuiButton: {
      root: {
        marginTop: 6,
        marginBottom: 6,
        '&:before': {
          content: '""',
          height: 6,
          width: '100%',
          top: -6,
          position: 'absolute',
        },
        '&:after': {
          content: '""',
          height: 6,
          width: '100%',
          bottom: -6,
          position: 'absolute',
        },
      },
      label: {
        letterSpacing: 1,
      },
    },

    MuiList: {
      root: {
        outline: 'none',
      },
    },

    MuiBottomNavigationAction: {
      root: {
        padding: 0,
        '&:first-child': {
          paddingLeft: 8,
        },
        '&:last-child': {
          paddingRight: 8,
        },
      },
      label: {
        whiteSpace: 'nowrap',
      },
    },
    MuiTab: {
      wrapper: {
        whiteSpace: 'nowrap',
      },
    },
  },
}

export default baseTheme
