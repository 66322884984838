import AppBar from '@material-ui/core/AppBar'
import BackButton from 'src/components/BackButton'

import useStyles from './styles'

export default function Header() {
  const classes = useStyles()

  return (
    <AppBar className={classes.root} color='default'>
      <div
        className={classes.logoContainer}
        onClick={() => {
          window.location.href = '/'
        }}
      >
        <BackButton className={classes.backButton} color='black' handler={() => {}} />
        <picture>
          <source
            media='(max-width: 450px)'
            className={classes.logo}
            srcSet={`${process.env.IMG_ORIGIN}/logos/logo_dot_${process.env.BRAND_NAME}.svg`}
          />
          <img
            className={classes.logo}
            data-test='header-logo-link'
            src={`${process.env.IMG_ORIGIN}/logos/logo_full_${process.env.BRAND_NAME}.svg`}
            alt='logo'
          />
        </picture>
      </div>
    </AppBar>
  )
}
