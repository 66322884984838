import { connect } from 'react-redux'

import NumberAnimation from 'components/NumberAnimation'
import Typography from '@material-ui/core/Typography'

import { getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'
import { ApplicationState } from 'store/modules/types'
import { Currency } from 'store/modules/currencies/types'
import useStyles from './styles'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'

const t = getDataFromJson(content)
const mapState = (state: ApplicationState) => ({
  sweepstakeCurrency: getSweepstakeCurrencySelector(state),
})

type CurrencySwitcherProps = {
  sweepstakeCurrency: Currency
}

function SweepstakeCurrency(props: CurrencySwitcherProps) {
  const classes = useStyles(props)

  if (props.sweepstakeCurrency) {
    return (
      <Typography variant='caption' component='div' className={classes.curencyText}>
        <img src={`${process.env.IMG_ORIGIN}/sc-icon.png`} className={classes.icon} alt={t('sc icon')} />
        <span>
          SC <NumberAnimation amount={props.sweepstakeCurrency.amount} currency='SC' />
        </span>
      </Typography>
    )
  }

  return null
}

export default connect(mapState)(SweepstakeCurrency)
