import dynamic from 'next/dynamic'
import Loader from 'components/Loader'

const VIP_SUBSCRIPTION_PROMO_DIALOG = dynamic(() => import('dialogs/VipSubscriptionPromoDialog'), {
  loading: Loader,
})

const SCPR_AUTH_WAYS_DIALOG = dynamic(() => import('dialogs/AuthDialogs/SCPRConnectDialog'), {
  loading: Loader,
  ssr: false,
})
const CONNECT_ACCOUNT_DIALOG = dynamic(() => import('dialogs/AuthDialogs/ConnectAccount'), {
  loading: Loader,
  ssr: false,
})
const GUEST_CONFIRM_DIALOG = dynamic(() => import('dialogs/AuthDialogs/GuestConfirmDialog'), {
  loading: Loader,
  ssr: false,
})
const AUTH_CONFIRM_DIALOG = dynamic(() => import('dialogs/AuthDialogs/AuthConfirmDialog'), {
  loading: Loader,
  ssr: false,
})

const START_GAME_FLOW = dynamic(() => import('dialogs/StartGameDialog'), { loading: Loader, ssr: false })

const START_LOTTO_FLOW = dynamic(() => import('dialogs/StartLottoDialog'), { loading: Loader, ssr: false })

const PAYMENT_DIALOG = dynamic(() => import('dialogs/PaymentDialog'), { loading: Loader, ssr: false })
const PAYMENT_CVV_DIALOG = dynamic(() => import('dialogs/PaymentCvvDialog'), { loading: Loader, ssr: false })
const PAYMENT_ERROR_DIALOG = dynamic(() => import('dialogs/PaymentErrorDialog'), {
  loading: Loader,
  ssr: false,
})

const SHOP_DIALOG = dynamic(() => import('dialogs/ShopDialog'), { loading: Loader, ssr: false })

const ORDER_CONFIRMATION_MESSAGE = dynamic(() => import('dialogs/OrderConfirmationMessage'), {
  loading: Loader,
  ssr: false,
})

const LOTTO_CHOOSE_NUMBERS = dynamic(() => import('dialogs/LottoChooseNumbers'), {
  loading: Loader,
  ssr: false,
})

const LOTTO_QUICK_PICK = dynamic(() => import('dialogs/LottoQuickPick'), { loading: Loader, ssr: false })

const LOTTO_CONFIRM_DIALOG = dynamic(() => import('dialogs/LotteryConfirmDialog'), {
  loading: Loader,
  ssr: false,
})

const LOTTO_INFO_TABS_DIALOG = dynamic(() => import('dialogs/LottoInfoTabsDialog'), {
  loading: Loader,
  ssr: false,
})

const PLAYED_LOTTO_TICKET = dynamic(() => import('dialogs/PlayedLottoTicket'), {
  loading: Loader,
  ssr: false,
})

const LOTTO_COMPLETED_NOTIFICATION = dynamic(() => import('dialogs/LottoCompletedNotification'), {
  loading: Loader,
  ssr: false,
})

const AD_VIDEO_DIALOG = dynamic(() => import('dialogs/AdVideoDialog'), { loading: Loader, ssr: false })

const RESET_PASSWORD_DIALOG = dynamic(() => import('dialogs/AuthDialogs/ResetPassword'), {
  loading: Loader,
  ssr: false,
})

const GAME_INFO_DIALOG = dynamic(() => import('dialogs/GameInfoDialog'), { loading: Loader, ssr: false })

const NEW_USER_DIALOG = dynamic(() => import('dialogs/NewUserModal'), { loading: Loader, ssr: false })

const FAQ_DIALOG = dynamic(() => import('dialogs/FAQsDialog'), { loading: Loader, ssr: false })

const LEVEL_UP_NOTIFICATION = dynamic(() => import('dialogs/LevelUpModal'), { loading: Loader, ssr: false })

const HOW_TO_WIN_DIALOG = dynamic(() => import('dialogs/HowToWinDialog'), { loading: Loader, ssr: false })

const VIP_REWARDS_DIALOG = dynamic(() => import('dialogs/VIPRewardsDialog'), { loading: Loader, ssr: false })
const INVITE_FRIENDS_DIALOG = dynamic(() => import('dialogs/InviteFriendsDialog'), {
  loading: Loader,
  ssr: false,
})

const RATE_US_DIALOG = dynamic(() => import('dialogs/RateUsDialog'), { loading: Loader, ssr: false })
const CURRENCY_SWITCH_QUICKSTART = dynamic(() => import('dialogs/CurrencySwitcherQuickstart'), {
  loading: Loader,
  ssr: false,
})

const VIP_SUBSCRIPTION = dynamic(() => import('dialogs/VIPSubscription'), { loading: Loader })
const VIP_SUBSCRIPTION_CONFIRM_DIALOG = dynamic(() => import('dialogs/VIPSuccessSubscription'), {
  loading: Loader,
  ssr: false,
})

const EXTRA_GOLD_DIALOG = dynamic(() => import('dialogs/ExtraCoinsDialog'), { loading: Loader, ssr: false })

const SC_PREVIEW_DIALOG = dynamic(() => import('dialogs/PreviewModeDialog'), { loading: Loader, ssr: false })
const SC_PREVIEW_SESSION_START_DIALOG = dynamic(() => import('dialogs/SCPreviewSessionStartDialog'), {
  loading: Loader,
  ssr: false,
})

const DAILY_REWARD_DIALOG = dynamic(() => import('dialogs/DailyRewardDialog'), {
  loading: Loader,
  ssr: false,
})

const NOT_ENOUGH_FUNDS = dynamic(() => import('dialogs/NotEnoughFunds'), { loading: Loader, ssr: false })
const MINI_GAME_DIALOG = dynamic(() => import('dialogs/MiniGame'), { loading: Loader, ssr: false })
const MINI_GAME_WINNING_DIALOG = dynamic(() => import('dialogs/MiniGameWinDialog'), {
  loading: Loader,
  ssr: false,
})

const WELCOME_EMAIL_SEND_DIALOG = dynamic(() => import('dialogs/WelcomeMailSendDialog'), {
  loading: Loader,
  ssr: false,
})

const REWARD_DIALOG = dynamic(() => import('dialogs/RewardDialog'), { loading: Loader, ssr: false })
const JUMIO_KYC_DIALOG = dynamic(() => import('dialogs/JumioKYCDialog'), { loading: Loader, ssr: false })
const LOCK_USER_DIALOG = dynamic(() => import('dialogs/LockUserDialog'), { loading: Loader, ssr: false })
const RESTRICT_USER_DIALOG = dynamic(() => import('dialogs/RestrictUserDialog'), { loading: Loader, ssr: false })
const VERIFICATION_IN_PROGRESS_DIALOG = dynamic(() => import('dialogs/VerificationInProgressDialog'), {
  loading: Loader,
  ssr: false,
})
const SOFT_KYC_DIALOG = dynamic(() => import('dialogs/SoftKycDialog'), {
  loading: Loader,
  ssr: false,
})

// redeem dialogs
const SUBMIT_DOCUMENTS_DIALOG = dynamic(() => import('dialogs/RedeemFlow/SubmitDocuments'), {
  loading: Loader,
  ssr: false,
})

const WITHDRAW_SWEEPSTSKE_DIALOG = dynamic(() => import('dialogs/RedeemFlow/ACHProvider/WithdrawSweepstake'), {
  loading: Loader,
  ssr: false,
})
const WITHDRAW_DIALOG = dynamic(() => import('dialogs/RedeemFlow'), {
  loading: Loader,
  ssr: false,
})
const WITHDRAW_PROVIDERS_DIALOG = dynamic(() => import('dialogs/RedeemFlow/ChooseProvider'), {
  loading: Loader,
  ssr: false,
})
const WITHDRAW_CONFIRM_EMAIL_DIALOG = dynamic(() => import('dialogs/RedeemFlow/ConfirmEmail'), {
  loading: Loader,
  ssr: false,
})
const REDEEM_SUCCESSFUL_DIALOG = dynamic(() => import('dialogs/RedeemFlow/RedeemSuccessful/ACHSuccess'), {
  loading: Loader,
  ssr: false,
})
const PURCHASE_LIMIT_DIALOG = dynamic(() => import('dialogs/PurchaseLimitDialog'), {
  loading: Loader,
  ssr: false,
})
const SLOT_MAINTENANCE_DIALOG = dynamic(() => import('dialogs/SlotMaintenanceDialog'), {
  loading: Loader,
  ssr: false,
})
const PHONE_VERIFICATION_DIALOG = dynamic(() => import('dialogs/PhoneVerificationDialog'), {
  loading: Loader,
  ssr: false,
})
const PHONE_VERIFICATION_STATUS_DIALOG = dynamic(() => import('dialogs/PhoneVerificationStatusDialog'), {
  loading: Loader,
  ssr: false,
})

const OTP_HELLO_AGAIN_DIALOG = dynamic(() => import('dialogs/OTPHelloAgain'), {
  loading: Loader,
  ssr: false,
})

const PRELOADER_DIALOG = dynamic(() => import('src/dialogs/PreloaderDialog'), {
  loading: Loader,
  ssr: false,
})
const PROMOTIONS_DIALOG = dynamic(() => import('src/dialogs/PromotionsDialog'), {
  loading: Loader,
  ssr: false,
})

const GET_VERIFIED_DIALOG = dynamic(() => import('src/dialogs/GetVerifiedDialog'), {
  loading: Loader,
  ssr: false,
})
const TERMS_MODAL = dynamic(() => import('src/dialogs/TermsModal'), {
  loading: Loader,
  ssr: false,
})

const RULES_UPDATED_DIALOG = dynamic(() => import('src/dialogs/RulesUpdateDialog'), {
  loading: Loader,
  ssr: false,
})

const FREE_SPINS_DIALOG = dynamic(() => import('src/dialogs/FreeSpinsDialog'), {
  loading: Loader,
  ssr: false,
})

const dialogs = {
  START_LOTTO_FLOW,
  START_GAME_FLOW,
  AD_VIDEO_DIALOG,
  PAYMENT_DIALOG,
  PAYMENT_CVV_DIALOG,
  PAYMENT_ERROR_DIALOG,
  SHOP_DIALOG,
  ORDER_CONFIRMATION_MESSAGE,
  LOTTO_CHOOSE_NUMBERS,
  LOTTO_QUICK_PICK,
  LOTTO_CONFIRM_DIALOG,
  LOTTO_INFO_TABS_DIALOG,
  PLAYED_LOTTO_TICKET,
  LOTTO_COMPLETED_NOTIFICATION,
  SCPR_AUTH_WAYS_DIALOG,
  CONNECT_ACCOUNT_DIALOG,
  GUEST_CONFIRM_DIALOG,
  RESET_PASSWORD_DIALOG,
  GAME_INFO_DIALOG,
  SUBMIT_DOCUMENTS_DIALOG,
  WITHDRAW_SWEEPSTSKE_DIALOG,
  WITHDRAW_DIALOG,
  WITHDRAW_PROVIDERS_DIALOG,
  WITHDRAW_CONFIRM_EMAIL_DIALOG,
  REDEEM_SUCCESSFUL_DIALOG,
  NEW_USER_DIALOG,
  FAQ_DIALOG,
  AUTH_CONFIRM_DIALOG,
  LEVEL_UP_NOTIFICATION,
  HOW_TO_WIN_DIALOG,
  VIP_REWARDS_DIALOG,
  RATE_US_DIALOG,
  INVITE_FRIENDS_DIALOG,
  VIP_SUBSCRIPTION,
  EXTRA_GOLD_DIALOG,
  VIP_SUBSCRIPTION_PROMO_DIALOG,
  VIP_SUBSCRIPTION_CONFIRM_DIALOG,
  CURRENCY_SWITCH_QUICKSTART,
  SC_PREVIEW_DIALOG,
  SC_PREVIEW_SESSION_START_DIALOG,
  DAILY_REWARD_DIALOG,
  NOT_ENOUGH_FUNDS,
  MINI_GAME_DIALOG,
  MINI_GAME_WINNING_DIALOG,
  WELCOME_EMAIL_SEND_DIALOG,
  REWARD_DIALOG,
  JUMIO_KYC_DIALOG,
  LOCK_USER_DIALOG,
  VERIFICATION_IN_PROGRESS_DIALOG,
  SOFT_KYC_DIALOG,
  PURCHASE_LIMIT_DIALOG,
  SLOT_MAINTENANCE_DIALOG,
  PHONE_VERIFICATION_DIALOG,
  PRELOADER_DIALOG,
  PHONE_VERIFICATION_STATUS_DIALOG,
  PROMOTIONS_DIALOG,
  GET_VERIFIED_DIALOG,
  TERMS_MODAL,
  RULES_UPDATED_DIALOG,
  OTP_HELLO_AGAIN_DIALOG,
  FREE_SPINS_DIALOG,
  RESTRICT_USER_DIALOG,
}

export default dialogs
