import { Action } from 'redux'
import { ThunkType } from 'store/modules/types'
import wsResponseHandler from 'services/websocket/wsResponseHandler'
import wsNotifyHandler from 'services/websocket/wsNotifyHandler'
import wsErrorHandler from 'services/websocket/wsErrorHandler'
import wsErrorResponseByTypeHandler from 'services/websocket/wsErrorResponseByTypeHandler'
import { NotifyResponses } from 'src/services/websocket/types/notifications'
import { WSinstance } from 'services/websocket'

export const initSocketListeners = (): ThunkType => (dispatch) => {
  const socketRoot = WSinstance.getInstance()

  if (!socketRoot || socketRoot?.readyState !== WebSocket.OPEN) {
    // eslint-disable-next-line
    console.log('ws logs:  initSocketListeners when socket closed')
    // sentry logs
  }

  dispatch(WSinstance.emitWS({ type: 'SessionHandshakeRequest' }))

  socketRoot.onmessage = (resp) => {
    try {
      const event = JSON.parse(resp.data)
      // eslint-disable-next-line
      console.log(`ws logs:  message ${event.body.type}`, event)

      // notify events
      if (!event.status) {
        dispatch(wsNotifyHandler(event as { body: NotifyResponses }))

        return null
      }

      // resp events
      const { status, body } = event
      // handle all response errors from websocket
      if (status.errorCode !== 'err_ok') {
        dispatch(wsErrorHandler(event))
        dispatch(wsErrorResponseByTypeHandler(event))
      } else {
        // handle success responses
        dispatch(wsResponseHandler(body))
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('WSinstance.node.onmessage - error', error)
    }

    return null
  }

  socketRoot.onclose = (event) => {
    // eslint-disable-next-line
    console.log('ws logs:  onclose event', event)

    // clear 'KeepAliveRequest' timer to prevent duplicate timers
    WSinstance.clear()

    // do not reconnect browser tab is not active
    if (document.hidden) {
      return null
    }

    // do not reconnect when socket disconnected by user using WSinstance.disconnect()
    if (event.code === 4444) {
      return null
    }

    // reconnect
    WSinstance.connect(dispatch)

    return null
  }

  socketRoot.onerror = (error) => {
    // eslint-disable-next-line
    console.log('ws logs: onerror event', error)
  }
}

export const initSocketListenersMiddleware = (store: any) => (next: any) => (action: Action & { payload?: any }) => {
  if (action.type === 'INIT_SOCKET_LISTENERS') {
    // eslint-disable-next-line
    console.log('INIT_SOCKET')
    store.dispatch(initSocketListeners())
  }

  next(action)
}
