import SocialButton from 'components/SocialButton'
import EXTERNAL_LINKS from 'src/config/externalLinks.json'

export default function SocialButtons() {
  return (
    <>
      {EXTERNAL_LINKS.fb && <SocialButton type='facebook' href={EXTERNAL_LINKS.fb} />}
      {EXTERNAL_LINKS.instagram && <SocialButton type='instagram' href={EXTERNAL_LINKS.instagram} />}
    </>
  )
}
