import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})
