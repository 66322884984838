import { Reducer } from 'redux'
import { UserActionTypes, UserState, Actions } from './types'

const initialState: UserState = {
  info: null,
  xpLevel: null,
  vipLevel: null,
  firstOffer: null,
  userFlowIsRunning: false,
  skipOtp: false,
}

const reducer: Reducer<UserState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case UserActionTypes.SET_USER: {
      return {
        ...state,
        info: action.payload.data,
      }
    }

    case UserActionTypes.SET_SENT_EMAIL_USER_FIELD: {
      return {
        ...state,
        info: {
          ...state.info,
          sentWelcomeEmail: true,
        },
      }
    }

    case UserActionTypes.SET_USER_SUBSCRIPTION: {
      return {
        ...state,
        info: {
          ...state.info,
          subscriptions: action.payload.subscriptions,
        },
      }
    }

    case UserActionTypes.SET_XP_LEVEL: {
      return {
        ...state,
        xpLevel: action.payload.xpLevel,
      }
    }

    case UserActionTypes.SET_FIRST_DEPOSIT_DATE: {
      return {
        ...state,
        info: {
          ...state.info,
          firstDeposit: action.payload.firstDeposit,
        },
      }
    }

    case UserActionTypes.SET_FIRST_PURCHASE_OFFER: {
      return {
        ...state,
        firstOffer: action.payload.firstOffer,
      }
    }

    case UserActionTypes.SET_KYC_STATUS: {
      return {
        ...state,
        info: {
          ...state.info,
          kycStatus: action.payload.kycStatus,
        },
      }
    }

    case UserActionTypes.SET_VIP_LEVEL: {
      return {
        ...state,
        vipLevel: action.payload.vipLevel,
      }
    }

    case UserActionTypes.SET_USER_FLOW_IS_RUNNING: {
      return {
        ...state,
        info: {
          ...state.info,
        },
        userFlowIsRunning: action.payload.userFlowIsRunning,
      }
    }

    case UserActionTypes.SET_SKIP_OTP_FLAG: {
      return {
        ...state,
        info: {
          ...state.info,
        },
        skipOtp: action.payload.skipOtp,
      }
    }

    case UserActionTypes.SET_IS_EMAIL_VERIFIED: {
      return {
        ...state,
        info: {
          ...state.info,
          emailVerified: action.payload.isVerified,
        },
      }
    }

    case UserActionTypes.DELETE_USER: {
      return initialState
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as userReducer }
