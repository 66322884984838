import { Star } from 'mdi-material-ui'
import LinearProgress from '@material-ui/core/LinearProgress'
import useStyles from './styles'
import { ILevel } from 'store/modules/user/types'
import { ApplicationState } from 'store/modules/types'
import { getUserXpLevelSelector, getCurrentLevelPercentageSelector } from 'store/modules/user/selectors'
import { connect } from 'react-redux'

type Props = {
  xpLevel: ILevel
  levelProgress: number
}
const mapState = (state: ApplicationState) => ({
  xpLevel: getUserXpLevelSelector(state),
  levelProgress: getCurrentLevelPercentageSelector(state),
})
function LevelProgress(props: Props) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <div className={classes.level}>
        <span className={classes.levelCaption}>{props?.xpLevel?.level}</span>
        <Star className={classes.star} />
      </div>
      <LinearProgress
        variant='determinate'
        classes={{
          barColorPrimary: classes.progressColor,
        }}
        className={classes.progress}
        value={props?.levelProgress}
      />
      {/* <div /> */}
    </div>
  )
}

export default connect(mapState)(LevelProgress)
