import {
  CurrenciesActionTypes,
  Currency,
  Currencies,
  InitCurrenciesAction,
  ClearCurrencyAction,
  SetActiveCurrencyAction,
  SetFiatCurrencyAction,
  SetActiveModalCurrencyAction,
  UpdateBalanceAction,
  ToggleActiveCurrencyAction,
} from './types'
import { ThunkType } from 'store/modules/types'
import { getActiveModalCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { CurrencyToProductMode } from './utils'
import { gamesRoutesGenerator, routeResolver } from 'src/utils/navigator'
import Router from 'next/router'
import { clearLottoLines } from '../lottoGameFlow/actions'
import { trackGAEvent } from 'src/utils/gtag'
import { User } from 'store/modules/user/types'

export const initCurrencies = (currencies: Array<Currency>): InitCurrenciesAction => ({
  type: CurrenciesActionTypes.INIT_CURRENCIES,
  payload: {
    allCurrencies: [...currencies],
  },
})

export const setActiveCurrency = (activeCurrencyId: Currencies): SetActiveCurrencyAction => {
  trackGAEvent('switch currency ', 'switch_currency', activeCurrencyId)

  return {
    type: CurrenciesActionTypes.SET_ACTIVE_CURRENCY,
    payload: {
      activeCurrencyId,
    },
  }
}

export const setFiatCurrency = (currencyId: Currencies): SetFiatCurrencyAction => ({
  type: CurrenciesActionTypes.SET_FIAT_CURRENCY,
  payload: {
    currencyId,
  },
})
// @TODO create middleware for this action
export const toggleActiveCurrency = (): ToggleActiveCurrencyAction => ({
  type: CurrenciesActionTypes.TOGGLE_ACTIVE_CURRENCY,
})

export const setActiveModalCurrency = (currencyId: Currencies): SetActiveModalCurrencyAction => ({
  type: CurrenciesActionTypes.SET_ACTIVE_MODAL_CURRENCY,
  payload: {
    currencyId,
  },
})

export const updateCurrenciesBalance = (currency: Array<Currency>): UpdateBalanceAction => ({
  type: CurrenciesActionTypes.UPDATE_BALANCE,
  payload: {
    currency,
  },
})

export const clearCurrency = (): ClearCurrencyAction => ({
  type: CurrenciesActionTypes.CLEAR_CURRENCY,
})

export const switchGlobalCurrencyHandler =
  (currency: Currencies): ThunkType =>
  (dispatch, getState) => {
    const router = Router
    const modalCurrency = getActiveModalCurrencyIdSelector(getState())
    if (
      modalCurrency !== currency &&
      Object.prototype.hasOwnProperty.call(gamesRoutesGenerator, router.pathname) &&
      CurrencyToProductMode[currency] !== router.query.currencyName
    ) {
      const currentRouterKey =
        router.query.gameId || router.query.scratchId || router.query.gameId || router.query.gameId
      const path = routeResolver[router.pathname]
      const asPath = gamesRoutesGenerator[path](currentRouterKey.toString(), CurrencyToProductMode[currency])

      router.push(path, asPath).then(() => {
        dispatch(setActiveModalCurrency(currency))
        dispatch(setActiveCurrency(currency))
        // refactor to more flexible callback (slot,lotto....)
        dispatch(clearLottoLines())
      })
    } else {
      dispatch(setActiveCurrency(currency))
    }
  }

export const defaultCurrencyHandler =
  (accountMode: User['mode'], isScEnabled: boolean): ThunkType =>
  (dispatch) => {
    // do not switch to SC on GC slot games page:
    // /games/slots/sun-of-egypt-2/play/ - use default GC mode even if SC allowed
    // /games/slots/indianas-quest/play/mobile/ - use default GC mode even if SC allowed
    if (
      Router.router.asPath.includes('/play') &&
      Router.router.asPath.includes('games/slots') &&
      !Router.router.asPath.includes('/sweepstake')
    ) {
      return null
    }

    // switch to SC by default
    if (accountMode === 'sweepstake_preview' || isScEnabled) {
      dispatch(setActiveCurrency('SC'))
    }

    return null
  }
