import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'

import { ShopOffer } from './types'
import { sweepstakeEnabledSelector } from '../appConfig/selectors'

const getShopOffers = (state: ApplicationState) => state.shop.offers
const getAppliedOffers = (state: ApplicationState) => state.shop.applied
const purchaseLimits = (state: ApplicationState) => state.shop.limits

const checkOfferType = (el: ShopOffer) => {
  return Object.prototype.hasOwnProperty.call(el, 'offerType')
}

export const getOffersSelector = createSelector(getShopOffers, (offers) => offers)
export const getAppliedOffersSelector = createSelector(getAppliedOffers, (appliedOffers) => appliedOffers)
export const purchaseLimitsSelector = createSelector(purchaseLimits, (limits) => limits)

export const getVipOffersSelector = createSelector(getOffersSelector, (offers) => {
  return offers?.filter((it) => {
    if (checkOfferType(it)) {
      return it.offerType.toLowerCase() === 'subscription'
    } else {
      return it
    }
  })
})

export const getShopOffersSelector = createSelector(getOffersSelector, (offers) => {
  return offers?.filter((it) => {
    if (checkOfferType(it)) {
      return it.offerType.toLowerCase() === 'one_time' || it.offerType.toLowerCase() === 'permanent'
    } else {
      return it
    }
  })
})

export const getAppliedOffersOffersSelector = createSelector(getOffersSelector, (offers) => {
  return offers?.filter((it) => {
    if (checkOfferType(it)) {
      return it.offerType.toLowerCase() === 'one_time' || it.offerType.toLowerCase() === 'permanent'
    } else {
      return it
    }
  })
})

export const getOfferByCodeSelector = (code: string) =>
  createSelector(
    getShopOffersSelector,
    () => code,
    (offers, _code) => {
      return offers?.find((i) => i.code === _code)
    }
  )

export const getAllSCDiscountOffersSelector = createSelector(
  getShopOffersSelector,
  getAppliedOffersSelector,
  (offers, appliedOffers) => {
    const appliedOfferCodes = appliedOffers ? appliedOffers.map((i) => i.code) : []
    const scFirstTimeOffers =
      offers?.filter((i) => {
        return i.sweepstakeFirstOffer !== 0 && i.sweepstakeFirstOffer !== i.sweepstakeMoney
      }) || []

    return scFirstTimeOffers.filter((el) => !appliedOfferCodes.includes(el.code))
  }
)

export const getClosestDiscountOffer = createSelector(getAllSCDiscountOffersSelector, (discountOffers) => {
  return discountOffers[0]
})

export const discountSCOfferAvailableSelector = createSelector(getAllSCDiscountOffersSelector, (discountOffers) => {
  return discountOffers?.length > 0
})

export const offersWereAppliedSelector = createSelector(
  getAppliedOffersSelector,
  (appliedOffers) => appliedOffers?.length > 0
)

export const isDiscountOffersAvailable = createSelector(
  sweepstakeEnabledSelector,
  offersWereAppliedSelector,
  discountSCOfferAvailableSelector,
  (scEnabled, appliedOffers, scDiscountApplied) => {
    if (scEnabled) {
      return scDiscountApplied
    }

    return !appliedOffers
  }
)

export const getRandomDiscountOfferSelector = (state: ApplicationState) => {
  const discountOffers = getAllSCDiscountOffersSelector(state)

  return discountOffers?.length ? discountOffers[Math.floor(Math.random() * discountOffers.length)] : null
}
