// Use `enum`s for better autocompletion of action type names. These will
import { GameProduct } from '../games/types'

// be compiled away leaving only the final value in your compiled code.
export enum UserActionTypes {
  SET_USER = 'user/SET_USER',
  SET_XP_LEVEL = 'user/SET_XP_LEVEL',
  DELETE_USER = 'user/DELETE_USER',
  UPDATE_ACCOUNT_INFO = 'user/UPDATE_ACCOUNT_INFO',
  SET_VIP_LEVEL = 'user/SET_VIP_LEVEL',
  SET_FIRST_PURCHASE_OFFER = 'user/SET_FIRST_PURCHASE_OFFER',
  SET_USER_SUBSCRIPTION = 'user/SET_USER_SUBSCRIPTION',
  SET_SENT_EMAIL_USER_FIELD = 'user/SET_SENT_EMAIL_USER_FIELD',
  SET_USER_FLOW_IS_RUNNING = 'user/SET_USER_FLOW_IS_RUNNING',
  SET_KYC_STATUS = 'user/SET_KYC_STATUS',
  SET_FIRST_DEPOSIT_DATE = 'user/SET_FIRST_DEPOSIT_DATE',
  SET_SKIP_OTP_FLAG = 'user/SET_SKIP_OTP_FLAG',
  SET_IS_EMAIL_VERIFIED = 'user/SET_IS_EMAIL_VERIFIED',
}

export type LevelCategories = 'free_level' | 'vip_level'

export type SubscriptionType = {
  code: string
  offerCode: string
}

export const VIPNames = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Royal Diamond', 'Black Diamond']

export type ILevel = {
  level: number
  current: number
  threshold: number
  range: number
  category: LevelCategories
  name: string
  levelChanged: boolean
  bonus?: number
  unlocked?: Array<GameProduct>
  vipPoints?: number
}

export type UserInvitationInfo = {
  gold: number
  goldFirstPurchase: number
  goldPurchase: number
  goldSignUp: number
  invited: number
  purchases: number
  purchasesQualified: number
  sweepstake: number
  sweepstakeFirstPurchase: number
  sweepstakeFirstWithdraw: number
  sweepstakePurchase: number
  sweepstakeSignUp: number
  sweepstakeWithdraw: number
  withdraws: number
  withdrawsQualified: number
}

export type Address = {
  firstName: string
  lastName: string
  address: string
  address2: string
  city: string
  country: string
  state: string
  zip: string
}

// remove static level
export type User = {
  email: string
  firstName: string
  lastName: string
  profilePicture: string
  oauth: Array<string>
  username?: string
  phoneNumber: string
  tcVersion: string
  acceptedTcVersion: string
  id: string
  bot?: boolean
  guest?: boolean
  country?: string
  xpLevel: ILevel
  vipLevel: ILevel
  kycStatus:
    | 'initial'
    | 'in_review'
    | 'confirmed'
    | 'id_confirmed'
    | 'doc_review'
    | 'doc_declined'
    | 'declined'
    | 'blocked'
  firstOffer?: null | string
  firstOfferAt?: null | string
  subscriptions?: Array<SubscriptionType> | null
  createdAt?: string
  lastDeposit?: string
  lastWithdraw?: string
  mode: 'default' | 'sweepstake_preview' | 'sweepstake_preview_web' | 'sweepstake' | 'gold' | 'gold_free'
  sentWelcomeEmail: boolean
  invitation: UserInvitationInfo
  restrictions: ('no_game' | 'no_purchase' | 'no_redeem')[]
  realEmail?: string
  admin?: boolean
  firstDeposit?: string
  softKycInfo?: Address
  kycInfo?: Address
  emailVerified: boolean
  purchaseVipLevel?: string
  birthDate?: string
}

export type Password = {
  oldPassword: string
  password: string
  confirmPassword: string
}

export interface SetUserAction {
  type: UserActionTypes.SET_USER
  payload: {
    data: User
  }
}

export interface SetUserSentEmailFieldAction {
  type: UserActionTypes.SET_SENT_EMAIL_USER_FIELD
}

export interface SetUserSubscriptionAction {
  type: UserActionTypes.SET_USER_SUBSCRIPTION
  payload: {
    subscriptions: Array<SubscriptionType>
  }
}

export interface SetVipLevelAction {
  type: UserActionTypes.SET_VIP_LEVEL
  payload: {
    vipLevel: ILevel
  }
}
export interface SetXPLevelAction {
  type: UserActionTypes.SET_XP_LEVEL
  payload: {
    xpLevel: ILevel
  }
}

export interface SetFirstDepositDateAction {
  type: UserActionTypes.SET_FIRST_DEPOSIT_DATE
  payload: {
    firstDeposit: string
  }
}

export interface SetFirstPurchaseOfferAction {
  type: UserActionTypes.SET_FIRST_PURCHASE_OFFER
  payload: {
    firstOffer: string
  }
}

export interface DeleteUserAction {
  type: UserActionTypes.DELETE_USER
}

export interface SetKycStatusAction {
  type: UserActionTypes.SET_KYC_STATUS
  payload: {
    kycStatus: User['kycStatus']
  }
}

export interface SetUserFlowIsRunningAction {
  type: UserActionTypes.SET_USER_FLOW_IS_RUNNING
  payload: {
    userFlowIsRunning: boolean
  }
}

export interface SetSkipOTPFlagAction {
  type: UserActionTypes.SET_SKIP_OTP_FLAG
  payload: {
    skipOtp: boolean
  }
}

export interface SetIsEmailVerifiedAction {
  type: UserActionTypes.SET_IS_EMAIL_VERIFIED
  payload: {
    isVerified: boolean
  }
}

export type Actions =
  | SetUserAction
  | SetXPLevelAction
  | SetVipLevelAction
  | DeleteUserAction
  | SetFirstPurchaseOfferAction
  | SetUserSubscriptionAction
  | SetUserSentEmailFieldAction
  | SetUserFlowIsRunningAction
  | SetKycStatusAction
  | SetFirstDepositDateAction
  | SetSkipOTPFlagAction
  | SetIsEmailVerifiedAction

export type UserState = {
  readonly info: User | null
  readonly xpLevel: ILevel | null
  readonly vipLevel: ILevel | null
  readonly firstOffer: null | string
  readonly userFlowIsRunning: boolean
  readonly skipOtp: boolean
}
