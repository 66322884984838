import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'

import { LottoPlayedGame } from 'store/modules/playedGames/types'
import gameCategories from 'store/modules/games/categories'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { ProductModes } from 'store/modules/games/types'

const getUpcomingLottos = (state: ApplicationState) => state.playedGames.acceptedLottos
const getPastLottos = (state: ApplicationState) => state.playedGames.closedLottos
const getPlayedGames = (state: ApplicationState) => state.playedGames.games
const getOwnGameTypeProps = (state: ApplicationState, ownProps: 'slot' | 'scratch') => ownProps

export const getUpcomingLottosSelector = createSelector(
  getUpcomingLottos,
  sweepstakeEnabledSelector,
  (upcomingLottos, sweepstakeEnabled) => {
    return upcomingLottos?.filter((i) => {
      // hide SC games if required
      if (!sweepstakeEnabled && i.currency === 'SC') {
        return false
      }

      return true
    })
  }
)

export const getAllPastLottoDrawsIdCodesSelector = createSelector(getPastLottos, (games) => {
  if (games) {
    return games.map((el) => {
      return { product: el.product, drawId: el.drawId }
    })
  }

  return null
})

export const getPastLottosSelector = createSelector(
  getPastLottos,
  sweepstakeEnabledSelector,
  (pastLottos, sweepstakeEnabled) => {
    return pastLottos?.filter((i) => {
      // hide SC games if required
      if (!sweepstakeEnabled && i.currency === 'SC') {
        return false
      }

      return true
    })
  }
)

export const getUpcomingFilteredLottosSelector = createSelector(getUpcomingLottosSelector, (games) => {
  if (games) {
    return games.filter((it) => it.picks?.length !== 0)
  }

  return null
})

export const getPastFilteredLottosSelector = createSelector(getPastLottosSelector, (games) => {
  if (games) {
    return games.filter((it) => it.picks?.length !== 0)
  }

  return null
})

export const noPlayedGameSelector = createSelector(
  getUpcomingLottosSelector,
  getPastLottosSelector,
  (upcomingLottos: Array<LottoPlayedGame>, pastLottos: Array<LottoPlayedGame>) => {
    if (upcomingLottos && pastLottos) {
      return [...upcomingLottos, ...pastLottos].length === 0
    }

    return null
  }
)

export const getAllGamesSelector = createSelector(
  getUpcomingLottosSelector,
  getPastLottosSelector,
  (upcomingLottos, pastLottos) => {
    return {
      pastGames: [...(pastLottos || [])],
      upcomingGames: [...(upcomingLottos || [])],
    }
  }
)

export const getAllPlayedLottoCodesSelector = createSelector(getPastLottosSelector, (pastLottos) => {
  const lottoCodes = new Set<string>()
  const lottos = pastLottos || []

  lottos.forEach((i) => {
    lottoCodes.add(i.product)
  })

  return lottoCodes
})

export const getPlayedGameByIdSelector = (id: number) =>
  createSelector(
    getAllGamesSelector,
    () => id,
    (games) => {
      return games
    }
  )
export const getPlayedSlotGames = createSelector(getPlayedGames, (games) => {
  if (!games) {
    return []
  }

  return games.filter((el) => el.type !== 'lottery')
})

export const getPlayedSlotsSelector = createSelector(getPlayedSlotGames, (games) => {
  if (games) {
    return games.filter((it) => gameCategories.slots.includes(it.type) && !it.tags.includes('table'))
  }

  return null
})

export const getPlayedTableGamesSelector = createSelector(getPlayedSlotGames, (games) => {
  if (games) {
    return games.filter((it) => it.tags.includes('table'))
  }

  return null
})

export const isPlayedSlotsSelector = createSelector(getPlayedSlotsSelector, (slots) => {
  if (slots === null) return null

  return Boolean(slots && slots.length > 0)
})

export const getPlayedGamesByType = createSelector(getPlayedSlotsSelector, getOwnGameTypeProps, (slots, gameType) => {
  switch (gameType) {
    case 'slot': {
      return slots
    }
    default:
      throw Error('we do not have games by this type')
  }
})

export const isSCGameWasPlayedSelector = createSelector(
  getPlayedGames,
  getUpcomingLottos,
  getPastLottos,
  (games, upcomingLotto, pastLotto) => {
    return Boolean(
      games?.find((el) => el.mode === ProductModes.SWEEPSTAKE) ||
        upcomingLotto?.find((el) => el.currency === 'SC') ||
        pastLotto?.find((el) => el.currency === 'SC')
    )
  }
)
