export enum ActionTypes {
  SET_OTP = 'phoneVerification/SET_OTP',
  SET_PHONE_NUMBER = 'phoneVerification/SET_PHONE_NUMBER',
  SET_TIMER_STATE = 'phoneVerification/SET_TIMER_STATE',
  CLEAR = 'phoneVerification/CLEAR',
}

export interface SetOtpAction {
  type: ActionTypes.SET_OTP
  payload: {
    phoneCode: string
  }
}

export interface SetPhoneNumberAction {
  type: ActionTypes.SET_PHONE_NUMBER
  payload: {
    phoneNumber: string
  }
}

export interface SetTimerStateAction {
  type: ActionTypes.SET_TIMER_STATE
  payload: {
    timerRunning: boolean
  }
}

export interface ClearPhoneVerificationState {
  type: ActionTypes.CLEAR
}

export type Actions = SetOtpAction | SetTimerStateAction | SetPhoneNumberAction | ClearPhoneVerificationState

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface PhoneVerificationState {
  readonly phoneCode: string
  readonly phoneNumber: string | null
  readonly timerRunning: boolean
}
