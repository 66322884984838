import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resendEmailRequest } from 'src/store/modules/auth/actions'

const useResendEmail = () => {
  const [inProgress, setInProgress] = useState(false)
  const dispatch = useDispatch()

  const resendMail = () => {
    setInProgress(true)
    dispatch(resendEmailRequest())
  }

  useEffect(() => {
    if (inProgress) {
      setTimeout(() => {
        setInProgress(false)
      }, 60000)
    }
  }, [inProgress])

  return {
    resendMail,
    inProgress,
  }
}

export default useResendEmail
