import { useState, useEffect } from 'react'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useRouter } from 'next/router'

import pageTabsConfig, { pageAnimationDelay } from 'config/pageTabsConfigs'
import LAYOUTS from 'src/config/layouts'

import useStyles from './styles'

type Props = {
  // children: ReactNode
}

function AllGamesHeader(props: Props) {
  const classes = useStyles(props)
  const router = useRouter()
  const [currentRouter, setRouter] = useState('')
  const tabsProperties = pageTabsConfig[LAYOUTS.ALL_GAMES_TABS_LAYOUT](router)

  useEffect(() => {
    setTimeout(() => {
      setRouter(router.pathname)
    }, pageAnimationDelay)
  }, [router.pathname])

  return (
    <Tabs
      variant='scrollable'
      scrollButtons='off'
      value={tabsProperties.findIndex((it) => it.pathname.includes(currentRouter))}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
    >
      {tabsProperties.map((it) => (
        <Tab
          classes={{
            root: classes.tabButton,
            selected: classes.selected,
          }}
          selected={it.pathname === currentRouter}
          key={it.pathname}
          label={it.title}
          data-test={`all-games-tabs-${it.title}`}
          onClick={() => {
            it.onClick(setRouter.bind(null, it.pathname))
          }}
        />
      ))}
    </Tabs>
  )
}

export default AllGamesHeader
