import { v4 as uuid } from 'uuid'
import { PaymentsProviderTypes } from 'src/store/modules/paymentsFlow/types'
import { ConfirmOrderResponse, CreateOrderResponse } from 'src/services/websocket/types/response'
import { getItemFormLocalStorage } from 'src/utils/localStorage'
import gatewayEmit from 'src/services/gateway/gatewayEmit'

export const fetchOrderData = (
  provider: PaymentsProviderTypes,
  offerCode: string,
  options?: { [key: string]: string | boolean | Record<string, string | boolean> }
) =>
  gatewayEmit<CreateOrderResponse>({
    type: 'CreateOrderRequest',
    provider,
    brandName: process.env.BRAND_NAME,
    transactionId: uuid(),
    offer: offerCode,
    referer: window.location.href,
    session: getItemFormLocalStorage('SESSION_ID', false),
    ...options,
  })

export const confirmOrderRequestPromise = (
  transactionId: string,
  token: string,
  billingAddress: { [key: string]: any }
) =>
  gatewayEmit<ConfirmOrderResponse>({
    type: 'ConfirmOrderRequest',
    session: getItemFormLocalStorage('SESSION_ID', false),
    transactionId,
    token,
    billingAddress,
  })
