import { useEffect } from 'react'
import { DialogNames } from 'store/modules/dialog/types'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { openDialog } from 'store/modules/dialog/actions'
import dialogs from 'src/dialogs'

const parseDialogParams = (dialogProps: any) => {
  // parse query string
  if (!dialogProps || !Object.keys(dialogProps)?.length) {
    return null
  }

  return Object.keys(dialogProps).reduce((acc: { [key: string]: string }, key) => {
    if (key.includes('dialogProps')) {
      const parsedKey = key.split('.')[1]
      acc[parsedKey] = dialogProps[key]

      return acc
    }
    acc[key] = dialogProps[key]

    return acc
  }, {})
}

export default function useOpenDialogByQuery() {
  const router = useRouter()
  const dispatch = useDispatch()
  useEffect(() => {
    const { dialogName, ...otherParams } = router.query
    const cleanAsPath = router.asPath.replace(window.location.search, '')

    if (dialogName && Object.prototype.hasOwnProperty.call(dialogs, dialogName.toString())) {
      const parsedDialogProps = parseDialogParams(otherParams)
      window.history.replaceState({}, document.title, cleanAsPath)
      dispatch(openDialog(dialogName as DialogNames, parsedDialogProps))
    }
  }, [router])
}
