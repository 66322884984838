import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useIsReactNative from 'src/hooks/useIsReactNative'
import { setAuthLoading } from 'src/store/modules/auth/actions'

function TurnOffSpinnersInButton(): null {
  const dispatch = useDispatch()
  const isAndroidApp = useIsReactNative()

  useEffect(() => {
    if (!isAndroidApp) {
      window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
          dispatch(setAuthLoading(false))
        }
      })
    }
  }, [])

  return null
}

export default TurnOffSpinnersInButton
