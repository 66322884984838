import { CountUp } from 'use-count-up'

import usePrevious from 'src/hooks/usePrevious'
import { Currencies } from 'src/store/modules/currencies/types'

type Props = {
  amount: number
  currency: Currencies
  decimalSeparator?: string
  thousandsSeparator?: string
}
function NumberAnimation(props: Props) {
  const { amount, currency } = props
  const prevAmount = usePrevious(amount)

  return (
    <>
      <CountUp
        start={prevAmount || 0}
        end={amount}
        duration={0.8}
        isCounting
        autoResetKey={amount}
        decimalSeparator={props.decimalSeparator}
        thousandsSeparator={props.thousandsSeparator}
        decimalPlaces={currency === 'GC' ? 0 : 2}
      />
    </>
  )
}

NumberAnimation.defaultProps = {
  decimalSeparator: '.',
  thousandsSeparator: ',',
}

export default NumberAnimation
