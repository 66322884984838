import useStyles from './styles'
import Button from '@material-ui/core/Button'
import MessageContent from './MessageContent'

type Props = {
  actionButton: {
    action: Function
    text: string
  }
  message: string
  [key: string]: any
}
export default function Message(props: Props) {
  const classes = useStyles(props)
  const { actionButton, message, ...buttonOptions } = props

  return (
    <>
      <div id='client-snackbar' className={classes.message}>
        {/* <Icon className={classes.variantIcon} /> */}
        <div className={actionButton ? classes.messageTextWithButton : classes.messageText}>
          <MessageContent message={message} />
        </div>
        {actionButton && (
          <Button
            {...buttonOptions}
            className={classes.actionButton}
            onClick={() => {
              actionButton.action()
            }}
          >
            {actionButton.text}
          </Button>
        )}
      </div>
    </>
  )
}
