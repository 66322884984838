import { useEffect } from 'react'
import Router from 'next/router'

import { trackScreenViewEvent } from 'src/utils/gtag'

function AppScreenViewTracker(): null {
  useEffect(() => {
    trackScreenViewEvent(Router.asPath)
    Router.events.on('routeChangeComplete', trackScreenViewEvent)

    return () => {
      Router.events.off('routeChangeComplete', trackScreenViewEvent)
    }
  }, [])

  return null
}

export default AppScreenViewTracker
