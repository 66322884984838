import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    margin: 'auto auto 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    width: 117.333,
  },
  withPwa: {
    width: '100%',
    justifyContent: 'center',
  },
}))
