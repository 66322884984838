import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    paddingTop: 4,
    paddingBottom: 4,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    // mobile
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 35,
    },
  },

  GC: {
    color: theme.palette.goldCoinTheme.themeColor,
  },
  SC: {
    color: theme.palette.sweepCoinTheme.themeColor,
  },
  USD: {
    color: 'red',
  },
  activeLink: {
    color: theme.palette.sweepCoinTheme.themeColor,
  },
  linkText: {
    paddingLeft: '0.5rem',
    fontWeight: 500,
  },
}))
