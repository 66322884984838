import { makeStyles, Theme } from '@material-ui/core/styles'
import layoutConfig from 'config/layoutConstants.json'

export default makeStyles((theme: Theme) => ({
  root: {
    alignContent: 'center',
    textAlign: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '3rem',

    // desktop
    [theme.breakpoints.up('sm')]: {
      marginLeft: layoutConfig.desktopMenuWidth,
    },
    // mobile
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 78,
    },
  },
  content: {
    maxWidth: '42rem',
    margin: 'auto',
  },

  logo: {
    marginBottom: '1rem',
    opacity: 0.5,
  },
  text: {
    marginBottom: '1rem',
    color: 'rgba(0,0,0,0.5)',
  },
  span: {
    fontSize: '0.75rem',
    opacity: 0.5,
    marginBottom: '1rem',
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textTransform: 'uppercase',
  },
  textLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  link: {
    padding: 8,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    // letter-spacing: 0.25px;
  },
  buildVersion: {
    fontSize: '0.5rem',
    opacity: 0.6,
    paddingBottom: '1rem',
  },
  phoneLink: {
    display: 'inline-block',
    color: '#0084CE',
  },
}))
