import { getNextSessionDialog, getCorrectSessionDialogs } from './utils'
import { sessionDialogsResolver } from '../modules/dialog/actions'

import { isNativeAppSelector, sweepstakeEnabledSelector } from '../modules/appConfig/selectors'
import promoDialogs from 'config/promoDialogs.json'
import {
  LAST_DIALOG_OPEN,
  INVITE_FRIENDS_HIDDEN,
  setDataToLocalStorage,
  getItemFormLocalStorage,
} from 'src/utils/localStorage'

import { getUserSubscriptionsSelector } from '../modules/user/selectors'
import { isDiscountOffersAvailable } from '../modules/shop/selectors'
import { ThunkType } from '../modules/types'

const openSessionDialogs = (): ThunkType => (dispatch, getState) => {
  const isNative = isNativeAppSelector(getState())
  const subscriptions = getUserSubscriptionsSelector(getState())
  const isFirstPurchaseNotUsed = isDiscountOffersAvailable(getState())
  const scEnabled = sweepstakeEnabledSelector(getState())
  const inviteFriendsLastDateOpened = getItemFormLocalStorage(INVITE_FRIENDS_HIDDEN, false)

  const differenceInTime = new Date().getTime() - new Date(inviteFriendsLastDateOpened).getTime()
  const differenceInDays = differenceInTime / (1000 * 3600 * 24)
  const PAUSE_PERIOD = 5
  const inviteFriendsAvailable = inviteFriendsLastDateOpened
    ? differenceInDays >= PAUSE_PERIOD
    : !inviteFriendsLastDateOpened

  if (inviteFriendsAvailable && inviteFriendsLastDateOpened) {
    dispatch(setDataToLocalStorage(INVITE_FRIENDS_HIDDEN, new Date(), false))
  }
  const availableDialogs = getCorrectSessionDialogs(
    {
      platform: isNative ? 'mobile' : 'web',
      flags: {
        isFirstPurchaseNotUsed,
        scEnabled,
        inviteFriendsAvailable,
        isSubscriptionNotUsed: !subscriptions,
      },
    },
    promoDialogs
  )
  const currentDialog = getNextSessionDialog(availableDialogs)
  if (currentDialog) {
    dispatch(setDataToLocalStorage(LAST_DIALOG_OPEN, currentDialog))
    dispatch(sessionDialogsResolver(currentDialog))
  }
}

export default openSessionDialogs
