import { connect } from 'react-redux'

import Hidden from '@material-ui/core/Hidden'
import Button from 'components/Button'

import { switchGlobalCurrencyHandler } from 'store/modules/currencies/actions'

import { getCurrencyInitializedSelector, getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { Currencies } from 'store/modules/currencies/types'
import { ApplicationState } from 'store/modules/types'
import useStyles from './styles'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import USDCurrency from './USDCurrency'
import { isSCVisibleSelector } from 'store/modules/appConfig/selectors'
import { freeLottoAllowedSelector } from 'store/modules/games/selectors'

import CashSwitcher from 'src/containers/CashSwitcher'
import GoldCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/GoldCurrency'
import SweepstakeCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/SweepstakeCurrency'
import { openShopHandler } from 'src/store/modules/shop/actions'

const t = getDataFromJson(content)

const mapState = (state: ApplicationState) => ({
  currencyInitialized: getCurrencyInitializedSelector(state),
  activeCurrency: getActiveCurrencyIdSelector(state),
  freeLottoAllowed: freeLottoAllowedSelector(state),
  isSCVisible: isSCVisibleSelector(state),
})

const mapDispatch = {
  switchGlobalCurrencyHandler,
  openShopHandler,
}

type LoggedInProps = {
  currencyInitialized: boolean
  activeCurrency: Currencies
  switchGlobalCurrencyHandler: typeof switchGlobalCurrencyHandler
  isSCVisible: boolean
  freeLottoAllowed: boolean
  openShopHandler: typeof openShopHandler
}

function LoggedIn(props: LoggedInProps) {
  const classes = useStyles(props)

  if (!props.currencyInitialized) {
    return null
  }

  return (
    <div className={classes.root}>
      <Hidden only={['xs']}>
        <Button
          data-test='common-header-buy-button'
          onClick={() => props.openShopHandler(false)}
          variant='contained'
          size='small'
          className={classes.shopLink}
        >
          {t('Get coins')}
        </Button>
      </Hidden>

      {props.isSCVisible ? (
        <CashSwitcher
          dataAttribute='header'
          mode='headerMode'
          activeCurrency={props.activeCurrency}
          handleChange={props.switchGlobalCurrencyHandler}
          GoldCurrencyComponent={GoldCurrency}
          SweepstakeCurrencyComponent={SweepstakeCurrency}
          reversed
        />
      ) : (
        <div className={classes.currency}>
          <GoldCurrency />
          {props.freeLottoAllowed && <USDCurrency />}
        </div>
      )}
    </div>
  )
}

export default connect(mapState, mapDispatch)(LoggedIn)
