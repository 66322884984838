import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Typography from '@material-ui/core/Typography'
import Head from 'next/head'
import Button from 'components/Button'
import { getUserSelector } from 'store/modules/user/selectors'
import { getDataFromJson } from 'src/utils/helpers'
import useStyles from './styles'
import ROUTES from 'config/routes.json'
import Layout from './Layout'
import useResendEmail from './useResendEmail'
import VerifyEmailFail from './Fail'
import VerifyEmailSuccess from './Success'

const t = getDataFromJson({})

type Props = {}

function VerifyEmail(props: Props) {
  const classes = useStyles(props)
  const userInfo = useSelector(getUserSelector)
  const router = useRouter()
  const { resendMail, inProgress } = useResendEmail()

  if (!userInfo) {
    return null
  }

  if (router.asPath.includes('/confirm-email/fail')) {
    return <VerifyEmailFail />
  }

  if (router.asPath.includes('/confirm-email/success')) {
    return <VerifyEmailSuccess />
  }

  return (
    <>
      <Head>
        <title>
          {userInfo.emailVerified
            ? t(`You are welcome at ${process.env.BRAND}.com | Email verified`)
            : t(`Check your email before visit ${process.env.BRAND}.com`)}
        </title>
      </Head>

      <Layout>
        <>
          <Typography component='h1' variant='h4' className={classes.title}>
            {userInfo.emailVerified ? t('Email verified') : t('Check your email')}
          </Typography>

          {userInfo.emailVerified ? (
            <Button
              onClick={() => {
                router.push(ROUTES.HOME_LOGGED_IN)
              }}
              className={classes.logoutBtn}
            >
              go to home page
            </Button>
          ) : (
            <>
              <Typography component='p' variant='subtitle1' className={classes.info}>
                {t('We’ve sent a message to ')} <b className={classes.email}> {userInfo.email} </b>{' '}
                {t('with a link to activate your account.')}
              </Typography>

              <Button
                variant='outlined'
                className={classes.reloadBtn}
                onClick={() => {
                  document.location.reload()
                }}
              >
                reload page
              </Button>

              <Typography component='span' variant='subtitle1' className={classes.text}>
                {t('If you don’t see an email from us within a few minutes, a few things could have happened:')}
              </Typography>
              <Typography component='span' variant='subtitle1' className={classes.text}>
                - {t('The email is in your spam folder. (Sometimes things get lost in there.)')}
              </Typography>
              <Typography component='span' variant='subtitle1' className={classes.text}>
                - {t('The email address you entered had a mistake or typo. (Happens to the best of us.)')}
              </Typography>
              <Typography component='span' variant='subtitle1' className={classes.text}>
                -{' '}
                {t(
                  'You accidentally gave us another email address. (Usually a work or personal one instead of the one you meant.)'
                )}
              </Typography>
              <Typography component='span' variant='subtitle1' className={classes.text} paragraph>
                -{' '}
                {t(
                  'We can’t deliver the email to this address. (Usually because of corporate firewalls or filtering.)'
                )}
              </Typography>

              <Typography variant='body2'>
                <b>If you did not get email please:</b>{' '}
                <Button disabled={inProgress} onClick={resendMail}>
                  send again
                </Button>
              </Typography>
            </>
          )}
        </>
      </Layout>
    </>
  )
}

export default VerifyEmail
