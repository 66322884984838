import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import { getAllGamesProductsSelector } from 'store/modules/games/selectors'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'

const getActiveGameCode = (state: ApplicationState) => state.slotGameFlow.activeGameCode
const getGamesWithFreeSpins = (state: ApplicationState) => state.slotGameFlow.freeSpins

export const getActiveGameCodeSelector = createSelector(getActiveGameCode, (gameCode) => gameCode)
export const getGamesWithFreeSpinsSelector = createSelector(getGamesWithFreeSpins, (spins) => spins)

export const getActiveSlotSelector = createSelector(
  getAllGamesProductsSelector,
  getActiveGameCodeSelector,
  (games, gameCode) => {
    return games?.find((game) => game.code === gameCode)
  }
)

export const getSlotBetsAndPrizes = createSelector(
  getActiveSlotSelector,
  getActiveCurrencyIdSelector,
  (game, activeCurrency) => {
    if (game && activeCurrency) {
      const {
        sweepstakeMinBet,
        goldMinBet,
        sweepstakeMaxBetMultiplier,
        goldMaxBetMultiplier,
        sweepstakeMaxWinMultiplier,
        goldMaxWinMultiplier,
      } = game
      const maxWinMultipliers = {
        SC: sweepstakeMaxWinMultiplier,
        GC: goldMaxWinMultiplier,
        USD: 1,
      }
      const minBetMultipliers = {
        SC: sweepstakeMinBet,
        GC: goldMinBet,
        USD: 1,
      }
      const maxBetMultipliers = {
        SC: sweepstakeMaxBetMultiplier,
        GC: goldMaxBetMultiplier,
        USD: 1,
      }

      return {
        minBet: minBetMultipliers[activeCurrency],
        maxBet: minBetMultipliers[activeCurrency] * maxBetMultipliers[activeCurrency],
        maxPrize:
          minBetMultipliers[activeCurrency] * maxBetMultipliers[activeCurrency] * maxWinMultipliers[activeCurrency],
      }
    }

    return null
  }
)
