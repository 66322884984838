export function mapColorIndexByName(name) {
  switch (name && name[0].toLowerCase()) {
    case 'a':
    case 'b':
    case 'c':
    case '1':
    case '2':
      return 1

    case 'd':
    case 'e':
    case 'f':
    case '3':
    case '4':
      return 2

    case 'g':
    case 'h':
    case 'i':
    case '5':
    case '6':
      return 3

    case 'j':
    case 'k':
    case 'l':
    case '7':
    case '8':
      return 4

    case 'm':
    case 'n':
    case 'o':
    case '9':
    case '0':
      return 5

    case 'p':
    case 'q':
    case 'r':
    case 's':
      return 6

    case 't':
    case 'u':
    case 'v':
      return 7

    default:
      return null
  }
}
