import { batch } from 'react-redux'
import * as Sentry from '@sentry/nextjs'
import {
  SIGN_UP_URL,
  SIGN_UP_URL_GUEST,
  SIGN_IN_URL,
  SIGN_OUT_URL,
  RESET_PASSWORD_URL,
  SIGN_UP_FACEBOOK_URL,
  SIGN_UP_GOOGLE_URL,
  SIGN_IN_FACEBOOK_URL,
  SIGN_IN_GOOGLE_URL,
  SIGN_IN_PERMANENT_URL,
} from 'services/gateway/config'
import { WSinstance } from 'services/websocket/'
import { parseStatus, convertToJson, BaseResponse } from 'utils/request'
import gatewayFetch from 'services/gateway/gatewayFetch'
import { trackGAEvent, gaSetUserId } from 'utils/gtag'
import { sendRNevent, socialAuthResolver } from 'utils/helpers'
import {
  setDataToLocalStorage,
  getItemFormLocalStorage,
  removeDataFromLocalStorage,
  IS_QUICK_START_OPENED,
  SAVED_UPCOMMING_GAMES,
  LS_AUTH_TYPE,
  IS_USER_REGISTERED,
} from 'utils/localStorage'

import { deleteUser } from 'store/modules/user/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { clearCurrency } from 'store/modules/currencies/actions'
import { setOpenPrerequisites } from 'store/modules/prerequisites/actions'
import { requestTempToken } from 'store/modules/tempToken/actions'
import { removeDialogByName, openDialog, closeLatestDialog, closeAllDialogs } from 'store/modules/dialog/actions'
import { clearPlayedGames } from 'store/modules/playedGames/actions'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import {
  getAndroidDeviceIdSelector,
  getPushTokenSelector,
  getForcementModeSelector,
  getGaidDataSelector,
  getFacebookAppIdSelector,
  getTrackingIdSelector,
} from 'store/modules/appConfig/selectors'

import {
  RegisterRequest,
  LoginRequest,
  RegisterGuestRequest,
  LoginFormValues,
  VerifyEmailType,
  GatewayRegisterResponse,
  AuthActionTypes,
  SetAuthLoadingAction,
  SetGoogleAppInitAction,
  AuthViaSocialAPI,
  RegisterViaEmail,
  SocialAuthTypes,
} from './types'
import { DialogNames } from '../dialog/types'
import { ThunkType } from 'store/modules/types'
import { formatTimezoneToTime } from 'utils/date'
import gatewayEmit from 'src/services/gateway/gatewayEmit'
import ROUTES from 'src/config/routes.json'
import Router from 'next/router'
import { fbCompleteRegistration, getFacebookUserData, initFacebookApp } from 'src/services/facebook/utils'
import { getInternalTokenDataSelector } from 'store/modules/tempToken/selectors'
import { onExternalLinkClick } from 'containers/NativeBridge/utils'
import { clearRedeemState } from '../redeem/actions'
import { clearPaymentsState } from '../paymentsFlow/actions'
import { SetPushInfoResponse } from 'src/services/websocket/types/response'

export const setAuthLoading = (loading: boolean): SetAuthLoadingAction => {
  return {
    type: AuthActionTypes.SET_AUTH_LOADING,
    payload: {
      loading,
    },
  }
}

const authSuccessHandle =
  (userID: string, sessionId?: string, isRegisterActon?: boolean): ThunkType =>
  (dispatch) => {
    dispatch(removeDialogByName('AUTH_CONFIRM_DIALOG'))
    dispatch(removeDialogByName('CONNECT_ACCOUNT_DIALOG'))
    dispatch(removeDialogByName('GUEST_CONFIRM_DIALOG'))
    dispatch(removeDialogByName('PRELOADER_DIALOG'))
    dispatch(setAuthLoading(false))

    dispatch(setDataToLocalStorage(IS_USER_REGISTERED, true, false))
    dispatch(setDataToLocalStorage('USER_ID', userID, false))

    dispatch(removeDataFromLocalStorage(SAVED_UPCOMMING_GAMES))
    dispatch(removeDataFromLocalStorage('REFERRER', false))
    dispatch(removeDataFromLocalStorage('QUERY', false))

    if (isRegisterActon && process.env.BRAND_NAME === 'onl') {
      dispatch(setDataToLocalStorage('RULES_UPDATE_DIALOG_HIDDEN', true))
    }
  }

const authErrorHandler =
  (
    err_code: 'err_not_found' | 'err_duplicate' | undefined,
    options: {
      errorText: string
      form: AuthViaSocialAPI | LoginFormValues | RegisterViaEmail
      isMobile: boolean
      method?: 'Facebook' | 'Google'
    }
  ): ThunkType =>
  (dispatch) => {
    if ('isMobile' in options && !options.isMobile) {
      dispatch(closeLatestDialog())
    }

    dispatch(setAuthLoading(false))
    switch (err_code) {
      case 'err_not_found': {
        Router.push(ROUTES.REGISTER)
        break
      }
      case 'err_duplicate': {
        dispatch(closeAllDialogs())

        if (options?.errorText) {
          dispatch(
            openSnackbar({
              message: options?.errorText,
            })
          )
        }
        break
      }
      default: {
        break
      }
    }
  }

export const resetPasswordAction =
  (form: VerifyEmailType): ThunkType =>
  (dispatch) => {
    const request: VerifyEmailType = {
      email: form.email,
      brandName: process.env.BRAND_NAME,
    }

    gatewayFetch(RESET_PASSWORD_URL, request)
      .then((data) => {
        return dispatch(parseStatus(data))
      })
      .then(convertToJson)
      .then((res) => {
        batch(() => {
          dispatch(setOpenPrerequisites(res.prerequisites))
          dispatch(authSuccessHandle(res.id))
          dispatch(
            openSnackbar({
              message: 'Please check your email',
              variant: 'info',
            })
          )
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Request failed', error)
      })
  }

export const login =
  (form: LoginFormValues, setSubmitting?: (arg: boolean) => void, isMobile?: boolean): ThunkType =>
  (dispatch, getState) => {
    const pushToken = getPushTokenSelector(getState())
    const query = getItemFormLocalStorage('QUERY', false)
    const session = getItemFormLocalStorage('SESSION_ID', false)

    const request: LoginRequest = {
      lang: 'en',
      timezone: formatTimezoneToTime(new Date().getTimezoneOffset()),
      brandName: process.env.BRAND_NAME,
      query,
      session,

      ...form,
    }

    if (pushToken) {
      request.pushToken = pushToken
    }

    dispatch(setAuthLoading(true))

    WSinstance.disconnect()
    gatewayFetch(SIGN_IN_URL, request)
      .then((data) => dispatch(parseStatus(data)))
      .then(convertToJson)
      .then((res) => {
        WSinstance.connect(dispatch).then(() => {
          batch(() => {
            dispatch(setOpenPrerequisites(res.prerequisites))
            dispatch(authSuccessHandle(res.id, session))
            setSubmitting?.(false)
            trackGAEvent('Login', 'login', 'Email')
            sendRNevent({
              type: 'loginSuccess',
              method: 'Email',
            })
          })
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Request failed', error)
        setSubmitting?.(false)

        WSinstance.connect(dispatch).then(() => {
          dispatch(authErrorHandler(error?.errorCode, { errorText: error?.errorText, form, isMobile }))
        })
      })
  }

const guestAuthSuccesHandler =
  (isNewUser: boolean, userId: string, sessionId: string): ThunkType =>
  (dispatch) => {
    if (isNewUser) {
      Router.push(ROUTES.HOME_LOGGED_IN).then(() => {
        trackGAEvent('Sign up', 'sign_up', 'Guest')
        sendRNevent({
          type: 'signUpSuccess',
          method: 'Guest',
        })
      })
    } else {
      trackGAEvent('Login', 'login', 'Guest')
      sendRNevent({
        type: 'loginSuccess',
        method: 'Guest',
      })
    }
    dispatch(authSuccessHandle(userId, sessionId))
  }

export const registerAsGuest = (): ThunkType => (dispatch, getState) => {
  const deviceId = getAndroidDeviceIdSelector(getState())
  const pushToken = getPushTokenSelector(getState())
  const forcement = getForcementModeSelector(getState())
  const gaidData = getGaidDataSelector(getState())
  const referrer = getItemFormLocalStorage('REFERRER', false)
  const query = getItemFormLocalStorage('QUERY', false)
  const first_utm = getItemFormLocalStorage('FIRST_UTM', false)
  const session = getItemFormLocalStorage('SESSION_ID', false)
  const campaignString = first_utm !== 'blank' && first_utm ? `first_utm_campaign=${first_utm}` : null

  const reqData: RegisterGuestRequest = {
    lang: 'en',
    timezone: formatTimezoneToTime(new Date().getTimezoneOffset()),
    brandName: process.env.BRAND_NAME,
    fingerprint: deviceId,
    forcement,
    referrer,
    query: query ? `${query}${campaignString ? `&${campaignString}` : ''}` : campaignString,
    session,
  }

  if (deviceId) {
    dispatch(setAuthLoading(true))

    if (pushToken) {
      reqData.pushToken = pushToken
    }

    if (gaidData) {
      reqData.advertisingId = gaidData.advertisingId
      reqData.osVersion = gaidData.osVersion
      reqData.appVersion = gaidData.appVersion
    }

    WSinstance.disconnect()

    gatewayFetch(SIGN_UP_URL_GUEST, reqData)
      .then((data: BaseResponse) => dispatch(parseStatus(data)))
      .then(convertToJson)
      .then((data: GatewayRegisterResponse) => {
        WSinstance.connect(dispatch).then(() => {
          const isUserRegistered = getItemFormLocalStorage(IS_USER_REGISTERED, false)
          dispatch(guestAuthSuccesHandler(!isUserRegistered && data.newAccount, data.id, session))
        })
      })
      .catch((err) => {
        WSinstance.connect(dispatch).then(() => {
          dispatch(setAuthLoading(false))
        })
        // eslint-disable-next-line
        console.log(err)
      })
  } else {
    dispatch(
      openSnackbar({
        message: 'No device id. Guest mode works only on mobile app',
        variant: 'error',
      })
    )
  }
}

export const waitForDeviceId =
  (cb: Function): ThunkType =>
  (dispatch, getState) => {
    const intervalId = setInterval(() => {
      const deviceId = getAndroidDeviceIdSelector(getState())
      if (deviceId) {
        dispatch(cb())

        clearInterval(intervalId)
      }
    }, 200)
  }

// login via social
export const socialSignInHandler =
  (form: AuthViaSocialAPI, method: 'Facebook' | 'Google', isMobile: boolean): ThunkType =>
  (dispatch, getState) => {
    const fingerprint = getAndroidDeviceIdSelector(getState())
    const pushToken = getPushTokenSelector(getState())
    const forcement = getForcementModeSelector(getState())
    const query = getItemFormLocalStorage('QUERY', false)
    const session = getItemFormLocalStorage('SESSION_ID', false)

    const signInUrls: Record<typeof method, string> = {
      Facebook: SIGN_IN_FACEBOOK_URL,
      Google: SIGN_IN_GOOGLE_URL,
    }

    const request: RegisterRequest = {
      lang: 'en',
      timezone: formatTimezoneToTime(new Date().getTimezoneOffset()),
      brandName: process.env.BRAND_NAME,
      forcement,
      query,
      session,
      ...form,
    }

    if (fingerprint) {
      request.fingerprint = fingerprint
    }

    if (pushToken) {
      request.pushToken = pushToken
    }

    WSinstance.disconnect()

    gatewayFetch(signInUrls[method], request)
      .then((data: BaseResponse) => dispatch(parseStatus(data)))
      .then(convertToJson)
      .then((res) => {
        WSinstance.connect(dispatch).then(() => {
          dispatch(authSuccessHandle(res.id, session))
          dispatch(removeDataFromLocalStorage(LS_AUTH_TYPE, false))

          trackGAEvent('Login', 'login', method)
          sendRNevent({
            type: 'loginSuccess',
            method,
          })
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Request failed', error)
        dispatch(removeDataFromLocalStorage(LS_AUTH_TYPE, false))
        WSinstance.connect(dispatch).then(() => {
          dispatch(authErrorHandler(error?.errorCode, { errorText: error?.errorText, form, method, isMobile }))
        })
      })
  }

export const signInPermanent =
  (token: string, launchGameParams: { gameDialogName: string; gameCode: string }): ThunkType =>
  (dispatch) => {
    WSinstance.disconnect()
    const session = getItemFormLocalStorage('SESSION_ID', false)

    gatewayFetch(SIGN_IN_PERMANENT_URL, { token, session })
      .then((data: BaseResponse) => dispatch(parseStatus(data)))
      .then(convertToJson)
      .then((res) => {
        WSinstance.connect(dispatch).then(() => {
          batch(() => {
            dispatch(authSuccessHandle(res.id, session))

            Router.replace(ROUTES.HOME_LOGGED_IN).then(() => {
              // launch game if params available
              if (launchGameParams.gameDialogName && launchGameParams.gameCode) {
                dispatch(setDataToLocalStorage(IS_QUICK_START_OPENED, true))
                dispatch(
                  openDialog(launchGameParams.gameDialogName as DialogNames, {
                    gameCode: launchGameParams.gameCode,
                  })
                )
              }
            })
          })
          trackGAEvent('Login', 'login', 'auto-login')
          sendRNevent({
            type: 'loginSuccess',
            method: 'auto-login',
          })
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Request failed', error)
        WSinstance.connect(dispatch).then(() => {
          Router.replace(ROUTES.HOME_LOGGED_OUT)
          dispatch(setAuthLoading(false))
        })
      })
  }

// register via email
export const registerViaEmail =
  (form: RegisterViaEmail, setSubmitting: (arg: boolean) => void, isMobile: boolean): ThunkType =>
  (dispatch, getState) => {
    const fingerprint = getAndroidDeviceIdSelector(getState())
    const pushToken = getPushTokenSelector(getState())
    const trackingId = getTrackingIdSelector(getState())
    const forcement = getForcementModeSelector(getState())
    const referrer = getItemFormLocalStorage('REFERRER', false)
    const query = getItemFormLocalStorage('QUERY', false)
    const first_utm = getItemFormLocalStorage('FIRST_UTM', false)
    const session = getItemFormLocalStorage('SESSION_ID', false)
    const campaignString = first_utm !== 'blank' && first_utm ? `first_utm_campaign=${first_utm}` : null

    const request: RegisterRequest = {
      lang: 'en',
      timezone: formatTimezoneToTime(new Date().getTimezoneOffset()),
      brandName: process.env.BRAND_NAME,
      forcement,
      referrer,
      query: query ? `${query}${campaignString ? `&${campaignString}` : ''}` : campaignString,
      session,
      ...form,
    }

    if (fingerprint) {
      request.fingerprint = fingerprint
    }
    if (pushToken) {
      request.pushToken = pushToken
    }
    if (trackingId) {
      request.trackingId = trackingId
    }

    dispatch(setAuthLoading(true))

    WSinstance.disconnect()

    gatewayFetch(SIGN_UP_URL, request)
      .then((data: BaseResponse) => dispatch(parseStatus(data)))
      .then(convertToJson)
      .then((data: GatewayRegisterResponse) => {
        WSinstance.connect(dispatch).then(() => {
          batch(() => {
            if (data.newAccount) {
              Router.push(ROUTES.CONFIRM_EMAIL).then(() => {
                trackGAEvent('Sign up', 'sign_up', 'Email')
                sendRNevent({
                  type: 'signUpSuccess',
                  method: 'Email',
                })
                fbCompleteRegistration()
              })
            }

            dispatch(authSuccessHandle(data.id, session, true))
            setSubmitting(false)
          })
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Request failed', error)
        setSubmitting(false)

        WSinstance.connect(dispatch).then(() => {
          dispatch(authErrorHandler(error?.errorCode, { errorText: error?.errorText, form, isMobile }))
        })
      })
  }

export const socialSignUpHandler =
  (
    form: AuthViaSocialAPI,
    method: 'Facebook' | 'Google',
    setSubmitting?: (arg: boolean) => void,
    isMobile?: boolean,
    trackingId?: string
  ): ThunkType =>
  (dispatch, getState) => {
    const fingerprint = getAndroidDeviceIdSelector(getState())
    const pushToken = getPushTokenSelector(getState())
    const forcement = getForcementModeSelector(getState())
    const gaidData = getGaidDataSelector(getState())
    const referrer = getItemFormLocalStorage('REFERRER', false)
    const query = getItemFormLocalStorage('QUERY', false)
    const first_utm = getItemFormLocalStorage('FIRST_UTM', false)
    const session = getItemFormLocalStorage('SESSION_ID', false)
    const campaignString = first_utm !== 'blank' && first_utm ? `first_utm_campaign=${first_utm}` : null

    const signUpUrls: Record<typeof method, string> = {
      Facebook: SIGN_UP_FACEBOOK_URL,
      Google: SIGN_UP_GOOGLE_URL,
    }

    const request: RegisterRequest = {
      lang: 'en',
      timezone: formatTimezoneToTime(new Date().getTimezoneOffset()),
      brandName: process.env.BRAND_NAME,
      forcement,
      referrer,
      query: query ? `${query}${campaignString ? `&${campaignString}` : ''}` : campaignString,
      session,
      ...form,
    }

    if (fingerprint) {
      request.fingerprint = fingerprint
    }
    if (trackingId) {
      request.trackingId = trackingId
    }
    if (pushToken) {
      request.pushToken = pushToken
    }

    if (gaidData) {
      request.advertisingId = gaidData.advertisingId
      request.osVersion = gaidData.osVersion
      request.appVersion = gaidData.appVersion
    }

    dispatch(setAuthLoading(true))
    WSinstance.disconnect()

    gatewayFetch(signUpUrls[method], request)
      .then((data: BaseResponse) => dispatch(parseStatus(data)))
      .then(convertToJson)
      .then((res) => {
        WSinstance.connect(dispatch).then(() => {
          batch(() => {
            Router.push(ROUTES.HOME_LOGGED_IN).then(() => {
              dispatch(authSuccessHandle(res.id, session, true))
              trackGAEvent('Sign up', 'sign_up', method)
              sendRNevent({
                type: 'signUpSuccess',
                method,
              })
              fbCompleteRegistration()
            })
          })

          dispatch(removeDataFromLocalStorage(LS_AUTH_TYPE, false))

          // @TODO - check usage
          if (setSubmitting && typeof setSubmitting === 'function') {
            setSubmitting(false)
          }
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Request failed', error)
        dispatch(removeDataFromLocalStorage(LS_AUTH_TYPE, false))

        // @TODO - check usage
        if (setSubmitting && typeof setSubmitting === 'function') {
          setSubmitting(false)
        }

        WSinstance.connect(dispatch).then(() => {
          dispatch(authErrorHandler(error?.errorCode, { errorText: error?.errorText, form, method, isMobile }))
        })
      })
  }

export const logOutSuccess =
  (cbUrl: string): ThunkType =>
  (dispatch) => {
    batch(() => {
      sendRNevent({
        type: 'logOutSuccess',
      })

      gaSetUserId()
      dispatch(removeDataFromLocalStorage(SAVED_UPCOMMING_GAMES))
      dispatch(removeDataFromLocalStorage('USER_ID', false))
      dispatch(removeDataFromLocalStorage('SESSION_ID', false))

      dispatch(deleteUser())
      dispatch(clearCurrency())
      dispatch(clearPlayedGames())
      dispatch(clearRedeemState())
      dispatch(clearPaymentsState())

      window.location.href = cbUrl
    })
  }

export const logoutRequest =
  (cbUrl: string = ROUTES.HOME_LOGGED_OUT): ThunkType =>
  (dispatch) => {
    WSinstance.disconnect()

    gatewayFetch(SIGN_OUT_URL, {
      brandName: process.env.BRAND_NAME,
    })
      .then((data) => dispatch(parseStatus(data)))
      .then(convertToJson)
      .then(() => {
        dispatch(logOutSuccess(cbUrl))
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Request failed', error)
        WSinstance.connect(dispatch)
      })
  }
export const logOut = (): ThunkType => (dispatch, getState) => {
  const loggedIn = isLoggedInSelector(getState())

  if (loggedIn) {
    dispatch(logoutRequest())
  } else {
    // eslint-disable-next-line
    console.log("you've already logged out")
  }
}

export const setGoogleAppInit = (isInit: boolean): SetGoogleAppInitAction => {
  return {
    type: AuthActionTypes.SET_GOOGLE_APP_INIT,
    payload: {
      googleAppInit: isInit,
    },
  }
}

export const sendAndroidPushToken =
  (pushToken: string): ThunkType =>
  () => {
    gatewayEmit<SetPushInfoResponse>({ type: 'SetPushInfoRequest', pushToken }).catch((err) => {
      Sentry.captureMessage(`fetch SetPushInfoRequest ${err.message}`)
    })
  }

export const authProcessComplete = (): ThunkType => (dispatch) => {
  dispatch(setAuthLoading(false))
}

export const socialAuthRedirectHanlder =
  (token: string, email: string, socialProvider: 'Facebook' | 'Google'): ThunkType =>
  async (dispatch) => {
    const authType = getItemFormLocalStorage(LS_AUTH_TYPE, false)
    dispatch(openDialog('PRELOADER_DIALOG', { title: `${socialProvider} auth in progress` }))

    switch (authType) {
      case SocialAuthTypes.LOGIN: {
        dispatch(socialSignInHandler({ accessToken: token }, socialProvider, false))
        break
      }
      case SocialAuthTypes.REGISTRATION: {
        dispatch(
          openDialog('AUTH_CONFIRM_DIALOG', {
            socialType: socialProvider,
            token,
            ...(email && { email }),
          })
        )
        dispatch(removeDialogByName('PRELOADER_DIALOG'))
        break
      }

      default: {
        break
      }
    }
  }

let currentAuthFlowEXECUTED = false
export const runsCurrentAuthFlow =
  (isLoggedIn: boolean): ThunkType =>
  (dispatch, getState) => {
    if (!isLoggedIn && !currentAuthFlowEXECUTED) {
      currentAuthFlowEXECUTED = true
      if (window.location.href.includes('access_token')) {
        socialAuthResolver('access_token', async (token) => {
          try {
            const fbAppId = getFacebookAppIdSelector(getState())
            await initFacebookApp(fbAppId)
            const { email } = await getFacebookUserData(token)
            dispatch(socialAuthRedirectHanlder(token, email, 'Facebook'))
          } catch (err) {
            dispatch(
              openSnackbar({
                message: err.message,
              })
            )
          }
        })
      } else if (window.location.href.includes('id_token')) {
        socialAuthResolver('id_token', (token) => {
          dispatch(socialAuthRedirectHanlder(token, null, 'Google'))
        })
      } else if (Router.query.a) {
        dispatch(openDialog('PRELOADER_DIALOG', { title: 'Auth in progress' }))

        dispatch(
          signInPermanent(Router.query.a as string, {
            gameDialogName: Router.query?.gameDialogName?.toString(),
            gameCode: Router.query?.gameCode?.toString(),
          })
        )
      } else if (Router.query?.invited_by) {
        Router.push({
          pathname: ROUTES.REGISTER,
          query: {
            type: 'invited_by',
          },
        })
      }
    }
  }

export const autoLoginInWebWithSCEnabled =
  (cb: () => void): ThunkType =>
  (dispatch, getState) => {
    dispatch(requestTempToken({ counterparty: 'internal', shop: true }))

    // run timeout until get token
    const intervalId = setInterval(() => {
      const tokenData = getInternalTokenDataSelector(getState())
      if (tokenData.token) {
        clearInterval(intervalId)
        onExternalLinkClick<null>(
          null,
          `${process.env.ROOT_URL}?a=${tokenData.token}&utm_source=${process.env.BRAND_NAME}_App&utm_medium=gotoweb`
        )
        cb()
      }
    }, 500)
  }

export const resendEmailRequest = (): ThunkType => (dispatch) => {
  dispatch(WSinstance.emitWS({ type: 'ResendEmailVerificationRequest' }))
}

export const detectSessionExpired =
  (isServerLoggedIn: boolean): ThunkType =>
  (dispatch, getState) => {
    const isClientLoggedIn = isLoggedInSelector(getState())
    if (!isServerLoggedIn && isClientLoggedIn) {
      dispatch(logOutSuccess(ROUTES.HOME_LOGGED_OUT))
    }
  }
