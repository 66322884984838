import { Currencies } from 'store/modules/currencies/types'
import { GameProduct, ProductModes } from 'store/modules/games/types'

// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum PlayedGamesActionTypes {
  SAVE_LOTTOS = 'playedGames/SAVE_LOTTOS',
  UPDATE_PLAYED_LOTTO = 'playedGames/UPDATE_PLAYED_LOTTO',
  CLEAR_ALL_GAMES = 'playedGames/CLEAR_ALL_GAMES',
  UPDATE_GAME = 'playedGames/UPDATE_GAME',
  GET_PLAYED_GAMES = 'playedGames/GET_PLAYED_GAMES',
}

export type LottoPickLine = {
  numbers: Array<number>
  additionalNumbers: Array<number>
}

export type LottoPick = {
  id: number
  lines: Array<LottoPickLine>
  winningAmount: number
  currency?: Currencies
}

export type LottoPlayedGame = {
  currency: Currencies
  product: string
  mode: ProductModes
  drawDate: string
  id: number
  amount: number
  drawId: string
  jackpot: number
  status: 'accepted' | 'closed'
  winningAmount: number
  picks: Array<LottoPick>
  winningNumbers: Array<number>
  winningAdditionalNumbers: Array<number>
  transactionId: string
}

export interface SavePlayedLottosAction {
  type: PlayedGamesActionTypes.SAVE_LOTTOS
  payload: {
    accepted: Array<LottoPlayedGame>
    closed: Array<LottoPlayedGame>
  }
}

export interface UpdatePlayedLottoAction {
  type: PlayedGamesActionTypes.UPDATE_PLAYED_LOTTO
  payload: {
    game: LottoPlayedGame
  }
}

export interface UpdateGameAction {
  type: PlayedGamesActionTypes.UPDATE_GAME
  payload: {
    game: LottoPlayedGame
  }
}

export interface ClearPLayedGamesAction {
  type: PlayedGamesActionTypes.CLEAR_ALL_GAMES
}

export interface GetPlayedGamesAction {
  type: PlayedGamesActionTypes.GET_PLAYED_GAMES
  payload: {
    games: Array<GameProduct>
  }
}
export type Actions =
  | SavePlayedLottosAction
  | ClearPLayedGamesAction
  | UpdatePlayedLottoAction
  | UpdateGameAction
  | GetPlayedGamesAction

export interface PlayedGamesState {
  readonly acceptedLottos: Array<LottoPlayedGame> | null
  readonly closedLottos: Array<LottoPlayedGame> | null
  readonly games: Array<GameProduct> | null
}
