import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import ROUTES from 'config/routes.json'
import { getUserSelector } from 'store/modules/user/selectors'
import TermsListener from './TermsListener'
import IgnoreRoutes from './IgnoreRoutes'

export default function TermsUpdaterProvider() {
  const user = useSelector(getUserSelector)
  const router = useRouter()
  const ignoreRoutes = [ROUTES.TERMS, ROUTES.SWEEPSTAKE_RULES, ROUTES.PRIVACY]
  const ighoreRoute = ignoreRoutes.includes(router.route)

  if (!user) {
    return null
  }

  // ignore if latest version accepted
  if (user.acceptedTcVersion === user.tcVersion) {
    return null
  }

  // ingore for Terms and SC rules pages
  if (ighoreRoute) {
    return <IgnoreRoutes />
  }

  return <TermsListener />
}
