import { createSelector } from 'reselect'
import { ApplicationState } from 'store/modules/types'
import { VIPNames } from './types'

const getUserRoot = (state: ApplicationState) => state.user
const getUser = (state: ApplicationState) => state.user.info
const getUserCreatedAt = (state: ApplicationState) => state.user?.info?.createdAt
const getIsAdminUser = (state: ApplicationState) => state.user?.info?.admin
const getUserFirstOffer = (state: ApplicationState) => state.user?.firstOffer

export const getUserFlowIsRunnig = (state: ApplicationState) => state.user.userFlowIsRunning

export const getUserSelector = createSelector(getUser, (user) => user)
export const getUserModeSelector = createSelector(getUser, (user) => user?.mode)
export const getUserFlowIsRunningSelector = createSelector(getUserFlowIsRunnig, (isRunning) => isRunning)

export const oauthUsedSelector = createSelector(getUserSelector, (user) => user?.oauth.length > 0)

export const getUserXpLevelSelector = createSelector(getUserRoot, (user) => user.xpLevel)
export const isGuestModeSelector = createSelector(getUserRoot, (user) => user.info?.guest)
export const skipOtpSelector = createSelector(getUserRoot, (user) => user.skipOtp)
export const getUserBirthDateSelector = createSelector(getUserSelector, (user) => user?.birthDate)

export const getUserCreatedAtSelector = createSelector(getUserCreatedAt, (date) => date)

export const getUserVipPointsLevelSelector = createSelector(getUserRoot, (user) => user.vipLevel)
export const getUserVipNextLevelName = createSelector(getUserVipPointsLevelSelector, (vipInfo) => {
  if (VIPNames.indexOf(vipInfo?.name) === -1 || !vipInfo?.name) {
    return 'Wrong status'
  }
  if (VIPNames.indexOf(vipInfo.name) === VIPNames.length - 1) {
    return VIPNames[VIPNames.indexOf(vipInfo.name)]
  }

  return VIPNames[VIPNames.indexOf(vipInfo.name) + 1]
})

export const needToVerifyEmailSelector = createSelector(getUser, (user) => {
  if (!user) {
    return false
  }

  if (user.oauth.length === 0 && !user.emailVerified) {
    return true
  }

  return false
})

export const getUserEmailSelector = createSelector(getUser, (user) => user.email)
export const getUserRealEmail = createSelector(getUser, (user) => user?.realEmail)
export const isLoggedInSelector = createSelector(getUserSelector, (user) => !!user)

export const getKycStatusSelector = createSelector(getUserSelector, (user) => user?.kycStatus)
export const jumioKycAddressSelector = createSelector(getUserSelector, (user) => user.kycInfo)
export const jumioKycStateSelector = createSelector(jumioKycAddressSelector, (address) => address?.state)
export const softKycAddressLineSelector = createSelector(getUserSelector, (user) => user.softKycInfo?.address)
export const softKycFirstNameSelector = createSelector(getUserSelector, (user) => user.softKycInfo?.firstName)
export const softKycLastNameSelector = createSelector(getUserSelector, (user) => user.softKycInfo?.lastName)
export const SoftKycStateSelector = createSelector(getUserSelector, (user) => user.softKycInfo?.state)
export const userPhoneNumberSelector = createSelector(getUserSelector, (user) => user?.phoneNumber)
export const getUserRestrictionsSelector = createSelector(getUserSelector, (user) => user?.restrictions)

export const getUserSelectorSoftKYCInfo = createSelector(getUserSelector, (user) => user?.softKycInfo)

export const getCurrentLevelPercentageSelector = createSelector(
  getUserXpLevelSelector,
  isLoggedInSelector,
  (xpLevel, loggedIn) => {
    const MAX_UI_LVL = 87

    if (!loggedIn) {
      return 0
    }

    if (xpLevel.current === xpLevel.range || xpLevel.current === 0) {
      return 0
    }

    const currentPosition = -(xpLevel.threshold - xpLevel.range - xpLevel.current) / xpLevel.range

    const progress = currentPosition * MAX_UI_LVL

    return Math.round(progress)
  }
)

export const getCurrentVIPLevelPercentageSelector = createSelector(
  getUserVipPointsLevelSelector,
  isLoggedInSelector,
  (xpLevel, loggedIn) => {
    if (!loggedIn) {
      return 0
    }

    if (xpLevel.current === xpLevel.range || xpLevel.current === 0) {
      return 0
    }

    if (xpLevel.threshold - xpLevel.range < xpLevel.current) {
      const currentPosition = -(xpLevel.threshold - xpLevel.range - xpLevel.current) / xpLevel.range

      const progress = currentPosition * 100

      return Math.round(progress)
    } else {
      const currentPosition = xpLevel.current / xpLevel.threshold

      const progress = currentPosition * 100

      return Math.round(progress)
    }
  }
)

export const getUserIdSelector = createSelector(getUserSelector, (user) => user?.id)

export const getUserSubscriptionsSelector = createSelector(getUserSelector, (user) =>
  user?.subscriptions?.length ? user?.subscriptions : null
)

export const getUserRealEmailSelector = createSelector(getUserRealEmail, (email) => {
  return email
})

export const emailHiddenSelector = createSelector(
  getUserSelector,
  (user) => user.realEmail.includes('onlinelotto.com') || user.realEmail.includes('pulsz.com')
)

export const isPreviewModeSelector = createSelector(
  getUserModeSelector,
  () => {
    if (process.browser) {
      return !window?.ReactNativeWebView
    }

    return false
  },
  (mode, isDesktop) => {
    if (isDesktop) return false
    if (!mode) return false

    switch (mode) {
      case 'sweepstake_preview': {
        return true
      }
      default: {
        return false
      }
    }
  }
)

export const facebookConnectedSelector = createSelector(
  getUser,
  isLoggedInSelector,
  (user, loggedIn) => loggedIn && user.oauth.includes('facebook')
)

export const getUserInvitationInfoSelector = createSelector(getUser, (user) => user?.invitation)

export const getSentWelcomeEmailFieldSelector = createSelector(getUserSelector, (user) => {
  return user?.sentWelcomeEmail
})

export const getIsAdminUserSelector = createSelector(getIsAdminUser, (isAdmin) => isAdmin)

export const isKYCRequiredSelector = createSelector(getKycStatusSelector, (kycStatus) => {
  return ['initial', 'in_review', 'declined', 'doc_declined', 'id_confirmed'].includes(kycStatus)
})

export const KYCinReviewSelector = createSelector(getKycStatusSelector, (kycStatus) => {
  return kycStatus === 'doc_review'
})

export const getUserFirstOfferSelector = createSelector(getUserFirstOffer, (firstOffer) => firstOffer)

export const getSkipOTPshopSelector = createSelector(
  skipOtpSelector,
  userPhoneNumberSelector,
  getUserFirstOffer,
  (skipOtp, phoneNumber, firstOffer) => {
    if (skipOtp && !phoneNumber && !firstOffer) {
      return true
    }
    if (skipOtp && !phoneNumber && firstOffer) {
      return false
    }

    return skipOtp
  }
)
