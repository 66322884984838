import { Dispatch } from 'redux'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { requestErrorsGAHandler } from './gtag'
import { sendRNevent } from 'utils/helpers'

export type BaseResponse = {
  ok: boolean
  body: any
  status: number
  statusText: string
  json: () => Promise<object | any>
  errorText?: string
  errorCode?: 'err_denied'
  headers: any
}
export const parseStatus = (response: BaseResponse) => (dispatch: Dispatch) => {
  if (response.ok || response.status === 304) {
    return Promise.resolve(response)
  }

  return response
    .json()
    .then((data) => {
      const errMessage = data.errorText || data.message || response.statusText

      // check err_duplicate
      if (!('errorCode' in data && data.errorCode === 'err_duplicate')) {
        if (errMessage && data?.errorCode) {
          dispatch(
            openSnackbar({
              message: errMessage,
            })
          )
          sendRNevent({
            type: 'gatewayError',
            message: errMessage,
            errorCode: data.errorCode,
          })
        }
      }

      requestErrorsGAHandler(response.errorCode)

      return data
    })
    .catch((err) => {
      // eslint-disable-next-line
      console.log('parseStatus error:', err)
      dispatch(
        openSnackbar({
          message: response.statusText,
        })
      )
      sendRNevent({
        type: 'gatewayError',
        message: response.statusText,
        errorCode: response.errorCode,
      })
    })
}

export function convertToJson(response: BaseResponse) {
  if (response && response.json) {
    return response.json()
  }

  return Promise.reject(response)
}
