import { createSelector } from 'reselect'
import { compose } from 'redux'

import { getUpcomingLottosSelector, getAllPlayedLottoCodesSelector } from 'store/modules/playedGames/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'

import { getClosestDraw, filterFreeLottos, sortLottosByClosestDraw } from 'store/modules/games/utils'
import { ApplicationState } from 'store/modules/types'
import { FormattedLottoDraws, GameProduct, ProductModes, LottoProduct } from 'store/modules/games/types'
import { CurrentLottoPick } from 'store/modules/lottoGameFlow/types'

const getOwnPropsObject = (state: ApplicationState, ownProps: any) => ownProps

const getCategorizedGames = (state: ApplicationState) => {
  return state.games.games
}

const getAllLottoRules = (state: ApplicationState) => state.games.lottoRules
const getLottoDraws = (state: ApplicationState) => state.games.lotteryDraws
const getGameId = (state: ApplicationState, gameId: string) => gameId
const gamesIsLoaded = (state: ApplicationState) => state.games.gamesIsLoaded

export const getAllLottoRulesSelector = createSelector(getAllLottoRules, (lottoRules) => lottoRules)
export const getLottoDrawsSelector = createSelector(getLottoDraws, (lottoDraws) => lottoDraws)

export const isLottoDrawsLoadedSelector = createSelector(
  getLottoDrawsSelector,
  (lottoDraws) => lottoDraws && Object.keys(lottoDraws).length > 0
)

export const getCurrentLotteryPicksInfoByCode = createSelector(
  getUpcomingLottosSelector,
  getOwnPropsObject,
  (upGames, ownProps): CurrentLottoPick => {
    const closestDraw = getClosestDraw(ownProps.lotto)
    const currentPick = upGames?.find((it) => {
      if (ownProps.lotto.mode === ProductModes.FREE) {
        return it.product === ownProps.lotto.code && closestDraw?.id === it?.drawId
      } else {
        return (
          it.product === ownProps.lotto.code &&
          closestDraw?.id === it?.drawId &&
          it.currency === ownProps.activeCurrency
        )
      }
    })

    if (currentPick) {
      return {
        lines: currentPick.picks.map((it) => it.lines[0]),
        transactionId: currentPick.transactionId,
        drawId: currentPick.drawId,
        currency: currentPick.currency,
      }
    }

    return null
  }
)

export const getRawLottosSelector = createSelector(
  getCategorizedGames,
  getAllPlayedLottoCodesSelector,
  (allGames, playedLottoCodes) => {
    const lottos = allGames.lottery || []

    return lottos.map((i) => {
      if (playedLottoCodes.has(i.code)) {
        return i
      }

      // when user play lotto for first time - allow only 1 line
      return {
        ...i,
        maxLines: 1,
      }
    })
  }
)

export const getLottosWithDrawsSelector = createSelector(
  getRawLottosSelector,
  getLottoDrawsSelector,
  (lottos, draws: FormattedLottoDraws): LottoProduct[] => {
    // if no lottos or draws
    if (!draws) {
      return lottos
    }

    const data: LottoProduct[] = lottos.map((i) => {
      if (Object.prototype.hasOwnProperty.call(draws, i.code)) {
        const { draws: gettedDraws, ...rest } = draws[i.code]

        return {
          ...i,
          draws: gettedDraws,
          ...rest,
        }
      }

      return i
    })

    return data
  }
)

export const getAllGamesProductsSelector = createSelector(getCategorizedGames, (allGames) => {
  // @ts-ignore
  return Object.values(allGames).reduce((acc: GameProduct[], currentValue: GameProduct) => {
    if (!currentValue.hidden) {
      return acc.concat(currentValue)
    }

    return acc
  }, [])
})

export const getSlotProductsSelector = createSelector(getCategorizedGames, (allGames) =>
  allGames?.slots.filter((it) => !!it)
)

export const getRawTableProductsSelector = createSelector(getCategorizedGames, (allGames) => {
  if (!allGames) {
    return []
  }

  const tableSlots = allGames.slots.filter((i) => i.tags.includes('table'))

  return [...(allGames.table || []), ...(allGames.table_games || []), ...(tableSlots || [])]
})

export const getNewGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('new'))
)

export const getTopGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('top'))
)

export const getMegawaysGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('Meg'))
)
export const getClassicGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('Cla'))
)
export const getEpicWinsGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('Epi'))
)

export const getTopGamesByCountSelector = createSelector(
  getTopGamesSelector,
  (_: ApplicationState, numOfGames: number) => numOfGames,
  (games, numOfGames) => {
    const allTopGames = games.filter((it) => it.tags.includes('top'))
    const topGamesByCount = [...allTopGames.slice(0, numOfGames)]

    return topGamesByCount
  }
)

export const getProgJackpotsSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('prog_jackpot'))
)

export const getHoldAndWinGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('HAW'))
)

export const getHalloweenGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('HAL'))
)

export const getVisibleAndSortedLottosSelector = createSelector(
  getLottosWithDrawsSelector,
  isLoggedInSelector,
  (lottos, loggedIn) => {
    return compose(
      // hide free lottos for logged out users
      filterFreeLottos(loggedIn),
      // Sort game by closest draw
      sortLottosByClosestDraw()
    )(lottos as LottoProduct[])
  }
)

export const freeLottoAllowedSelector = createSelector(getVisibleAndSortedLottosSelector, (lottos) =>
  lottos.some((i) => i.mode === ProductModes.FREE)
)

export const getClossestLottoSelector = createSelector(getVisibleAndSortedLottosSelector, (lottos) => {
  if (lottos) {
    return lottos[0]
  }

  return null
})

export const getGamesForTournament = createSelector(
  getSlotProductsSelector,
  getOwnPropsObject,
  (games, { tournament }) => {
    const tournamentsRec = {
      thursday: [
        'magic-apple',
        'super-rich-god',
        'book-of-sun-multi-chance',
        'book-of-sun-choice',
        'sun-of-egypt',
        'sun-of-egypt-2',
        '15-dragon-pearls',
        'dragon-pearls',
        'aztec-sun',
        'scarab-temple',
      ],
      weekend: [
        'hot-triple-sevens',
        'cycle-of-luck',
        'unlimited-wishes',
        'fruit-super-nova',
        "indiana's-quest",
        'elven-princesses',
        'fruit-nova',
        'ice-mania',
      ],
      tuesday: [
        'tigers-gold',
        'great-panda',
        '777-gems-respin',
        'thunder-of-olympus',
        'super-marble',
        '15-golden-eggs',
        'buddha-fortune',
        'aztec-megaways-booongo',
      ],
      monday: [
        'mystic-fortune-deluxe',
        'calaveras-explosivas',
        'four-divine-beasts',
        'marvelous-furlongs',
        'return-to-feature',
        'orbs-of-atlantis',
        'totem-towers-habanero',
      ],
      halloween: games.filter((it) => it.tags.includes('HAL')).map((el) => el.code),
      fallbackfrenzy: [] as string[],
      secret_santa_giveaway: ['vs20xmascarol', 'santasstack', 'vs20santawonder', 'vs10bxmasbnza', 'freezing-classics'],
      back_to_the_classics: ['vs5joker', 'vs15diamond', 'hot-triple-sevens-special'],
    }

    return games.filter((i) => tournamentsRec[tournament as keyof typeof tournamentsRec].includes(i.code))
  }
)

export const getVisibleTableGamesSelector = createSelector(getRawTableProductsSelector, (games) => games || [])

export const isGamesLoadedSelector = createSelector(gamesIsLoaded, (loaded) => loaded)

export const getGameByIdSelector = createSelector(getAllGamesProductsSelector, getGameId, (games, gameId: string) => {
  return games?.find((it) => it.code === gameId)
})

export const getLottoGameByIdSelector = createSelector(
  getLottosWithDrawsSelector,
  getGameId,
  (games: LottoProduct[], gameId: string) => {
    return games?.find((it) => it.code === gameId)
  }
)

export const getUnlockedSlotsGamesSelector = createSelector(getSlotProductsSelector, (slots) => {
  if (slots) {
    return slots.filter((it) => it.unlocked)
  }

  return null
})

export const getFirstUnlockedSlotSelector = createSelector(getSlotProductsSelector, (games) => {
  if (games && games.length > 0) return games[0]

  return null
})
