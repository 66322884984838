import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from './styles'

function Loader(props: any) {
  // eslint-disable-next-line
  const { isLoading, pastDelay, timedOut, retry, ...restProps } = props
  const classes = useStyles(props)

  return (
    <div className={classes.loader}>
      <CircularProgress color='secondary' size={50} thickness={4} {...restProps} />
    </div>
  )
}

export default Loader
