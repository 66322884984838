import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import AppBar from '@material-ui/core/AppBar'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'

import More from './components/More'

import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import useStyles from './styles'
import mobileBottomNavigation from 'config/mobileBottomNavigation'

type Props = {}

const t = getDataFromJson(content)

function MenuMobile(props: Props) {
  const router = useRouter()
  const classes = useStyles(props)
  const mobileMenuLinks = mobileBottomNavigation()
  const activeCurrency = useSelector(getActiveCurrencyIdSelector)
  const selectedClass = classes[activeCurrency]

  return (
    <AppBar className={classes.root} color='default'>
      <BottomNavigation
        onChange={(e, newValue) => {
          // get the first link route array
          const route = mobileMenuLinks[newValue]?.path?.[0]

          if (route) {
            router.push(route).then(() => {
              window.scrollTo(0, 0)
            })
          }
        }}
        showLabels
        className={classes.root}
      >
        {mobileMenuLinks.map((i) => {
          const isActive = i.path && (i.path.includes(router.pathname) || i.path.includes(`${router.pathname}/`))

          return (
            <BottomNavigationAction
              label={i.label && t(i.label)}
              icon={(() => {
                const Icon = i.icon

                return <Icon />
              })()}
              classes={{ root: isActive && selectedClass }}
              key={i.id}
              className={classes.navigationButton}
            />
          )
        })}
        <More />
      </BottomNavigation>
    </AppBar>
  )
}

export default MenuMobile
