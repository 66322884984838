import { connect } from 'react-redux'
import { ApplicationState } from 'store/modules/types'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Divider from '@material-ui/core/Divider'
import Button from 'components/Button'
import { Menu, LoginVariant, Facebook, FrequentlyAskedQuestions, AccountMultiplePlusOutline } from 'mdi-material-ui'

import Drawer from '@material-ui/core/Drawer'
import MenuLink from 'containers/Menu/MenuLink'
import LogoLink from 'containers/LogoLink'

import { getdarkThemeSelector, isNativeAppSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { freeLottoAllowedSelector } from 'store/modules/games/selectors'
import { toggleDarkTheme } from 'store/modules/appConfig/actions'
import { openDialog } from 'store/modules/dialog/actions'
import { logOut } from 'store/modules/auth/actions'
import { connectFacebook } from 'services/facebook/actions'
import {
  isLoggedInSelector,
  getUserSubscriptionsSelector,
  isGuestModeSelector,
  facebookConnectedSelector,
} from 'store/modules/user/selectors'

import { OpenDialogAction, DialogNames } from 'store/modules/dialog/types'

import useStyles from './styles'
import mobileLinks from 'config/mobileLinks.json'
import getLinks from '../../../utils'
import AccountInfo from '../../../AccountInfo'
import MenuFooter from '../../../MenuFooter'

import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import { SubscriptionType } from 'store/modules/user/types'
import { useState, SyntheticEvent } from 'react'
import { useRouter } from 'next/router'
import ROUTES from 'config/routes.json'

const mapState = (state: ApplicationState) => ({
  isDarkTheme: getdarkThemeSelector(state),
  isLoggedIn: isLoggedInSelector(state),
  isNativeApp: isNativeAppSelector(state),
  vipSubscriptions: getUserSubscriptionsSelector(state),
  isGuest: isGuestModeSelector(state),
  freeLottoAllowed: freeLottoAllowedSelector(state),
  sweepstakeEnabled: sweepstakeEnabledSelector(state),
  facebookConnected: facebookConnectedSelector(state),
})
const mapDispatch = {
  toggleDarkTheme,
  logOut,
  openDialog,
  connectFacebook,
}

type Props = {
  logOut: () => ReturnType<typeof logOut>
  isLoggedIn: boolean
  isNativeApp: boolean
  openDialog: (modalName: DialogNames, arg2?: any) => OpenDialogAction
  vipSubscriptions: Array<SubscriptionType> | null
  isGuest: boolean
  freeLottoAllowed: boolean
  sweepstakeEnabled: boolean
  facebookConnected: boolean
  connectFacebook: typeof connectFacebook
}

const t = getDataFromJson(content)

function More(props: Props) {
  const classes = useStyles(props)
  const links = getLinks(mobileLinks)
  const [isOpen, toggleMenu] = useState(false)
  const router = useRouter()

  const toggleDrawer = (open: boolean) => (event: SyntheticEvent<any, Event> | KeyboardEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    toggleMenu(open)
  }

  return (
    <>
      <BottomNavigationAction
        label='More'
        icon={<Menu />}
        aria-haspopup='true'
        showLabel
        onClick={toggleDrawer(true)}
        className={classes.navigationButton}
      />

      <Drawer
        anchor='right'
        className={classes.root}
        open={isOpen}
        onClose={toggleDrawer(false)}
        classes={{ paperAnchorRight: classes.root }}
      >
        <div className={classes.menuItems}>
          <div className={classes.topContent}>
            <div className={classes.logoLink}>
              <LogoLink />
            </div>

            {props.isLoggedIn ? (
              <>
                <AccountInfo closeMenu={() => toggleMenu(false)} />
              </>
            ) : (
              !props.isNativeApp && (
                <Button
                  onClick={() => {
                    toggleMenu(false)
                    router.push(ROUTES.REGISTER)
                  }}
                  size='small'
                  classes={{ root: classes.button }}
                >
                  {t('Join now')}
                </Button>
              )
            )}
          </div>

          <Divider className={classes.divider} />

          {/* {props.isLoggedIn && (
          <MenuLink
            text='My account'
            link={ROUTES.MY_ACCOUNT}
            icon={AccountArrowLeftOutline}
            onClick={() => {
              toggleMenu(false)
            }}
          />
        )} */}
          <div className={classes.links}>
            {links.map((i) => (
              <MenuLink
                {...i}
                key={i.id}
                onClick={() => {
                  toggleMenu(false)
                }}
              />
            ))}
            {process.env.INVITE_FRIENDS_MOBILE && (
              <MenuLink
                text='Invite friends'
                icon={AccountMultiplePlusOutline}
                onClick={() => {
                  props.openDialog('INVITE_FRIENDS_DIALOG')
                }}
              />
            )}

            {process.env.FAQ_LINK && props.sweepstakeEnabled ? (
              <MenuLink text='FAQs' icon={FrequentlyAskedQuestions} link={process.env.FAQ_LINK} />
            ) : null}
            <>
              {props.isLoggedIn && !props.facebookConnected && (
                <MenuLink text='Connect Facebook' icon={Facebook} onClick={props.connectFacebook} />
              )}

              {props.isLoggedIn && (!props.isGuest || !props.isNativeApp) && (
                <>
                  <MenuLink
                    text='Logout'
                    icon={LoginVariant}
                    onClick={() => {
                      toggleMenu(false)
                      props.logOut()
                    }}
                  />
                </>
              )}
            </>
          </div>
          <MenuFooter />
        </div>
      </Drawer>
    </>
  )
}

export default connect(mapState, mapDispatch)(More)
