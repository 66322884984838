import cx from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import { ChevronLeft } from 'mdi-material-ui'
import useStyles from './styles'

type BackButtonType = {
  handler?: Function
  className?: string
  color?: 'white' | 'black'
}

export default function BackButton(props: BackButtonType) {
  const classes = useStyles(props)

  return (
    <IconButton
      className={cx(props.className, classes.root, classes[props?.color || 'black'])}
      onClick={() => {
        props.handler()
      }}
      data-test='modal-back-button'
    >
      <ChevronLeft className={classes.icon} />
    </IconButton>
  )
}
