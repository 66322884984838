import {
  ClearOffersAction,
  AddOffersAction,
  AddAppliedOffersAction,
  ShopActionTypes,
  ShopOffer,
  AppliedShopOffer,
  SetPurchaseLimitsAction,
  PurchaseLimits,
} from './types'
import { ThunkType } from 'store/modules/types'
import ROUTES from 'config/routes.json'
import Router from 'next/router'
import {
  getUserRestrictionsSelector,
  getUserXpLevelSelector,
  isLoggedInSelector,
  skipOtpSelector,
} from 'store/modules/user/selectors'
import { getAllSCDiscountOffersSelector, purchaseLimitsSelector } from 'store/modules/shop/selectors'
import { openDialog, replaceDialog } from 'store/modules/dialog/actions'
import { trackGAEvent, GAEvents } from 'src/utils/gtag'
import { getActiveModalCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { sweepstakeEnabledSelector } from 'src/store/modules/appConfig/selectors'

import { Currencies } from 'store/modules/currencies/types'
import { payWithGooglePay } from 'src/services/paymentSystem/actions'
import gatewayEmit from 'src/services/gateway/gatewayEmit'
import { GetOffersResponse } from 'src/services/websocket/types/response'
import { openSnackbar } from '../snackbar/actions'
import { sendRNevent } from 'src/utils/helpers'

export const clearProducts = (): ClearOffersAction => ({
  type: ShopActionTypes.CLEAR_OFFERS,
})

export const addOffersAction = (offers: Array<ShopOffer>): AddOffersAction => ({
  type: ShopActionTypes.ADD_OFFERS,
  payload: {
    offers,
  },
})

export const addAppliedOffers = (appliedOffers: Array<AppliedShopOffer>): AddAppliedOffersAction => ({
  type: ShopActionTypes.ADD_APPLIED_OFFERS,
  payload: {
    appliedOffers,
  },
})

export const setPurchaseLimits = (limits: PurchaseLimits | null): SetPurchaseLimitsAction => ({
  type: ShopActionTypes.SET_PURCHASE_LIMITS,
  payload: {
    limits,
  },
})

export const getOffers = (): ThunkType => (dispatch) => {
  gatewayEmit<GetOffersResponse>({ type: 'GetOffersRequest' })
    .then((body) => {
      dispatch(addOffersAction(body.offers))
      dispatch(addAppliedOffers(body.applied))

      if (body.limitAmount) {
        dispatch(
          setPurchaseLimits({
            limitAmount: body.limitAmount,
            limitAvailable: body.limitAvailable,
            limitEnd: body.limitEnd,
            limitPeriod: body.limitPeriod,
          })
        )
      }
    })
    .catch((error) => {
      dispatch(
        openSnackbar({
          message: error.status.errorText,
        })
      )

      sendRNevent({
        type: 'wsError',
        message: error.status.errorText,
        errorCode: error.status.errorCode,
      })
    })
}

// export const getVipSubscriptions = () => (dispatch: (arg: ReturnType<typeof WSinstance.emitWS>) => void) => {
//   dispatch(WSinstance.emitWS('GetOffersRequest', { offerType: OfferTypes.subscription }))
// }

export const openExtraSweepDialogIfPossible = (): ThunkType => (dispatch, getState) => {
  const bonusOffer = getAllSCDiscountOffersSelector(getState())
  const isSweepAvailable = sweepstakeEnabledSelector(getState())

  if (bonusOffer?.length && isSweepAvailable) {
    dispatch(replaceDialog('EXTRA_GOLD_DIALOG'))
  }
}

export const outOfMoneyHandler =
  (name: string, gameType: 'slots' | 'lottery', currency: Currencies): ThunkType =>
  (dispatch, getState) => {
    const bonusOffer = getAllSCDiscountOffersSelector(getState())
    if (bonusOffer && currency === 'GC') {
      dispatch(replaceDialog('EXTRA_GOLD_DIALOG'))
    }

    switch (gameType) {
      case 'slots': {
        const userLevel = getUserXpLevelSelector(getState())

        trackGAEvent(name, 'spin_out_of_balance', userLevel.level.toString())
        break
      }
      case 'lottery': {
        const activeCurrency = getActiveModalCurrencyIdSelector(getState())
        const userLevel = getUserXpLevelSelector(getState())

        trackGAEvent(
          name,
          `lotto_insufficient_funds_${activeCurrency}`.toLowerCase() as GAEvents,
          userLevel.level.toString()
        )
        break
      }
      default: {
        break
      }
    }
  }

export const openShopHandler =
  (isMobile: boolean, cb?: () => void): ThunkType =>
  (dispatch) => {
    trackGAEvent('clicked_on_shop_button', 'clicked_on_shop_button', 'clicked_on_shop_button')
    // open offer dialog after shop was opened
    const shopOpenedCallBack = () => {
      dispatch(openExtraSweepDialogIfPossible())
      cb?.()
    }

    if (isMobile) {
      dispatch(openDialog('SHOP_DIALOG', { shopOpenedCallBack }))
    } else {
      Router.push(ROUTES.SHOP).then(() => {
        shopOpenedCallBack()
      })
    }
  }

// const IS_OFFER_PROCESSING = false
export const buyShopOffer =
  (offer: ShopOffer): ThunkType =>
  (dispatch, getState) => {
    const purchaseLimits = purchaseLimitsSelector(getState())
    const isSkipOtp = skipOtpSelector(getState())
    const isLoggedIn = isLoggedInSelector(getState())
    const userRestrictions = getUserRestrictionsSelector(getState())
    // const kycRequired = isKYCRequiredSelector(getState())
    // const scEnabled = sweepstakeEnabledSelector(getState())
    // const isGuest = isGuestModeSelector(getState())

    //
    trackGAEvent('clicked_on_offer', 'clicked_on_offer', offer.code)

    if (!isLoggedIn) {
      Router.push(ROUTES.LOGIN)

      return null
    }
    if (!isSkipOtp) {
      dispatch(openDialog('PHONE_VERIFICATION_DIALOG'))

      return null
    }

    if (userRestrictions.includes('no_purchase')) {
      dispatch(openDialog('RESTRICT_USER_DIALOG'))

      return null
    }

    // check for purchase limits
    if (purchaseLimits && Number(purchaseLimits.limitAvailable) < offer.price) {
      dispatch(openDialog('PURCHASE_LIMIT_DIALOG', purchaseLimits))

      return null
    }

    // use google pay
    if (window.ReactNativeWebView) {
      payWithGooglePay(offer)
      // if (process.env.BRAND_NAME === 'onl' && isGuest) {
      //   payWithGooglePay(offer)
      // } else if (!IS_OFFER_PROCESSING) {
      //   IS_OFFER_PROCESSING = true
      //   dispatch(
      //     autoLoginInWebWithSCEnabled(() => {
      //       IS_OFFER_PROCESSING = false
      //     })
      //   )
      // }

      return null
    }

    // // pay with card
    // skrill pwmb
    dispatch(
      openDialog('PAYMENT_DIALOG', {
        code: offer.code,
      })
    )

    return null
  }
