import { ReactNode } from 'react'
import cx from 'classnames'

import Paper from '@material-ui/core/Paper'

import useStyles from './styles'

type PageProps = {
  children?: ReactNode
  maxWidth?: number
  className?: string
  rootClassName?: string
}

function Page(props: PageProps) {
  const classes = useStyles(props)

  return (
    <div
      className={cx(classes.root, props.rootClassName && props.rootClassName)}
      style={props.maxWidth && { maxWidth: `${props.maxWidth}px` }}
    >
      <Paper className={cx(classes.content, props.className)}>{props.children}</Paper>
    </div>
  )
}

export default Page
