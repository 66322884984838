import Link from 'next/link'

import ROUTES from 'config/routes.json'
import useStyles from './styles'

export default function LogoLink() {
  const classes = useStyles()
  const userId = typeof window !== 'undefined' && window.localStorage.getItem('USER_ID')

  return (
    <Link href={userId ? ROUTES.HOME_LOGGED_IN : ROUTES.HOME_LOGGED_OUT} prefetch={false}>
      <img
        className={classes.root}
        src={`${process.env.IMG_ORIGIN}/logos/logo_full_white_${process.env.BRAND_NAME}.svg`}
        alt='logo'
      />
    </Link>
  )
}
