import config from 'config/layoutConstants.json'
import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: {
      background: theme.palette.common.white,
      position: 'fixed',
      left: 0,
      width: '100%',
      top: config.headerHeight,
      zIndex: 2,
      borderBottom: 'none',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 14px 10px 0px rgba(0,0,0,0.12)',
    },
  },
  indicator: {
    background: theme.palette.defaultTheme.gradient,
  },
  tabButton: {
    maxWidth: 'none',
    fontSize: '0.875rem',
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 0,
    position: 'relative',
    display: 'block',
    '& span': {
      display: 'inline',
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      opacity: 0,
      transition: 'all .25s ease',
      background: theme.palette.defaultTheme.gradient,
      zIndex: -1,
    },
    '&:hover': {
      '&:after': {
        opacity: '0.25',
      },
      [theme.breakpoints.down('xs')]: {
        '&:after': {
          opacity: 0,
        },
      },
    },
  },
  selected: {
    '& span': {
      background: theme.palette.defaultTheme.gradient,
      '-webkit-mask-image': 'linear-gradient(blue, green)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      '&:hover': {
        '&:after': {
          opacity: '0',
        },
      },
    },
  },
}))
