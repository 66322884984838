import { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  initNativeApp,
  setAndroidDeviceId,
  setPushToken,
  setForcementMode,
  deeplinkModeHandler,
  saveGaidData,
  saveTrackingId,
} from 'store/modules/appConfig/actions'
import {
  setAuthLoading,
  socialSignInHandler,
  socialSignUpHandler,
  sendAndroidPushToken,
  login,
  resetPasswordAction,
} from 'store/modules/auth/actions'

import { openSnackbar } from 'store/modules/snackbar/actions'
import { confirmGooglePayOrder } from 'services/paymentSystem/actions'
import { openDialog } from 'store/modules/dialog/actions'
import { SocialAuthTypes, AuthViaSocialAPI } from 'store/modules/auth/types'

import { DialogNames, OpenDialogAction } from 'store/modules/dialog/types'
import { appStateActiveHandler } from 'services/websocket'
import { openVipPromoDialogAfterFirstAd } from 'src/services/ads'
import { adVideoClosedByUserInMiniGame, adVideoFinishedInMiniGame } from 'src/store/modules/miniGameFlow/actions'
import { LS_AUTH_TYPE, getItemFormLocalStorage } from 'utils/localStorage'

type Props = {
  initNativeApp: typeof initNativeApp
  setForcementMode: typeof setForcementMode
  socialSignInHandler: typeof socialSignInHandler
  openSnackbar: Function
  openDialog: (modalName: DialogNames, dialogProps?: any) => OpenDialogAction
  confirmGooglePayOrder: (
    token: string,
    offerCode: string,
    receiptSignature: string,
    osVersion: string,
    appVersion: string,
    deviceId: string,
    platform: string,
    receiptData: string
  ) => Function
  setAuthLoading: (loading: boolean) => ReturnType<typeof setAuthLoading>
  setAndroidDeviceId: (deviceId: string) => ReturnType<typeof setAndroidDeviceId>
  sendAndroidPushToken: (pushToken: string) => ReturnType<typeof sendAndroidPushToken>
  socialSignUpHandler: typeof socialSignUpHandler
  openVipPromoDialogAfterFirstAd: typeof openVipPromoDialogAfterFirstAd
  setPushToken: typeof setPushToken
  appStateActiveHandler: typeof appStateActiveHandler
  login: typeof login
  resetPasswordAction: typeof resetPasswordAction
  saveGaidData: typeof saveGaidData
  deeplinkModeHandler: typeof deeplinkModeHandler
  adVideoFinishedInMiniGame: typeof adVideoFinishedInMiniGame
  adVideoClosedByUserInMiniGame: typeof adVideoClosedByUserInMiniGame
  saveTrackingId: typeof saveTrackingId
}

const mapDispatch = {
  initNativeApp,
  setForcementMode,
  socialSignInHandler,
  socialSignUpHandler,
  openVipPromoDialogAfterFirstAd,
  openSnackbar,
  confirmGooglePayOrder,
  openDialog,
  setAuthLoading,
  setAndroidDeviceId,
  sendAndroidPushToken,
  setPushToken,
  appStateActiveHandler,
  login,
  resetPasswordAction,
  deeplinkModeHandler,
  saveGaidData,
  adVideoFinishedInMiniGame,
  adVideoClosedByUserInMiniGame,
  saveTrackingId,
}

function NativeBridge(props: Props): null {
  const handleEvents = (e: any) => {
    const event = e.detail

    // eslint-disable-next-line
    console.log('web', event)

    switch (event.type) {
      case 'ironSourceRewardedVideoClosedByUser':
      case 'ironSourceRewardedVideoAdRewarded': {
        // props.openVipPromoDialogAfterFirstAd()

        if (event.type === 'ironSourceRewardedVideoAdRewarded') {
          props.adVideoFinishedInMiniGame()
        } else {
          props.adVideoClosedByUserInMiniGame()
        }
        break
      }

      case 'IAPConfirmation': {
        setTimeout(() => {
          props.confirmGooglePayOrder(
            event.purchaseToken,
            event.productId,
            event.receiptSignature,
            event.osVersion,
            event.appVersion,
            event.deviceId,
            event.platform,
            event.receiptData
          )
        }, 1000)

        break
      }

      case 'changeAppState': {
        if (event.appState === 'active') {
          props.appStateActiveHandler()
        }
        break
      }

      case 'fbTokenEvent': {
        const reqData: AuthViaSocialAPI = { accessToken: event.token }
        const authTypeOld = getItemFormLocalStorage(LS_AUTH_TYPE, false)
        const authType = event.authType || authTypeOld

        switch (authType) {
          case SocialAuthTypes.LOGIN: {
            props.socialSignInHandler(reqData, 'Facebook', true)
            break
          }

          case SocialAuthTypes.REGISTRATION: {
            // props.socialSignUpHandler({ accessToken: reqData.accessToken }, 'Facebook')
            props.openDialog('AUTH_CONFIRM_DIALOG', {
              socialType: 'Facebook',
              token: reqData.accessToken,
              trackingId: event.trackingId,
            })
            break
          }

          default: {
            break
          }
        }

        break
      }
      case 'googleTokenEvent': {
        const reqData: AuthViaSocialAPI = { accessToken: event.token }

        const authTypeOld = getItemFormLocalStorage(LS_AUTH_TYPE, false)
        const authType = event.authType || authTypeOld

        switch (authType) {
          case SocialAuthTypes.LOGIN: {
            props.socialSignInHandler(reqData, 'Google', true)
            break
          }

          case SocialAuthTypes.REGISTRATION: {
            // props.socialSignUpHandler({ accessToken: reqData.accessToken }, 'Google')
            props.openDialog('AUTH_CONFIRM_DIALOG', {
              socialType: 'Google',
              token: event.token,
              trackingId: event.trackingId,
            })
            break
          }

          default:
            break
        }

        break
      }

      case 'loginManual': {
        props.login({ email: event.email, password: event.password }, null, true)
        break
      }

      case 'resetPassword': {
        props.resetPasswordAction({ email: event.email })
        break
      }

      case 'saveAndroidDeviceId': {
        props.setAndroidDeviceId(event.deviceId)
        break
      }

      case 'authEnded':
      case 'fbAuthCanceled': {
        props.setAuthLoading(false)
        break
      }

      case 'sendPushToken': {
        props.sendAndroidPushToken(event.pushToken)
        props.setPushToken(event.pushToken)
        break
      }

      case 'sendAdveritsingId': {
        props.saveGaidData(event.advertisingId, event.osVersion, event.appVersion)
        props.deeplinkModeHandler(event.advertisingId, event.osVersion, event.appVersion)
        break
      }

      case 'saveAppsflyerUID': {
        props.saveTrackingId(event.appsFlyerUID)
        break
      }

      default:
        // eslint-disable-next-line
        console.log('rn-to-web-event', event)
        break
    }
  }

  // -----------------------------------------
  useEffect(() => {
    const options = { no_journeys: false }

    if (window.ReactNativeWebView) {
      props.initNativeApp()
      // hide journey for webview
      options.no_journeys = true
    }

    try {
      document.addEventListener('rn-to-web-event', handleEvents, {
        passive: true,
        capture: false,
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error)
    }

    return function cleanup() {
      window.removeEventListener('rn-to-web-event', handleEvents)
    }
  }, [])

  return null
}

export default connect(null, mapDispatch)(NativeBridge)
