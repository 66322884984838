import { makeStyles, Theme } from '@material-ui/core/styles'
import layoutConfig from 'config/layoutConstants.json'

export default makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.defaultTheme.gradient,
    width: layoutConfig.mobileMenuHeight,
    height: layoutConfig.mobileMenuHeight,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: `-${layoutConfig.mobileMenuHeight / 2}px`,
  },
  animatedBg: {
    backgroundColor: 'transparent',
    animation: '$pulse 2s infinite',
    width: 56,
    height: 56,
    position: 'absolute',
    borderRadius: '50%',
    zIndex: -1,
  },
  disable: {
    animation: 'none',
    background: '#9e9e9e',
    cursor: 'disabled',
  },

  text: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 14,
  },

  smallText: {
    fontSize: 11,
    letterSpacing: 1,
  },

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      backgroundColor: 'transparent',
    },

    '70%': {
      transform: 'scale(1.25)',
      backgroundColor: 'rgba(254, 19, 156, 0.4)',
    },

    '100%': {
      transform: 'scale(1)',
      backgroundColor: 'transparent',
    },
  },
}))
