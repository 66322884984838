import {
  PrerequisitesActionTypes,
  PrerequisitesType,
  UpdatePrerequisitesAction,
  SetOpenPrerequisitedsAction,
} from './types'

export const prerequisitesChange = (prerequisites: PrerequisitesType | null): UpdatePrerequisitesAction => {
  return {
    type: PrerequisitesActionTypes.UPDATE_PREREQUISITES,
    payload: { prerequisites },
  }
}

export const setOpenPrerequisites = (prerequisites: PrerequisitesType | null): SetOpenPrerequisitedsAction => {
  return {
    type: PrerequisitesActionTypes.SET_OPEN_PREREQUISITES,
    payload: { prerequisites },
  }
}
