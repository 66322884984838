import { Reducer } from 'redux'
import { DialogState, DialogActionTypes, Actions } from './types'

const initialState: DialogState = {
  // last in first out
  stack: [],
}

const reducer: Reducer<DialogState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case DialogActionTypes.OPEN_DIALOG: {
      return {
        ...state,
        stack: state.stack.concat({
          modalName: action.payload.modalName,
          dialogProps: action.payload.dialogProps,
          options: action.payload.options,
          id: action.payload.id,
        }),
      }
    }

    case DialogActionTypes.REPLACE_DIALOG: {
      return {
        ...state,
        stack: action.payload.stack,
      }
    }

    case DialogActionTypes.REMOVE_DIALOG_BY_NAME: {
      return {
        ...state,
        stack: state.stack.filter((i) => {
          return i.modalName !== action.payload.modalName
        }),
      }
    }

    case DialogActionTypes.CLOSE_LATEST_DIALOG: {
      return {
        ...state,
        stack: state.stack.slice(0, -1),
      }
    }

    case DialogActionTypes.CLOSE_ALL: {
      return {
        ...state,
        stack: [],
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as dialogReducer }
