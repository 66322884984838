import { Reducer } from 'redux'
import { SlotGameFlowState, Actions, SlotGameFlowActionsType } from './types'

const initialState: SlotGameFlowState = {
  activeGameCode: null,
  gameBet: null,
  freeSpins: null,
}

const reducer: Reducer<SlotGameFlowState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case SlotGameFlowActionsType.SET_ACTIVE_GAME_CODE: {
      return {
        ...state,
        activeGameCode: action.payload.code,
      }
    }
    case SlotGameFlowActionsType.SET_GAME_BET: {
      return {
        ...state,
        gameBet: action.payload.gameBet,
      }
    }

    case SlotGameFlowActionsType.SAVE_FREE_SPIN_GAMES: {
      return {
        ...state,
        freeSpins: action.payload.freeSpins,
      }
    }

    case SlotGameFlowActionsType.CLEAR_SLOT_GAME_FLOW: {
      return initialState
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as slotGameFlowReducer }
