import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  headerLink: {
    textDecoration: 'none',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
}))
