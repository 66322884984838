export type DialogNames =
  | 'START_LOTTO_FLOW'
  | 'START_GAME_FLOW'
  | 'AD_VIDEO_DIALOG'
  | 'PAYMENT_DIALOG'
  | 'PAYMENT_CVV_DIALOG'
  | 'PAYMENT_ERROR_DIALOG'
  | 'SHOP_DIALOG'
  | 'ORDER_CONFIRMATION_MESSAGE'
  | 'LOTTO_CHOOSE_NUMBERS'
  | 'LOTTO_QUICK_PICK'
  | 'LOTTO_CONFIRM_DIALOG'
  | 'LOTTO_INFO_TABS_DIALOG'
  | 'PLAYED_LOTTO_TICKET'
  | 'LOTTO_COMPLETED_NOTIFICATION'
  | 'SCPR_AUTH_WAYS_DIALOG'
  | 'GUEST_CONFIRM_DIALOG'
  | 'CONNECT_ACCOUNT_DIALOG'
  | 'RESET_PASSWORD_DIALOG'
  | 'GAME_INFO_DIALOG'
  | 'SUBMIT_DOCUMENTS_DIALOG'
  | 'WITHDRAW_SWEEPSTSKE_DIALOG'
  | 'REDEEM_SUCCESSFUL_DIALOG'
  | 'NEW_USER_DIALOG'
  | 'FAQ_DIALOG'
  | 'AUTH_CONFIRM_DIALOG'
  | 'LEVEL_UP_NOTIFICATION'
  | 'HOW_TO_WIN_DIALOG'
  | 'VIP_REWARDS_DIALOG'
  | 'RATE_US_DIALOG'
  | 'INVITE_FRIENDS_DIALOG'
  | 'VIP_SUBSCRIPTION'
  | 'EXTRA_GOLD_DIALOG'
  | 'VIP_SUBSCRIPTION_PROMO_DIALOG'
  | 'VIP_SUBSCRIPTION_CONFIRM_DIALOG'
  | 'CURRENCY_SWITCH_QUICKSTART'
  | 'SC_PREVIEW_DIALOG'
  | 'DAILY_REWARD_DIALOG'
  | 'NOT_ENOUGH_FUNDS'
  | 'MINI_GAME_DIALOG'
  | 'MINI_GAME_WINNING_DIALOG'
  | 'WELCOME_EMAIL_SEND_DIALOG'
  | 'REWARD_DIALOG'
  | 'JUMIO_KYC_DIALOG'
  | 'LOCK_USER_DIALOG'
  | 'VERIFICATION_IN_PROGRESS_DIALOG'
  | 'WITHDRAW_DIALOG'
  | 'WITHDRAW_PROVIDERS_DIALOG'
  | 'WITHDRAW_CONFIRM_EMAIL_DIALOG'
  | 'SOFT_KYC_DIALOG'
  | 'PURCHASE_LIMIT_DIALOG'
  | 'SLOT_MAINTENANCE_DIALOG'
  | 'PHONE_VERIFICATION_DIALOG'
  | 'PRELOADER_DIALOG'
  | 'PHONE_VERIFICATION_STATUS_DIALOG'
  | 'PROMOTIONS_DIALOG'
  | 'GET_VERIFIED_DIALOG'
  | 'TERMS_MODAL'
  | 'RULES_UPDATED_DIALOG'
  | 'OTP_HELLO_AGAIN_DIALOG'
  | 'SC_PREVIEW_SESSION_START_DIALOG'
  | 'OTP_HELLO_AGAIN_DIALOG'
  | 'FREE_SPINS_DIALOG'
  | 'RESTRICT_USER_DIALOG'

export enum DialogActionTypes {
  OPEN_DIALOG = 'dialog/OPEN_DIALOG',
  REMOVE_DIALOG_BY_NAME = 'dialog/REMOVE_DIALOG_BY_NAME',
  CLOSE_LATEST_DIALOG = 'dialog/CLOSE_LATEST_DIALOG',
  CLOSE_ALL = 'dialogs/CLOSE_ALL',
  REPLACE_DIALOG = 'dialogs/REPLACE_DIALOG',
}

export type ModalOptions = {
  zIndex: number
}

export type ModalItem = {
  modalName: DialogNames
  dialogProps: any
  options: ModalOptions
  id: string
}

export interface OpenDialogAction {
  type: DialogActionTypes.OPEN_DIALOG
  payload: {
    modalName: DialogNames
    dialogProps?: any
    options?: ModalOptions
    id?: string
  }
}

export interface ReplaceDialogAction {
  type: DialogActionTypes.REPLACE_DIALOG
  payload: {
    stack: Array<ModalItem>
  }
}
export interface RemoveDialogByNameAction {
  type: DialogActionTypes.REMOVE_DIALOG_BY_NAME
  payload: {
    modalName: DialogNames
  }
}

export interface CloseLatestDialogAction {
  type: DialogActionTypes.CLOSE_LATEST_DIALOG
}

export interface CloseAllDialogsAction {
  type: DialogActionTypes.CLOSE_ALL
}

export type Actions =
  | OpenDialogAction
  | CloseLatestDialogAction
  | CloseAllDialogsAction
  | RemoveDialogByNameAction
  | ReplaceDialogAction

export interface DialogState {
  readonly stack: Array<ModalItem>
}
