import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    margin: '16px auto',
    padding: '0 16px',
    minHeight: '40vh',
    maxWidth: `calc(800px + ${theme.spacing(4)}px)`,
    // mobile
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  content: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    '&:nth-last-of-type(1)': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}))
