import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  white: {
    color: theme.palette.common.white,
  },
  black: {
    color: theme.palette.common.black,
  },
  icon: {
    fontSize: '1.75rem',
  },
}))
