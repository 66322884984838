// @ts-nocheck
import { createSelector } from 'reselect'
import { getUserSubscriptionsSelector } from 'store/modules/user/selectors'
import { getActiveModalCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { getActiveLotteryModeSelector, getCurrentLottoPicksLines } from 'store/modules/lottoGameFlow/selectors'
import { ProductModes } from 'store/modules/games/types'

// @TODO will fix this part without ts-ignore
// @ts-ignore
export const isAdsAvailableSelector = createSelector(
  getUserSubscriptionsSelector,
  getActiveModalCurrencyIdSelector,
  getCurrentLottoPicksLines,
  getActiveLotteryModeSelector,
  (subscription, activeModalCurrency, lottoPicks, gameMode) => {
    if (subscription) {
      return false
    }
    if (activeModalCurrency === 'SC' && gameMode === ProductModes.GOLD_SWEEPSTAKE) {
      return false
    }
    if (!lottoPicks?.lines || lottoPicks.lines?.length === 0) {
      return false
    }

    return true
  }
)
