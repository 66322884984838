import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    // mobile
    [theme.breakpoints.down('xs')]: {
      marginTop: 56,
      display: 'block',
    },
  },

  root: {
    display: 'flex',
    margin: '3rem auto 1rem',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  content: {
    padding: '1.5rem',
    textAlign: 'center',
    width: '100%',
    // mobile
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    height: 56,
    padding: '0 1rem',
  },
  logoutBtn: {
    minWidth: 90,
  },
}))
