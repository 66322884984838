import { combineReducers } from 'redux'

import { appConfigReducer } from './appConfig/reducer'
import { snackbarReducer } from './snackbar/reducer'
import { currenciesReducer } from './currencies/reducer'
import { userReducer } from './user/reducer'
import { gamesReducer } from './games/reducer'
import { playedGamesReducer } from './playedGames/reducer'
import { dialogReducer } from './dialog/reducer'
import { prerequisitesReducer } from './prerequisites/reducer'
import { shopReducer } from './shop/reducer'
import { lottoGameFlowReducer } from './lottoGameFlow/reducer'
import { slotGameFlowReducer } from './slotGameFlow/reducer'
import { authReducer } from './auth/reducer'
import { miniGameFlowReducer } from './miniGameFlow/reducer'
import { redeemReducer } from './redeem/reducer'
import { paymentsFlowReducer } from './paymentsFlow/reducer'
import { bonusesFlowReducer } from './bonusesFlow/reducer'
import { tempTokenReducer } from './tempToken/reducer'
import { phoneVerificationReducer } from './phoneVerification/reducer'

export default combineReducers({
  appConfig: appConfigReducer,
  snackbar: snackbarReducer,
  currencies: currenciesReducer,
  user: userReducer,
  games: gamesReducer,
  playedGames: playedGamesReducer,
  dialog: dialogReducer,
  prerequisites: prerequisitesReducer,
  shop: shopReducer,
  auth: authReducer,
  lottoGameFlow: lottoGameFlowReducer,
  slotGameFlow: slotGameFlowReducer,
  miniGameFlow: miniGameFlowReducer,
  redeem: redeemReducer,
  paymentsFlow: paymentsFlowReducer,
  bonusesFlow: bonusesFlowReducer,
  tempToken: tempTokenReducer,
  phoneVerification: phoneVerificationReducer,
})
