import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'classnames'
import { User } from 'store/modules/user/types'
import useStyles from './styles'
import { mapColorIndexByName } from './normalizers'

// static propTypes = {
//   userInfo: PropTypes.object,
//   className: PropTypes.any,
//   classes: PropTypes.object
// }

type UserAvatarProps = {
  userInfo: User
  avatarClass?: string
}

function UserAvatar(props: UserAvatarProps) {
  const classes = useStyles(props)
  const { userInfo, avatarClass } = props
  const { firstName, lastName, profilePicture, bot } = userInfo

  if (!userInfo) {
    return <CircularProgress className={classes.loader} />
  }

  return (
    <Avatar
      data-test='user-avatar'
      className={cx(
        classes.base,
        // @ts-ignore
        classes[`bg${mapColorIndexByName(lastName)}`],
        avatarClass
      )}
      src={bot ? `${process.env.IMG_ORIGIN}/bot.jpg` : profilePicture}
    >
      {`${firstName.slice(0, 1)}`}
    </Avatar>
  )
}

export default UserAvatar
