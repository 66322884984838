import { v4 as uuid } from 'uuid'
import { parseISO, isPast } from 'date-fns'
import Router from 'next/router'
import { WSinstance } from 'services/websocket'
import { ApplicationState, ThunkType } from 'store/modules/types'
import { openDialog, closeLatestDialog, replaceDialog, sessionDialogsResolver } from 'store/modules/dialog/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import {
  getArraysLimitersSelector,
  getActiveLotterySelector,
  getLottoClosestDrawSelector,
  getLottoLinesSelector,
  getMaxDrawLinesSelector,
  getCurrentLottoPicksLines,
  getActiveLottoRulesSelector,
  getCurrentTransactionId,
} from './selectors'
import {
  getLottosWithDrawsSelector,
  getCurrentLotteryPicksInfoByCode,
  getClossestLottoSelector,
} from 'store/modules/games/selectors'
import { getActiveModalCurrencyIdSelector, getFiatCurrencySelector } from 'store/modules/currencies/selectors'
import { generateRandomNumberInRange } from 'utils/numbers'
import { CurrencyToProductMode } from 'store/modules/currencies/utils'
import { Currencies } from 'store/modules/currencies/types'
import { showVideoAd } from 'services/ads'

import {
  AddNumbersAction,
  LottoArrayTypes,
  LottoGameFlowActionsType,
  ClearActiveLotteryAction,
  SetActiveLotteryAction,
  LottoNumbersHistoryType,
  SaveLottoNumbersHistory,
  AddLottoLineAction,
  UpdateLottoTicketDrawAction,
  LottoLine,
  LottoFlowTypes,
  SetIsSendingAction,
  ClearLottoLinesAction,
  ClearNumbersAction,
} from './types'
import { WSReponseErrorCodes } from 'services/websocket/types/types'
import { LottoProduct, DrawItem, ProductModes } from 'store/modules/games/types'

import { batch } from 'react-redux'
import {
  isLoggedInSelector,
  isGuestModeSelector,
  isPreviewModeSelector,
  skipOtpSelector,
} from 'store/modules/user/selectors'

import ROUTES from 'config/routes.json'
import { gamesRoutesGenerator } from 'src/utils/navigator'
import { OnAdEndCallbackType } from 'src/services/ads/types'

import { getDialogStackSelector } from '../dialog/selectors'
import { LottoPlayedGame } from '../playedGames/types'
import { updateGame } from '../playedGames/actions'
import { isAdsAvailableSelector } from 'src/services/ads/selectors'
import { outOfMoneyHandler } from '../shop/actions'
import { openPreviewModePromo } from '../appConfig/actions'
import { isScLottoGameFinished } from './utils'

export const addNumbers = (numbers: Array<LottoLine>): AddNumbersAction => {
  return {
    type: LottoGameFlowActionsType.ADD_NUMBERS,
    payload: {
      lines: numbers.map((it) => ({
        numbers: [...it.numbers],
        additionalNumbers: [...it.additionalNumbers],
      })),
    },
  }
}
export const setActiveLottery = (code: string | any, closestDraw?: DrawItem): SetActiveLotteryAction => ({
  type: LottoGameFlowActionsType.SET_ACTIVE_LOTTERY,
  payload: {
    activeLottery: code,
    closestDraw,
  },
})

export const clearLottoLines = (): ClearLottoLinesAction => ({
  type: LottoGameFlowActionsType.CLEAR_CURRENT_LOTTO_LINES,
})

export const clearLottoLineDispatchAction = (lines: Array<LottoLine>): ClearNumbersAction => ({
  type: LottoGameFlowActionsType.CLEAR_NUMBERS,
  payload: {
    lines,
  },
})

export const setLineIsSending = (isSending: boolean): SetIsSendingAction => {
  return {
    type: LottoGameFlowActionsType.LINE_IS_SENDING,
    payload: {
      isSending,
    },
  }
}

export const clearLottoLine =
  (id: number): ThunkType =>
  (dispatch, getState) => {
    const { lines } = getLottoLinesSelector(getState())

    const ln = lines.map((it, index) => {
      if (index === id) {
        return {
          numbers: [],
          additionalNumbers: [],
        }
      }

      return it
    })

    dispatch(clearLottoLineDispatchAction(ln))
  }

export const clearActiveLottery = (): ClearActiveLotteryAction => ({
  type: LottoGameFlowActionsType.CLEAR_ACTIVE_LOTTERY,
})

export const addLine = (): AddLottoLineAction => ({
  type: LottoGameFlowActionsType.ADD_LOTTO_LINE,
  payload: {
    line: { numbers: [], additionalNumbers: [] },
  },
})

export const removeLineById =
  (id: number): ThunkType =>
  (dispatch, getState) => {
    const { lines } = getLottoLinesSelector(getState())
    const ln = lines.filter((it, index) => index !== id)

    dispatch(clearLottoLineDispatchAction(ln))
  }

export const toggleNumber =
  (lineId: number | null, type: LottoArrayTypes, number: number): ThunkType =>
  (dispatch, getState) => {
    const lines: Array<LottoLine> = getLottoLinesSelector(getState())?.lines
    const limiters = {
      [LottoArrayTypes.NUMBERS]: getArraysLimitersSelector(getState()).maxNumbers,
      [LottoArrayTypes.BONUS_NUMBERS]: getArraysLimitersSelector(getState()).maxBonus,
    }

    if (lines) {
      if (lines[lineId][type].includes(number)) {
        lines[lineId][type] = lines[lineId][type].filter((it: number) => it !== number)
        dispatch(
          addNumbers(lines.map((it) => ({ numbers: [...it.numbers], additionalNumbers: [...it.additionalNumbers] })))
        )

        return
      }

      if (limiters[type] > lines[lineId][type].length) {
        lines[lineId][type] = [...lines[lineId][type], number]
        dispatch(
          addNumbers(lines.map((it) => ({ numbers: [...it.numbers], additionalNumbers: [...it.additionalNumbers] })))
        )
      }
    }
  }

function generateRandomArrayNumbers(min: number, max: number, length: number): Array<number> {
  const result: Array<number> = []

  while (result.length !== length) {
    const randomNumber = generateRandomNumberInRange(min, max)

    if (!result.includes(randomNumber)) {
      result.push(randomNumber)
    }
  }

  return result
}

export const generateRandomNumbers =
  (ticketId: number | undefined): ThunkType =>
  (dispatch, getState) => {
    const rules = getActiveLottoRulesSelector(getState())
    const lines = getLottoLinesSelector(getState())?.lines
    if (lines) {
      if (ticketId !== null || ticketId !== undefined) {
        lines[ticketId] = {
          numbers: generateRandomArrayNumbers(1, rules.maxNumbersArrayLength, rules.maxNumbers),
          additionalNumbers: generateRandomArrayNumbers(1, rules.maxBonusNumbersArrayLength, rules.maxBonus),
        }

        dispatch(addNumbers(lines))
      }
    }
  }

export const setActiveClosestLottery =
  (lotto: LottoProduct): ThunkType =>
  (dispatch) => {
    const closestActiveDraw = lotto.draws?.find((i) => !isPast(parseISO(i.closingDate)))

    return dispatch(setActiveLottery(lotto.code, closestActiveDraw))
  }

export const getLottoNumberHistory =
  (code: string): ThunkType =>
  (dispatch) => {
    dispatch(WSinstance.emitWS({ type: 'GetLottoNumbersRequest', product: code }))
  }

export const saveLottoNumbersHistory = (numbers: Array<LottoNumbersHistoryType>): SaveLottoNumbersHistory => ({
  type: LottoGameFlowActionsType.SAVE_LOTTO_NUMBERS_HISTORY,
  payload: numbers,
})

export const updatedLottoTicketDrawAction = (transactionId: string): UpdateLottoTicketDrawAction => ({
  type: LottoGameFlowActionsType.UPDATE_LOTTO_TICKET_DRAW_ACTION,
  payload: {
    transactionId,
    lines: [
      {
        numbers: [],
        additionalNumbers: [],
      },
    ],
  },
})

export const setActiveLotteryByCode =
  (code: string): ThunkType =>
  (dispatch, getState) => {
    const lotteries = getLottosWithDrawsSelector(getState()) as LottoProduct[]
    const lottery = lotteries.find((it) => it.code === code)

    if (lottery) {
      dispatch(setActiveClosestLottery(lottery))
    }
  }

export const addLineWithRandomNumbers = (): ThunkType => (dispatch, getState) => {
  const maxLines = getMaxDrawLinesSelector(getState())
  const currentLines = getLottoLinesSelector(getState())
  if (currentLines?.lines.length < maxLines) {
    dispatch(addLine())
    dispatch(generateRandomNumbers(currentLines?.lines?.length))
  }
  if (!currentLines?.lines) {
    dispatch(addLine())
    dispatch(generateRandomNumbers(0))
  }
}

export const fetchCurrentLottoLines = (): ThunkType => (dispatch, getState) => {
  const lottoPick = getCurrentLottoPicksLines(getState())
  // set active lottery with numbers if we have, or add new empty line
  if (lottoPick.lines) {
    dispatch({
      type: LottoGameFlowActionsType.SET_CURRENT_LOTTO_LINES,
      payload: {
        ...lottoPick,
      },
    })
  }
}

export const updateLottoTicketDraw = (): ThunkType => (dispatch) => {
  const transactionId = uuid()
  dispatch(updatedLottoTicketDrawAction(transactionId))
  dispatch(fetchCurrentLottoLines())
}

export const addLineIfPossible = (): ThunkType => (dispatch, getState) => {
  const currentLines = getLottoLinesSelector(getState())
  const maxLines = getMaxDrawLinesSelector(getState())
  if (currentLines?.lines.length < maxLines) {
    dispatch(addLine())
  }
  if (!currentLines?.lines) {
    dispatch(addLine())
  }
}

export const startLottoGame =
  (game: LottoProduct): ThunkType =>
  (dispatch) => {
    batch(() => {
      dispatch(
        openDialog('START_LOTTO_FLOW', {
          game,
          gameCode: game.code,
        })
      )
    })
  }

// desktop flow start =======================================

export const redirectToLottoGamePage = (getState: () => ApplicationState) => {
  const currentLotto = getActiveLotterySelector(getState())
  const currentCurrency = getActiveModalCurrencyIdSelector(getState())
  const currentUrl = currentCurrency === 'SC' ? ROUTES.LOTTERY_PLAY_WITH_CURRENCY : ROUTES.LOTTERY_PLAY

  return Router.push(
    currentUrl,
    gamesRoutesGenerator[currentUrl](currentLotto.title, CurrencyToProductMode[currentCurrency])
  )
}
export const gameLottoDesktopFlow =
  (gameMode: LottoFlowTypes): ThunkType =>
  (dispatch, getState) => {
    const gameStartModeActionRecord = {
      quickpick: () => dispatch(addLineWithRandomNumbers()),
      selectNumbers: () => dispatch(addLine()),
    }

    // fetching data for active lotto
    dispatch(clearLottoLines())
    dispatch(closeLatestDialog())
    dispatch(fetchCurrentLottoLines())

    redirectToLottoGamePage(getState).then(() => gameStartModeActionRecord[gameMode]())
  }

// mobile game flow ================================
export const startSelectNumbersLottoMobileFLow = (): ThunkType => (dispatch) => {
  dispatch(addLineIfPossible())
  dispatch(replaceDialog('LOTTO_CHOOSE_NUMBERS'))
}
export const startSelectRandomNumbersLottoMobileFlow = (): ThunkType => (dispatch, getState) => {
  const currentLines = getLottoLinesSelector(getState())
  dispatch(addLineIfPossible())
  dispatch(generateRandomNumbers(currentLines?.lines?.length || 0))
  dispatch(openDialog('LOTTO_CHOOSE_NUMBERS'))
}

export const startLottoGameMobileFlow =
  (gameMode: LottoFlowTypes): ThunkType =>
  (dispatch) => {
    dispatch(clearLottoLines())
    dispatch(fetchCurrentLottoLines())

    const startGameActionsRecord = {
      selectNumbers: () => dispatch(startSelectNumbersLottoMobileFLow()),
      quickpick: () => dispatch(startSelectRandomNumbersLottoMobileFlow()),
    }
    startGameActionsRecord[gameMode]()
  }

// common ================== game flow
export const startLottoGameFlow =
  (isMobile: boolean, gameMode: LottoFlowTypes): ThunkType =>
  (dispatch, getState): null => {
    const activeModalCurrenncy = getActiveModalCurrencyIdSelector(getState())
    const isPreviewMode = isPreviewModeSelector(getState()) && activeModalCurrenncy === 'SC'
    const isGuest = isGuestModeSelector(getState())
    const isLoggedIn = isLoggedInSelector(getState())
    const isSkipOtp = skipOtpSelector(getState())
    const activeLottery = getActiveLotterySelector(getState())

    if (isPreviewMode && isGuest) {
      dispatch(openDialog('SCPR_AUTH_WAYS_DIALOG'))

      return null
    }

    if (isPreviewMode) {
      dispatch(openPreviewModePromo('START_LOTTO_FLOW', activeLottery.code))

      return null
    }

    // check for phone number only for sc games
    if (isLoggedIn && !isSkipOtp && activeModalCurrenncy === 'SC') {
      dispatch(closeLatestDialog())
      dispatch(openDialog('PHONE_VERIFICATION_DIALOG'))

      return null
    }

    if (isMobile) {
      if (isLoggedIn) {
        dispatch(startLottoGameMobileFlow(gameMode))
      } else {
        Router.push(ROUTES.REGISTER)
      }
    } else {
      // desktop flow
      dispatch(gameLottoDesktopFlow(gameMode))
    }

    return null
  }

export const sendLotteryNumbers = (): ThunkType => (dispatch, getState) => {
  const lottery = getActiveLotterySelector(getState())
  const fiatCurrency = getFiatCurrencySelector(getState())
  const lottoLines = getLottoLinesSelector(getState())
  const transactionId = getCurrentTransactionId(getState())
  const currentCurrency = getActiveModalCurrencyIdSelector(getState())
  const closestDraw = getLottoClosestDrawSelector(getState())
  const isGameClosed = isPast(parseISO(closestDraw?.closingDate))

  if (isGameClosed) {
    dispatch(
      openSnackbar({
        message: 'This draw finished. Please submit numbers again for the updated next upcoming draw.',
        variant: 'error',
        action: {
          text: 'Refresh',
          action: () => {
            dispatch(setActiveClosestLottery(lottery))
            dispatch(updateLottoTicketDraw())
          },
          buttonOptions: {
            variant: 'outlined',
          },
        },
        autoHide: null,
      })
    )
  } else {
    const currency = lottery.mode === ProductModes.FREE ? fiatCurrency : currentCurrency

    dispatch(setLineIsSending(true))

    dispatch(
      WSinstance.emitWS({
        type: 'PostLottoPicksRequest',
        drawId: closestDraw.id,
        transactionId: transactionId || uuid(), // send transaction id old lottery or new id
        lines: [...lottoLines.lines],
        currency,
      })
    )
  }
}

export const preSubmitNumbers =
  (type: OnAdEndCallbackType): ThunkType =>
  (dispatch, getState) => {
    const isLoggedIn = isLoggedInSelector(getState())
    const isAdsAvailable = isAdsAvailableSelector(getState())

    if (isLoggedIn) {
      dispatch(sendLotteryNumbers())

      if (isAdsAvailable) {
        dispatch(showVideoAd(type))
      }
    } else {
      Router.push(ROUTES.REGISTER)
    }
  }

// new lottery flow

export const startLottoGameFormPage =
  (currency: Currencies): ThunkType =>
  (dispatch, getState) => {
    const activeLottery = getActiveLotterySelector(getState())
    const lottoPick = getCurrentLotteryPicksInfoByCode(getState(), { lotto: activeLottery, activeCurrency: currency })
    const currentLines = getLottoLinesSelector(getState())
    // set active lottery with numbers if we have, or add new empty line
    if (!currentLines?.lines && lottoPick) {
      dispatch({
        type: LottoGameFlowActionsType.SET_CURRENT_LOTTO_LINES,
        payload: {
          ...lottoPick,
        },
      })
    }
    if (!currentLines?.lines) {
      dispatch(addLineIfPossible())
    }
  }

export const startClosesDrawLottery =
  (location: string): ThunkType =>
  (dispatch, getState) => {
    const dialogStack = getDialogStackSelector(getState())
    // alexander+test_set_lotto1@quizbeat.com
    if (location === ROUTES.HOME_LOGGED_OUT && !dialogStack?.find((it) => it.modalName === 'START_LOTTO_FLOW')) {
      const closestLotto = getClossestLottoSelector(getState())
      dispatch(startLottoGame(closestLotto))
    }
  }

export const setNextLottery =
  (lotto: LottoProduct): ThunkType =>
  (dispatch) => {
    const closestActiveDraw = lotto.draws?.find((i) => !isPast(parseISO(i.closingDate)))
    batch(() => {
      dispatch(setActiveLottery(lotto.code, closestActiveDraw))
      dispatch(startLottoGameMobileFlow('selectNumbers'))
    })
  }

export const openViewResultsDialog =
  (game: LottoPlayedGame | Array<LottoPlayedGame>): ThunkType =>
  (dispatch) => {
    // don't show any dialogs on 'my-games' page
    if (!Router.pathname.includes('my-games')) {
      dispatch(
        sessionDialogsResolver('LOTTO_COMPLETED_NOTIFICATION', {
          isScLottoGameFinished: isScLottoGameFinished(game),
        })
      )
    }
  }

export const lottoPickRequsetErrorHandler =
  (errorCode: WSReponseErrorCodes, body: LottoPlayedGame & { type: string }): ThunkType =>
  (dispatch) => {
    dispatch(setLineIsSending(false))
    switch (errorCode) {
      case 'err_insufficient_funds': {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, ...rest } = body
        if (rest?.transactionId) {
          dispatch(updateGame(rest))
          dispatch(outOfMoneyHandler(rest.product, 'lottery', body.currency))
        }
        break
      }
      default: {
        break
      }
    }
  }
