import { useDispatch, useSelector } from 'react-redux'
import { removeDialogByName } from 'store/modules/dialog/actions'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'

export default function IgnoreRoutes(): null {
  const dispatch = useDispatch()
  const dialogStack = useSelector(getDialogStackSelector)

  const isOpened = dialogStack.find((i) => i.modalName === 'TERMS_MODAL')

  //   // close Terms dialog on SC rules and Terms pages
  if (isOpened) {
    dispatch(removeDialogByName('TERMS_MODAL'))

    return null
  }

  return null
}
