import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    transition: 'all .5s ease',
    // display: 'flex',
    alignItems: 'center',
    transform: 'translateY(0)',
  },
  amount: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginRight: 5,
    transition: 'all .5s ease',
    opacity: 1,
  },
  icon: {
    height: 15,
    marginRight: theme.spacing(1) / 2,
  },
  animate: {
    transform: 'translateY(-120%)',
  },
  hide: {
    opacity: 0,
  },
  button: {
    position: 'absolute',
    opacity: 0,
    transition: 'all .5s ease',
    color: '#fff',
    textAlign: 'center',
    // background: '#fff',
    width: '100%',
  },
  showButton: {
    opacity: 1,
  },
}))
