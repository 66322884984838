import Head from 'next/head'
import { useEffect } from 'react'

const removeIframes = () => {
  const timerIdccButton = setInterval(() => {
    if (document.getElementById('cc-button')) {
      document.getElementById('cc-button')?.remove()
      clearInterval(timerIdccButton)
    }
  }, 100)
  const timerIdccWindow = setInterval(() => {
    if (document.getElementById('cc-window')) {
      document.getElementById('cc-window')?.remove()
      clearInterval(timerIdccWindow)
    }
  }, 100)
}

const ZendeskChatRoot = () => {
  useEffect(() => {
    return () => {
      removeIframes()
    }
  }, [])

  return (
    <>
      <Head>
        <style
          // eslint-disable-next-line
        dangerouslySetInnerHTML={{
            __html: `
          @media screen and (max-width: 870px) {
            #cc-button {
              bottom: 57px !important;
            }
          }
          `,
          }}
        />

        <script
          id='ze-snippet'
          src='https://static.zdassets.com/ekr/snippet.js?key=b0c49686-5da8-41dc-a1a0-e4e472f6fd55'
        />

        <script
          id='cc-snippet'
          src={`https://chatcreate.com/api/bots/${process.env.ZENDESK_APP_ID}/zendesk/snippet`}
          defer
        />
      </Head>
    </>
  )
}

export default ZendeskChatRoot
