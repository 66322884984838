import { Theme, makeStyles } from '@material-ui/core/styles'
import layoutConfig from 'config/layoutConstants.json'

export default makeStyles((theme: Theme) => ({
  '@global': {
    html: {
      '--scroll': '0px',
    },
    body: {
      // @ts-ignore
      backgroundColor: theme.palette.background.page,
    },
    '*': {
      '-webkit-tap-highlight-color': 'transparent',
    },
  },

  menuDesktop: {
    // @ts-ignore
    background: theme.palette.primary.background,
    position: 'fixed',
    width: layoutConfig.desktopMenuWidth,
    height: '100%',
    left: '0',
    paddingTop: layoutConfig.headerHeight,
    boxShadow: theme.shadows[4],
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    // mobile
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  content: {
    minHeight: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    paddingTop: layoutConfig.headerHeight,
    height: '100%',
    overflow: 'hidden',
    // desktop
    [theme.breakpoints.up('sm')]: {
      marginLeft: layoutConfig.desktopMenuWidth,
    },
    // mobile
    [theme.breakpoints.down('sm')]: {
      paddingBottom: layoutConfig.mobileMenuHeight,
      // fix sizes for ios with notch
      // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
      '@supports (padding-top: constant(safe-area-inset-bottom))': {
        '--safe-area-inset-bottom': 'constant(safe-area-inset-bottom)',
        paddingBottom: `calc(${layoutConfig.mobileMenuHeight}px + var(--safe-area-inset-bottom))`,
      },

      // Browsers which fully support CSS Environment variables (iOS 11.2+).
      '@supports (padding-top: env(safe-area-inset-bottom))': {
        '--safe-area-inset-bottom': 'env(safe-area-inset-bottom)',
        paddingBottom: `calc(${layoutConfig.mobileMenuHeight}px + var(--safe-area-inset-bottom))`,
      },
    },
  },
}))
