import { useEffect, useState } from 'react'

export default function useIsReactNative() {
  const [state, setState] = useState(false)

  useEffect(() => {
    setState(typeof window !== 'undefined' && Boolean(window.ReactNativeWebView))
  }, [])

  return state
}
