import { Reducer } from 'redux'
import { LottoGameFlowState, Actions, LottoGameFlowActionsType } from './types'

const initialState: LottoGameFlowState = {
  activeLottery: null,

  closestDraw: null,
  lottoNumbersHistory: null,
  lottoLines: null,
  isSending: null,
}

const reducer: Reducer<LottoGameFlowState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case LottoGameFlowActionsType.ADD_NUMBERS: {
      return {
        ...state,
        lottoLines: {
          ...state.lottoLines,
          lines: action.payload.lines,
        },
      }
    }
    case LottoGameFlowActionsType.ADD_LOTTO_LINE: {
      return {
        ...state,
        lottoLines: {
          ...state.lottoLines,
          lines: state.lottoLines ? [...state.lottoLines.lines, action.payload.line] : [action.payload.line],
        },
      }
    }

    case LottoGameFlowActionsType.ADD_LOTTO_LINES: {
      return {
        ...state,
        lottoLines: {
          ...state.lottoLines,
          lines: action.payload.lines,
        },
      }
    }

    case LottoGameFlowActionsType.CLEAR_ACTIVE_LOTTERY: {
      return {
        ...state,
        activeLottery: null,
        closestDraw: null,
      }
    }
    case LottoGameFlowActionsType.SET_ACTIVE_LOTTERY: {
      return {
        ...state,
        activeLottery: action.payload.activeLottery,
        closestDraw: action.payload.closestDraw,
      }
    }

    case LottoGameFlowActionsType.LINE_IS_SENDING: {
      return {
        ...state,
        isSending: action.payload.isSending,
      }
    }
    // clear lotto line ticket
    case LottoGameFlowActionsType.CLEAR_NUMBERS: {
      return {
        ...state,
        lottoLines: {
          ...state.lottoLines,
          lines: action.payload.lines,
        },
      }
    }

    // clear all lines
    case LottoGameFlowActionsType.CLEAR_CURRENT_LOTTO_LINES: {
      return {
        ...state,
        lottoLines: null,
      }
    }

    case LottoGameFlowActionsType.SAVE_LOTTO_NUMBERS_HISTORY: {
      return {
        ...state,
        lottoNumbersHistory: action.payload,
      }
    }

    // set picks form server
    case LottoGameFlowActionsType.SET_CURRENT_LOTTO_LINES: {
      return {
        ...state,
        lottoLines: {
          ...state.lottoLines,
          lines: action.payload.lines,
          transactionId: action.payload.transactionId,
        },
      }
    }

    case LottoGameFlowActionsType.UPDATE_LOTTO_TICKET_DRAW_ACTION: {
      return {
        ...state,
        lottoLines: {
          ...state.lottoLines,
          transactionId: action.payload.transactionId,
          lines: action.payload.lines,
        },
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as lottoGameFlowReducer }
