import { sendRNevent } from 'utils/helpers'
import { ShopOffer } from 'store/modules/shop/types'

export type GAEvents =
  | 'sign_up'
  | 'login'
  | 'country_ip_denied'
  | 'spin_out_of_balance'
  | 'lotto_insufficient_funds_gc'
  | 'lotto_insufficient_funds_sc'
  | 'lotto_submitted_gc'
  | 'lotto_submitted_sc'
  | 'lotto_submitted_usd'
  | 'played_slot_game_gc'
  | 'played_slot_game_sc'
  | 'level_up'
  | 'socket_connect'
  | 'socket_disconnect'
  | 'socket_response_success'
  | 'socket_response_error'
  | 'socket_notify'
  | 'socket_error'
  | 'socket_connect_error'
  | 'socket_connect_timeout'
  | 'socket_reconnect_error'
  | 'socket_reconnect_failed'
  | 'socket_reconnect'
  | 'set_attribution'
  | 'go_to_web'
  | 'currency_quickstart_accepted'
  | 'switch_currency'
  | 'sc_daily_bonus_accepted'
  | 'welcome_email_submit'
  | 'prizeout_widget_started'
  | 'prizeout_widget_closed'
  | 'prizeout_cashout_failed'
  | 'prizeout_cashout_completed'
  | 'soft_kyc_open'
  | 'soft_kyc_submit'
  | 'soft_kyc_close'
  | 'phone_verification_open'
  | 'phone_verification_confirm_code'
  | 'phone_verification_success'
  | 'phone_verification_failed'
  | 'login_google_auth_fb_iOS_error'
  | 'registration_google_auth_fb_iOS_error'
  | 'clicked_on_shop_button'
  | 'clicked_on_offer'
  | 'submitted_payment_success'
  | 'submitted_payment_error'
  | 'purchase_vip_level'
  | 'jumio_kyc_prompt'
  | 'phone_duplicate'

const firebaseEvents = (eventName: GAEvents, category: string, label: string) => {
  const fbAppEvents: Record<GAEvents, Record<string, string>> = {
    sign_up: {
      method: label,
    },
    login: {
      method: label,
    },
    country_ip_denied: {
      error_code: category,
    },
    spin_out_of_balance: {
      slot_name: category,
      user_level: label,
    },
    lotto_insufficient_funds_gc: {
      lotto_name: category,
      user_level: label,
    },
    lotto_insufficient_funds_sc: {
      lotto_name: category,
      user_level: label,
    },
    lotto_submitted_gc: {
      lotto_name: category,
      lotto_line_info: label,
    },
    lotto_submitted_sc: {
      lotto_name: category,
      lotto_line_info: label,
    },
    lotto_submitted_usd: {
      lotto_name: category,
      lotto_line_info: label,
    },
    played_slot_game_gc: {
      slot_name: category,
    },
    played_slot_game_sc: {
      slot_name: category,
    },
    level_up: {
      level: label,
      character: category,
    },
    socket_connect: {
      isConnect: 'true',
    },
    socket_disconnect: {
      reason: category,
    },
    socket_response_success: {
      respType: category,
    },
    socket_response_error: {
      errCode: category,
    },
    socket_notify: {
      notifyType: category,
    },
    socket_error: {
      message: category,
    },
    socket_connect_error: {
      message: category,
    },
    socket_connect_timeout: {
      timeot: category,
    },
    socket_reconnect_error: {
      message: category,
    },
    socket_reconnect_failed: {
      message: 'reconnect_failed',
    },
    socket_reconnect: {
      attempt: category,
    },
    set_attribution: {
      mode: label,
    },
    go_to_web: {
      method: category,
    },
    currency_quickstart_accepted: {
      method: category,
    },
    switch_currency: {
      currency: label,
    },
    sc_daily_bonus_accepted: {
      dayStreak: label,
    },
    welcome_email_submit: {
      method: label,
    },
    prizeout_widget_started: {
      brand: label,
    },
    prizeout_widget_closed: {
      brand: label,
    },
    prizeout_cashout_failed: {
      brand: label,
    },
    prizeout_cashout_completed: {
      brand: label,
    },
    soft_kyc_open: {
      brand: label,
    },
    soft_kyc_submit: {
      brand: label,
    },
    soft_kyc_close: {
      brand: label,
    },
    phone_verification_open: {
      brand: label,
    },
    phone_verification_confirm_code: {
      brand: label,
    },
    phone_verification_success: {
      label,
    },
    phone_verification_failed: {
      label,
    },
    login_google_auth_fb_iOS_error: {
      message: category,
    },
    registration_google_auth_fb_iOS_error: {
      message: category,
    },
    clicked_on_shop_button: {
      label,
    },
    clicked_on_offer: {
      offer: label,
    },
    submitted_payment_success: {
      provider: category,
      offer: label,
    },
    submitted_payment_error: {
      call: category,
      reason: label,
    },
    purchase_vip_level: {
      level: category,
    },
    jumio_kyc_prompt: {
      label,
    },
    phone_duplicate: {
      label,
    },
  }

  if (Object.prototype.hasOwnProperty.call(fbAppEvents, eventName)) {
    return {
      event: eventName,
      ...fbAppEvents[eventName],
    }
  } else {
    // eslint-disable-next-line
    console.log('fb event not specified')

    return null
  }
}

export function trackGAEvent(category: string, event: GAEvents, label: string) {
  // send event to ga web+app only from android
  if (window.ReactNativeWebView) {
    const fbaEvent = firebaseEvents(event, category, label)

    if (fbaEvent) {
      sendRNevent({
        type: 'gaEvent',
        ...fbaEvent,
      })
    } else {
      sendRNevent({
        type: 'gaEvent',
        event,
        category,
        label,
      })
    }
  } else {
    // send event to ga web
    try {
      window?.dataLayer.push({ event, label, category })
    } catch (e) {
      // eslint-disable-next-line no-console
      // console.warn(`Google analytics error: ${e}`)
    }
  }
}

export function gaSetUserId(userId?: string) {
  try {
    window?.dataLayer.push({ event: 'set_user_id', user_id: userId })
  } catch (err) {
    // eslint-disable-next-line
    //console.warn(`Google analytics userId send:${err}`)
  }
}

export function trackScreenViewEvent(name: string) {
  try {
    if (window.ReactNativeWebView) {
      sendRNevent({
        type: 'screenChange',
        name,
      })
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.warn(`Google analytics error: ${e}`)
  }
}
// // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// export const pageview = (url: string) => {
//   window.gtag('config', GA_TRACKING_ID, {
//     page_path: url
//   })
// }

export const tagManagerEcommerceEvent = (transactionId: string, offer: Partial<ShopOffer>, provider: string) => {
  window.dataLayer?.push({
    event: 'purchase',
    transactionId,
    transactionAffiliation: provider,
    transactionTotal: offer.price,
    transactionTax: 0,
    transactionShipping: 0,
    transactionProducts: [
      {
        sku: offer.code,
        name: offer.title,
        category: `Purchase $${offer.price}`,
        price: offer.price,
        quantity: 1,
      },
    ],
  })
}

export const requestErrorsGAHandler = (errCode: 'err_denied') => {
  switch (errCode) {
    case 'err_denied': {
      trackGAEvent('err_denied', 'country_ip_denied', 'err_denied')
      break
    }
    default: {
      break
    }
  }
}
