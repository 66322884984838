import { createMuiTheme } from '@material-ui/core/styles'
import baseTheme from './baseTheme'

const lightTheme = createMuiTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#0084CE',
      // @ts-ignore
      background: '#fff',
      //
      coinColor: '#ff9800',
      sweepColor: '#00ad45',
    },
    secondary: {
      main: '#00ad45',
      light: '#04cc53',
      dark: '#00963c',
    },
    background: {
      // @ts-ignore
      page: '#f2f3f5',
    },
    sweepCoinTheme: {
      gradient: 'linear-gradient(-135deg, #1BEE02 0.56%, #00AD45 100%)',
      themeColor: '#00AD45',
      textColor: '#fff',
      themeColorLighter: '#1BEE02',
      boxShadow:
        '0px 2px 4px -1px rgba(0, 173, 69, 0.2), 0px 4px 5px 0px rgba(0, 173, 69, 0.14), 0px 1px 10px 0px rgba(0, 173, 69, 0.12)',
    },
    goldCoinTheme: {
      gradient: 'linear-gradient(-135deg, #FFB300 4.17%, #F57C00 96.87%)',
      themeColor: '#F57C00',
      textColor: '#fff',
      themeColorLighter: '#FFB300',
      boxShadow:
        '0px 2px 4px -1px rgba(245, 124, 0, 0.2), 0px 4px 5px 0px rgba(245, 124, 0, 0.14), 0px 1px 10px 0px rgba(245, 124, 0, 0.12)',
    },
    defaultTheme: {
      gradient: 'linear-gradient(-135deg, #FE139C 0%, #C71CB8 100%)',
      themeColor: '#C71CB8',
      textColor: '#fff',
      themeColorLighter: '#FE139C',
      boxShadow:
        '0px 2px 4px -1px rgba(199, 28, 184, 0.2), 0px 4px 5px 0px rgba(199, 28, 184, 0.14), 0px 1px 10px 0px rgba(199, 28, 184, 0.12)',
    },
  },
})

export default lightTheme
