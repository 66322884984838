import { ReactNode } from 'react'
import Header from './Header'
import useStyles from './styles'

type CleanLayoutType = {
  children: ReactNode
}

function CleanLayout(props: CleanLayoutType) {
  const classes = useStyles(props)

  return (
    <>
      <Header />
      <div className={classes.root}>{props.children}</div>
    </>
  )
}

export default CleanLayout
