import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  page: {
    display: 'flex',
    height: '100%',
  },
  logoutBtn: {
    minWidth: 90,
  },

  root: {
    display: 'flex',
    margin: '3rem auto 1rem',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  content: {
    padding: '1.5rem',
    textAlign: 'center',
    width: '100%',
    // mobile
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },

  title: {
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
  },

  text: {
    textAlign: 'left',
    textIndent: '1rem',
    display: 'block',
    marginTop: '0.5rem',
  },
  info: {
    textAlign: 'center',
    marginBottom: '1rem',
  },

  email: {
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  reloadBtn: {
    marginBottom: '1rem',
  },
}))
