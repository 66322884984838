import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    maxWidth: 312,
    padding: '16px 8px 8px',
    width: '100%',
    background: theme.palette.primary.light,
    bottom: '8px',
    left: 0,
    right: 0,
    margin: '0 auto',
    zIndex: 1501,
    display: 'flex',
    flexWrap: 'wrap',
  },
  withArrow: {
    '&:after': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '8px 8px 0 8px',
      borderColor: `${theme.palette.primary.light} transparent transparent transparent`,
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  },
  text: {
    color: theme.palette.common.white,
  },
  button: {
    background: 'none',
    border: '1px solid #fff',
    marginLeft: 'auto',
  },
  close: {
    color: '#fff',
    padding: 0,
    position: 'absolute',
    right: 4,
    top: 2,
    fontSize: '1rem',
  },
  icon: {
    fontSize: '1rem',
  },
}))
