import { LocationData } from 'src/store/modules/appConfig/types'
import { User } from 'src/store/modules/user/types'

export const getCustomerData = (userData: User, location: LocationData, firstDeposit: string) => {
  const address: { [key: string]: string } = {}
  address.city = userData.softKycInfo?.city || location.city
  address.state = userData.softKycInfo?.state || location.state
  address.country = userData.softKycInfo?.country || location.country

  if (userData.softKycInfo?.address) {
    address.address1 = userData.softKycInfo.address
  }
  if (userData.softKycInfo?.address2) {
    address.address2 = userData.softKycInfo.address2
  }
  if (userData.softKycInfo?.zip) {
    address.zip = userData.softKycInfo.zip
  }

  return {
    externalId: userData.id,
    name: `${userData.firstName} ${userData.lastName}`,
    email: userData.realEmail,
    enrollDate: new Date(firstDeposit).valueOf(),
    address,
  }
}
