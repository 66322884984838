export enum SnackbarActionTypes {
  OPEN = 'snackbar/OPEN',
  CLOSE = 'snackbar/CLOSE',
  CLEAR = 'snackbar/CLEAR',
}

export type SnackbarVariants = 'success' | 'warning' | 'error' | 'info'

export type MessageAlign = 'center' | 'flex-end' | 'flex-start' | 'space-between'

export type SnackbarActionButton = {
  text: string
  action: Function
  buttonOptions?: {
    variant?: 'text' | 'outlined' | 'contained'
    size?: 'small' | 'medium' | 'large'
  }
}

export type OpenSnackbarOptions = {
  message: string | Function
  variant?: SnackbarVariants
  action?: SnackbarActionButton
  autoHide?: number
  messageAlign?: MessageAlign
  componentProps?: Record<string, any>
  positionVertical?: 'bottom' | 'top'
  positionHorizontal?: 'center' | 'left' | 'right'
}

export interface OpenSnackbarAction {
  type: SnackbarActionTypes.OPEN
  payload: OpenSnackbarOptions
}

export interface CloseSnackbarAction {
  type: SnackbarActionTypes.CLOSE
}

export interface ClearSnackbarAction {
  type: SnackbarActionTypes.CLEAR
}

export type Actions = OpenSnackbarAction | CloseSnackbarAction | ClearSnackbarAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export type SnackbarState = OpenSnackbarOptions & {
  readonly visible: boolean
}
