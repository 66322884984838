import useStyles from './styles'
import { Typography } from '@material-ui/core'
import cx from 'classnames'

import { getDataFromJson } from 'utils/helpers'
import content from 'translates/en/pwa/ios.json'

const t = getDataFromJson(content)

export default function IOS(props: any) {
  const classes = useStyles(props)

  return (
    <>
      <Typography className={cx(classes.text, classes.withArrow)} align='center' variant='body2'>
        {t('message1')}{' '}
        <svg height='25' width='25' viewBox='0 0 50 50'>
          <path
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeMiterlimit='10'
            strokeWidth='2'
            d='M17 10l8-8 8 8M25 32V2.333'
          />
          <path fill='none' d='M0 0h50v50H0z' />
          <path
            d='M17 17H8v32h34V17h-9'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeMiterlimit='10'
            strokeWidth='2'
          />
        </svg>{' '}
        {t('message2')}
      </Typography>
    </>
  )
}
