import { Reducer } from 'redux'
import { ActionTypes, PhoneVerificationState, Actions } from './types'

// Type-safe initialState
export const initialState: PhoneVerificationState = {
  phoneCode: '',
  phoneNumber: null,
  timerRunning: false,
}

export const phoneVerificationReducer: Reducer<PhoneVerificationState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.SET_OTP: {
      return {
        ...state,
        phoneCode: action.payload.phoneCode,
      }
    }

    case ActionTypes.SET_TIMER_STATE: {
      return {
        ...state,
        timerRunning: action.payload.timerRunning,
      }
    }

    case ActionTypes.SET_PHONE_NUMBER: {
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
      }
    }

    case ActionTypes.CLEAR: {
      return initialState
    }

    default:
      return state
  }
}
