import { makeStyles, Theme } from '@material-ui/core/styles'
import layoutConfig from 'config/layoutConstants.json'

export default makeStyles((theme: Theme) => ({
  root: {
    // @ts-ignore
    background: theme.palette.primary.background,
    position: 'fixed',
    width: '100%',
    height: layoutConfig.mobileMenuHeight,
    left: 0,
    top: 'auto',
    bottom: '0',
    zIndex: 2,
    // fix sizes for ios with notch
    // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
    '@supports (padding-top: constant(safe-area-inset-bottom))': {
      height: `calc(${layoutConfig.mobileMenuHeight}px + var(--safe-area-inset-bottom))`,
      '@media (max-width: 345px)': {
        minHeight: `calc(${layoutConfig.mobileMenuHeight}px + var(--safe-area-inset-bottom))`,
        height: 'auto',
      },
    },

    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    '@supports (padding-top: env(safe-area-inset-bottom))': {
      height: `calc(${layoutConfig.mobileMenuHeight}px + var(--safe-area-inset-bottom))`,
      '@media (max-width: 345px)': {
        minHeight: `calc(${layoutConfig.mobileMenuHeight}px + var(--safe-area-inset-bottom))`,
        height: 'auto',
      },
    },
  },
  navigationButton: {
    '@media (max-width: 340px)': {
      minWidth: '1px',
    },
  },
  GC: {
    color: theme.palette.goldCoinTheme.themeColor,
  },
  SC: {
    color: theme.palette.sweepCoinTheme.themeColor,
  },
  USD: {
    color: 'red',
  },
}))
