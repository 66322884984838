import { Currency } from '../currencies/types'
import { User } from '../user/types'

// be compiled away leaving only the final value in your compiled code.
export enum BonusesFlowActionTypes {
  SAVE_DAILY_BONUSES = 'bonusesFlow/SAVE_DAILY_BONUSES',
  SAVE_MANUAL_BONUSES = 'bonusesFlow/SAVE_MANUAL_BONUSES',
  SAVE_DELAYED_BONUSES = 'bonusesFlow/SAVE_DELAYED_BONUSES',
  SAVE_INVITATION_BONUS = 'bonusesFlow/SAVE_INVITATION_BONUS',
  RECEIVED_ALL_USERS_BONUSES = 'bonusesFlow/RECEIVED_ALL_USERS_BONUSES',
}

export type AcceptBonus = {
  aplied: boolean
  balances: Array<Currency>
  code: string
}

export type BaseBonus = {
  code: string
  goldAmount?: number
  sweepstakeAmount?: number
  fiatAmount?: number
  at: string
}

export type InvitationBonus = {
  category: 'first_purchase'
  account?: User
} & BaseBonus

export type DailyBonus = {
  at: string
  code: string
  goldAmount: number
  offset: number
  eligible: boolean
  sweepstakeAmount: number
  time?: string
}

export interface SaveDailyBonusesAction {
  type: BonusesFlowActionTypes.SAVE_DAILY_BONUSES
  payload: {
    dailyBonuses: DailyBonus[]
  }
}

export interface SaveManualBonusesAction {
  type: BonusesFlowActionTypes.SAVE_MANUAL_BONUSES
  payload: {
    manualBonuses: BaseBonus[]
  }
}

export interface SaveDelayedManualBonuses {
  type: BonusesFlowActionTypes.SAVE_DELAYED_BONUSES
  payload: {
    delayedBonuses: BaseBonus[]
  }
}

export interface SaveInvitatonBonusesAction {
  type: BonusesFlowActionTypes.SAVE_INVITATION_BONUS
  payload: {
    invitationBonuses: InvitationBonus[]
  }
}

export interface ReceivedAllUsersBonusesAction {
  type: BonusesFlowActionTypes.RECEIVED_ALL_USERS_BONUSES
}

export type Actions =
  | SaveDailyBonusesAction
  | SaveManualBonusesAction
  | SaveDelayedManualBonuses
  | SaveInvitatonBonusesAction
  | ReceivedAllUsersBonusesAction

export type BonusesFlowState = {
  readonly dailyBonuses: null | DailyBonus[]
  readonly manualBonuses: null | BaseBonus[]
  readonly delayedBonuses: null | BaseBonus[]
  readonly invitationBonuses: null | InvitationBonus[]
}
