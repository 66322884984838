import { useEffect, FC } from 'react'
import { AppProps } from 'next/app'
import AppContent from 'containers/AppContent'
import HeadApp from 'containers/HeadApp'
import '../public/styles/global.css'
import 'url-search-params-polyfill'
import { wrapper } from 'src/store/'

const _App: FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    // register service worker only on desktop and supported browsers
    if (!window?.ReactNativeWebView && navigator?.serviceWorker) {
      // @ts-ignore
      window.workbox?.register()
    }
  }, [])

  return (
    <>
      <HeadApp />

      <AppContent pageProps={pageProps} Component={Component} />
    </>
  )
}

export default wrapper.withRedux(_App)
