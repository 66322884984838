import { ReactNode } from 'react'
import MaterialButton, { ButtonProps } from '@material-ui/core/Button'
import useStyles from './styles'
import { Currencies } from 'store/modules/currencies/types'

export type ButtonPropsType = Omit<ButtonProps, 'color'>

type Props = {
  children?: ReactNode
  disabled?: boolean
  color?: Currencies
  className?: string
} & ButtonPropsType

const Button = (props: Props) => {
  const classes = useStyles(props)
  const { color, children, className, ...rest } = props
  const themeName = color || 'default'

  return (
    <MaterialButton
      {...rest}
      onClick={props?.onClick}
      classes={{
        root: `${classes.root} ${className} ${!rest.disabled ? classes[themeName] : classes.disabled}`,
      }}
    >
      {children}
    </MaterialButton>
  )
}

export default Button
