import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import useStyles from './styles'
import { Currency } from 'store/modules/currencies/types'
import { Typography, Tooltip } from '@material-ui/core'
import { intlnumberFormat } from 'src/utils/numbers'
import { InformationOutline } from 'mdi-material-ui'

type Props = {
  balances: Currency
}
const SweepToolTipTitle = (props: { t: (arg: string) => string; balances: Currency }) => {
  if (props.balances) {
    return (
      <>
        <Typography align='center' component='p' variant='caption' paragraph>
          {props.t('moreSCDescription')}
        </Typography>
        <Typography align='center' component='p' variant='caption'>
          {props.t('SweepItemTitle1')}: SC
          {intlnumberFormat(props.balances.amount, 'en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
          })}
        </Typography>
        <Typography align='center' component='p' variant='caption'>
          {props.t('SweepItemTitle2')}: SC
          {intlnumberFormat(props.balances.redeemable, 'en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
          })}
        </Typography>
        <Typography align='center' component='p' variant='caption'>
          {props.t('SweepItemTitle3')}: SC
          {intlnumberFormat(props.balances.unplayed, 'en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
          })}
        </Typography>
      </>
    )
  }

  return null
}

const t = getDataFromJson(content)

export default function SweepTooltip(props: Props) {
  const classes = useStyles(props)

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      enterTouchDelay={0}
      leaveTouchDelay={8000}
      title={<SweepToolTipTitle t={t} balances={props.balances} />}
    >
      <InformationOutline className={classes.link} />
    </Tooltip>
  )
}
