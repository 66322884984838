import { WSinstance } from 'services/websocket'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import {
  AppConfigActionTypes,
  ToggleDarkThemeAction,
  SaveAppsConfigsAction,
  SavePasswordPolicyAction,
  SetGoldCurrencyAction,
  SetSweepstakeEnabledAction,
  SetAndroidDeviceIdAction,
  AppItem,
  InitAppAction,
  InitNativeAppAction,
  PasswordPolicyType,
  SetPushTokenAction,
  SetForcementModeAction,
  SetIsOpenPWAMessageAction,
  SaveGaidDataAction,
  SaveLocationDataAction,
  SaveTrackingIdAction,
  LocationData,
} from './types'
import { Currencies } from 'store/modules/currencies/types'
import { ThunkType } from 'store/modules/types'
import { closeAllDialogs, openDialog, sessionDialogsResolver } from '../dialog/actions'
import { DialogNames } from '../dialog/types'
import { sweepstakeEnabledSelector } from './selectors'
import { getItemFormLocalStorage, IS_QUICK_START_OPENED, setDataToLocalStorage } from 'src/utils/localStorage'
import { isMobileScreenSize } from 'src/utils/helpers'
import { scriptInitialization } from 'src/utils/loadScript'
import { v4 as uuid } from 'uuid'

export function toggleDarkTheme(): ToggleDarkThemeAction {
  return {
    type: AppConfigActionTypes.TOGGLE_DARK_THEME,
  }
}

export function initApp(): InitAppAction {
  return {
    type: AppConfigActionTypes.INIT_APP,
  }
}

export function initNativeApp(): InitNativeAppAction {
  return {
    type: AppConfigActionTypes.INIT_NATIVE_APP,
  }
}

export function savePasswordPolicy(passwordPolicy: PasswordPolicyType): SavePasswordPolicyAction {
  return {
    type: AppConfigActionTypes.SAVE_PASSWORD_POLICY,
    payload: {
      passwordPolicy,
    },
  }
}

export function setGoldCurrency(goldCurrency: Currencies): SetGoldCurrencyAction {
  return {
    type: AppConfigActionTypes.SET_GOLD_CURRENCY,
    payload: {
      goldCurrency,
    },
  }
}

export function saveLocationData(location: LocationData): SaveLocationDataAction {
  return {
    type: AppConfigActionTypes.SAVE_LOCATION_DATA,
    payload: {
      location,
    },
  }
}

export function setSweepstakeEnabled(enabled: boolean): SetSweepstakeEnabledAction {
  return {
    type: AppConfigActionTypes.SET_SC_ENABLED,
    payload: {
      enabled,
    },
  }
}

export const togglePWAMessage = (isPWAMessageOpen: boolean): SetIsOpenPWAMessageAction => ({
  type: AppConfigActionTypes.SET_IS_OPEN_PWA_MESSAGE,
  payload: {
    isPWAMessageOpen,
  },
})

export function setAndroidDeviceId(deviceId: string): SetAndroidDeviceIdAction {
  return {
    type: AppConfigActionTypes.SET_ANDROID_DEVICE_ID,
    payload: {
      deviceId,
    },
  }
}

export function saveAppsConfigs(apps: { [key: string]: AppItem }): SaveAppsConfigsAction {
  return {
    type: AppConfigActionTypes.SAVE_APPS_CONFIGS,
    payload: {
      apps,
    },
  }
}

export const setPushToken = (pushToken: string): SetPushTokenAction => ({
  type: AppConfigActionTypes.SET_PUSHTOKEN,
  payload: {
    pushToken,
  },
})

export const setForcementMode = (forcement: string): SetForcementModeAction => ({
  type: AppConfigActionTypes.SET_FORCEMENT_MODE,
  payload: {
    forcement,
  },
})

export const saveGaidData = (advertisingId: string, osVersion: string, appVersion: string): SaveGaidDataAction => ({
  type: AppConfigActionTypes.SAVE_GAID_DATA,
  payload: {
    advertisingId,
    osVersion,
    appVersion,
  },
})

export const saveTrackingId = (trackingId: string): SaveTrackingIdAction => ({
  type: AppConfigActionTypes.SAVE_TRACKING_ID,
  payload: {
    trackingId,
  },
})

export const deeplinkModeHandler =
  (advertisingId: string, osVersion: string, appVersion: string): ThunkType =>
  (dispatch, getState) => {
    const loggedIn = isLoggedInSelector(getState())

    if (loggedIn) {
      dispatch(WSinstance.emitWS({ type: 'SetAccountAttributionInfoRequest', advertisingId, osVersion, appVersion }))
    }
  }

export const openPreviewModePromo =
  (gameDialogName: DialogNames, gameCode: string): ThunkType =>
  (dispatch) => {
    const isMobile = isMobileScreenSize()
    if (!isMobile) {
      dispatch(closeAllDialogs())
    }
    dispatch(openDialog('SC_PREVIEW_DIALOG', { gameDialogName, gameCode }))
  }

export const openQuickTourDialog = (): ThunkType => (dispatch, getState) => {
  const isScAvailable = sweepstakeEnabledSelector(getState())
  const isOpened = getItemFormLocalStorage(IS_QUICK_START_OPENED)
  if (!isOpened && isScAvailable) {
    dispatch(openDialog('CURRENCY_SWITCH_QUICKSTART'))
  }
}

export const openRulesUpdateDialog = (): ThunkType => (dispatch, getState) => {
  const isScAvailable = sweepstakeEnabledSelector(getState())
  const isOpened = getItemFormLocalStorage('RULES_UPDATE_DIALOG_HIDDEN')

  if (!isOpened && isScAvailable && process.env.BRAND_NAME === 'onl') {
    dispatch(sessionDialogsResolver('RULES_UPDATED_DIALOG'))
  }
}
export const captureSeonData = (): ThunkType => (dispatch) => {
  const SESSION_ID = getItemFormLocalStorage('SESSION_ID', false)
  if (SESSION_ID) {
    return null
  }

  return (
    scriptInitialization('https://cdn.seon.io/js/v4/agent.js', 'seon')
      .then(() => {
        const session_id = uuid()
        window.seon.config({
          session_id,
          host: 'deviceinf.com',
          audio_fingerprint: true,
          canvas_fingerprint: true,
          webgl_fingerprint: true,
          // onSuccess(message: string) {
          //   console.log('success', message)
          // },
          onError(message: string) {
            // eslint-disable-next-line
            console.log('error', message)
          },
        })

        window.seon.getBase64Session((data: string) => {
          dispatch(setDataToLocalStorage('SESSION_ID', data, false))
        })
      })
      // eslint-disable-next-line
      .catch(console.log)
  )
}
