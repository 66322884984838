import { RealMoneyCurrencies } from 'src/store/modules/currencies/types'
import { ProvidersData } from 'src/store/modules/redeem/types'

const calculateSum = (amounts: ProvidersData) => {
  const amountsArray = Object.values(amounts).filter((el) => el !== null)

  return amountsArray.reduce((accumulator: number, el: number) => {
    return accumulator + (el !== null ? el : 0)
  }, 0)
}

export const validateRedeemAmount = (
  validationOptions: {
    redeemMax: number
    redeemMin: number
    skrillMaximum: {
      SkrillACH: number
      Skrill: number
    }
    balance: number
    activeCurrency: RealMoneyCurrencies
  },
  amounts: ProvidersData
) => {
  const { redeemMax, redeemMin, balance, skrillMaximum } = validationOptions
  const redeemableSUMM = calculateSum(amounts)
  if (!redeemableSUMM) {
    return 'Prease input redeemable amount'
  }
  if (Boolean(amounts.Skrill > skrillMaximum.Skrill) || Boolean(amounts.SkrillACH > skrillMaximum.SkrillACH)) {
    return `Sorry, maximum redeem amount via ${amounts.SkrillACH ? 'ACH' : 'Skrill'} is ${
      skrillMaximum.Skrill || skrillMaximum.SkrillACH
    }$`
  }
  if (redeemableSUMM < redeemMin) {
    return 'Sorry, the amount you are trying to redeem is below the minimum amount'
  } else if (redeemableSUMM > balance) {
    return 'Sorry, you do not have enough redeemable Sweepstake Coins to make this transaction'
  } else if (redeemableSUMM > redeemMax) {
    return 'Sorry, the amount you are attempting to redeem is more than the maximum available'
  } else {
    return null
  }
}
