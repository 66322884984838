import { LottoPlayedGame } from '../playedGames/types'

export const isScLottoGameFinished = (game: LottoPlayedGame | Array<LottoPlayedGame>) => {
  if (!game) {
    return false
  }
  if (Array.isArray(game)) {
    return game.find((g) => g.currency === 'SC')
  } else {
    return game.currency === 'SC'
  }
}
