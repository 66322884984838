import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    // width: '66%',
    // maxWidth: 300
    width: 'calc(100% - 56px)',
    height: '100%',
  },
  menuItems: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 30,
    height: 30,
    marginLeft: '0.5rem',
  },

  logoLink: {
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  topContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  divider: {
    margin: '1rem',
    marginBottom: '0',
  },

  accountItem: {
    display: 'flex',
  },
  links: {
    overflow: 'auto',
  },
  link: {},
  button: {
    marginTop: '0.5rem',
  },
  navigationButton: {
    '@media (max-width: 345px)': {
      minWidth: '1px',
    },
  },
  activeMenu: {
    color: theme.palette.primary.main,
  },
}))
