import { Dispatch } from 'redux'
import { getUserIdSelector } from 'store/modules/user/selectors'
import { ApplicationState } from 'store/modules/types'

export const LOGGED_OUT_ID = 'logged-out'
export const RATE_US_DIALOG_OPENED = 'RATE_US_DIALOG_OPENED'
export const IS_QUICK_START_OPENED = 'IS_QUICK_START_OPENED'

export const SAVED_UPCOMMING_GAMES = 'SAVED_UPCOMMING_GAMES'
export const NEW_USER_DIALOG_OPEN = 'NEW_USER_DIALOG_OPEN'

export const LAST_DIALOG_OPEN = 'LAST_DIALOG_OPEN'
export const DELAYED_DIALOGS = 'DELAYED_DIALOGS'
export const LS_AUTH_TYPE = 'authType'
export const LS_FIRST_OPEN_SLOT = 'LS_FIRST_OPEN_SLOT'

export const IS_USER_REGISTERED = 'IS_USER_REGISTERED'
export const IS_USER_OPENED_SC_RULES = 'IS_USER_OPENED_SC_RULES'
export const TABLE_GAMES_BANNERS_COUNTER = 'TABLE_GAMES_BANNERS_COUNTER'

export const CLOSE_AUTO_PWA_DIALOG = 'CLOSE_AUTO_PWA_DIALOG'
export const HIDE_INSTALL_PWA_BUTTON = 'HIDE_INSTALL_PWA_BUTTON'
export const INVITE_FRIENDS_HIDDEN = 'INVITE_FRIENDS_HIDDEN'
export const RULES_UPDATE_DIALOG_HIDDEN = 'RULES_UPDATE_DIALOG_HIDDEN'

type LocalStorageKeys =
  | 'howToWinModal'
  | 'REFERRER'
  | 'QUERY'
  | 'AD_FIRST_TIME_OPENED'
  | 'ORDERED_OFFER'
  | 'REDEEM_PROVIDER'
  | 'NOT_ENOUGH_FUNDS'
  | 'SESSION_ID'
  | 'LAST_PAYMENT_METHOD'
  | 'USER_ID'
  | 'WELCOME_EMAIL_DIALOG_TIMER_ID'
  | 'FIRST_UTM'
  | 'LAST_UTM'
  | 'IS_USER_VISITED_FB'
  | 'IS_USER_VISITED_INSTAGRAM'
  | typeof DELAYED_DIALOGS
  | typeof LAST_DIALOG_OPEN
  | typeof NEW_USER_DIALOG_OPEN
  | typeof SAVED_UPCOMMING_GAMES
  | typeof IS_QUICK_START_OPENED
  | typeof LS_AUTH_TYPE
  | typeof IS_USER_REGISTERED
  | typeof LS_FIRST_OPEN_SLOT
  | typeof CLOSE_AUTO_PWA_DIALOG
  | typeof HIDE_INSTALL_PWA_BUTTON
  | typeof RATE_US_DIALOG_OPENED
  | typeof IS_USER_OPENED_SC_RULES
  | typeof TABLE_GAMES_BANNERS_COUNTER
  | typeof INVITE_FRIENDS_HIDDEN
  | typeof RULES_UPDATE_DIALOG_HIDDEN

const generateLsKey = (key: LocalStorageKeys, userId: string | null, personalized: boolean) => {
  const personalizedKey = userId ? `${key}-${userId}` : `${key}-${LOGGED_OUT_ID}`

  return personalized ? personalizedKey : key
}

// returns userID from localStorage or from redux store
function getUserId(state: ApplicationState) {
  if (!process.browser || typeof window === 'undefined') {
    return null
  }

  const userIdFromStore = getUserIdSelector(state)
  const userIdFromLS = window.localStorage?.getItem('USER_ID')

  return userIdFromLS || userIdFromStore
}

export const setDataToLocalStorage =
  (key: LocalStorageKeys, value?: object | string | boolean, personalized = true) =>
  (_dispatch: Dispatch, getState: () => ApplicationState) => {
    const userId = getUserId(getState())
    const lsKey = generateLsKey(key, userId, personalized)

    try {
      window?.localStorage.setItem(lsKey, JSON.stringify(value))
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

export const removeDataFromLocalStorage =
  (key: LocalStorageKeys, personalized = true) =>
  (_dispatch: Dispatch, getState: () => ApplicationState) => {
    const userId = getUserId(getState())
    const lsKey = generateLsKey(key, userId, personalized)

    try {
      window?.localStorage.removeItem(lsKey)
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

export const getItemFormLocalStorage = (key: LocalStorageKeys, personalized = true) => {
  if (!process.browser || Boolean(window?.localStorage) === false) {
    return null
  }

  try {
    const userId = window.localStorage.getItem('USER_ID')
    const lsKey = generateLsKey(key, userId, personalized)

    const data = window.localStorage.getItem(lsKey)

    if (data && data !== 'undefined') {
      const result = JSON.parse(data)

      return result
    }

    return null
  } catch (err) {
    // eslint-disable-next-line
    console.log(err)

    return null
  }
}
