import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  logo: {
    cursor: 'pointer',
    maxHeight: 16,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
}))
