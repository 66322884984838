// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum GamesActionTypes {
  SAVE_GAME_PRODUCTS = 'games/SAVE_GAME_PRODUCTS',
  SAVE_LOTTOS_DRAWS = 'games/SAVE_LOTTOS_DRAWS',
  SAVE_SLOTS = 'games/SAVE_SLOTS',
  CLEAR_GAMES = 'games/CLEAR_GAMES',
  GAMES_IS_LOADED = 'games/GAMES_IS_LOADED',
}

export type LottoPoolItem = { amount: number; jackpot: number }

export type GameTypes =
  | 'table'
  | 'table_games'
  | 'lottery'
  | 'keno'
  | 'video_slots'
  | 'classic_slots'
  | 'slots'
  | 'roulette'
  | 'baccarat'
  | 'blackjack'
  | 'baccarat_new'
  | 'video_poker'

export enum ProductModes {
  GOLD = 'gold',
  SWEEPSTAKE = 'sweepstake',
  FIAT = 'fiat',
  GOLD_SWEEPSTAKE = 'gold_sweepstake',
  GOLD_FIAT = 'gold_fiat',
  FREE = 'free',
}
export type DrawItem = {
  additionalNumbers?: []
  closingDate?: string
  drawDate: string
  id: string
  numbers?: []
  product: string
  status: string
  goldPool: LottoPoolItem
  sweepstakePool: LottoPoolItem
  fiatPool: LottoPoolItem
}

export type DrawsList = {
  product: string
  draws: Array<DrawItem>
}

export type TagsType =
  | 'top'
  | 'new'
  | 'jackpot'
  | 'table'
  | 'HAW'
  | 'prog_jackpot'
  | 'HAL'
  | 'Chr'
  | 'Meg'
  | 'Cla'
  | 'Epi'

export type GameProduct = {
  code: string
  title: string
  provider: 'netent' | 'markortech' | 'pragmatic' | 'relax'
  type: GameTypes
  mode: ProductModes
  unlocked: boolean
  hidden: boolean
  fromLevel: number
  rank: number
  volatility: number
  orientation: 'both' | 'portrait' | 'landscape'
  supplier: string
  moreInfo: boolean

  sweepstakeMinBet: number
  sweepstakeMaxWinMultiplier: number
  sweepstakeMaxBetMultiplier: number

  goldMinBet: number
  goldMaxWinMultiplier: number
  goldMaxBetMultiplier: number
  maxLines?: number

  tags: TagsType[]
}

export interface FormattedLottoDraws {
  [key: string]: DrawsList
}

export type LottoDraws = FormattedLottoDraws | {} | null

export type LottoRuleNames = 'WITH_1_ADDITIONAL_BALL' | 'WITH_2_ADDITIONAL_BALLS'

export type LottoRule = {
  maxNumbers: number
  maxBonus: number
  maxNumbersArrayLength: number
  maxBonusNumbersArrayLength: number
}

export interface SaveGameProductsAction {
  type: GamesActionTypes.SAVE_GAME_PRODUCTS
  payload: {
    games: Record<GameTypes, Array<GameProduct>>
  }
}

export interface SetGamesIsLoadedAction {
  type: GamesActionTypes.GAMES_IS_LOADED
}

export interface SaveLottoDrawsAction {
  type: GamesActionTypes.SAVE_LOTTOS_DRAWS
  payload: {
    lotteryDraws: LottoDraws
  }
}

export interface ClearGamesAction {
  type: GamesActionTypes.CLEAR_GAMES
}

// export enum LottoProductCodes {
//   // normal odds
//   ONL_JACKPOT_HOUR = 'onlJackpotHour',
//   ONL_JACKPOT_MINUTE = 'onlJackpotMinute', // ~ every minute for testing
//   ONL_MADE = 'onlMade',
//   ONL_TAKE3 = 'onlTake3',
//   ONL_JACKPOT = 'onlJackpot',

//   // long odds
//   ONL_MILLIONS = 'onlMillions',
//   ONL_MORNING = 'onlMorning',
//   ONL_AFTERNOON = 'onlAfternoon',
//   ONL_EVENING = 'onlEvening'
// }

export type PayoutTypes = {
  goldPayout?: { [key: string]: string }
  sweepstakePayout?: { [key: string]: string }
  fiatPayout?: { [key: string]: string }
}

export type LottoProductDraws = {
  product: string
  draws: Array<DrawsList>
} & PayoutTypes

export type LottoGameDraws = {
  draws?: Array<DrawItem>
} & PayoutTypes

export type LottoProduct = GameProduct & LottoGameDraws

export type Actions = SaveGameProductsAction | ClearGamesAction | SaveLottoDrawsAction | SetGamesIsLoadedAction

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface GamesState {
  readonly lottoRules: Record<LottoRuleNames, LottoRule>
  readonly games: Record<GameTypes, Array<GameProduct>>
  readonly lotteryDraws: LottoDraws
  readonly gamesIsLoaded: boolean
}
