import { SyntheticEvent } from 'react'
import { sendRNevent } from 'utils/helpers'

export const onExternalLinkClick = <T extends SyntheticEvent>(e: T, link: string) => {
  if (window.ReactNativeWebView) {
    e && e.preventDefault()

    sendRNevent({
      type: 'openLinkInBrowser',
      link,
    })
  }
}

export const onExternalLinkHandler = (href: string) => (e: SyntheticEvent) => onExternalLinkClick(e, href)
