import {
  DialogActionTypes,
  OpenDialogAction,
  CloseAllDialogsAction,
  RemoveDialogByNameAction,
  DialogNames,
  ModalOptions,
  CloseLatestDialogAction,
} from './types'
import { ThunkType } from 'store/modules/types'
import { v4 as uuid } from 'uuid'

import { getDialogStackSelector } from './selectors'
import { trackScreenViewEvent } from 'src/utils/gtag'
import { accumDelayedDialogs } from 'src/store/middlewares/utils'

export const openDialog = (modalName: DialogNames, dialogProps?: any, options?: ModalOptions): OpenDialogAction => {
  trackScreenViewEvent(modalName)

  return {
    type: DialogActionTypes.OPEN_DIALOG,
    payload: {
      modalName,
      dialogProps,
      options,
      id: uuid(),
    },
  }
}

export const replaceDialog =
  (modalName: DialogNames, dialogProps?: any, options?: ModalOptions): ThunkType =>
  (dispatch, getState) => {
    const stack = getDialogStackSelector(getState())

    const idx = stack.findIndex((it) => it.modalName === modalName)
    if (idx !== -1) {
      dispatch({
        type: DialogActionTypes.REPLACE_DIALOG,
        payload: {
          stack: stack.concat({ modalName, dialogProps, options, id: uuid() }).filter((_, index) => index !== idx),
        },
      })
    } else {
      dispatch(openDialog(modalName, dialogProps, options))
    }
  }

export const removeDialogByName = (modalName: DialogNames): RemoveDialogByNameAction => ({
  type: DialogActionTypes.REMOVE_DIALOG_BY_NAME,
  payload: {
    modalName,
  },
})

export const closeLatestDialog = (): CloseLatestDialogAction => ({
  type: DialogActionTypes.CLOSE_LATEST_DIALOG,
})

export const closeAllDialogs = (): CloseAllDialogsAction => ({
  type: DialogActionTypes.CLOSE_ALL,
})

export const sessionDialogsResolver =
  (dialogName: DialogNames, queryParams?: any): ThunkType =>
  (dispatch, getState) => {
    const dialgStack = getDialogStackSelector(getState()).filter((it) =>
      [
        'INVITE_FRIENDS_DIALOG',
        'VIP_SUBSCRIPTION_PROMO_DIALOG',
        'VIP_SUBSCRIPTION',
        'EXTRA_GOLD_DIALOG',
        'NEW_USER_DIALOG',
        'CURRENCY_SWITCH_QUICKSTART',
        'WELCOME_EMAIL_SEND_DIALOG',
        'ORDER_CONFIRMATION_MESSAGE',
        'CURRENCY_SWITCH_QUICKSTART',
        'DAILY_REWARD_DIALOG',
        'REWARD_DIALOG',
      ].includes(it.modalName)
    )
    if (dialgStack && dialgStack.length) {
      dispatch(accumDelayedDialogs(dialogName, queryParams))
    } else {
      dispatch(replaceDialog(dialogName, queryParams))
    }
  }
