import { Action } from 'redux'
import { getItemFormLocalStorage, removeDataFromLocalStorage, SAVED_UPCOMMING_GAMES } from 'src/utils/localStorage'
import { openViewResultsDialog } from '../modules/lottoGameFlow/actions'
import { LottoPlayedGame, PlayedGamesActionTypes } from '../modules/playedGames/types'

function comparer(savedGames: Array<{ product: string; drawId: string }>) {
  return (serverGameCurrent: LottoPlayedGame) => {
    if (!Array.isArray(savedGames)) {
      return false
    }

    return (
      savedGames.filter((other: { product: string; drawId: string }) => {
        return other.drawId === serverGameCurrent.drawId && other.product === serverGameCurrent.product
      }).length === 0
    )
  }
}

export const viewResultsMiddleware = (store: any) => (next: any) => (action: Action & { payload?: any }) => {
  if (action.type === PlayedGamesActionTypes.SAVE_LOTTOS) {
    const savedGames = getItemFormLocalStorage(SAVED_UPCOMMING_GAMES)
    const diffGames = action?.payload?.closed?.filter(comparer(savedGames))
    if (diffGames && diffGames.length > 0) {
      store.dispatch(openViewResultsDialog(diffGames))
      store.dispatch(removeDataFromLocalStorage(SAVED_UPCOMMING_GAMES))
    }
  }

  next(action)
}
