import { Reducer } from 'redux'
import { Actions, BonusesFlowState, BonusesFlowActionTypes } from './types'

const initialState: BonusesFlowState = {
  dailyBonuses: null,
  manualBonuses: null,
  delayedBonuses: null,
  invitationBonuses: null,
}

const reducer: Reducer<BonusesFlowState> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case BonusesFlowActionTypes.SAVE_DAILY_BONUSES: {
      return {
        ...state,
        dailyBonuses: action.payload.dailyBonuses,
      }
    }

    case BonusesFlowActionTypes.SAVE_MANUAL_BONUSES: {
      return {
        ...state,
        manualBonuses: action.payload.manualBonuses,
      }
    }

    case BonusesFlowActionTypes.SAVE_INVITATION_BONUS: {
      return {
        ...state,
        invitationBonuses: action.payload.invitationBonuses,
      }
    }

    case BonusesFlowActionTypes.SAVE_DELAYED_BONUSES: {
      return {
        ...state,
        delayedBonuses: action.payload.delayedBonuses,
      }
    }

    default:
      return state
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as bonusesFlowReducer }
